import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFieldTags: build.query<GetFieldTagsApiResponse, GetFieldTagsApiArg>({
      query: (queryArg) => ({
        url: `/fieldTags`,
        params: { clientId: queryArg.clientId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetFieldTagsApiResponse =
  /** status 200 Returns list of Field Tags */ FieldTag[];
export type GetFieldTagsApiArg = {
  clientId: number;
};
export type FieldTag = {
  fieldTagId?: number;
  fieldTagName?: string | null;
  fieldTagDescription?: string | null;
};
export const { useGetFieldTagsQuery, useLazyGetFieldTagsQuery } =
  injectedRtkApi;
