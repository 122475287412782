import { useMemo } from 'react'
import { getMasteredFieldVariables } from 'Components/TemplateFieldSelection/hooks/templateMasteredFieldsHelper'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { ITemplateMasteredFieldsHelperValues } from 'Components/TemplateFieldSelection/constants/entities/ITemplateMasteredFieldsHelperValues'
import { FieldDataType } from 'Constants/enums/FieldDataType'

export const useTemplateMasteredFields = ({ templateFieldRows }: {
  templateFieldRows: IMediaPlanFieldRow[]
}): ITemplateMasteredFieldsHelperValues => {
  const mediaPlanMasteredFields = useMemo(() => templateFieldRows.filter((item) =>
    item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.FINANCE_BOOKING_CATEGORY_LIST ||
  item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.FINANCE_BUYING_AUDIENCE_LIST ||
  item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.FINANCE_TARGET_AUDIENCE_LIST ||
  item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.FINANCE_PRODUCT_HIERARCHY ||
  item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.FINANCE_STATION_HIERARCHY ||
  item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.COST_BUYING_ROUTE_HIERARCHY
  ), [templateFieldRows])

  const masteredFieldValues = useMemo(
    () => {
      return mediaPlanMasteredFields.reduce((values: ITemplateMasteredFieldsHelperValues, currentField) => ({
        ...values,
        [currentField.clientMediaPlanFieldId]:
        getMasteredFieldVariables(
          { currentField, fields: templateFieldRows }
        )
      }), {})
    },
    [templateFieldRows, mediaPlanMasteredFields]
  )

  return masteredFieldValues
}
