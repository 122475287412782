import React from 'react'
import { Tag } from 'antd'
import { FieldTag } from 'Apis/generated/mediaPlanFieldsApi'

export interface ITagListComponentProps {
  tags: FieldTag[]
}

const TagListComponent: React.FC<ITagListComponentProps> = ({ tags }) => (
  <>
    {tags?.map(tag => (
      <Tag key={tag.fieldTagId}>
        {tag.fieldTagName}
      </Tag>
    ))}
  </>
)

export default TagListComponent
