import { baseApi as api } from '../apis/generated/fieldTagsApi'
import { queryCacheValidTime } from './queryCacheValidTime'

const enhancedRtkApi = api.enhanceEndpoints({
  endpoints: {
    getFieldTags: { keepUnusedDataFor: queryCacheValidTime.getFieldTags }
  }
})
export { enhancedRtkApi as baseApi }

export const {
  useGetFieldTagsQuery,
  useLazyGetFieldTagsQuery
} = enhancedRtkApi
