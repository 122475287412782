const text = `# Cron schedule syntax

## Day
| | |
|---|-----------|
| * | any value |
| , | value list separator |
| - | range of values |
| / | step values |
| 0-59 | allowed values |

---

## Hour
| | |
|---|-----------|
| * | any value |
| , | value list separator |
| - | range of values |
| / | step values |
| 0-23 | allowed values |

---

## Day (month)
| | |
|---|-----------|
| * | any value |
| , | value list separator |
| - | range of values |
| / | step values |
| 1-31 | allowed values |

---

## Month
| | |
|---|-----------|
| * | any value |
| , | value list separator |
| - | range of values |
| / | step values |
| 1-12 | allowed values |
| JAN-DEC | alternative single values |

---

## Day (week)
| | |
|---|-----------|
| * | any value |
| , | value list separator |
| - | range of values |
| / | step values |
| 0-6 | allowed values |
| SUN-SAT | alternative single values |`

export default text
