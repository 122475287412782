import React, { useEffect, useState } from 'react'
import cronstrue from 'cronstrue'
import { Button, Divider, Input, Switch } from 'antd'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { MSLayoutContentRow } from 'mindshare.layout'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import { DataImport } from 'Apis/generated/dataImportsApi'
import { Cron } from 'react-js-cron'
import createImportText from 'Components/tooltipContent/Create Import'

export interface IProps {
  buttons: {
    back: () => void
    save: () => void
  }
  editMode: boolean
  importValues: DataImport
  setImportValues: (newObject: DataImport) => void
}

const CreateImportComponent: React.FC<IProps> = ({
  buttons,
  editMode,
  importValues,
  setImportValues
}) => {
  const [cronExpression, setCronExpression] = useState(importValues.cronSchedule)
  useEffect(() => {
    setCronExpression(importValues.cronSchedule)
  }, [importValues.cronSchedule])

  return (
    <>
      <TitleAndButtonsComponent
        subtitle=''
        title={editMode ? `${importValues.dataImportName}` : 'Create New Import'}
        tooltipText={createImportText}
      />

      <MSLayoutContentRow>
        <div className='form-table create-new-import-form'>
          <div className='form-table-row'>
            <div className='form-table-cell'>Import name</div>
            <div className='form-table-cell'>
              <Input
                data-testid='input-import-name'
                onChange={event => setImportValues({ ...importValues, dataImportName: event.currentTarget.value })}
                value={importValues.dataImportName}
              />
            </div>
          </div>
          <div className='form-table-row'>
            <div className='form-table-cell'>Cron Schedule</div>
            <div className='form-table-cell'>
              <div className='cron'>
                <div data-testid='cron-expression-generator'>
                  <Cron
                    value={importValues.cronSchedule}
                    setValue={(value) => setImportValues({ ...importValues, cronSchedule: value })}
                    clearButton={false}
                  />
                </div>
                <div className='cron-parts'>
                  <InfoCircleOutlined />
                  <span>Double click on a dropdown option to automatically select / unselect a periodicity</span>
                </div>
                <Divider className='cron-divider'>OR</Divider>
                <div className='cron-readable' data-testid='cron-readable'>
                  {`${cronstrue.toString(importValues.cronSchedule, { throwExceptionOnParseError: false })}`}
                </div>
                <Input
                  data-testid='input-import-chron-schedule'
                  onBlur={() => setImportValues({ ...importValues, cronSchedule: cronExpression })}
                  onPressEnter={() => setImportValues({ ...importValues, cronSchedule: cronExpression })}
                  onChange={event => setCronExpression(event.currentTarget.value)}
                  value={cronExpression}
                />
                <div className='cron-parts'>
                  <span>minute</span>
                  <span>hour</span>
                  <span>day (month)</span>
                  <span>month</span>
                  <span>day (week)</span>
                </div>
              </div>
            </div>
          </div>
          <div className='form-table-row'>
            <div className='form-table-cell'>Active</div>
            <div className='form-table-cell'>
              <Switch
                data-testid='isactive-switch'
                checked={importValues.isActive}
                onChange={checked => setImportValues({ ...importValues, isActive: checked })}
                size="small"
              />
            </div>
          </div>
          {
            !editMode && (
              <div className='form-table-row'>
                <div className='form-table-cell' />
                <div className='form-table-cell form-buttons-cell'>
                  <InfoCircleOutlined /><span className="credentialMessage">Please speak to an administrator to configure import credentials</span>
                </div>
              </div>
            )
          }
          <div className='form-table-row'>
            <div className='form-table-cell' />
            <div className='form-table-cell form-buttons-cell'>
              <Button
                onClick={() => buttons.back()}
                type='primary'
              >
                Back
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                data-testid='save-button'
                disabled={importValues.dataImportName.length < 1}
                icon={<EditOutlined />}
                onClick={() => buttons.save()}
                type='primary'
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </MSLayoutContentRow>
    </>
  )
}

export default CreateImportComponent
