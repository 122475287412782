import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    buyingAgencies: build.query<
      BuyingAgenciesApiResponse,
      BuyingAgenciesApiArg
    >({
      query: (queryArg) => ({
        url: `/buyingAgencies`,
        params: { clientId: queryArg.clientId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type BuyingAgenciesApiResponse =
  /** status 200 Returns Buying Agencies per client */ Agency[];
export type BuyingAgenciesApiArg = {
  clientId: number;
};
export type Agency = {
  agencyId?: number;
  agencyName?: string | null;
  agencyDisplayName?: string | null;
  agencyLogoLocation?: string | null;
  agencyFavIconLocation?: string | null;
  agencyLoadingGifLocation?: string | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  tertiaryColor?: string | null;
  quaternaryColor?: string | null;
  quinaryColor?: string | null;
  primaryGradient?: string | null;
  secondaryGradient?: string | null;
  mediaPlanningToolName?: string | null;
};
export const { useBuyingAgenciesQuery, useLazyBuyingAgenciesQuery } =
  injectedRtkApi;
