// App
export const SHOW_ERROR_PAGE = 'SHOW_ERROR_PAGE'
export type SHOW_ERROR_PAGE = typeof SHOW_ERROR_PAGE

export const USER_FORBIDDEN = 'USER_FORBIDDEN'
export type USER_FORBIDDEN = typeof USER_FORBIDDEN

export const ADD_TO_COLLAPSED_FLIGHT_GROUPS = 'ADD_TO_COLLAPSED_FLIGHT_GROUPS'
export type ADD_TO_COLLAPSED_FLIGHT_GROUPS = typeof ADD_TO_COLLAPSED_FLIGHT_GROUPS

export const REMOVE_FROM_COLLAPSED_FLIGHT_GROUPS = 'REMOVE_FROM_COLLAPSED_FLIGHT_GROUPS'
export type REMOVE_FROM_COLLAPSED_FLIGHT_GROUPS = typeof REMOVE_FROM_COLLAPSED_FLIGHT_GROUPS

export const EXPAND_ALL_FLIGHT_GROUPS = 'EXPAND_ALL_FLIGHT_GROUPS'
export type EXPAND_ALL_FLIGHT_GROUPS = typeof EXPAND_ALL_FLIGHT_GROUPS

export const COLLAPSE_ALL_FLIGHT_GROUPS = 'COLLAPSE_ALL_FLIGHT_GROUPS'
export type COLLAPSE_ALL_FLIGHT_GROUPS = typeof COLLAPSE_ALL_FLIGHT_GROUPS

// DataImport
export const GET_DATA_IMPORTS = 'GET_DATA_IMPORTS'
export type GET_DATA_IMPORTS = typeof GET_DATA_IMPORTS

export const GET_DATA_IMPORT = 'GET_DATA_IMPORT'
export type GET_DATA_IMPORT = typeof GET_DATA_IMPORT

export const SAVE_NEW_IMPORT = 'SAVE_NEW_IMPORT'
export type SAVE_NEW_IMPORT = typeof SAVE_NEW_IMPORT

export const UPDATE_EXISTING_IMPORT = 'UPDATE_EXISTING_IMPORT'
export type UPDATE_EXISTING_IMPORT = typeof UPDATE_EXISTING_IMPORT

// Linked Lookups
export const SET_LINKED_LOOKUPS_LIST = 'SET_LINKED_LOOKUPS_LIST'
export type SET_LINKED_LOOKUPS_LIST = typeof SET_LINKED_LOOKUPS_LIST

export const UNSET_LINKED_LOOKUPS_LIST = 'UNSET_LINKED_LOOKUPS_LIST'
export type UNSET_LINKED_LOOKUPS_LIST = typeof UNSET_LINKED_LOOKUPS_LIST

export const ADD_LINKED_LOOKUPS_TO_LIST = 'ADD_LINKED_LOOKUPS_TO_LIST'
export type ADD_LINKED_LOOKUPS_TO_LIST = typeof ADD_LINKED_LOOKUPS_TO_LIST

export const REMOVE_LINKED_LOOKUPS_FROM_LIST = 'REMOVE_LINKED_LOOKUPS_FROM_LIST'
export type REMOVE_LINKED_LOOKUPS_FROM_LIST = typeof REMOVE_LINKED_LOOKUPS_FROM_LIST

export const UPDATE_LINKED_LOOKUPS_LIST = 'UPDATE_LINKED_LOOKUPS_LIST'
export type UPDATE_LINKED_LOOKUPS_LIST = typeof UPDATE_LINKED_LOOKUPS_LIST

// Media Plans
export const GET_MEDIA_PLANS = 'GET_MEDIA_PLANS'
export type GET_MEDIA_PLANS = typeof GET_MEDIA_PLANS

export const SET_CURRENT_MEDIA_PLAN = 'SET_CURRENT_MEDIA_PLAN'
export type SET_CURRENT_MEDIA_PLAN = 'SET_CURRENT_MEDIA_PLAN'

export const SET_MEDIA_PLAN_HISTORY = 'SET_MEDIA_PLAN_HISTORY'
export type SET_MEDIA_PLAN_HISTORY = typeof SET_MEDIA_PLAN_HISTORY

export const SET_PLAN_ERRORS = 'SET_PLAN_ERRORS'
export type SET_PLAN_ERRORS = typeof SET_PLAN_ERRORS

export const SET_SELECTED_CALCULATED_FIELD = 'SET_SELECTED_CALCULATED_FIELD'
export type SET_SELECTED_CALCULATED_FIELD = typeof SET_SELECTED_CALCULATED_FIELD

export const DELETE_MEDIAPLAN_FLIGHT = 'DELETE_MEDIAPLAN_FLIGHT'
export type DELETE_MEDIAPLAN_FLIGHT = typeof DELETE_MEDIAPLAN_FLIGHT

export const DELETE_MEDIAPLAN_SUB_FLIGHT = 'DELETE_MEDIAPLAN_SUB_FLIGHT'
export type DELETE_MEDIAPLAN_SUB_FLIGHT = typeof DELETE_MEDIAPLAN_SUB_FLIGHT

export const MERGE_FLIGHT_PLAN = 'MERGE_FLIGHT_PLAN'
export type MERGE_FLIGHT_PLAN = typeof MERGE_FLIGHT_PLAN

export const MERGE_SUB_FLIGHT_PLAN = 'MERGE_SUB_FLIGHT_PLAN'
export type MERGE_SUB_FLIGHT_PLAN = typeof MERGE_SUB_FLIGHT_PLAN

export const BUILD_FLIGHT_GRID = 'BUILD_FLIGHT_GRID'
export type BUILD_FLIGHT_GRID = typeof BUILD_FLIGHT_GRID

export const DELETE_MEDIA_PLAN = 'DELETE_MEDIA_PLAN'
export type DELETE_MEDIA_PLAN = typeof DELETE_MEDIA_PLAN

export const REMOVE_MEDIA_PLAN_FROM_REPORTING = 'REMOVE_MEDIA_PLAN_FROM_REPORTING'
export type REMOVE_MEDIA_PLAN_FROM_REPORTING = typeof REMOVE_MEDIA_PLAN_FROM_REPORTING

export const ADD_PLAN_FLIGHT_GROUP = 'ADD_PLAN_FLIGHT_GROUP'
export type ADD_PLAN_FLIGHT_GROUP = typeof ADD_PLAN_FLIGHT_GROUP

export const GET_MEDIA_PLAN_TEMPLATES = 'GET_MEDIA_PLAN_TEMPLATES'
export type GET_MEDIA_PLAN_TEMPLATES = typeof GET_MEDIA_PLAN_TEMPLATES

export const GET_MEDIA_PLAN_TEMPLATE_BY_ID = 'GET_MEDIA_PLAN_TEMPLATE_BY_ID'
export type GET_MEDIA_PLAN_TEMPLATE_BY_ID = typeof GET_MEDIA_PLAN_TEMPLATE_BY_ID

export const SET_MEDIA_PLAN_VERSION_BY_ID = 'SET_MEDIA_PLAN_VERSION_BY_ID'
export type SET_MEDIA_PLAN_VERSION_BY_ID = typeof SET_MEDIA_PLAN_VERSION_BY_ID

export const SET_MEDIA_PLAN_VERSION_UNMODIFIED = 'SET_MEDIA_PLAN_VERSION_UNMODIFIED'
export type SET_MEDIA_PLAN_VERSION_UNMODIFIED = typeof SET_MEDIA_PLAN_VERSION_UNMODIFIED

export const SYNC_MEDIA_PLAN_VERSION_UNMODIFIED = 'SYNC_MEDIA_PLAN_VERSION_UNMODIFIED'
export type SYNC_MEDIA_PLAN_VERSION_UNMODIFIED = typeof SYNC_MEDIA_PLAN_VERSION_UNMODIFIED

export const RESET_MEDIA_PLAN_VERSION_UNMODIFIED = 'RESET_MEDIA_PLAN_VERSION_UNMODIFIED'
export type RESET_MEDIA_PLAN_VERSION_UNMODIFIED = typeof RESET_MEDIA_PLAN_VERSION_UNMODIFIED

export const CLONE_MEDIA_PLAN = 'CLONE_MEDIA_PLAN'
export type CLONE_MEDIA_PLAN = typeof CLONE_MEDIA_PLAN

export const CLONE_MEDIA_PLAN_TEMPLATE = 'CLONE_MEDIA_PLAN_TEMPLATE'
export type CLONE_MEDIA_PLAN_TEMPLATE = typeof CLONE_MEDIA_PLAN_TEMPLATE

export const DELETE_MEDIA_PLAN_TEMPLATE = 'DELETE_MEDIA_PLAN_TEMPLATE'
export type DELETE_MEDIA_PLAN_TEMPLATE = typeof DELETE_MEDIA_PLAN_TEMPLATE

export const COLLAPSE_FLIGHT_GROUP = 'COLLAPSE_FLIGHT_GROUP'
export type COLLAPSE_FLIGHT_GROUP = typeof COLLAPSE_FLIGHT_GROUP

export const COLLAPSE_ALL_FLIGHT_GROUP_ROWS = 'COLLAPSE_ALL_FLIGHT_GROUP_ROWS'
export type COLLAPSE_ALL_FLIGHT_GROUP_ROWS = typeof COLLAPSE_ALL_FLIGHT_GROUP_ROWS

export const EXPAND_ALL_FLIGHT_GROUP_ROWS = 'EXPAND_ALL_FLIGHT_GROUP_ROWS'
export type EXPAND_ALL_FLIGHT_GROUP_ROWS = typeof EXPAND_ALL_FLIGHT_GROUP_ROWS

export const INSERT_FLIGHT_GROUPS = 'INSERT_FLIGHT_GROUPS'
export type INSERT_FLIGHT_GROUPS = typeof INSERT_FLIGHT_GROUPS

export const UPSERT_FLIGHT_GROUPS = 'UPSERT_FLIGHT_GROUPS'
export type UPSERT_FLIGHT_GROUPS = typeof UPSERT_FLIGHT_GROUPS

export const DUPLICATE_FLIGHT_GROUP = 'DUPLICATE_FLIGHT_GROUP'
export type DUPLICATE_FLIGHT_GROUP = typeof DUPLICATE_FLIGHT_GROUP

export const DELETE_FLIGHT_GROUP = 'DELETE_FLIGHT_GROUP'
export type DELETE_FLIGHT_GROUP = typeof DELETE_FLIGHT_GROUP

export const RESET_FLIGHT_GROUPS = 'RESET_FLIGHT_GROUPS'
export type RESET_FLIGHT_GROUPS = typeof RESET_FLIGHT_GROUPS

export const SET_SELECTED_FLIGHTS = 'SET_SELECTED_FLIGHTS'
export type SET_SELECTED_FLIGHTS = typeof SET_SELECTED_FLIGHTS

export const SET_SELECTED_DATES = 'SET_SELECTED_DATES'
export type SET_SELECTED_DATES = typeof SET_SELECTED_DATES

export const COPY_FLIGHT = 'COPY_FLIGHT'
export type COPY_FLIGHT = typeof COPY_FLIGHT

export const SET_FLIGHT_PASTE_MODAL_START_DATE = 'SET_FLIGHT_PASTE_MODAL_START_DATE'
export type SET_FLIGHT_PASTE_MODAL_START_DATE = typeof SET_FLIGHT_PASTE_MODAL_START_DATE

export const ADD_CHOSEN_FLIGHT_FIELD = 'ADD_CHOSEN_FLIGHT_FIELD'
export type ADD_CHOSEN_FLIGHT_FIELD = typeof ADD_CHOSEN_FLIGHT_FIELD

export const CALCULATION_ISSUES = 'CALCULATION_ISSUES'
export type CALCULATION_ISSUES = typeof CALCULATION_ISSUES

export const CALCULATIONS_RUNNING = 'CALCULATIONS_RUNNING'
export type CALCULATIONS_RUNNING = typeof CALCULATIONS_RUNNING

export const DISABLED_APPROVAL = 'DISABLED_APPROVAL'
export type DISABLED_APPROVAL = typeof DISABLED_APPROVAL

export const DISABLED_VERSION_CONTROL = 'DISABLED_VERSION_CONTROL'
export type DISABLED_VERSION_CONTROL = typeof DISABLED_VERSION_CONTROL

export const SET_SUBTOTAL_CONFIGURATIONS = 'SET_SUBTOTAL_CONFIGURATIONS'
export type SET_SUBTOTAL_CONFIGURATIONS = typeof SET_SUBTOTAL_CONFIGURATIONS

export const REMOVE_MEDIA_PLAN_FROM_COREM = 'REMOVE_MEDIA_PLAN_FROM_COREM'
export type REMOVE_MEDIA_PLAN_FROM_COREM = typeof REMOVE_MEDIA_PLAN_FROM_COREM

// Media Plan Fields
export const SET_MEDIA_PLAN_FIELD_DATA = 'SET_MEDIA_PLAN_FIELD_DATA'
export type SET_MEDIA_PLAN_FIELD_DATA = typeof SET_MEDIA_PLAN_FIELD_DATA

export const SET_CLIENT_MEDIA_FIELD_DATA = 'SET_CLIENT_MEDIA_FIELD_DATA'
export type SET_CLIENT_MEDIA_FIELD_DATA = typeof SET_CLIENT_MEDIA_FIELD_DATA

export const CLIENT_MEDIA_FIELD_READY = 'CLIENT_MEDIA_FIELD_READY'
export type CLIENT_MEDIA_FIELD_READY = typeof CLIENT_MEDIA_FIELD_READY

export const SET_TEMPLATE_DATA_FIELDS = 'SET_TEMPLATE_DATA_FIELDS'
export type SET_TEMPLATE_DATA_FIELDS = typeof SET_TEMPLATE_DATA_FIELDS

export const TEMPLATE_AVAILABILITY_TYPE = 'TEMPLATE_AVAILABILITY_TYPE'
export type TEMPLATE_AVAILABILITY_TYPE = typeof TEMPLATE_AVAILABILITY_TYPE

export const TEMPLATE_FIELD_TYPE = 'TEMPLATE_FIELD_TYPE'
export type TEMPLATE_FIELD_TYPE = typeof TEMPLATE_FIELD_TYPE

export const TEMPLATE_META_DATA = 'TEMPLATE_META_DATA'
export type TEMPLATE_META_DATA = 'TEMPLATE_META_DATA'

export const SET_PLAN_FIELD_LEVEL_VALUE = 'SET_PLAN_FIELD_LEVEL_VALUE'
export type SET_PLAN_FIELD_LEVEL_VALUE = 'SET_PLAN_FIELD_LEVEL_VALUE'

export const SET_PLAN_FLIGHT_GROUP_VALUE = 'SET_PLAN_FLIGHT_GROUP_VALUE'
export type SET_PLAN_FLIGHT_GROUP_VALUE = 'SET_PLAN_FLIGHT_GROUP_VALUE'

export const SET_PLAN_FLIGHT_LEVEL_VALUE = 'SET_PLAN_FLIGHT_LEVEL_VALUE'
export type SET_PLAN_FLIGHT_LEVEL_VALUE = 'SET_PLAN_FLIGHT_LEVEL_VALUE'

export const PASTE_FLIGHT_TABLE_VALUE = 'PASTE_FLIGHT_TABLE_VALUE'
export type PASTE_FLIGHT_TABLE_VALUE = 'PASTE_FLIGHT_TABLE_VALUE'

export const SET_PLAN_SUB_FLIGHT_LEVEL_VALUE = 'SET_PLAN_SUB_FLIGHT_LEVEL_VALUE'
export type SET_PLAN_SUB_FLIGHT_LEVEL_VALUE = 'SET_PLAN_SUB_FLIGHT_LEVEL_VALUE'

export const SET_TEMPLATE_EXAMPLE_SELECTED_ITEM = 'SET_TEMPLATE_EXAMPLE_SELECTED_ITEM'
export type SET_TEMPLATE_EXAMPLE_SELECTED_ITEM = 'SET_TEMPLATE_EXAMPLE_SELECTED_ITEM'

export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS'
export type GET_ALL_CLIENTS = typeof GET_ALL_CLIENTS

export const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT'
export type SET_CURRENT_CLIENT = typeof SET_CURRENT_CLIENT

export const CLIENTS_LOADED = 'CLIENTS_LOADED'
export type CLIENTS_LOADED = typeof CLIENTS_LOADED

export const ADD_AVAILABLE_FIELD = 'ADD_AVAILABLE_FIELD'
export type ADD_AVAILABLE_FIELD = typeof ADD_AVAILABLE_FIELD

export const UPDATE_MEDIA_PLAN_VERSION_FIELDS = 'UPDATE_MEDIA_PLAN_VERSION_FIELDS'
export type UPDATE_MEDIA_PLAN_VERSION_FIELDS = typeof UPDATE_MEDIA_PLAN_VERSION_FIELDS

export const ADD_FLIGHT_TABLE_VIEW = 'ADD_FLIGHT_TABLE_VIEW'
export type ADD_FLIGHT_TABLE_VIEW = typeof ADD_FLIGHT_TABLE_VIEW

export const REMOVE_FLIGHT_TABLE_VIEW = 'REMOVE_FLIGHT_TABLE_VIEW'
export type REMOVE_FLIGHT_TABLE_VIEW = typeof REMOVE_FLIGHT_TABLE_VIEW

export const DUPLICATE_FLIGHT_TABLE_VIEW = 'DUPLICATE_FLIGHT_TABLE_VIEW'
export type DUPLICATE_FLIGHT_TABLE_VIEW = typeof DUPLICATE_FLIGHT_TABLE_VIEW

export const VALIDATE_OVERLAP_FLIGHTS = 'VALIDATE_OVERLAP_FLIGHTS'
export type VALIDATE_OVERLAP_FLIGHTS = typeof VALIDATE_OVERLAP_FLIGHTS

export const VALIDATE_FLIGHT_DATE = 'VALIDATE_FLIGHT_DATE'
export type VALIDATE_FLIGHT_DATE = typeof VALIDATE_FLIGHT_DATE

export const CLEAN_FLIGHT_GROUP_WARNINGS = 'CLEAN_FLIGHT_GROUP_WARNINGS'
export type CLEAN_FLIGHT_GROUP_WARNINGS = typeof CLEAN_FLIGHT_GROUP_WARNINGS

export const CLEAN_INVALID_FLIGHTS = 'CLEAN_INVALID_FLIGHTS'
export type CLEAN_INVALID_FLIGHTS = typeof CLEAN_INVALID_FLIGHTS

export const CLEAN_FLIGHT_GROUP_WIDTHS = 'CLEAN_FLIGHT_GROUP_WIDTHS'
export type CLEAN_FLIGHT_GROUP_WIDTHS = typeof CLEAN_FLIGHT_GROUP_WIDTHS

export const WEEK_MODE_ENABLED = 'WEEK_MODE_ENABLED'
export type WEEK_MODE_ENABLED = typeof WEEK_MODE_ENABLED

export const SAVE_NEW_UNIQUE_STRING = 'SAVE_NEW_UNIQUE_STRING'
export type SAVE_NEW_UNIQUE_STRING = typeof SAVE_NEW_UNIQUE_STRING

export const CLEAR_CURRENT_MEDIA_PLAN_TEMPLATE = 'CLEAR_CURRENT_MEDIA_PLAN_TEMPLATE'
export type CLEAR_CURRENT_MEDIA_PLAN_TEMPLATE = typeof CLEAR_CURRENT_MEDIA_PLAN_TEMPLATE

export const TOGGLE_CALCULATIONS = 'TOGGLE_CALCULATIONS'
export type TOGGLE_CALCULATIONS = typeof TOGGLE_CALCULATIONS

export const GET_GOAL_SEEK_DATA = 'GET_GOAL_SEEK_DATA'
export type GET_GOAL_SEEK_DATA = typeof GET_GOAL_SEEK_DATA

export const UPDATE_GOAL_SEEK_MODAL_STATE = 'UPDATE_GOAL_SEEK_MODAL_STATE'
export type UPDATE_GOAL_SEEK_MODAL_STATE = typeof UPDATE_GOAL_SEEK_MODAL_STATE

export const TOGGLE_GOAL_SEEK_MODAL_VISIBLE = 'TOGGLE_GOAL_SEEK_MODAL_VISIBLE'
export type TOGGLE_GOAL_SEEK_MODAL_VISIBLE = typeof TOGGLE_GOAL_SEEK_MODAL_VISIBLE

export const REPLACE_SINGLE_FLIGHT_GROUP_SELECTION = 'REPLACE_SINGLE_FLIGHT_GROUP_SELECTION'
export type REPLACE_SINGLE_FLIGHT_GROUP_SELECTION = typeof REPLACE_SINGLE_FLIGHT_GROUP_SELECTION

export const REPLACE_FLIGHT_GROUPS_SELECTION = 'REPLACE_FLIGHT_GROUPS_SELECTION'
export type REPLACE_FLIGHT_GROUPS_SELECTION = typeof REPLACE_FLIGHT_GROUPS_SELECTION

export const CLEAR_FLIGHT_GROUP_SELECTION = 'CLEAR_FLIGHT_GROUP_SELECTION'
export type CLEAR_FLIGHT_GROUP_SELECTION = typeof CLEAR_FLIGHT_GROUP_SELECTION

export const SET_PLAN_SUBTOTALS = 'SET_PLAN_SUBTOTALS'
export type SET_PLAN_SUBTOTALS = typeof SET_PLAN_SUBTOTALS

// Front Sheet
export const INITIALISE_FRONT_SHEET_LIST_CONTAINER = 'INITIALISE_FRONT_SHEET_LIST_CONTAINER'
export type INITIALISE_FRONT_SHEET_LIST_CONTAINER = typeof INITIALISE_FRONT_SHEET_LIST_CONTAINER

export const INITIALISE_FRONT_SHEET_SELECT_FIELDS_CONTAINER = 'INITIALISE_FRONT_SHEET_SELECT_FIELDS_CONTAINER'
export type INITIALISE_FRONT_SHEET_SELECT_FIELDS_CONTAINER = typeof INITIALISE_FRONT_SHEET_SELECT_FIELDS_CONTAINER

export const INITIALISE_FRONT_SHEET_SELECT_PLANS_CONTAINER = 'INITIALISE_FRONT_SHEET_SELECT_PLANS_CONTAINER'
export type INITIALISE_FRONT_SHEET_SELECT_PLANS_CONTAINER = typeof INITIALISE_FRONT_SHEET_SELECT_PLANS_CONTAINER

export const INITIALISE_FRONT_SHEET_VIEW_CONTAINER = 'INITIALISE_FRONT_SHEET_VIEW_CONTAINER'
export type INITIALISE_FRONT_SHEET_VIEW_CONTAINER = typeof INITIALISE_FRONT_SHEET_VIEW_CONTAINER

export const INITIALISE_NEW_FRONT_SHEET_VIEW_CONTAINER = 'INITIALISE_NEW_FRONT_SHEET_VIEW_CONTAINER'
export type INITIALISE_NEW_FRONT_SHEET_VIEW_CONTAINER = typeof INITIALISE_NEW_FRONT_SHEET_VIEW_CONTAINER

export const GET_FRONT_SHEETS = 'GET_FRONT_SHEETS'
export type GET_FRONT_SHEETS = typeof GET_FRONT_SHEETS

export const GET_FRONT_SHEET_BY_ID = 'GET_FRONT_SHEET_BY_ID'
export type GET_FRONT_SHEET_BY_ID = typeof GET_FRONT_SHEET_BY_ID

export const GET_FRONT_SHEET_VIEW = 'GET_FRONT_SHEET_VIEW'
export type GET_FRONT_SHEET_VIEW = typeof GET_FRONT_SHEET_VIEW

export const GET_NEW_FRONT_SHEET_VIEW = 'GET_NEW_FRONT_SHEET_VIEW'
export type GET_NEW_FRONT_SHEET_VIEW = typeof GET_NEW_FRONT_SHEET_VIEW

export const UPDATE_FRONT_SHEET_VIEW_AGGREGATE_DATA = 'UPDATE_FRONT_SHEET_VIEW_AGGREGATE_DATA'
export type UPDATE_FRONT_SHEET_VIEW_AGGREGATE_DATA = typeof UPDATE_FRONT_SHEET_VIEW_AGGREGATE_DATA

export const UPDATE_FRONT_SHEET_VIEW_GROUP_BY_DATA = 'UPDATE_FRONT_SHEET_VIEW_GROUP_BY_DATA'
export type UPDATE_FRONT_SHEET_VIEW_GROUP_BY_DATA = typeof UPDATE_FRONT_SHEET_VIEW_GROUP_BY_DATA

export const UPDATE_FRONT_SHEET_VIEW_NAME = 'UPDATE_FRONT_SHEET_VIEW_NAME'
export type UPDATE_FRONT_SHEET_VIEW_NAME = typeof UPDATE_FRONT_SHEET_VIEW_NAME

export const UPDATE_FRONT_SHEET_VIEW_LAYDOWN_FIELD = 'UPDATE_FRONT_SHEET_VIEW_LAYDOWN_FIELD'
export type UPDATE_FRONT_SHEET_VIEW_LAYDOWN_FIELD = typeof UPDATE_FRONT_SHEET_VIEW_LAYDOWN_FIELD

export const UPDATE_FRONT_SHEET_CHECKED_PLANS = 'UPDATE_FRONT_SHEET_CHECKED_PLANS'
export type UPDATE_FRONT_SHEET_CHECKED_PLANS = typeof UPDATE_FRONT_SHEET_CHECKED_PLANS

export const UPDATE_FRONT_SHEET_NOTES = 'UPDATE_FRONT_SHEET_NOTES'
export type UPDATE_FRONT_SHEET_NOTES = typeof UPDATE_FRONT_SHEET_NOTES

export const ADD_FRONT_SHEET_VIEW = 'ADD_FRONT_SHEET_VIEW'
export type ADD_FRONT_SHEET_VIEW = typeof ADD_FRONT_SHEET_VIEW

export const DELETE_FRONT_SHEET_VIEW = 'DELETE_FRONT_SHEET_VIEW'
export type DELETE_FRONT_SHEET_VIEW = typeof DELETE_FRONT_SHEET_VIEW

export const RESET_CURRENT_FRONT_SHEET_STATE = 'RESET_CURRENT_FRONT_SHEET_STATE'
export type RESET_CURRENT_FRONT_SHEET_STATE = typeof RESET_CURRENT_FRONT_SHEET_STATE

export const UPDATE_FRONT_SHEETS_SELECT_FIELDS_SAVE_STATE = 'UPDATE_FRONT_SHEETS_SELECT_FIELDS_SAVE_STATE'
export type UPDATE_FRONT_SHEETS_SELECT_FIELDS_SAVE_STATE = typeof UPDATE_FRONT_SHEETS_SELECT_FIELDS_SAVE_STATE

export const GET_REDISTRIBUTIONS = 'GET_REDISTRIBUTIONS'
export type GET_REDISTRIBUTIONS = typeof GET_REDISTRIBUTIONS

export const UPDATE_REDISTRIBUTION = 'UPDATE_REDISTRIBUTION'
export type UPDATE_REDISTRIBUTION = typeof UPDATE_REDISTRIBUTION

export const SET_CURRENT_REDISTRIBUTION = 'SET_CURRENT_REDISTRIBUTION'
export type SET_CURRENT_REDISTRIBUTION = typeof SET_CURRENT_REDISTRIBUTION

export const DELETE_REDISTRIBUTION = 'DELETE_REDISTRIBUTION'
export type DELETE_REDISTRIBUTION = typeof DELETE_REDISTRIBUTION

export const SET_SELECTED_REDISTRIBUTE_BY_FIELD = 'SET_SELECTED_REDISTRIBUTE_BY_FIELD'
export type SET_SELECTED_REDISTRIBUTE_BY_FIELD = typeof SET_SELECTED_REDISTRIBUTE_BY_FIELD

export const REMOVE_SELECTED_REDISTRIBUTE_BY_FIELD = 'REMOVE_SELECTED_REDISTRIBUTE_BY_FIELD'
export type REMOVE_SELECTED_REDISTRIBUTE_BY_FIELD = typeof REMOVE_SELECTED_REDISTRIBUTE_BY_FIELD

export const CLEAR_SELECTED_REDISTRIBUTE_BY_FIELDS = 'CLEAR_SELECTED_REDISTRIBUTE_BY_FIELDS'
export type CLEAR_SELECTED_REDISTRIBUTE_BY_FIELDS = typeof CLEAR_SELECTED_REDISTRIBUTE_BY_FIELDS

export const SET_REDISTRIBUTE_MODAL_VISIBLE = 'SET_REDISTRIBUTE_MODAL_VISIBLE'
export type SET_REDISTRIBUTE_MODAL_VISIBLE = typeof SET_REDISTRIBUTE_MODAL_VISIBLE

export const SET_CALCULATION_MESSAGE_KEY = 'SET_CALCULATION_MESSAGE_KEY'
export type SET_CALCULATION_MESSAGE_KEY = typeof SET_CALCULATION_MESSAGE_KEY

export const EXPAND_FLIGHT_GROUP_ROW = 'EXPAND_FLIGHT_GROUP_ROW'
export type EXPAND_FLIGHT_GROUP_ROW = typeof EXPAND_FLIGHT_GROUP_ROW

export const SET_TEMPLATE_ERRORS = 'SET_TEMPLATE_ERRORS'
export type SET_TEMPLATE_ERRORS = typeof SET_TEMPLATE_ERRORS
