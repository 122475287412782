import { ILinkedLookupHelperValues } from '../constants/entities/ILinkedLookupHelperValues'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { LinkedLookup } from 'Apis/generated/linkedLookupsApi'
import { TemplateFieldAvailability } from 'Constants/enums/TemplateFieldAvailability'
import { getFieldLevel, isFieldOfSameOrHigherLevel } from 'Components/LinkedLookup/helpers/fieldHelper'
import { compare } from 'Helpers/sortHelper'

export const getLinkedLookupVariables = ({ currentField, templateFieldRows, linkedLookups }: {
  currentField: IMediaPlanFieldRow
  templateFieldRows: IMediaPlanFieldRow[]
  linkedLookups: LinkedLookup[]
}): ILinkedLookupHelperValues => {
  const linkedLookupConnection = linkedLookups.find(
    item =>
      currentField.mediaPlanField.mediaPlanFieldId === item.dependentMediaPlanFieldId
  )

  const sortedTemplateFieldRows = [...templateFieldRows].sort((f1, f2) => getFieldLevel(f2) - getFieldLevel(f1))

  const dependentMediaPlanField = sortedTemplateFieldRows.find(
    item =>
      item.mediaPlanField.mediaPlanFieldId ===
      linkedLookupConnection?.dependentMediaPlanFieldId &&
      isFieldOfSameOrHigherLevel(item, currentField)
  )

  const referencedMediaPlanField = sortedTemplateFieldRows.find(
    item =>
      item.mediaPlanField.mediaPlanFieldId ===
      linkedLookupConnection?.referencedMediaPlanFieldId &&
      isFieldOfSameOrHigherLevel(item, currentField)
  )

  const closestReferencedMediaPlanField = sortedTemplateFieldRows.find(
    item =>
      item.mediaPlanField.mediaPlanFieldId ===
      linkedLookupConnection?.referencedMediaPlanFieldId &&
      isFieldOfSameOrHigherLevel(item, currentField) &&
      item.defaultValue
  )

  const availableLinkedLookupOptions = linkedLookupConnection?.linkedLookupValues.filter(
    item =>
      item.referencedValueId.toString() ===
      closestReferencedMediaPlanField?.defaultValue?.toString()
  )

  const isEnforcedLink = linkedLookupConnection?.enforced

  const isReferencedFieldMandatory = referencedMediaPlanField?.templateFieldAvailabilityId === TemplateFieldAvailability.MANDATORY

  const isLinkedFieldDisabled = !!(
    !!referencedMediaPlanField &&
    isReferencedFieldMandatory &&
    !referencedMediaPlanField?.defaultValue &&
    !!referencedMediaPlanField?.clientFieldValues &&
    !!dependentMediaPlanField?.clientFieldValues &&
    isEnforcedLink
  )

  const isEnforcedCombinationInvalid = item =>
    isEnforcedLink &&
    isReferencedFieldMandatory &&
    item.mediaPlanField.mediaPlanFieldId ===
    referencedMediaPlanField?.mediaPlanField.mediaPlanFieldId &&
    !referencedMediaPlanField?.defaultValue &&
    !!dependentMediaPlanField?.defaultValue

  return {
    availableLinkedLookupOptions,
    isFilteredLookupValuesVisible: !!closestReferencedMediaPlanField?.defaultValue,
    isLinkedFieldDisabled,
    isEnforcedLink,
    referencedMediaPlanField,
    dependentMediaPlanField,
    linkedLookupConnection,
    isReferencedFieldMandatory,
    isEnforcedCombinationInvalid
  }
}

export const linkedLookupsSorter = (selectedOptions, fieldType: 'referenced' | 'dependent') =>
  (a: LinkedLookup, b: LinkedLookup, sortType: 'ascend' | 'descend') => {
    if (a.linkedLookupId < 0) {
      return sortType === 'ascend' ? 1 : -1
    }
    if (b.linkedLookupId < 0) {
      return sortType === 'ascend' ? -1 : 1
    }
    const compareId = `${fieldType}MediaPlanFieldId`
    const labelA = selectedOptions[a[compareId]]?.label ?? ''
    const labelB = selectedOptions[b[compareId]]?.label ?? ''
    return compare(labelA, labelB)
  }
