import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFieldLevels: build.query<
      GetFieldLevelsApiResponse,
      GetFieldLevelsApiArg
    >({
      query: (queryArg) => ({
        url: `/fieldLevels`,
        params: { clientId: queryArg.clientId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetFieldLevelsApiResponse =
  /** status 200 Returns list of Field Levels */ FieldLevel[];
export type GetFieldLevelsApiArg = {
  clientId: number;
};
export type FieldLevel = {
  fieldLevelId?: number;
  fieldLevelName?: string | null;
};
export const { useGetFieldLevelsQuery, useLazyGetFieldLevelsQuery } =
  injectedRtkApi;
