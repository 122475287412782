import React from 'react'
import { IMenuItems } from 'mindshare.layout'
import {
  BookOutlined,
  FileTextOutlined,
  FileDoneOutlined,
  LineChartOutlined,
  UserAddOutlined,
  ExportOutlined
} from '@ant-design/icons'
import { appPaths } from '../providers/AppRoutes'


const menuItems: IMenuItems[] = [
  {
    icon: <LineChartOutlined />,
    text: 'Plans',
    key: 'plan-list',
    href: appPaths.plansList
  },
  {
    icon: <FileTextOutlined />,
    text: 'Front Sheets',
    key: 'front-sheets',
    href: appPaths.frontSheets
  }
  // {
  //   icon: 'file-text',
  //   text: 'Reports',
  //   key: 'reports',
  //   href: '/reports'
  // }
]

export const templateListItem: IMenuItems = {
  icon: <BookOutlined />,
  text: 'Template List',
  key: 'template-list',
  href: appPaths.templateList
}

export const clientSetupItem: IMenuItems = {
  icon: <UserAddOutlined />,
  text: 'Client Setup',
  key: 'client-data-terms',
  href: appPaths.clientDataTerms
}

export const aliasSetup: IMenuItems = {
  icon: <FileDoneOutlined />,
  text: 'Alias Setup',
  key: 'alias-setup',
  href: '/alias-setup'
}

export const importItem: IMenuItems = {
  icon: <BookOutlined />,
  text: 'Imports',
  key: 'import-list',
  href: appPaths.importList
}
export const customExportsItem: IMenuItems = {
  icon: <ExportOutlined />,
  text: 'Custom exports',
  key: 'custom-export-list',
  href: appPaths.customExports
}

export const reportItem: IMenuItems = {
  icon: <LineChartOutlined />,
  text: 'Reports',
  key: 'report',
  href: appPaths.report
}
export default menuItems
