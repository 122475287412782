import { v4 as uuid } from 'uuid'
import { CustomExportSortByField } from 'Apis/generated/customExportsApi'

export type ISortByField = CustomExportSortByField & { itemRowId?: string }

export const initialSortByField: ISortByField = {
  customExportSortByFieldId: 0,
  sortAscending: true,
  sortOrder: 0,
  customExportId: 0,
  clientMediaPlanFieldId: null,
  customExportControlFieldId: null,
  itemRowId: uuid()
}
