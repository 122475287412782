import React from 'react'
import { Form, Input, FormItemProps } from 'antd'

interface IFormItemInputProps {
  label: string
  name: string
  onChange: (value: any, name: string) => void
  formItemProps?: FormItemProps
}

export const FormItemInput: React.FC<IFormItemInputProps> = ({ name, label, formItemProps, onChange }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      {...formItemProps}
    >
      <Input
        aria-label={name}
        className="ant-input ms-input"
        type="text"
        onChange={(e) => onChange(e.target.value, name)}
      />
    </Form.Item>
  )
}
