import React, { memo } from 'react'
import { IMSHierarchies } from 'mindshare.layout'

import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import PlanLevelFieldContainer from './PlanLevelFieldContainer'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import { IMediaPlanVersionMasteredFieldsHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IClickableFieldProps, useClickableField } from 'Components/MediaPlanVersion/hooks'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IExamplePlanLevel {
  calculationMode: boolean
  mediaPlanFields: IMediaPlanTemplateFields[]
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  unfilteredHierarchies?: IMSHierarchies
  masteredListsData: IMasteredListsData
  tokenHandler: (token: string) => void
  onAvailableFieldSelected: (c: string) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  linkedLookupFieldsHelperValues: IMediaPlanVersionLinkedLookupHelperValues
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  clickableFieldProps: IClickableFieldProps
}

export const PlanLevelFieldsContainer: React.FunctionComponent<IExamplePlanLevel> = (examplePlanLevel: IExamplePlanLevel) => {
  const {
    mediaPlanFields,
    availableMediaPlanFields,
    calculationMode,
    unfilteredHierarchies,
    masteredListsData,
    tokenHandler,
    onAvailableFieldSelected,
    removePlanField,
    linkedLookupFieldsHelperValues,
    masteredDataHelperValues,
    clickableFieldProps
  } = examplePlanLevel

  const { isFieldValidForClick } = useClickableField(clickableFieldProps)

  const sortedMediaPLanFields = mediaPlanFields && [...mediaPlanFields].sort((a, b) => a.sortOrder - b.sortOrder)

  return (
    <>
      <div className='plan-level-container'>
        <div className='container-grid'>
          {
            sortedMediaPLanFields.map(item => {
              const valid = isFieldValidForClick(item)
              return (
                <PlanLevelFieldContainer
                  calculationMode={calculationMode}
                  key={item.mediaPlanTemplateFieldId ? item.mediaPlanTemplateFieldId : item.mediaPlanVersionFieldId}
                  tokenHandler={tokenHandler}
                  valid={valid}
                  masteredListsData={masteredListsData}
                  mediaPlanFields={mediaPlanFields}
                  availableMediaPlanFields={availableMediaPlanFields}
                  onAvailableFieldSelected={onAvailableFieldSelected}
                  unfilteredHierarchies={unfilteredHierarchies}
                  mediaPlanField={item}
                  removePlanField={removePlanField}
                  linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
                  masteredDataHelperValues={masteredDataHelperValues}
                />
              )
            })
          }
        </div>
      </div>
    </>
  )
}

export default memo(PlanLevelFieldsContainer)
