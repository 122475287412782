import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { frontSheetValidationError } from 'Components/shared/constants/entities/IFieldMetaData'
import { IFrontSheetLabel, IFrontSheetsCalendar, IFrontSheetsCalendarFlightCell, IFrontSheetsCalendarFlightGroup, IFrontSheetsCalendarFlightRow } from 'Components/FrontSheets/constants/entities/IFrontSheets'
import { numberFormatter } from './numberHelper'
import FileSaver from 'file-saver'
import { downloadFrontSheetExcel } from 'Services/mediaPlanService'
import { NUMBER_FIELD_DATA_TYPES } from 'Constants/enums/FieldDataType'
import { SubtotalGroup, ViewDisplayRow, ViewDisplayColumn } from 'Apis/generated/frontSheetsApi'

export const validationMessages = {
  [frontSheetValidationError.fieldLevelIdTooLow]: 'Selected Aggregate fields must be at the same level or lower than the subtotal Group By field',
  [frontSheetValidationError.duplicateField]: 'You cannot have duplicates',
  [frontSheetValidationError.laydownDuplicateField]: 'You cannot have a laydown field which is also a group by field',
  [frontSheetValidationError.missingField]: 'Missing field'
}

export const getValidationMessage = (validationError: frontSheetValidationError) => validationMessages[validationError]

const daysDiff = (startDate: Date, endDate: Date) => {
  const diffTime = Math.abs(startDate.getTime() - endDate.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

const addDays = (date: Date, days: number) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

const utcDate = (strDate: string) => {
  const date: Date = new Date(strDate)
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
}

export const frontSheetNumberFormatter = (value: string, column: ViewDisplayColumn) => {
  const dataType = column.clientMediaPlanField.mediaPlanField.fieldDataTypeId
  let formatted = value

  if (NUMBER_FIELD_DATA_TYPES.find(x => x === dataType)) {
    formatted = value === 'Unclassified' ? '' : value
    formatted = numberFormatter(formatted, column.clientMediaPlanField.mediaPlanField.fieldDataTypeId)
  }

  return formatted
}

export const generateCalendarModel = (
  columns: ViewDisplayColumn[],
  laydownField: IClientMediaPlanField,
  subtotalGroups: SubtotalGroup[],
  startDate: string,
  endDate: string,
  grandTotal: string[]
): IFrontSheetsCalendar => {
  const flightGroups: IFrontSheetsCalendarFlightGroup[] = []
  const groupCols: number = columns.filter((x) => x.columnType === 'GroupBy').length
  const aggregateCols: ViewDisplayColumn[] = columns.filter((x) => x.columnType === 'Aggregate')
  const formattedGrandtotal = grandTotal ? aggregateCols.map((aggCol, aggColIndex) =>
    frontSheetNumberFormatter(grandTotal[aggColIndex], aggCol)
  ) : null
  subtotalGroups.forEach((subtotalGroup) => {
    const subtotalGroupRows: IFrontSheetsCalendarFlightRow[] = []
    const subTotals = subtotalGroup.subtotals ? aggregateCols.map((aggCol, aggColIndex) =>
      frontSheetNumberFormatter(subtotalGroup.subtotals?.[aggColIndex], aggCol)
    ) : null

    if (subtotalGroup.rows?.length) {
      subtotalGroup.rows.forEach((row) => {
        const labels: IFrontSheetLabel[] = row.rowValues?.map((value, valueIndex) => ({
          value: frontSheetNumberFormatter(value, columns[valueIndex]),
          isAggregate: valueIndex >= groupCols
        }))

        let flightCells: IFrontSheetsCalendarFlightCell[] = []


        if (row.flights?.length) {
          const startCell = addStartFlight(row, startDate)
          const middleCells = addFlightsAndGaps(row, laydownField)
          const endCell = addEndFight(row, endDate)
          flightCells = [startCell, ...middleCells, endCell]
        }

        // eslint-disable-next-line functional/immutable-data
        subtotalGroupRows.push({ flightCells, labels })
      })
    } else {
      const labels: IFrontSheetLabel[] = columns.map((_, valueIndex) => ({
        value: null,
        isAggregate: valueIndex >= groupCols
      }))
      const flightCells = createDummyFlight(startDate, endDate)

      // eslint-disable-next-line functional/immutable-data
      subtotalGroupRows.push({ flightCells, labels })
    }

    // eslint-disable-next-line functional/immutable-data
    flightGroups.push({ groups: subtotalGroupRows, subTotals })
  })

  return { flightGroups, grandTotal: formattedGrandtotal }
}

const addStartFlight = (row: ViewDisplayRow, startDate: string) => {
  const flightGroupStartDate = utcDate(startDate)
  const firstFlightStartDate = utcDate(row.flights?.[0].flightStartDate)
  const diffInDays = daysDiff(flightGroupStartDate, firstFlightStartDate)
  const startCell: IFrontSheetsCalendarFlightCell = {
    start: flightGroupStartDate,
    end: firstFlightStartDate,
    daysDiff: diffInDays,
    isFlight: false
  }

  return startCell
}

const addFlightsAndGaps = (row: ViewDisplayRow, laydownField: IClientMediaPlanField) => {
  const cells: IFrontSheetsCalendarFlightCell[] = []

  row.flights?.forEach((flight, flightIndex) => {
    const flightStartDate = utcDate(flight?.flightStartDate)
    const flightEndDate = addDays(utcDate(flight.flightEndDate), 1)
    const diff = daysDiff(flightStartDate, flightEndDate)
    // eslint-disable-next-line functional/immutable-data
    cells.push({
      start: flightStartDate,
      end: flightEndDate,
      daysDiff: diff,
      isFlight: true,
      flightIndex,
      value: flight.value ? numberFormatter(flight.value, laydownField.mediaPlanField.fieldDataTypeId) : ''
    })

    if (flightIndex < row.flights?.length - 1) {
      const nextStartDate = utcDate(row.flights?.[flightIndex + 1].flightStartDate)
      const diffEnd = daysDiff(flightEndDate, nextStartDate)
      // eslint-disable-next-line functional/immutable-data
      cells.push({
        start: flightEndDate,
        end: nextStartDate,
        daysDiff: diffEnd,
        isFlight: false
      })
    }
  })
  return cells
}

const addEndFight = (row: ViewDisplayRow, endDate: string) => {
  const lastEndDate = addDays(utcDate(row.flights?.[row.flights.length - 1].flightEndDate), 1)
  const flightGroupEndDate = addDays(utcDate(endDate), 1)
  const diffInDaysEnd = daysDiff(lastEndDate, flightGroupEndDate)
  const endCell: IFrontSheetsCalendarFlightCell = {
    start: lastEndDate,
    end: flightGroupEndDate,
    daysDiff: diffInDaysEnd,
    isFlight: false
  }
  return endCell
}
function createDummyFlight (startDate: string, endDate: string) {
  const diffInDaysEnd = daysDiff(new Date(startDate), new Date(endDate))

  const flightCells: IFrontSheetsCalendarFlightCell[] = [{
    start: new Date(startDate),
    end: new Date(endDate),
    daysDiff: diffInDaysEnd,
    isFlight: false
  }]

  return flightCells
}

export const downloadExcelFrontSheet = async (
  frontSheetName: string,
  frontSheetId: number,
  clientId: number,
  setLoading: (loading: number) => void
) => {
  try {
    setLoading(frontSheetId)
    const file = await downloadFrontSheetExcel(frontSheetId, clientId)
    FileSaver.saveAs(file, `${frontSheetName}-${frontSheetId}`)
  } catch (err) {
    console.error(err)
  } finally {
    setLoading(null)
  }
}
