import * as actionTypes from 'Constants/actionTypes'
import { MediaPlanFieldActions } from 'Actions/mediaPlanFieldActions'
import { IMediaPlanFieldExtended } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'

export interface IMediaPlanFieldsState {
  mediaPlanFields: IMediaPlanFieldExtended[]
}

const initialState: IMediaPlanFieldsState = {
  mediaPlanFields: []
}

export default function (state: IMediaPlanFieldsState = initialState, action: MediaPlanFieldActions): IMediaPlanFieldsState {
  switch (action.type) {
    case actionTypes.SET_MEDIA_PLAN_FIELD_DATA: {
      return {
        ...state,
        mediaPlanFields: action.payload
      }
    }
    default:
      return state
  }
}
