import { GetCustomExportsControlFieldsApiResponse } from 'Apis/generated/customExportsApi'
import { useGetCustomExportsControlFieldsQuery } from 'Apis/enhancedCustomExportsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'
import { useMemo } from 'react'

export const useControlFields = (): UseQueryCommand<GetCustomExportsControlFieldsApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetCustomExportsControlFieldsQuery(
    { clientId: currentClient.id },
    { skip: !currentClient.id }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
