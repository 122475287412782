import React, { CSSProperties, memo, useEffect, useLayoutEffect, useRef, useState } from 'react'
import FrontSheetCalendarBody from './FrontSheetCalendarBody'
import FrontSheetCalendarHeaders from './FrontSheetCalendarHeader'
import { generateCalendarModel as generateCalendarFlightGroups } from 'Helpers/frontSheetHelper'
import { IFrontSheetsCalendar } from 'Components/FrontSheets/constants/entities/IFrontSheets'
import DoubleScrollbar from 'react-double-scrollbar'
import { compare } from 'Helpers/sortHelper'
import { IStickyColumn } from 'Constants/entities/IStickyColumn'
import { FrontSheetViewDisplay } from 'Apis/generated/frontSheetsApi'

interface IProps {
  currentFrontSheetView: FrontSheetViewDisplay
}

export const FrontSheetCalendarComponent: React.FC<IProps> = ({
  currentFrontSheetView
}): React.ReactElement => {
  const [calendarModel, setCalendarModel] = useState<IFrontSheetsCalendar>()
  const { subtotalGroups, startDate, endDate, columns, laydownField, grandTotal } = currentFrontSheetView
  const headersRef = useRef<HTMLTableCellElement[]>([])
  const laydownRef = useRef<HTMLTableCellElement>()
  const [sticky, setSticky] = useState<IStickyColumn[]>([])
  useEffect(() => {
    setSticky([])
    const model = generateCalendarFlightGroups(columns, laydownField, subtotalGroups, startDate, endDate, grandTotal)
    setCalendarModel(model)
  }, [columns, laydownField, subtotalGroups, startDate, endDate, grandTotal])
  useLayoutEffect(() => {
    if (laydownRef.current) {
      // eslint-disable-next-line functional/immutable-data
      headersRef.current = headersRef.current.slice(0, columns.length)
      setSticky([{ index: columns.length, width: laydownRef.current.clientWidth }])
    }
  }, [calendarModel, columns.length])

  const toggleColumnSticky = (index: number) => {
    const element: HTMLElement = headersRef.current[index]

    if (sticky.find(x => x.index === index)) {
      setSticky([...sticky.filter(x => x.index !== index)])
    } else {
      setSticky([...sticky, { index, width: element.clientWidth }])
    }
  }

  const getStickyStyle = (index: number): CSSProperties => {
    const item = sticky.find(x => x.index === index)
    const itemIndex = [...sticky].sort((a, b) => compare(a.index, b.index)).findIndex(x => x.index === index)
    const beforeItems = sticky.filter((x) => x.index < index)
    const total = beforeItems.reduce((a, b) => a + b.width, 0)
    if (item) {
      return {
        left: `${total + itemIndex}px`,
        zIndex: 101
      }
    } else {
      return {}
    }
  }
  return (
    <>
      {
        calendarModel && (
          <div className='msCalendar frontSheets__table'>
            <DoubleScrollbar>
              <table>
                <FrontSheetCalendarHeaders
                  currentFrontSheetView={currentFrontSheetView}
                  headersRef={headersRef}
                  getStickyStyle={getStickyStyle}
                  toggleColumnSticky={toggleColumnSticky}
                  laydownRef={laydownRef} />
                <FrontSheetCalendarBody
                  flightGroups={calendarModel.flightGroups}
                  columns={columns}
                  laydownField={laydownField}
                  getStickyStyle={getStickyStyle}
                  grandTotal={calendarModel.grandTotal} />
              </table>
            </DoubleScrollbar>
          </div>
        )
      }
    </>
  )
}

export default memo(FrontSheetCalendarComponent)
