import React from 'react'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import FieldAvailableComponent from 'Components/FieldAvailableComponent'
import FlightLabel from 'Components/MediaPlanVersion/FlightLabel'

interface IProps {
  id: number
  flightGroupIndex: number
  valid: boolean
  mediaPlanField: IMediaPlanTemplateFields
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  mediaPlanFields: IMediaPlanTemplateFields[]
  onAvailableFieldSelected: (c: string, mediaPlanFields: IMediaPlanTemplateFields[],
    setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void,
    availableFields: IMediaPlanTemplateFields[],
    flightGroupIndex?: number) => void
  setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields, flightGroupIndex?: number) => void
}

export const FlightLevelLabelsComponent: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    id,
    mediaPlanField,
    valid,
    onAvailableFieldSelected,
    availableMediaPlanFields,
    setFunction,
    mediaPlanFields,
    flightGroupIndex,
    removePlanField
  } = props

  if (id !== 0) {
    return (
      <FlightLabel {...{ valid, flightGroupIndex, mediaPlanField, removePlanField }} />
    )
  } else {
    return (
      <div className="flightGroup__field">
        <FieldAvailableComponent
          onAvailableFieldSelected={(selectedValue: string) => onAvailableFieldSelected(selectedValue, mediaPlanFields, setFunction, availableMediaPlanFields, flightGroupIndex)}
          availableMediaPlanFields={availableMediaPlanFields}
        />
      </div>
    )
  }
}

export default FlightLevelLabelsComponent
