import { v4 as uuid } from 'uuid'
import { CustomExportFilter } from 'Apis/generated/customExportsApi'

export type IFilterField =
  | Omit<
  CustomExportFilter,
  'customExportControlFieldId'
  > & { itemRowId?: string }
  | Omit<
  CustomExportFilter,
  'clientMediaPlanFieldId' | 'customExportId'
  > & { itemRowId?: string }

export const initialFilterField: IFilterField = {
  customExportId: 0,
  customExportFilterId: 0,
  clientMediaPlanFieldId: 0,
  customExportControlFieldId: 0,
  fieldDataTypeOperatorId: 0,
  customExportFilterDefaultValue: [],
  sortOrder: 0,
  itemRowId: uuid()
}
