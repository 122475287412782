import { baseApi as api } from '../apis/generated/templatesApi'

export type MediaPlanTemplate = {
  mediaPlanTemplateId?: number
  templateName?: string | null
  createdBy?: string | null
  createdOn?: string
  modifiedBy?: string | null
  modifiedOn?: string
  deletedOn?: string | null
  brandHierarchyId?: number | null
  businessHierarchyId?: number | null
  geographyHierarchyId?: number | null
  mediaHierarchyId?: number | null
  leftAlignNumbersInExcelExport?: boolean
  hideSortOrderInExcelExport?: boolean
}
export type MediaPlanTemplateField = {
  mediaPlanTemplateFieldId?: number
  mediaPlanTemplateId?: number
  clientMediaPlanFieldId?: number
  fieldLabel?: string | null
  displayLabel?: string | null
  defaultValue?: string | null
  calculation?: string | null
  includeLevelsBelowHierarchy?: boolean
  isComplexCalculation?: boolean
  isEditable?: boolean
  isApportionmentField?: boolean
  isFooter?: boolean
  sortOrder?: number
  requiredHierarchyLevelId?: number | null
  approvedBy?: string | null
  approvedOn?: string | null
  templateFieldAvailabilityId?: number
  templateFieldTypeId?: number
  functionId?: number | null
}
export type MediaPlanTemplateWithMediaTemplateFields = {
  mediaPlanTemplateId?: number
  templateName?: string | null
  createdBy?: string | null
  createdOn?: string
  modifiedBy?: string | null
  modifiedOn?: string
  deletedOn?: string | null
  brandHierarchyId?: number | null
  businessHierarchyId?: number | null
  geographyHierarchyId?: number | null
  mediaHierarchyId?: number | null
  leftAlignNumbersInExcelExport?: boolean
  hideSortOrderInExcelExport?: boolean
  mediaPlanTemplateFields?: MediaPlanTemplateField[] | null
}
export type MappingDestinationOptions = 1 | 2 | 3 | 4 | 5 | 100 | 200
export type FieldDataType = {
  fieldDataTypeId?: number
  dataTypeName?: string | null
  dataType?: string | null
  label?: string | null
  mappingDestination?: MappingDestinationOptions
}
export type FieldDataTypeRead = {
  fieldDataTypeId?: number
  dataTypeName?: string | null
  dataType?: string | null
  label?: string | null
  isLookupType?: boolean
  isClientDefinedListOrUniqueString?: boolean
  isHierarchy?: boolean
  isNumeric?: boolean
  isLongInt?: boolean
  isDecimalType?: boolean
  isString?: boolean
  isDate?: boolean
  cSharpDataType?: string | null
  mappingDestination?: MappingDestinationOptions
}
export type FieldLevel = {
  fieldLevelId?: number
  fieldLevelName?: string | null
}
export type FieldTag = {
  fieldTagId?: number
  fieldTagName?: string | null
  fieldTagDescription?: string | null
}
export type MediaPlanFieldLevel = {
  mediaPlanFieldId?: number
  mediaPlanFieldLevelId?: number
  fieldLabel?: string | null
  fieldDataTypeId?: number
  columnName?: string | null
  fieldLevelId?: number
  fieldDescription?: string | null
  isMandatory?: boolean
  sortOrder?: number
  defaultValue?: string | null
  isApportionable?: boolean
  isDefaultApportionmentField?: boolean
  isDefaultApportioned?: boolean
  fieldDataType?: FieldDataType
  fieldLevel?: FieldLevel
  fieldTags?: FieldTag[] | null
}
export type MediaPlanFieldLevelRead = {
  mediaPlanFieldId?: number
  mediaPlanFieldLevelId?: number
  fieldLabel?: string | null
  fieldDataTypeId?: number
  columnName?: string | null
  fieldLevelId?: number
  fieldDescription?: string | null
  isMandatory?: boolean
  sortOrder?: number
  defaultValue?: string | null
  isApportionable?: boolean
  isDefaultApportionmentField?: boolean
  isDefaultApportioned?: boolean
  fieldDataType?: FieldDataTypeRead
  fieldLevel?: FieldLevel
  fieldTags?: FieldTag[] | null
}
export type ClientFieldValue = {
  clientFieldValueId?: number
  clientMediaPlanFieldId?: number
  mediaPlanFieldId?: number
  valueDisplayName?: string | null
  isDisabled?: boolean
  modifiedBy?: string | null
  modifiedOn?: string
  sortOrder?: number
}
export type ClientMediaPlanField = {
  clientMediaPlanFieldId?: number
  mediaPlanFieldId?: number
  mediaPlanFieldLevelId?: number
  fieldLabel?: string | null
  isMandatory?: boolean
  isDisabled?: boolean
  sortOrder?: number
  submittedBy?: string | null
  submittedOn?: string
  approvedBy?: string | null
  approvedOn?: string | null
  isApportioned?: boolean
  isApportionmentField?: boolean
  mediaPlanField?: MediaPlanFieldLevel
  clientFieldValues?: ClientFieldValue[] | null
}
export type ClientMediaPlanFieldRead = {
  clientMediaPlanFieldId?: number
  mediaPlanFieldId?: number
  mediaPlanFieldLevelId?: number
  fieldLabel?: string | null
  isMandatory?: boolean
  isDisabled?: boolean
  sortOrder?: number
  submittedBy?: string | null
  submittedOn?: string
  approvedBy?: string | null
  approvedOn?: string | null
  isApportioned?: boolean
  isApportionmentField?: boolean
  mediaPlanField?: MediaPlanFieldLevelRead
  clientFieldValues?: ClientFieldValue[] | null
}
export type MediaPlanTemplateFieldWithClientMediaPlanField = {
  mediaPlanTemplateFieldId?: number
  mediaPlanTemplateId?: number
  clientMediaPlanFieldId?: number
  displayLabel?: string | null
  fieldLabel?: string | null
  templateFieldAvailabilityId?: number
  templateFieldTypeId?: number
  functionId?: number | null
  defaultValue?: string | null
  calculation?: string | null
  isComplexCalculation?: boolean
  isEditable?: boolean
  isApportionmentField?: boolean
  isFooter?: boolean
  sortOrder?: number
  requiredHierarchyLevelId?: number | null
  includeLevelsBelowHierarchy?: boolean
  approvedBy?: string | null
  approvedOn?: string | null
  token?: string | null
  clientMediaPlanField?: ClientMediaPlanField
}
export type MediaPlanTemplateFieldWithClientMediaPlanFieldRead = {
  mediaPlanTemplateFieldId?: number
  mediaPlanTemplateId?: number
  clientMediaPlanFieldId?: number
  displayLabel?: string | null
  fieldLabel?: string | null
  templateFieldAvailabilityId?: number
  templateFieldTypeId?: number
  functionId?: number | null
  defaultValue?: string | null
  calculation?: string | null
  isComplexCalculation?: boolean
  isEditable?: boolean
  isApportionmentField?: boolean
  isFooter?: boolean
  sortOrder?: number
  requiredHierarchyLevelId?: number | null
  includeLevelsBelowHierarchy?: boolean
  approvedBy?: string | null
  approvedOn?: string | null
  token?: string | null
  clientMediaPlanField?: ClientMediaPlanFieldRead
}
export type MediaPlanTemplateWithMediaTemplateFieldsWithClientMediaPlanField = {
  mediaPlanTemplateId?: number
  templateName?: string | null
  createdBy?: string | null
  createdOn?: string
  modifiedBy?: string | null
  modifiedOn?: string
  deletedOn?: string | null
  brandHierarchyId?: number | null
  businessHierarchyId?: number | null
  geographyHierarchyId?: number | null
  mediaHierarchyId?: number | null
  leftAlignNumbersInExcelExport?: boolean
  hideSortOrderInExcelExport?: boolean
  mediaPlanTemplateFields?:
  | MediaPlanTemplateFieldWithClientMediaPlanField[]
  | null
}
export type MediaPlanTemplateWithMediaTemplateFieldsWithClientMediaPlanFieldRead =
  {
    mediaPlanTemplateId?: number
    templateName?: string | null
    createdBy?: string | null
    createdOn?: string
    modifiedBy?: string | null
    modifiedOn?: string
    deletedOn?: string | null
    brandHierarchyId?: number | null
    businessHierarchyId?: number | null
    geographyHierarchyId?: number | null
    mediaHierarchyId?: number | null
    leftAlignNumbersInExcelExport?: boolean
    hideSortOrderInExcelExport?: boolean
    mediaPlanTemplateFields?:
    | MediaPlanTemplateFieldWithClientMediaPlanFieldRead[]
    | null
  }

// for some reasons, @rtk-query/codegen-openapi cuts this endpoint out of generated types
// so it's added here manually alongside the types it is using
export type GetMediaPlanTemplatesByIdApiResponse =
  /** status 200 Returns a specific Media Plan Template */ MediaPlanTemplateWithMediaTemplateFieldsWithClientMediaPlanFieldRead
export type GetMediaPlanTemplatesByIdApiArg = {
  clientId: number
  id?: number
}


const enhancedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMediaPlanTemplatesById: build.query<
    GetMediaPlanTemplatesByIdApiResponse,
    GetMediaPlanTemplatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/mediaPlanTemplates/${queryArg.id}`,
        params: { clientId: queryArg.clientId }
      })
    })
  })
}).enhanceEndpoints({
  endpoints: {
    mediaPlanTemplates: { providesTags: ['templateList'] },
    postMediaPlanTemplates: { invalidatesTags: ['templateList'] },
    putMediaPlanTemplatesById: { invalidatesTags: ['templateList'] }
  }
})
export { enhancedRtkApi as baseApi }

export const {
  useMediaPlanTemplatesQuery,
  usePostMediaPlanTemplatesMutation,
  usePutMediaPlanTemplatesByIdMutation,
  useGetMediaPlanTemplatesByIdQuery,
  useLazyGetMediaPlanTemplatesByIdQuery
} = enhancedRtkApi
