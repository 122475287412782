import React, { Fragment, FunctionComponent } from 'react'
import { Alert } from 'antd'
import { useMindshareSelector } from 'mindshare.customhooks'
import { IMSHierarchies } from 'mindshare.layout'

import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import FlightTableFieldContainer from 'Containers/MediaPlanVersion/FlightTableFieldContainer'
import { RootState } from '../../redux/reducers'
import { IFlightDate } from 'Components/MediaPlanVersion/constants/entities/IFlightDate'
import FlightTableButtonsComponent from 'Components/MediaPlanVersion/FlightTableButtonsComponent'
import AlertConfirmationComponent from 'Components/AlertConfirmationComponent'
import { removeFlightTableView, duplicateFlightTableView } from 'Actions/mediaPlansActions'
import { useAppDispatch } from '../../store'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import { IClickableFieldProps, useClickableField } from 'Components/MediaPlanVersion/hooks'
import { IMediaPlanVersionMasteredFieldsHelperValues, IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  flightFields: IMediaPlanTemplateFields[]
  availableFlightFields: IMediaPlanTemplateFields[]
  flightGroupIndex: number
  calculationMode: boolean
  collapse: boolean
  unfilteredHierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  tokenHandler: (token: string) => void
  onAvailableFieldSelected: (selected: string) => void
  linkedLookupFieldsHelperValues: IMediaPlanVersionLinkedLookupHelperValues
  clickableFieldProps: IClickableFieldProps
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
  collapsedFlightGroups?: number[]
}

export const FlightTableFieldsContainer: FunctionComponent<IProps> = (props: IProps) => {
  const {
    flightFields,
    unfilteredHierarchies,
    masteredListsData,
    availableFlightFields,
    calculationMode,
    collapse,
    flightGroupIndex,
    onAvailableFieldSelected,
    tokenHandler,
    linkedLookupFieldsHelperValues,
    clickableFieldProps,
    masteredDataHelperValues,
    financeListFieldsData,
    collapsedFlightGroups
  } = props

  const dispatch = useAppDispatch()

  const flightsDates = useMindshareSelector((state: RootState) =>

    state.mediaPlans.currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex]?.flights?.map((flight, index) => ({
      flightStartDate: flight.flightStartDate,
      flightEndDate: flight.flightEndDate,
      mediaPlanFlightId: flight.mediaPlanFlightId,
      merge: flight.merge,
      width: flight.width,
      flightIndex: index
    })).filter(c => c.merge) || []
  ) as IFlightDate[]

  const hasOverlapDates = useMindshareSelector((state: RootState) =>
    state.mediaPlans.flightGroupWarnings.find(x => x.flightGroupIndex === flightGroupIndex)?.hasOverlapFlights || false
  ) as boolean

  const invalidFlightsByFlightGroup = useMindshareSelector((state: RootState) =>
    state.mediaPlans.invalidFlights.filter(i => i.flightGroupIndex === flightGroupIndex)
  ) as Array<{ flightGroupIndex: number; flightIndex: number }>

  const removeFlight = (flightDate: IFlightDate, currentFlightGroupIndex: number) =>
    dispatch(removeFlightTableView(flightDate, currentFlightGroupIndex))

  const duplicateFlight = (flightDate: IFlightDate, currentFlightGroupIndex: number) =>
    dispatch(duplicateFlightTableView(flightDate, currentFlightGroupIndex))

  const InvalidFlightError = ({ invalidFlights, flightDate }) => {
    return (
      invalidFlights.length > 0 &&
      invalidFlights.some(i => i.flightIndex === flightDate.flightIndex) &&
      (
        <Alert
          message='Error'
          description='Flight start date has to be before end date and needs to have a value'
          type='error'
        />
      )
    )
  }

  const EmptyTableFlight = ({ flightFieldsLength }: { flightFieldsLength: number }) => {
    const width = `${flightFieldsLength * 100}px`
    return (
      <td className="flight__tableRow">
        <div data-testid='empty-flight' style={{ maxWidth: `${width}`, minWidth: `${width}` }}></div>
      </td>
    )
  }

  const { isFieldValidForClick } = useClickableField(clickableFieldProps)

  return (
    flightsDates.length === 0 ? (
      <EmptyTableFlight flightFieldsLength={flightFields.length} />
    ) : (
      <td className="flight__tableRow">
        <AlertConfirmationComponent
          showWarning={hasOverlapDates}
          description='Check flights, there are missing or overlapping flight dates'
        />
        {flightsDates.map((flightDate, index) => {
          const result = flightFields.map((flightField: IMediaPlanTemplateFields, i: number) => (
            <div key={`${flightField.clientMediaPlanFieldId}-${flightDate.flightIndex}-${i}`} className="flight__tableCell">
              <FlightTableFieldContainer
                availableMediaPlanFields={availableFlightFields}
                calculationMode={calculationMode}
                masteredListsData={masteredListsData}
                mediaPlanField={flightField}
                onAvailableFieldSelected={onAvailableFieldSelected}
                tokenHandler={tokenHandler}
                valid={isFieldValidForClick(flightField)}
                unfilteredHierarchies={unfilteredHierarchies}
                startDate={flightDate.flightStartDate}
                endDate={flightDate.flightEndDate}
                flightGroupIndex={flightGroupIndex}
                flightIndex={flightDate.flightIndex}
                index={index}
                linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
                masteredDataHelperValues={masteredDataHelperValues}
                financeListFieldsData={financeListFieldsData}
                collapsedFlightGroups={collapsedFlightGroups}
              />
            </div>
          ))
          return (
            <Fragment key={`${flightDate.flightIndex}`}>
              <InvalidFlightError
                invalidFlights={invalidFlightsByFlightGroup}
                flightDate={flightDate}
              />
              <div className="flight__tableCells">
                <>
                  {result}
                  <FlightTableButtonsComponent
                    collapse={collapse}
                    flightGroupIndex={flightGroupIndex}
                    flight={flightDate}
                    removeFlight={removeFlight}
                    duplicateFlight={duplicateFlight}
                  />
                </>
              </div>
            </Fragment>
          )
        })}
      </td>
    )
  )
}

export default FlightTableFieldsContainer
