import React, { useState, ReactNode } from 'react'
import { DndContext } from '@dnd-kit/core'
import type { Coordinates } from '@dnd-kit/utilities'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { ModalProps } from 'antd'
import { DraggableModalComponent } from 'Components/DraggableModalComponent'

const defaultCoordinates = {
  x: 0,
  y: 0
}

export interface IDraggableModalProps extends ModalProps {
  modalBody: ReactNode
}

export const DraggableModalContainer = ({ modalBody, ...props }) => {
  const [{ x: left, y: top }, setCoordinates] = useState<Coordinates>(defaultCoordinates)
  const style = { top, left }

  return (
    <DndContext
      onDragEnd={({ delta }) => {
        setCoordinates(({ x, y }) =>
          ({
            x: x + delta.x,
            y: y + delta.y
          })
        )
      }}
      modifiers={[restrictToWindowEdges]}
    >
      <DraggableModalComponent
        modalBody={modalBody}
        style={style}
        {...props}
      />
    </DndContext>
  )
}

export default DraggableModalContainer
