import { DataNode } from 'rc-tree/lib/interface'

interface TreeDataNode extends DataNode {
  value?: number
}

export const getSelectedChildrenNodes = (hierarchyList: TreeDataNode[], checkedKeys: Array<string|number>) =>
  hierarchyList.reduce((acc, item) =>
    checkedKeys.includes(item.key)
      ? [
        ...acc,
        { title: item.title, value: item.value, key: item.value },
        ...getSelectedChildrenNodes(item.children, checkedKeys)
      ]
      : acc
  , [])

export const getFilteredDataSource = (hierarchyList: TreeDataNode[], selectedValues: TreeDataNode[]) =>
  hierarchyList.reduce((acc, item) => {
    const children = item.children.length ? getFilteredDataSource(item.children, selectedValues) : []
    const isSelected = selectedValues.some((s) => s.value === item.value)

    return isSelected || children.length > 0 ? [...acc, { ...item, children }] : acc
  }, [])

export const getExpandedKeys = (hierarchyList: TreeDataNode[]) =>
  hierarchyList?.reduce((result, item) =>
    item.children?.length > 0 ? [...result, item.key, ...getExpandedKeys(item.children)] : [...result, item.key], [])
