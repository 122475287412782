import { useMemo } from 'react'
import { getMasteredFieldVariables } from 'Components/MediaPlanVersion/helpers/getMasteredFieldVariables'
import { IMediaPlanVersionField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IMediaPlanVersionMasteredFieldsHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { useDeepEqualMemoized } from 'Hooks/useDeepEqualMemoized'

export const usePlanMasteredFieldDataHelperValues = ({ fields, parseData }: {
  fields: IMediaPlanVersionField[]
  parseData: any
}): IMediaPlanVersionMasteredFieldsHelperValues => {
  const mediaPlanMasteredFields = useMemo(
    () =>
      fields.filter(
        item =>
          [
            FieldDataType.FINANCE_BOOKING_CATEGORY_LIST,
            FieldDataType.FINANCE_BUYING_AUDIENCE_LIST,
            FieldDataType.FINANCE_TARGET_AUDIENCE_LIST,
            FieldDataType.FINANCE_PRODUCT_HIERARCHY,
            FieldDataType.FINANCE_STATION_HIERARCHY,
            FieldDataType.COST_BUYING_ROUTE_HIERARCHY
          ].includes(
            item.clientMediaPlanField.mediaPlanField.fieldDataType
              ?.fieldDataTypeId
          )
      ),
    [fields]
  )

  const masteredDataHelperValues = useMemo(
    () => {
      return mediaPlanMasteredFields.reduce((values: IMediaPlanVersionMasteredFieldsHelperValues, currentField) => ({
        ...values,
        [currentField.clientMediaPlanFieldId]:
        getMasteredFieldVariables(
          { currentField, fields, parseData }
        )
      }), {})
    },
    [fields, mediaPlanMasteredFields, parseData]
  )

  const memoizedMasteredDataHelperValues = useDeepEqualMemoized(masteredDataHelperValues)

  return memoizedMasteredDataHelperValues
}
