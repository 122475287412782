import React, { useState, FunctionComponent, ReactNode } from 'react'
import { Button, Popconfirm, message } from 'antd'
import { UnorderedListOutlined } from '@ant-design/icons'

import { ClientFieldValue } from 'Apis/generated/clientFieldAliasApi'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { popoverMessage } from 'Constants/enums/PopoverMessage'
import { saveClientDefinedListValues } from 'Actions/clientMediaPlanFieldActions'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useLinkedLookupsList } from 'Components/LinkedLookup/hooks/useLinkedLookupsList'

import ModalComponent from './ModalComponent'
import SetDropdownValuesComponent from './SetDropdownValuesComponent'

export interface IFieldType {
  mediaplanTerm: IClientMediaPlanField
  clientFieldValues: ClientFieldValue[]
  handleAddDropdown: (value: string, mediaPlanField: IClientMediaPlanField) => void
  handleItemDisabledToggle: (clientFieldValue: ClientFieldValue, clientMediaPlanField: IClientMediaPlanField) => void
  handleItemSort: (clientMediaPlanField: IClientMediaPlanField, direction: 'asc' | 'dsc') => void
  handleItemOrder: (clientFieldValue: ClientFieldValue, clientMediaPlanField: IClientMediaPlanField, direction: 'up' | 'down') => void
}

const FieldTypeComponent: FunctionComponent<IFieldType> = (fieldType: IFieldType) => {
  const { data: currentClient } = useCurrentClient()
  const { data: linkedLookups = [] } = useLinkedLookupsList()
  const currentDataType = fieldType.mediaplanTerm.mediaPlanField.fieldDataType.dataTypeName
  const currentFieldName = fieldType.mediaplanTerm.mediaPlanField.fieldLabel
  const [visible, setVisible] = useState(false)
  const showModal = () => setVisible(true)
  const hideModal = () => setVisible(false)

  const isFieldAssociatedWithLinkedLookups = !!linkedLookups.find(
    l =>
      l.dependentMediaPlanFieldId ===
      fieldType.mediaplanTerm.mediaPlanFieldId ||
    l.referencedMediaPlanFieldId ===
    fieldType.mediaplanTerm.mediaPlanFieldId
  )
  const handleSaveListValues = async () => {
    try {
      await saveClientDefinedListValues(
        fieldType.mediaplanTerm.clientFieldValues,
        fieldType.mediaplanTerm.mediaPlanFieldId,
        currentClient.id
      )
      hideModal()
    } catch (error) {
      if (typeof error === 'string') {
        message.error({ content: error, duration: 10 })
      } else if (error.title) {
        message.error({ content: error.title, duration: 10 })
      }
    }
  }

  if (currentDataType === 'Client defined list' || currentDataType === 'Unique string') {
    const modalTitle: ReactNode = (
      <>
        <h3>Edit values for <b>{currentFieldName}</b> </h3>
        <p className='modal-subtitle'>
          * Changes to this list values will be applied across all levels for the field.
        </p>
      </>
    )
    const modalBody: ReactNode = (
      <div>
        <SetDropdownValuesComponent
          clientFieldValues={fieldType.clientFieldValues}
          mediaPlanField={fieldType.mediaplanTerm}
          handleAddDropdown={fieldType.handleAddDropdown}
          handleItemDisabledToggle={fieldType.handleItemDisabledToggle}
          handleItemSort={fieldType.handleItemSort}
          handleItemOrder={fieldType.handleItemOrder}
        />
      </div>
    )
    const modalFooter: ReactNode = (
      <>
        <Button type='link' onClick={hideModal}>
          Cancel
        </Button>
        <Popconfirm
          cancelText='No'
          okText='Yes'
          onConfirm={handleSaveListValues}
          placement='rightTop'
          disabled={!isFieldAssociatedWithLinkedLookups}
          title={popoverMessage.confirmUpdateClientDefinedList}
        >
          <Button type='primary' onClick={!isFieldAssociatedWithLinkedLookups ? handleSaveListValues : () => {}}>
            Save
          </Button>
        </Popconfirm>
      </>
    )

    return (
      <>
        <ModalComponent
          title={modalTitle}
          modalBody={modalBody}
          onCancel={hideModal}
          visible={visible}
          footer={modalFooter}
        />
        <Button
          onClick={showModal}
          type='link'
          icon={<UnorderedListOutlined />}
        >
          Define List
        </Button>
      </>
    )
  } else {
    return (<span>Manual Input</span>)
  }
}
export default FieldTypeComponent
