import * as actionTypes from 'Constants/actionTypes'
import { MediaPlanTemplatesActions } from 'Actions/mediaPlanTemplatesActions'
import { MediaPlanTemplate, MediaPlanTemplateWithMediaTemplateFields } from 'Apis/generated/templatesApi'

export interface IMediaPlanTemplateState {
  mediaPlanTemplates: MediaPlanTemplate[]
  currentMediaPlanTemplate: MediaPlanTemplateWithMediaTemplateFields
}

const initialState: IMediaPlanTemplateState = {
  mediaPlanTemplates: [],
  currentMediaPlanTemplate: {
    mediaPlanTemplateFields: [],
    mediaPlanTemplateId: 0
  } as MediaPlanTemplate
}

export default function (state: IMediaPlanTemplateState = initialState, action: MediaPlanTemplatesActions): IMediaPlanTemplateState {
  switch (action.type) {
    case actionTypes.GET_MEDIA_PLAN_TEMPLATES: {
      return {
        ...state,
        mediaPlanTemplates: action.payload
      }
    }
    case actionTypes.GET_MEDIA_PLAN_TEMPLATE_BY_ID: {
      return {
        ...state,
        currentMediaPlanTemplate: action.payload
      }
    }
    case actionTypes.CLEAR_CURRENT_MEDIA_PLAN_TEMPLATE: {
      return {
        ...state,
        currentMediaPlanTemplate: initialState.currentMediaPlanTemplate
      }
    }
    default:
      return state
  }
}
