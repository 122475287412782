import { CalendarView } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import * as moment from 'moment'
import { parse, format as formatFns } from 'date-fns'
import { extendMoment } from 'moment-range'

const { range } = extendMoment(moment)
export const dateFormat = 'YYYY-MM-DD'
const cachedFlightWidth = []

export const DEFAULT_FLIGHT_WIDTH = 10

export const formatUnknownDateFormatToIsoString = (date: string | moment.Moment | Date | number) => {
  if (typeof date === 'string') {
    return date.substring(0, 10)
  }
  if (moment.isMoment(date)) {
    return date.format(dateFormat)
  }
  const copiedDate = new Date(typeof date === 'number' ? date : date.valueOf())
  copiedDate.setMinutes(copiedDate.getMinutes() - copiedDate.getTimezoneOffset())
  return copiedDate.toISOString()
}

export const getWeeksBetweenByWeekDay = (startDate: string | moment.Moment | Date, endDate: string | moment.Moment | Date) => {
  const startMoment = moment.utc(formatUnknownDateFormatToIsoString(startDate))
  const endMoment = moment.utc(formatUnknownDateFormatToIsoString(endDate))
  const datesBetween = range(startMoment, endMoment).snapTo('weeks').by('weeks')
  return Array.from(datesBetween).map(w => ({
    flightStartDate: w.isBefore(startMoment) ? startMoment : moment.utc(w.format(dateFormat)),
    flightEndDate: w.endOf('week').isAfter(endMoment) ? endMoment : moment.utc(w.endOf('week').format(dateFormat))
  }))
}

export const breakCurrentFlight = (currentFlight, newFlight) => {
  const daysBetween = range(moment.utc(currentFlight.flightStartDate), moment.utc(currentFlight.flightEndDate))
  let currentDays: string[] = []
  let mergeResult = [newFlight]
  Array.from(daysBetween.by('days')).forEach((day) => {
    const betweenDate = isDateBetween(newFlight.flightStartDate, newFlight.flightEndDate, day)
    if (!betweenDate) {
      currentDays = [...currentDays, day.format(dateFormat)]
    }
    if (currentDays.length > 0 && betweenDate) {
      const flight = { flightStartDate: currentDays[0], flightEndDate: currentDays[currentDays.length - 1] }
      mergeResult = [...mergeResult, flight]
      currentDays = []
    }
  })
  if (currentDays.length > 0) {
    const flight = { flightStartDate: currentDays[0], flightEndDate: currentDays[currentDays.length - 1] }
    mergeResult = [...mergeResult, flight]
    currentDays = []
  }
  // eslint-disable-next-line functional/immutable-data
  mergeResult.sort((current, next) => moment.utc(current.flightStartDate).diff(next.flightStartDate))
  return mergeResult
}

export const getCachedFlightWidth = (startDate: Date | string, endDate: Date | string, defaultWidth: number) => {
  let cachedValue = cachedFlightWidth.find((flight) => flight.startDate === startDate && flight.endDate === endDate)

  if (!cachedValue) {
    const currentFlightBetween = range(moment.utc(startDate), moment.utc(endDate))
    const totalFlightDays = Array.from(currentFlightBetween.by('days')).length
    const flightWidth = defaultWidth * totalFlightDays
    cachedValue = { startDate, endDate, defaultWidth, flightWidth }
    // eslint-disable-next-line functional/immutable-data
    cachedFlightWidth.push(cachedValue)
  }

  return cachedValue.flightWidth
}

export const flightWidthCalculation = (flights, defaultWidth = DEFAULT_FLIGHT_WIDTH) =>
  flights.map((flight) => {
    const flightWidth = getCachedFlightWidth(flight.flightStartDate, flight.flightEndDate, defaultWidth)
    return { ...flight, width: `${flightWidth}px` }
  })


export const subFlightWidthCalculation = (subFlights, defaultWidth = DEFAULT_FLIGHT_WIDTH) =>
  subFlights.map((subFlight) => {
    const flightWidth = getCachedFlightWidth(subFlight.subFlightStartDate, subFlight.subFlightEndDate, defaultWidth)
    return { ...subFlight, width: `${flightWidth}px` }
  })


export const calculateWidthFlight = (flight, defaultWidth = DEFAULT_FLIGHT_WIDTH) => {
  const flightWidth = getCachedFlightWidth(flight.flightStartDate, flight.flightEndDate, defaultWidth)
  return { ...flight, width: `${flightWidth}px` }
}

export const getPixelsPerDay = (calendarView: CalendarView): number => {
  switch (calendarView) {
    case 'week':
      return 10
    case 'week-iso':
      return 10
    case 'week-broadcast':
      return 10
    default:
      return 6
  }
}

export const isWeeklyView = (calendarView: CalendarView): boolean => calendarView.includes('week')

const formatDate = (date) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) {
    month = '0' + month
  }
  if (day.length < 2) {
    day = '0' + day
  }

  return [year, month, day].join('-')
}

export type isDateBetweenType = string | Date | moment.Moment

export const isDateBetween = (startDate: isDateBetweenType, endDate: isDateBetweenType, betweenDate: isDateBetweenType) => {
  const newDate = betweenDate instanceof Date ? formatDate(betweenDate) : betweenDate
  const newEndDate = endDate instanceof Date ? formatDate(endDate) : endDate
  if (moment.utc(newDate).format(dateFormat) === moment.utc(newEndDate).format(dateFormat)) {
    return true
  } else {
    const result = moment.utc(newDate).endOf('day').isBetween(moment.utc(startDate).startOf('day'), moment.utc(endDate).endOf('day'), null, '[]')
    return result
  }
}

export const isDateGap = (startDate: string | Date, endDate: string | Date) => {
  const result = moment.utc(endDate).diff(moment.utc(startDate))
  return result > 0
}

export const isSame = (startDate: Date, endDate: Date) => moment.utc(endDate).isSame(moment.utc(startDate))

export const findFlightBetweenDates = (c: any, flightStartDate: string | Date, flightEndDate: string | Date) => moment.utc(c.flightStartDate).isSame(moment.utc(flightStartDate)) &&
  moment.utc(c.flightEndDate).isSame(moment.utc(flightEndDate))

export const findSubFlightBetweenDates = (c: any, flightStartDate: string | Date, flightEndDate: string | Date) => {
  if (typeof c.subFlightStartDate === 'string' && typeof flightStartDate === 'string') {
    return c.subFlightStartDate === flightStartDate &&
      c.subFlightEndDate === flightEndDate
  } else {
    return c.subFlightStartDate.format(dateFormat) === flightStartDate &&
      c.subFlightEndDate.format(dateFormat) === flightEndDate
  }
}
/**
 * @param {string} dateInput Assumes an entry string in the following format '01-Jan-2020'
 * correspoding with the format 'dd-MMM-yyyy'
 * @returns {string} with the date in format needed 2020-01-20
 * If the library cant parse raises an exception, so I'm supressing and it will return an empty string
 */
export const parseDateStringIntoMediaPlanFormat = (dateInput: string): string => {
  try {
    return formatFns(parse(dateInput, 'dd-MMM-yyyy', new Date()), 'yyyy-MM-dd')
  } catch (e) {
    return ''
  }
}

export const toISOString = (value: moment.Moment | null) => value?.format(dateFormat)

export const toMomentDate = (date?: string): moment.Moment | null => {
  return date ? moment.utc(date) : null
}


export type dateValueType = Date | string | number
export const dayMs = 24 * 60 * 60 * 1000
/* Parse the date treating it as midnight in the local timezone */
export const parseDate = (date: dateValueType): Date => {
  if (date.constructor.name === 'String' &&
    /^\d{4}-\d{2}-\d{2}$/.test(date as string)) {
    // For date only string we have to add time component
    // otherwise it will be treated as UTC, and additionally corrected by adding/removing few hours
    return new Date(`${date}T00:00:00`)
  }
  return new Date(date)
}
export const dateToString = (date: dateValueType) => new Date(date).toLocaleDateString('sv') // Date only in local timezone (Sweden locale matches ISO8601 YYYY-MM-DD)
export const addDays = (date: dateValueType, days: number) => new Date(parseDate(date).getTime() + days * dayMs)
export const dateMin = (a: string, b: string) => moment.min(moment.utc(a), moment.utc(b))
export const dateMax = (a: string, b: string) => moment.max(moment.utc(a), moment.utc(b))
export const dateDifference = (a: dateValueType, b: dateValueType) => (parseDate(a).getTime() - parseDate(b).getTime()) / dayMs

export const weekDays = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
}

export const getCompressedFlightWidth = (width: string): string => `${(parseInt(width, 10) / 3)}px`
