import { baseApi as api } from '../apis/generated/mediaPlanFieldsByLookUpTypeApi'

const enhancedRtkApi = api.enhanceEndpoints({
  endpoints: {
    getMediaPlanFieldsByLookUpType: { providesTags: ['linkedLookupsMediaPlanFields'] }
  }
})
export { enhancedRtkApi as baseApi }

export const {
  useGetMediaPlanFieldsByLookUpTypeQuery
} = enhancedRtkApi
