import React, { useEffect, FunctionComponent, memo, useCallback } from 'react'
import { Input, InputProps } from 'antd'
import { MSCurrencyInput, msIntegerParser, MSNumberInput, MSPercentInput, NumberTypesEnum, isBlank } from 'mindshare.layout'
import { numberFormatter } from 'Helpers/numberHelper'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'

interface IProps extends Omit<InputProps, 'id'> {
  calculationMode?: boolean
  endDate?: string
  error?: string
  fieldTypeClasses?: string
  flightGroupIndex?: number
  flightIndex?: number
  id: number
  mediaPlanField: IMediaPlanTemplateFields | IMediaPlanFieldRow
  newValue?: string | number
  setNewValue?: React.Dispatch<React.SetStateAction<any>>
  startDate?: string
  subFlightIndex?: number
  tokenHandler?: () => void
  updateDataValues?: (selectedValue: string | number) => void
  onClickHandler?: () => void
  placeholder?: string
  updateDataValuesAndRunCalculation?: (selectedValue: string | number) => void
  isTemplate?: boolean
  onChange?: (value: React.ChangeEvent<HTMLInputElement> | string | number) => void
}

export const MediaPlanInputComponent: FunctionComponent<IProps> = ({
  value,
  mediaPlanField,
  fieldTypeClasses,
  calculationMode,
  disabled,
  newValue,
  setNewValue,
  tokenHandler,
  readOnly,
  updateDataValues,
  onClickHandler,
  placeholder,
  type,
  updateDataValuesAndRunCalculation,
  isTemplate,
  onChange
}: IProps) => {
  const valToMeasure = newValue !== undefined ? newValue : value
  const dataTypeName = mediaPlanField.clientMediaPlanField?.mediaPlanField.fieldDataType?.dataTypeName ||
  (mediaPlanField as IMediaPlanFieldRow).mediaPlanField?.fieldDataType?.dataTypeName
  const dataTypeId = mediaPlanField.clientMediaPlanField?.mediaPlanField.fieldDataTypeId ||
  (mediaPlanField as IMediaPlanFieldRow).mediaPlanField?.fieldDataTypeId

  const onInputChange = useCallback((event) => setNewValue(event.target.value), [setNewValue])
  const onInputBlur = useCallback(() => {
    if ((newValue && newValue.toString()) !== (value && value.toString())) {
      updateDataValues(newValue)
    }
  }, [newValue, value, updateDataValues])

  const sharedProps = {
    'data-testid': mediaPlanField.fieldLabel,
    className: fieldTypeClasses,
    onClick: onClickHandler,
    onChange: onChange || (isTemplate ? updateDataValues : updateDataValuesAndRunCalculation),
    disabled,
    value: isBlank(newValue) ? value : newValue,
    readOnly,
    style: { width: '100%' },
    placeholder
  }
  let input = <></>

  useEffect(() => {
    setNewValue && setNewValue(value)
  }, [setNewValue, value])

  switch (dataTypeName) {
    case NumberTypesEnum.INTEGER:
      input = (
        <MSNumberInput { ...sharedProps } parser={msIntegerParser} />
      )
      break
    case NumberTypesEnum.PERCENTAGE: {
      input = (
        <MSPercentInput { ...sharedProps } />
      )
      break
    }
    case NumberTypesEnum.DECIMAL:
      input = (
        <MSNumberInput { ...sharedProps } />
      )
      break
    case NumberTypesEnum.CURRENCY:
      input = (
        <MSCurrencyInput { ...sharedProps } />
      )
      break
    default:
      input = (
        <Input
          { ...sharedProps }

          value={calculationMode ? value : newValue}
          onChange={onInputChange}
          onClick={tokenHandler}
          onBlur={onInputBlur}
          type={type}
        />
      )
      break
  }

  return (
    <>
      {input}
      {/* dummy input used to auto expand width of column */}
      <span className='dummyInput'>{numberFormatter(valToMeasure?.toString(), dataTypeId)}</span>
    </>
  )
}

export default memo(MediaPlanInputComponent)
