import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { message } from 'antd'
import { useMindshareSelector } from 'mindshare.customhooks'
import { RootState } from '../../../redux/reducers'
import { checkPercentageAmount } from 'Helpers/redistributionHelpers'
import {
  createRedistribution,
  updateRedistribution
} from 'Actions/mediaPlansActions'
import { formApiRedistribution } from '../helpers/formApiRedistribution'

export const useRedistributionOnSubmit = () => {
  const mediaPlanId = useMindshareSelector(
    (state: RootState) =>
      state.mediaPlans.currentMediaPlanVersion
        .parseData?.mediaPlanId || ''
  )
  const currentClientId = useMindshareSelector((state: RootState) => state.app.currentClient.id) as number
  const dispatch = useDispatch()

  return useCallback(
    async values => {
      const { redistributionId, redistributeByValues } = values
      const isPercentageAmountValid = checkPercentageAmount(
        redistributeByValues
      )
      const action = redistributionId
        ? updateRedistribution(
          mediaPlanId,
          redistributionId,
          formApiRedistribution(values),
          currentClientId
        )
        : createRedistribution(mediaPlanId, formApiRedistribution(values), currentClientId)

      if (!isPercentageAmountValid) {
        message.error('The sum of percentages should be 100')
      } else {
        const result = await dispatch(action)
        return result
      }
    },
    [dispatch, mediaPlanId, currentClientId]
  )
}
