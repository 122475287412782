import React, { FunctionComponent, CSSProperties } from 'react'
import { Tooltip } from 'antd'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { getCorrectDisplayField, IMediaPlanMetaField, getFieldId } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { numberFormatter } from 'Helpers/numberHelper'
import { SubtotalCalculationResult, FlightGrandTotal } from 'Apis/generated/mediaPlanVersionsApi'
import { getCachedFlightWidth, DEFAULT_FLIGHT_WIDTH, getCompressedFlightWidth } from 'Helpers/calendarHelper'
import { getCalculatedGrandTotalFlights } from 'Components/Subtotals/helpers/subtotalFlightsHelper'

interface IProps {
  sortedFlightGroupFields: IMediaPlanMetaField[]
  getStickyStyle: (type: StickyColumnType, id?: number) => CSSProperties
  subtotals: SubtotalCalculationResult
  flightFields: IMediaPlanMetaField[]
  planStartDate: string
  planEndDate: string
  compressedCalendarView: boolean
}

interface IGrandTotals {
  [key: string]: FlightGrandTotal[]
}

export const SubtotalsGrandTotalRow: FunctionComponent<IProps> = ({ sortedFlightGroupFields, getStickyStyle, subtotals, flightFields, planStartDate, planEndDate, compressedCalendarView }) => {
  if (!subtotals?.grandTotals) return null

  const filteredFlightGroupFields = sortedFlightGroupFields.filter((item) => item.clientMediaPlanField.fieldLabel !== 'FlightGroupId')
  const grandTotals = subtotals.grandTotals.flightGrandTotals.reduce((acc, item) => {
    // eslint-disable-next-line functional/immutable-data
    (acc[item.clientMediaPlanFieldId] = acc[item.clientMediaPlanFieldId] || []).push(item)
    return acc
  }, {}) as IGrandTotals

  return (
    <tfoot>
      <tr className='flightGroup__subtotals -grandTotal' data-testid='flight-group-grand-total'>
        <th className='plus-button-container' rowSpan={5} style={getStickyStyle ? getStickyStyle(StickyColumnType.toolbar) : {} }>
          &nbsp;
          <div className='flightGroup__toolbar -header' />
        </th>

        {filteredFlightGroupFields.map((item, i) => {
          const grandTotal = subtotals.grandTotals.flightGroupGrandTotals
            .find((s) => s.clientMediaPlanFieldId === item.clientMediaPlanField.clientMediaPlanFieldId)?.grandTotal
          const grandTotalFormatted = grandTotal ? numberFormatter(grandTotal, item.clientMediaPlanField.mediaPlanField.fieldDataTypeId) : ''
          const id = getFieldId(item)

          return (
            <th
              data-testid='flight-group-fields'
              rowSpan={5}
              key={item.mediaPlanVersionFieldId}
              className='flightGroup__fields'
              colSpan={i === 0 ? 2 : 1}
              style={getStickyStyle(StickyColumnType.flightGroup, id)}
            >
              <Tooltip placement='bottom' title={grandTotalFormatted}>
                <div>{i === 0 ? 'Grand Total' : grandTotalFormatted}</div>
              </Tooltip>
            </th>
          )
        }
        )}
      </tr>

      <tr className='flight__subtotals -grandTotal' data-testid='flight-grand-total'>
        <th className='flight__itemLabels' style={getStickyStyle(StickyColumnType.flight)}>
          {Object.keys(grandTotals).map((key) => {
            const mediaPlanField = flightFields.find((f) => Number(key) === f.clientMediaPlanField.clientMediaPlanFieldId)

            if (!mediaPlanField) {
              return null
            }

            return (
              <div className='flight__itemLabel' key={Number(key)}>
                <Tooltip placement='bottom' title={getCorrectDisplayField(mediaPlanField)}>
                  {getCorrectDisplayField(mediaPlanField)}
                </Tooltip>
              </div>
            )
          })}
        </th>
        <th rowSpan={4} className='flightHeader__view' style={getStickyStyle(StickyColumnType.flight)}>
          {Object.entries(grandTotals).map(([key, values], idx) => {
            const mediaPlanField = flightFields
              .find((f) => Number(key) === f.clientMediaPlanField.clientMediaPlanFieldId)
            const flightList = getCalculatedGrandTotalFlights(values, planStartDate, planEndDate)

            if (!mediaPlanField) {
              return null
            }

            return (
              <div className='flightHeader__months' key={idx}>
                {flightList.map((v, index) => {
                  const grandTotal = numberFormatter(v.grandTotal, mediaPlanField.clientMediaPlanField.mediaPlanField.fieldDataTypeId)
                  const flightWidth = getCachedFlightWidth(v.startDate, v.endDate, DEFAULT_FLIGHT_WIDTH)
                  const width = compressedCalendarView ? getCompressedFlightWidth(flightWidth) : flightWidth

                  return (
                    <div data-testid='flight-month-container' className='flightHeader__month' style={{ width }} key={`${Number(v.clientMediaPlanFieldId)}-${index}}`}>
                      <Tooltip placement='bottom' title={grandTotal}>
                        {grandTotal}
                      </Tooltip>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </th>
      </tr>
    </tfoot>
  )
}

export default SubtotalsGrandTotalRow
