import { IDefaultSettings, IUserSettings } from 'Constants/entities/IMapSettings'
import { IUserPreferences } from 'Constants/entities/IUserPreferences'

export const updateUserPreferences = (
  settings: IUserSettings,
  values: IUserPreferences,
  mediaPlanTemplateId: number,
  settingKey: string,
  cb: (key: string, data: IDefaultSettings) => void
) => {
  const updatedSettings = Object.entries(values).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: {
      ...settings[key],
      [mediaPlanTemplateId]: value
    }
  }), {})

  cb(settingKey, updatedSettings)
}

export const getUserPreference = (
  settings: IDefaultSettings,
  mediaPlanTemplateId: number
) =>
  !!settings &&
  typeof mediaPlanTemplateId === 'number' &&
  settings[mediaPlanTemplateId]

export const getUserPreferences = (
  settings: IUserSettings,
  mediaPlanTemplateId: number
): IUserPreferences => !!settings &&
  typeof mediaPlanTemplateId === 'number' &&
  Object.entries(settings).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value[mediaPlanTemplateId]
  }), {})
