import React from 'react'
import LoadingClientMappingContainer from 'mindshare.clientmapping'
import { useAppSelector } from '../../store'

const ClientMappingContainer: React.FC = () => {
  const clientId = useAppSelector((state) => state.app.currentClient.id)

  return (
    clientId > 0 ? <LoadingClientMappingContainer clientId={clientId} /> : null
  )
}

export default ClientMappingContainer
