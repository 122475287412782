import React from 'react'
import { RouteProps } from 'react-router-dom'
import { Callback, SilentRenew } from '@mindshare/services'

import NotAuthorised from 'Components/initialisation/NotAuthorised'
import CreateImportLoadingContainer from 'Containers/views/CreateImportContainer'
import FrontSheetListLoadingContainer from 'Containers/views/FrontSheet/FrontSheetListContainer'
import FrontSheetSelectFieldsLoadingContainer from 'Containers/views/FrontSheet/FrontSheetSelectFieldsContainer'
import FrontSheetSelectPlansLoadingContainer from 'Containers/views/FrontSheet/FrontSheetSelectPlansContainer'
import FrontSheetViewLoadingContainer from 'Containers/views/FrontSheet/FrontSheetViewContainer'
import ImportListLoadingContainer from 'Containers/views/ImportListContainer'
import CustomExportListContainer from 'Components/CustomExport/containers/CustomExportListContainer'
import MediaPlanLoadingContainer from 'Containers/views/MediaPlanVersionContainer'
import PlanListLoadingContainer from 'Containers/views/PlanListContainer'
import ClientAdminContainer from 'Containers/views/ClientAdminContainer'
import ClientDataFieldSelectionContainer from 'Containers/views/ClientDataFieldSelectionContainer'
import ClientDataTermsContainer from 'Containers/views/ClientDataTermsContainer'
import UserManagerContainer from 'Containers/views/UserManagerContainer'
import TemplateExampleContainer from 'Containers/views/Template/TemplateExampleContainer'
import TemplateFieldContainer from 'Containers/views/Template/TemplateFieldContainer'
import TemplateListContainer from 'Containers/views/Template/TemplateListContainer'
import ClientMappingContainer from 'Containers/views/ClientMappingContainer'
import ReportContainer from 'Containers/views/ReportContainer'
import AliasSetupContainer from 'Containers/views/AliasSetupContainer'
import { RunCustomExportContainer, CustomExportFieldContainer } from 'Components/CustomExport'
import { LinkedLookupListContainer, LinkedLookupFieldContainer } from 'Components/LinkedLookup'

const aliasSetup = '/alias-setup'
const plansList = '/plan-list'
const clientDataTerms = '/client-data-terms'
const clientSetup = '/client-setup'
const templateList = '/template-list'
const templates = '/template-create'
const templateEdit = '/template-edit'
const templateExample = '/template-example'
const importList = '/import-list'
const frontSheets = '/front-sheets'
const report = '/report'
const customExports = '/custom-export-list'
const fieldConnections = '/client-data-terms/field-connections'

// TODO - standardise how paths are used in this file
export const appPaths = {
  clientDataTerms,
  templates,
  templateExample,
  templateList,
  plansList,
  clientSetup,
  templateEdit,
  importList,
  frontSheets,
  report,
  customExports,
  fieldConnections
}


export const appRoutes: RouteProps[] = [{
  exact: true,
  path: '/callback',
  component: Callback
}, {
  exact: true,
  path: '/notauthorised',
  component: NotAuthorised
}, {
  exact: true,
  path: '/silentrenew',
  component: SilentRenew
},
{
  exact: true,
  path: '/user-manager',
  component: UserManagerContainer
},
// Media Plans
{
  exact: true,
  path: [plansList, '/'],
  component: PlanListLoadingContainer
}, {
  path: '/mediaplanversion/:mediaPlanVersionId',
  component: ({ match }) => <MediaPlanLoadingContainer mediaPlanVersionId={Number(match.params.mediaPlanVersionId)} />
},
// Front sheets
{
  exact: true,
  path: frontSheets,
  component: FrontSheetListLoadingContainer
},
{
  exact: true,
  path: '/create-front-sheet',
  component: FrontSheetSelectPlansLoadingContainer
}, {
  path: '/view-front-sheet/:frontSheetId',
  component: ({ match }) => <FrontSheetViewLoadingContainer frontSheetId={Number(match.params.frontSheetId)} />
}, {
  exact: true,
  path: '/edit-front-sheet/:frontSheetId',
  component: ({ match }) => <FrontSheetSelectPlansLoadingContainer frontSheetId={Number(match.params.frontSheetId)} />
}, {
  exact: true,
  path: '/edit-front-sheet/select-fields/:frontSheetId',
  component: ({ match }) => <FrontSheetSelectFieldsLoadingContainer frontSheetId={Number(match.params.frontSheetId)} />
},
// Imports
{
  exact: true,
  path: importList,
  component: ImportListLoadingContainer
}, {
  exact: true,
  path: '/create-import',
  component: CreateImportLoadingContainer
}, {
  exact: true,
  path: '/edit-import/:importId',
  component: ({ match }) => <CreateImportLoadingContainer dataImportId={Number(match.params.importId)} />
},
// Templates
{
  exact: true,
  path: templateList,
  component: TemplateListContainer
}, {
  exact: true,
  path: templates,
  component: TemplateFieldContainer
}, {
  exact: true,
  path: `${templateEdit}/:templateId`,
  component: ({ match }) => <TemplateFieldContainer templateId={Number(match.params.templateId)} />
}, {
  path: `${templateExample}/:mediaPlanTemplateId`,
  component: ({ match }) => <TemplateExampleContainer mediaPlanTemplateId={Number(match.params.mediaPlanTemplateId)} />
},
// Client
{
  exact: true,
  path: clientSetup,
  component: ClientDataFieldSelectionContainer
}, {
  exact: true,
  path: clientDataTerms,
  component: ClientDataTermsContainer
}, {
  exact: true,
  path: aliasSetup,
  component: AliasSetupContainer
},
{
  exact: true,
  path: '/clientadmin',
  component: ClientAdminContainer
},
{
  exact: true,
  path: '/clientmapping',
  component: ClientMappingContainer
},
{
  exact: true,
  path: fieldConnections,
  component: LinkedLookupListContainer
},
{
  exact: true,
  path: `${fieldConnections}/:linkedLookupId/values`,
  component: ({ match }) => <LinkedLookupFieldContainer linkedLookupId={Number(match.params.linkedLookupId)} />
},
// Report
{
  exact: true,
  path: report,
  component: ReportContainer
},
// Custom Export
{
  exact: true,
  path: customExports,
  component: CustomExportListContainer
},
{
  exact: true,
  path: '/custom-export',
  component: CustomExportFieldContainer
},
{
  exact: true,
  path: '/custom-export/:customExportId',
  component: ({ match }) => <CustomExportFieldContainer customExportId={Number(match.params.customExportId)} />
},
{
  exact: true,
  path: '/custom-export/:customExportId/run',
  component: ({ match }) => <RunCustomExportContainer customExportId={Number(match.params.customExportId)} />
}
]
