import { Col, Row } from 'antd'
import React, { FC } from 'react'

interface IProps {
  from: JSX.Element
  to: JSX.Element
}

export const RangeGrid: FC<IProps> = ({ from, to }) => (
  <Row gutter={4}>
    <Col span={12}>
      {from}
    </Col>
    <Col span={12}>
      {to}
    </Col>
  </Row>
)
