
import React from 'react'
import DotComponent from './ShapeComponent'

const randomInRange = (min, max) => Math.random() * (max - min) + min
const colors = ['yellow', 'red', 'white', 'blue', 'green']

export const CannonComponent: React.FunctionComponent<{ dotCount: number; setDone: any }> = ({ dotCount, setDone }) => {
  return (
    <>
      {
        [...Array(dotCount).keys()].map((element, index) => (
          <DotComponent
            key={index}
            initialX={0}
            initialY={0}
            initialHorizontalPosition={randomInRange(-2000, 2000)}
            initialVerticalPosition={randomInRange(200, 700)}
            color={colors[Math.floor(randomInRange(0, colors.length - 1))]}
            setDone={setDone}
          />
        ))
      }
    </>
  )
}

