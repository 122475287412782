import { IClientHierarchy } from 'mindshare.layout'
import { LinkedLookupValue } from 'Apis/generated/linkedLookupsApi'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'

type IClientHierarchyExtended = IClientHierarchy & {
  disabled?: boolean
}

export const getLinkedLookupHierarchyOptions = ({
  linkedLookupValues,
  idProp,
  availableLinkedLookupOptions,
  isEnforcedLink,
  referencedMediaPlanField,
  hierarchyList = [],
  isParentValid = true
}: {
  linkedLookupValues: LinkedLookupValue[]
  idProp: string
  availableLinkedLookupOptions: LinkedLookupValue[]
  hierarchyList: IClientHierarchy[]
  isEnforcedLink: boolean
  referencedMediaPlanField: IMediaPlanFieldRow | IMediaPlanTemplateFields
  isParentValid?: boolean
}) =>
  hierarchyList.reduce((prev, node) => {
    const isLinkedLookupOptionValid = availableLinkedLookupOptions?.some(
      item => node[idProp] === item.dependentValueId
    )
    const currentNodeOptions = linkedLookupValues?.filter(v => (node[idProp] === v.dependentValueId))
    const isCurrentOptionValid = currentNodeOptions?.some(v => v.referencedValueId.toString() === referencedMediaPlanField?.defaultValue?.toString())
    const isInheritingFromParent = !currentNodeOptions?.length

    const isNodeValid = isLinkedLookupOptionValid || isCurrentOptionValid || isInheritingFromParent && Boolean(isParentValid)

    const children = node.children?.length ? getLinkedLookupHierarchyOptions({
      linkedLookupValues,
      idProp,
      availableLinkedLookupOptions,
      hierarchyList: node.children,
      isEnforcedLink,
      referencedMediaPlanField,
      isParentValid: isNodeValid
    }) : []

    return isNodeValid || children.length > 0
      ? [
        ...prev,
        {
          ...node,
          children,
          disabled: !isNodeValid && isEnforcedLink,
          selectable: !isNodeValid && !isEnforcedLink
        }
      ]
      : prev
  }, [])

export const getInvalidLinkedLookupHierarchyOptions = ({
  availableLinkedLookupOptions,
  linkedLookupValues,
  idProp,
  isEnforcedLink,
  hierarchyList = []
}: {
  availableLinkedLookupOptions: LinkedLookupValue[]
  linkedLookupValues: LinkedLookupValue[]
  idProp: string
  hierarchyList: IClientHierarchy[]
  isEnforcedLink: boolean
}) =>
  hierarchyList.reduce((prev, node) => {
    const isLinkedLookupValue = linkedLookupValues?.some(
      item => node[idProp] === item.dependentValueId
    )
    const isLinkedLookupOptionInvalid = !availableLinkedLookupOptions?.some(
      item => node[idProp] === item.dependentValueId
    )
    const children = node.children?.length ? getInvalidLinkedLookupHierarchyOptions({ availableLinkedLookupOptions, linkedLookupValues, idProp, hierarchyList: node.children, isEnforcedLink }) : []

    return (isLinkedLookupValue && isLinkedLookupOptionInvalid) || children.length > 0 ? [...prev, { ...node, children, disabled: isLinkedLookupValue && isLinkedLookupOptionInvalid && isEnforcedLink, selectable: isLinkedLookupValue && isLinkedLookupOptionInvalid && !isEnforcedLink }] : prev
  }, [])

export function checkIsHierarchyOptionInvalid (hierarchyList: IClientHierarchyExtended[], currentValue: string | number | string[] | number[], idProp: string) {
  const foundNode = hierarchyList.find(node => node[idProp] === currentValue && !node.disabled)

  return foundNode || hierarchyList.some((node) => checkIsHierarchyOptionInvalid(node.children, currentValue, idProp))
}
