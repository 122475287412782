
import { IClientHierarchyLevel, IMSHierarchies, getClientHierarchyLevelProp, getHierarchyLevelProp } from 'mindshare.layout'
import { HIERARCHY_TYPES_MAP } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { IExpandedHierarchiesFlightGroupsValues } from './getExpandedHierarchiesValues'

type ICurrentExpandedHierarchiesLevels = {
  [hierarchyTypeId: number]: IClientHierarchyLevel[]
}

export const getCurrentExpandedHierarchiesLevels = (
  flightGroupHierarchiesValues: IExpandedHierarchiesFlightGroupsValues,
  hierarchies: IMSHierarchies
): ICurrentExpandedHierarchiesLevels => {
  return Object.values(flightGroupHierarchiesValues).reduce<ICurrentExpandedHierarchiesLevels>((
    currentHierarchiesLevels,
    flightGroupHierarchies
  ) => {
    return Object.fromEntries(Object.entries(flightGroupHierarchies).map(([selectedHierarchyTypeId, selectedHierarchyValues]) => {
      const hierarchyType = HIERARCHY_TYPES_MAP[selectedHierarchyTypeId]
      const isMasteredHierarchyType = Number(selectedHierarchyTypeId) === FieldDataType.FINANCE_STATION_HIERARCHY || Number(selectedHierarchyTypeId) === FieldDataType.FINANCE_PRODUCT_HIERARCHY || Number(selectedHierarchyTypeId) === FieldDataType.COST_BUYING_ROUTE_HIERARCHY
      const hierarchyLevels: IClientHierarchyLevel[] = isMasteredHierarchyType ? hierarchies[getHierarchyLevelProp(hierarchyType)] : hierarchies[getClientHierarchyLevelProp(hierarchyType)]

      const alreadyAddedHierarchyLevels: IClientHierarchyLevel[] = currentHierarchiesLevels[selectedHierarchyTypeId] ?? []

      const namedHierarchyLevelsToAdd = hierarchyLevels.filter(hierarchyLevel => (
        hierarchyLevel.hierarchyLevelNumber >= alreadyAddedHierarchyLevels.length &&
        hierarchyLevel.hierarchyLevelNumber < selectedHierarchyValues.length
      )).sort((levelA, levelB) => levelA.hierarchyLevelNumber - levelB.hierarchyLevelNumber)

      // the array below exists only because some hierarchy levels are missing in api responses
      // they can be removed after backend fills in the missing levels
      // as namedHierarchyLevelsToAdd should then contain all levels and all should have names
      const hierarchyLevelsToAddFromPickedValues = selectedHierarchyValues
        .filter(hierarchyValue => (
          hierarchyValue.levelNumber >= hierarchyLevels.length &&
          hierarchyValue.levelNumber >= alreadyAddedHierarchyLevels.length
        ))
        .map((hierarchyValue, index) => ({
          hierarchyLevelId: -1 * hierarchyValue.id + index,
          hierarchyLevelName: '',
          hierarchyLevelNumber: hierarchyValue.levelNumber
        }))

      return [
        Number(selectedHierarchyTypeId),
        [
          ...alreadyAddedHierarchyLevels,
          ...namedHierarchyLevelsToAdd,
          ...hierarchyLevelsToAddFromPickedValues
        ]
      ]
    }))
  }, {})
}
