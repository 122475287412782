export enum mediaPlanTooltipMessage {
  calculationIssues = 'There are calculation issues',
  calculationsRunning = 'Calculations are running',
  missingPlanFlights = 'Unable to update planning stage due to missing flights',
  preserveApprovedPlans = 'Approved plans cannot be modified, any changes should be saved as new version'
}

export enum redistributionTooltipMessage {
  missingFields = 'Please select required fields',
  missingConfigName = 'Please fill in required field',
  duplicatedConfigName = 'Redistribution config name already exists',
  missingFlights = 'Please select all available flights to redistribute'
}
