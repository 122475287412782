import { CustomExportFieldDataTypeOperator } from 'Apis/generated/customExportsApi'

enum MultipleValuesOperatorName {
  between = 'BETWEEN',
  in = 'IN',
  notIn = 'NOT IN'
}

export const findOperatorByOperatorId = (operators: CustomExportFieldDataTypeOperator[], fieldDataTypeOperatorId: number) => operators.find((operator) => {
  return operator.fieldDataTypeOperatorId === fieldDataTypeOperatorId
})

export const getOperatorsByFieldDataTypeId = (operators: CustomExportFieldDataTypeOperator[], fieldDataTypeId?: number) => {
  return operators.filter(operator => operator.fieldDataTypeId === fieldDataTypeId)
}

export const isOperatorIdOfMultipleValueOperator = (operators: CustomExportFieldDataTypeOperator[], fieldDataTypeOperatorId?: number) => {
  if (!fieldDataTypeOperatorId) {
    return false
  }

  const multipleValueOperatorNames: string[] = Object.values(MultipleValuesOperatorName)
  const operatorName = operators.find((operator) => {
    return operator.fieldDataTypeOperatorId === fieldDataTypeOperatorId
  }).fieldDataTypeOperator

  return multipleValueOperatorNames.includes(operatorName)
}
