import React, { useCallback, useMemo, useRef } from 'react'

export const useFocus = <T extends HTMLElement>(): [React.MutableRefObject<T>, () => void] => {
  const ref = useRef<T>()

  const focus = useCallback(() => {
    ref.current?.focus()
  }, [])

  return useMemo(() => ([
    ref,
    focus
  ]), [focus])
}
