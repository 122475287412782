import React from 'react'
import {
  differenceInCalendarDays,
  eachWeekOfInterval,
  endOfWeek,
  getDate,
  getISOWeek,
  isAfter,
  isBefore,
  getWeek
} from 'date-fns'
import classNames from 'classnames'
import { CalendarView } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { getPixelsPerDay } from 'Helpers/calendarHelper'

interface IWeekProps {
  startDate: Date
  endDate: Date
  calendarView: CalendarView
  weekStateOn: any
  compressedCalendarView: boolean
}

interface IFlightItemProps{
  value: string | number
  width: number
  dataTestId: string
}

const Weeks: React.FC<IWeekProps> = ({ startDate, endDate, weekStateOn, calendarView, compressedCalendarView }) => {
  const FlightItem = ({ value, width, dataTestId }: IFlightItemProps) => {
    const widthStyle = { width: width + 'px' }
    return (
      <div data-testid={dataTestId} className={classNames('flightHeader__week', { '-compressed': compressedCalendarView })} style={widthStyle}>
        {value}
      </div>
    )
  }

  const pixelsPerDay = getPixelsPerDay(calendarView)
  const weeks = eachWeekOfInterval({ start: startDate, end: endDate }, weekStateOn)
  let weekElements = []
  let firstDayOfWeekElements = []

  weeks.forEach((week, i) => {
    const weekName = week.getDate() + '-' + week.getFullYear()
    const currentWeek = i === 0 && isBefore(week, startDate) ? startDate : week
    let weekEnd = endOfWeek(currentWeek, weekStateOn)
    weekEnd = isAfter(weekEnd, endDate) ? endDate : weekEnd
    const daysInWeek = differenceInCalendarDays(weekEnd, currentWeek) + 1
    const width = compressedCalendarView ? (daysInWeek * pixelsPerDay) / 3 : daysInWeek * pixelsPerDay
    const weekNumber = calendarView === 'week-iso' ? getISOWeek(currentWeek) : getWeek(currentWeek)
    const weekElement = (
      <FlightItem dataTestId='week-number' value={weekNumber} key={`week-${i}-${weekName}`} width={width} />
    )
    const firstDayElement = (
      <FlightItem dataTestId='week-starts' value={getDate(currentWeek)} key={`week-days-${i}-${weekName}`} width={width} />
    )
    weekElements = [...weekElements, weekElement]
    firstDayOfWeekElements = [...firstDayOfWeekElements, firstDayElement]
  })

  return (
    <div className="flightHeader__weeks">
      <div data-testid='week-number-container' className='flightHeader__weekNumber'>{weekElements}</div>
      <div data-testid='week-start-container' className='flightHeader__weekStart'>{firstDayOfWeekElements}</div>
    </div>
  )
}

export default Weeks
