export interface IUserPreferences {
  displayFlightDates?: boolean
  expandHierarchies?: boolean
  compressedCalendarView?: boolean
  collapseCalculated?: boolean
  collapseNonMandatory?: boolean
  collapseRows?: boolean
  collapsedViewField?: string
}

export const initialUserPreferences: IUserPreferences = {
  displayFlightDates: false,
  expandHierarchies: false,
  compressedCalendarView: false,
  collapseCalculated: false,
  collapseNonMandatory: false,
  collapseRows: false,
  collapsedViewField: ''
}
