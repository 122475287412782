export enum FieldDataType {
  INTEGER = 10,
  STRING = 20,
  DECIMAL = 30,
  PERCENTAGE = 40,
  DATE = 50,
  CLIENT_DEFINED_LIST = 60,
  UNIQUE_STRING = 70,
  BIG_STRING = 80,
  BUSINESS_HIERARCHY = 90,
  BRAND_HIERARCHY = 100,
  MEDIA_HIERARCHY = 110,
  GEOGRAPHY_HIERARCHY = 120,
  MEDIA_PARTNERS = 130,
  CURRENCY = 140,
  RICH_TEXT = 150,
  FINANCE_PRODUCT_HIERARCHY = 160,
  FINANCE_STATION_HIERARCHY = 170,
  FINANCE_TARGET_AUDIENCE_LIST = 180,
  FINANCE_BUYING_AUDIENCE_LIST = 190,
  FINANCE_BOOKING_CATEGORY_LIST = 200,
  CLIENT_CAMPAIGNS = 210,
  COST_BUYING_ROUTE_HIERARCHY = 220,
  CLIENT_AGENCY_LIST = 230
}

export const NUMBER_FIELD_DATA_TYPES = [
  FieldDataType.INTEGER,
  FieldDataType.DECIMAL,
  FieldDataType.PERCENTAGE,
  FieldDataType.CURRENCY
]

export const LOOKUP_FIELD_DATA_TYPES = [
  FieldDataType.CLIENT_DEFINED_LIST,
  FieldDataType.UNIQUE_STRING,
  FieldDataType.BUSINESS_HIERARCHY,
  FieldDataType.BRAND_HIERARCHY,
  FieldDataType.MEDIA_HIERARCHY,
  FieldDataType.GEOGRAPHY_HIERARCHY,
  FieldDataType.MEDIA_PARTNERS,
  FieldDataType.FINANCE_PRODUCT_HIERARCHY,
  FieldDataType.FINANCE_STATION_HIERARCHY,
  FieldDataType.COST_BUYING_ROUTE_HIERARCHY
]

export const FINANCE_FIELD_DATA_TYPES = [
  FieldDataType.FINANCE_PRODUCT_HIERARCHY,
  FieldDataType.FINANCE_STATION_HIERARCHY,
  FieldDataType.FINANCE_TARGET_AUDIENCE_LIST,
  FieldDataType.FINANCE_BUYING_AUDIENCE_LIST,
  FieldDataType.FINANCE_BOOKING_CATEGORY_LIST
]

export const MASTERED_FIELD_DATA_TYPES = [
  ...FINANCE_FIELD_DATA_TYPES,
  FieldDataType.COST_BUYING_ROUTE_HIERARCHY
]
