import { useMemo } from 'react'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'
import { GetLinkedLookupsApiResponse } from 'Apis/generated/linkedLookupsApi'
import { useGetLinkedLookupsQuery } from 'Apis/enhancedLinkedLookupsApi'

export const useLinkedLookupsList = (): UseQueryCommand<GetLinkedLookupsApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetLinkedLookupsQuery(
    { clientId: currentClient.id },
    { skip: !currentClient.id }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
