import React, { useCallback, useEffect, useMemo } from 'react'
import { Button, Select, Tooltip } from 'antd'
import { DragOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { v4 as uuid } from 'uuid'
import { MSTable } from 'mindshare.layout'

import { getDisplayLabel, IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { IButtonActionsAggregate } from 'Components/FrontSheets/constants/entities/IFrontSheets'
import { getValidationMessage } from 'Helpers/frontSheetHelper'
import { frontSheetValidationError } from 'Components/shared/constants/entities/IFieldMetaData'
import { FrontSheetColumnType } from 'Constants/enums/FrontSheetColumnType'
import FrontSheetTableComponent from './FrontSheetTableComponent'
import { FrontSheetViewAggregate } from 'Apis/generated/frontSheetsApi'
import { FieldLevel } from 'Apis/generated/mediaPlanFieldsApi'
import { SortableItem } from 'Components/shared/SortableItem'

const { Option, OptGroup } = Select

interface IProps {
  buttonActions: IButtonActionsAggregate
  aggregatedClientMediaPlanFields: IClientMediaPlanField[]
  aggregatedData: FrontSheetViewAggregate[]
  fieldLevels: FieldLevel[]
  subtotalCutOff: IClientMediaPlanField
  validateField: (field: IClientMediaPlanField, columnType: FrontSheetColumnType, laydownField?: IClientMediaPlanField) => frontSheetValidationError
  setAggregateValid: (valid: boolean) => void
}

const DragHandle = () => (
  <Tooltip title='Drag aggregated field'>
    <DragOutlined data-testid='drag-handle' style={{ cursor: 'pointer', color: '#999' }} />
  </Tooltip>
)

const columns = [
  {
    className: 'drag-visible',
    dataIndex: 'fieldName',
    key: 'fieldName',
    title: 'Field Name'
  },
  {
    className: 'drag-visible',
    dataIndex: 'spacer',
    key: 'spacer',
    title: '',
    width: 100
  },
  {
    className: 'drag-visible button-column',
    dataIndex: 'buttons',
    key: 'buttons',
    title: '',
    width: 120
  },
  {
    className: 'drag-visible',
    dataIndex: 'drag',
    key: 'drag',
    render: () => <DragHandle />,
    title: '',
    width: 50
  }
]

export const FrontSheetAggregatedTableComponent: React.FC<IProps> = ({
  buttonActions,
  aggregatedClientMediaPlanFields,
  aggregatedData,
  fieldLevels,
  subtotalCutOff,
  validateField,
  setAggregateValid
}): React.ReactElement => {
  const renderOptionsByFieldLevelId = useCallback((f: FieldLevel) => aggregatedClientMediaPlanFields
    .filter(c => c.mediaPlanField.fieldLevelId === f.fieldLevelId)
    .map(c => (
      <Option
        disabled={subtotalCutOff && c.mediaPlanField.fieldLevelId < subtotalCutOff.mediaPlanField.fieldLevelId}
        key={c.clientMediaPlanFieldId}
        value={c.clientMediaPlanFieldId}>{c.fieldLabel}
      </Option>
    ))
  , [aggregatedClientMediaPlanFields, subtotalCutOff])

  const dataSource: any[] = useMemo(() =>
    aggregatedData ? [...aggregatedData]
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((row, i) => {
        const exists = aggregatedClientMediaPlanFields.find(x => x.clientMediaPlanFieldId === row.clientMediaPlanFieldId)
        const validation = validateField(exists, FrontSheetColumnType.AGGREGATE)

        return {
          valid: validation === undefined,
          fieldName: (
            <Tooltip title={getValidationMessage(validation)}>
              <Select
                allowClear={true}
                className={`ms-select front-sheet-select-fields__select ${validation !== undefined ? 'front-sheet-select-fields__select-invalid' : ''}`}
                onChange={(val: string) => buttonActions.updateFieldName(i, val)}
                optionFilterProp='children'
                showSearch={true}
                placeholder={'Select aggregate field'}
                value={getDisplayLabel(exists)}
              >
                {
                  fieldLevels.map((f, index) => (
                    <OptGroup key={index} label={f.fieldLevelName}>
                      {renderOptionsByFieldLevelId(f)}
                    </OptGroup>
                  ))
                }
              </Select>
            </Tooltip>
          ),
          key: i,
          itemRowId: uuid(),
          buttons: (
            <div className='front-sheet-item-icons'>
              <Tooltip title='Add aggregated field'>
                <Button
                  className='plus-icon'
                  data-testid='aggregated-add-row'
                  icon={< PlusCircleOutlined />}
                  onClick={buttonActions.addRow}
                  size='large'
                  shape='circle'
                />
              </Tooltip>
              {
                aggregatedData.length > 1 && (
                  <Tooltip title='Remove aggregated field'>
                    <Button
                      className='minus-icon'
                      data-testid='aggregated-remove-row'
                      icon={<MinusCircleOutlined />}
                      onClick={() => buttonActions.removeRow(i)}
                      size='large'
                      shape='circle'
                    />
                  </Tooltip>
                )
              }
            </div>
          )
        }
      }) : []
  , [aggregatedClientMediaPlanFields, aggregatedData, buttonActions, fieldLevels, renderOptionsByFieldLevelId, validateField])


  const onSortEnd = useCallback(({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const oldIndex = dataSource.findIndex((i) => i.itemRowId === active.id)
      const newIndex = dataSource.findIndex((i) => i.itemRowId === over?.id)

      const newData = arrayMove([].concat(...aggregatedData), oldIndex, newIndex).filter(el => !!el)
      buttonActions.updateSortOrder(newData)
    }
  }, [aggregatedData, buttonActions, dataSource])

  useEffect(() => {
    const invalid = dataSource.find(x => !x.valid)
    setAggregateValid(!invalid)
  }, [setAggregateValid, dataSource])

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onSortEnd}>
      <SortableContext
        items={dataSource.map((i) => i.itemRowId)}
        strategy={verticalListSortingStrategy}
      >
        <FrontSheetTableComponent data-testid="frontSheets-select-aggregates-table" title="AGGREGATED">
          <MSTable
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            components={{
              body: {
                wrapper: (containerProps) => (
                  <tbody
                    {...containerProps}
                  />
                ),
                row: (restProps) => dataSource ? (
                  <SortableItem
                    index={dataSource.findIndex(x => x.key === restProps['data-row-key'])}
                    item={dataSource.find(x => x.key === restProps['data-row-key'])}
                    {...restProps}
                  />
                ) : null
              }
            }}
            rowKey='key'
          />
        </FrontSheetTableComponent>
      </SortableContext>
    </DndContext>
  )
}

export default FrontSheetAggregatedTableComponent
