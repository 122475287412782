import React, { FunctionComponent } from 'react'
import Moment from 'react-moment'
import { Switch, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { MSTable, formatEmailToName } from 'mindshare.layout'

import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { compare } from 'Helpers/sortHelper'
import CheckboxComponent from 'Components/CheckboxComponent'
import { IMediaPlanFieldExtended } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'

export interface IAliasTableProps {
  data: IClientMediaPlanField[]
  updateStatus: (clientMediaPlanFieldId: number, approve: boolean) => Promise<void>
}

const AliasTable: FunctionComponent<IAliasTableProps> = ({ data, updateStatus }) => {
  const columns: Array<ColumnProps<IClientMediaPlanField>> = [
    {
      key: 'ColumnName',
      title: 'Column name (MPT Real name)',
      sorter: (a, b) => compare(a.mediaPlanField.fieldLabel, b.mediaPlanField.fieldLabel),
      dataIndex: ['mediaPlanField'],
      render: (mediaPlanField: IMediaPlanFieldExtended) => (
        <Tooltip title={mediaPlanField.fieldDescription}>
          <div>{mediaPlanField.fieldLabel}</div>
        </Tooltip>
      )
    },
    {
      key: 'Label',
      title: 'Label (Alias)',
      sorter: (a, b) => compare(a.fieldLabel, b.fieldLabel),
      dataIndex: 'fieldLabel'
    },
    {
      key: 'FieldLevel',
      title: 'Field Level',
      sorter: (a, b) => compare(a.mediaPlanField.fieldLevel.fieldLevelName, b.mediaPlanField.fieldLevel.fieldLevelName),
      dataIndex: ['mediaPlanField', 'fieldLevel', 'fieldLevelName']
    },
    {
      key: 'DataType',
      title: 'Data Type',
      sorter: (a, b) => compare(a.mediaPlanField.fieldDataType.dataTypeName, b.mediaPlanField.fieldDataType.dataTypeName),
      dataIndex: ['mediaPlanField', 'fieldDataType', 'dataTypeName']
    },
    {
      key: 'MediaPlanFieldType',
      title: 'Disabled',
      dataIndex: 'isDisabled',
      sorter: (a, b) => compare(a.isDisabled, b.isDisabled),
      render: (isDisabled: boolean) => (
        <CheckboxComponent
          dataTestId='disabled'
          disabled={true}
          checked={isDisabled}
        />
      )
    },
    {
      key: 'SubmittedBy',
      title: 'Submitted By',
      sorter: (a, b) => compare(a.submittedBy, b.submittedBy),
      dataIndex: 'submittedBy',
      render: (submittedBy: string) => (
        <>{formatEmailToName(submittedBy)}</>
      )
    },
    {
      key: 'SubmittedOn',
      title: 'Submitted On',
      sorter: (a, b) => compare(new Date(a.submittedOn), new Date(b.submittedOn)),
      defaultSortOrder: 'descend',
      dataIndex: 'submittedOn',
      render: (date: string) => (
        <Moment format='DD MMM YY h:mmA'>{date}</Moment>
      )
    },
    {
      key: 'ApprovedBy',
      title: 'Approved By',
      sorter: (a, b) => compare(a.approvedBy || '', b.approvedBy || ''),
      dataIndex: 'approvedBy',
      render: (approvedBy: string) => (
        <>{approvedBy && formatEmailToName(approvedBy)}</>
      )
    },
    {
      key: 'ApprovedOn',
      title: 'Approved On',
      sorter: (a, b) => compare(new Date(a.approvedOn), new Date(b.approvedOn)),
      dataIndex: 'approvedOn',
      render: (approvedOn: string) => (
        <>{approvedOn && <Moment format='DD MMM YY h:mmA'>{approvedOn}</Moment>}</>
      )
    },
    {
      key: 'Status',
      title: 'Status',
      sorter: (a, b) => compare(new Date(a.approvedOn), new Date(b.approvedOn)),
      render: (clientMediaPlanField: IClientMediaPlanField) => (
        <Switch
          data-fieldid={clientMediaPlanField.clientMediaPlanFieldId}
          checked={!!clientMediaPlanField.approvedOn}
          onChange={checked => updateStatus(clientMediaPlanField.clientMediaPlanFieldId, checked)}
          size='small'
        />
      )
    }
  ]

  return (
    <MSTable
      data-testid='clientFieldAliasTable'
      rowKey='clientMediaPlanFieldId'
      columns={columns}
      dataSource={data}
      dynamicPagination={{ minRows: 6 }}
    />
  )
}

export default AliasTable
