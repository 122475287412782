import { useMemo } from 'react'
import { GetCustomExportsByIdApiArg, GetCustomExportsByIdApiResponse } from 'Apis/generated/customExportsApi'
import { useGetCustomExportsByIdQuery } from 'Apis/enhancedCustomExportsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'

export const useCurrentCustomExport = ({ id }: Omit<GetCustomExportsByIdApiArg, 'clientId'>): UseQueryCommand<GetCustomExportsByIdApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetCustomExportsByIdQuery(
    { clientId: currentClient.id, id },
    { skip: !currentClient.id || !id }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
