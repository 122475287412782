import React, { FunctionComponent } from 'react'
import { Select, Col } from 'antd'

import { IFlightFieldOption } from 'Components/MediaPlanVersion/constants/entities/IFlightFieldOption'

const { Option } = Select

interface IProps {
  options: IFlightFieldOption[]
  selectedField: string
  setSelectedField: (value: string) => void
}

export const CollapsedViewComponent: FunctionComponent<IProps> = ({
  options,
  setSelectedField,
  selectedField
}: IProps) => (
  <>
    <Col span={16}>
      <label className='settings-title'>Select flight field to display in collapsed view</label>
    </Col>
    <Col span={8}>
      <Select
        className='ms-select'
        placeholder='Select value'
        showSearch={true}
        value={selectedField}
        onChange={(value: string) => {
          setSelectedField(value)
        }}
        data-testid='collapsed-view-field-selector'
      >
        {options && options.map((option) => (
          <Option
            key={option.id}
            value={option.name}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </Col>
  </>
)

export default CollapsedViewComponent
