import React, { FunctionComponent, useCallback } from 'react'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import FieldAvailableComponent from 'Components/FieldAvailableComponent'
import { RootState } from '../../redux/reducers'
import { useMindshareSelector } from 'mindshare.customhooks'
import { IMediaPlanTemplateFields, availableFlightAndSubFlights } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

interface IProps {
  calculationMode: boolean
  flightGroupIndex: number
  handleAddField: (fieldType: FieldLevelType, flightGroupIndex?: number) => void
  onAvailableFieldSelected: (c: string, flightGroupIndex?: number) => void
}

export const EditListFlightContainer: FunctionComponent<IProps> = (props: IProps) => {
  const {
    calculationMode,
    flightGroupIndex,
    handleAddField,
    onAvailableFieldSelected
  } = props
  const handleEditList = useCallback(() => {
    handleAddField(FieldLevelType.FLIGHT, flightGroupIndex)
  }, [handleAddField, flightGroupIndex])

  const availableFlightsFields = useMindshareSelector((state: RootState) =>
    availableFlightAndSubFlights(
      state.mediaPlans.currentMediaPlanVersion.mediaPlanVersionFields,
      state.mediaPlans.currentMediaPlanVersion.parseData?.flightGroups[flightGroupIndex]?.chosenFlightFields || []
    )
  ) as IMediaPlanTemplateFields[]


  const enableDropDownField = useMindshareSelector((state: RootState) =>
    state.mediaPlans.currentMediaPlanVersion.parseData?.flightGroups[flightGroupIndex]?.chosenFlightFields?.some(c => c.clientMediaPlanFieldId === 0)
  ) as boolean

  return (
    <>
      {enableDropDownField && (
        <FieldAvailableComponent
          onAvailableFieldSelected={(selectedValue: string) => onAvailableFieldSelected(selectedValue, flightGroupIndex)}
          availableMediaPlanFields={availableFlightsFields}
        />
      )
      }
      {
        availableFlightsFields.length > 0 && (
          <Button
            icon={<EditOutlined />}
            type='primary'
            className='btn-edit-list'
            onClick={handleEditList}
            disabled={calculationMode}
            tabIndex={-1}
          >
            Edit List
          </Button>
        )
      }
    </>
  )
}

export default EditListFlightContainer
