import React from 'react'
import { Button } from 'antd'
import { navigate, MSLayoutContentRow, getColumnSearchProps, MSClientHierarchySelection, IMSHierarchies } from 'mindshare.layout'
import TemplateTitleComponent from 'Components/TemplateTitleComponent'
import PlansListTable from 'Components/PlansListTable'
import { ILookupPlan } from 'Components/MediaPlans/constants/entities/IMediaPlans'

interface IProps {
  buttonActions: {
    save: () => void
  }
  currentClientId: number
  currentClientDisplayName: string
  frontSheetName: string
  hierarchies: IMSHierarchies
  mediaPlans: ILookupPlan[]
  onHierarchyFilterSelectionChange: (name: string, id: number) => void
  saveInProgress: boolean
  saveSuccess: boolean
  selectedBrand: number
  selectedBusiness: number
  selectedGeography: number
  selectedMedia: number
  table: {
    checkPlans: (item: ILookupPlan) => void
    renderLastColumn: (item: ILookupPlan) => void
  }
  updateFrontSheetName: (value: string) => void
  isEditMode: boolean
  leftAlignNumbersInExcelExport: boolean
  updateLeftAlignExport: (alignLeft: boolean) => void
  hideSortOrderInExcelExport: boolean
  onHideSortOrderInExcelExport: (value: boolean) => void
}

export const FrontSheetSelectPlansComponent: React.FC<IProps> = (props): React.ReactElement => {
  const {
    buttonActions,
    currentClientDisplayName,
    currentClientId,
    frontSheetName,
    hierarchies,
    mediaPlans,
    onHierarchyFilterSelectionChange,
    saveInProgress,
    saveSuccess,
    selectedBrand,
    selectedBusiness,
    selectedGeography,
    selectedMedia,
    table,
    updateFrontSheetName,
    isEditMode,
    leftAlignNumbersInExcelExport,
    updateLeftAlignExport,
    hideSortOrderInExcelExport,
    onHideSortOrderInExcelExport
  } = props
  return (
    <>
      <MSLayoutContentRow>
        <TemplateTitleComponent
          inputLabel='Front Sheet Name'
          inputValue={frontSheetName}
          onTemplateNameChange={updateFrontSheetName}
          placeholder='Enter Front Sheet Title'
          tags={null}
          title={isEditMode ? 'Edit Front Sheet' : 'Create Front Sheet'}
          tooltip='createEditFrontSheetPlanSelection'
          leftAlignNumbersInExcelExport={leftAlignNumbersInExcelExport}
          onLeftAlignExportChange={updateLeftAlignExport}
          hideSortOrderInExcelExport={hideSortOrderInExcelExport}
          onHideSortOrderExportChange={onHideSortOrderInExcelExport}
        />
        <MSClientHierarchySelection
          hierarchies={hierarchies}
          onChange={onHierarchyFilterSelectionChange}
          selectedGeography={selectedGeography}
          selectedMedia={selectedMedia}
          selectedBusiness={selectedBusiness}
          selectedBrand={selectedBrand}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow fillAvailableSpace={true}>
        <PlansListTable
          createNewPlan={() => navigate('/')}
          currentClientId={currentClientId}
          currentClientDisplayName={currentClientDisplayName}
          filterFunction={getColumnSearchProps}
          lastColumn={table.renderLastColumn}
          mediaPlans={mediaPlans}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow extraClass='bottom-button-wrapper'>
        <div className='buttons-col'>
          <Button
            disabled={saveInProgress || saveSuccess}
            loading={saveInProgress}
            onClick={buttonActions.save}
            type='primary'
          >
            Save
          </Button>
        </div>
      </MSLayoutContentRow>
    </>
  )
}

export default FrontSheetSelectPlansComponent

