import React, { FunctionComponent, useState, useEffect } from 'react'

import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { SettingsModalComponent } from 'Components/MediaPlanVersion/SettingsModalComponent'
import { initialUserPreferences, IUserPreferences } from 'Constants/entities/IUserPreferences'
import { CalendarView } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'

interface IProps {
  hideModal: () => void
  visible: boolean
  calendarView: CalendarView
  mediaPlanTemplateFields: IMediaPlanTemplateFields[]
  isDisplayedFlightDates: boolean
  isExpandedMode: boolean
  isCompressedCalendarView: boolean
  isCollapsedCalculated: boolean
  isCollapsedNonMandatory: boolean
  isCollapsedRows: boolean
  collapsedViewFieldLabel: string
  setUserSettings: (values: IUserPreferences) => void
}

export const SettingsModalContainer: FunctionComponent<IProps> = ({
  hideModal,
  visible,
  calendarView,
  mediaPlanTemplateFields,
  isDisplayedFlightDates,
  isExpandedMode,
  isCompressedCalendarView,
  isCollapsedCalculated,
  isCollapsedNonMandatory,
  isCollapsedRows,
  collapsedViewFieldLabel,
  setUserSettings
}) => {
  const [userPreferences, setUserPreferences] = useState<IUserPreferences>(initialUserPreferences)

  useEffect(() => {
    setUserPreferences({
      displayFlightDates: isDisplayedFlightDates,
      expandHierarchies: isExpandedMode,
      compressedCalendarView: isCompressedCalendarView,
      collapseCalculated: isCollapsedCalculated,
      collapseNonMandatory: isCollapsedNonMandatory,
      collapseRows: isCollapsedRows,
      collapsedViewField: collapsedViewFieldLabel
    })
  }, [isDisplayedFlightDates, isExpandedMode, isCompressedCalendarView, isCollapsedCalculated, isCollapsedNonMandatory, isCollapsedRows, collapsedViewFieldLabel])

  const applySettings = () => {
    setUserSettings(userPreferences)
    hideModal()
  }

  return (
    <SettingsModalComponent
      hideModal={hideModal}
      mediaPlanTemplateFields={mediaPlanTemplateFields}
      collapsedViewField={userPreferences.collapsedViewField}
      visible={visible}
      calendarView={calendarView}
      applySettings={applySettings}
      collapseCalculated={userPreferences.collapseCalculated}
      collapseNonMandatory={userPreferences.collapseNonMandatory}
      collapseRows={userPreferences.collapseRows}
      displayFlightDates={userPreferences.displayFlightDates}
      expandedHierarchyMode={userPreferences.expandHierarchies}
      compressedCalendarViewMode={userPreferences.compressedCalendarView}
      setUserPreferences={setUserPreferences}
    />
  )
}

export default SettingsModalContainer
