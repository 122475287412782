import { useMemo } from 'react'
import { CustomExportCurrencyDisplay } from 'Apis/generated/customExportsApi'
import { useGetCustomExportsCurrencyDisplaysQuery } from 'Apis/enhancedCustomExportsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'

export const useCurrencyDisplays = (): UseQueryCommand<CustomExportCurrencyDisplay[]> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetCustomExportsCurrencyDisplaysQuery(
    { clientId: currentClient.id },
    { skip: !currentClient.id }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
