import React, { FunctionComponent } from 'react'
import { Row, Col, Tabs, Typography, Form, FormInstance } from 'antd'
import { useMindshareSelector } from 'mindshare.customhooks'
import { getApportionedFlightFields, getSortedFlightGroupFields, getSortedFlightFields, filterFlightFieldsById } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IMediaPlanVersion } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { IRedistributionFormValues } from 'Components/Redistribution/constants/entities/IRedistribution'
import { RootState } from '../../../redux/reducers'
import { FormItemRadioGroup } from '../form-elements/FormItemRadioGroup'

const { Paragraph } = Typography
const containerHeight = 235

interface IRedistributionFieldValues {
  fieldToRedistributeId: number
  fieldToRedistributeById: number
}

interface IProps {
  formInstance: FormInstance<IRedistributionFieldValues>
  setFieldValue: (value: any, name: string) => void
}

export const RedistributionFieldSetupForm: FunctionComponent<IProps &
IRedistributionFormValues> = ({
  formInstance,
  setFieldValue,
  fieldToRedistributeId,
  fieldToRedistributeById
}) => {
  const currentMediaPlanVersion = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion) as IMediaPlanVersion
  const apportionedFlightFields = getApportionedFlightFields(currentMediaPlanVersion.mediaPlanVersionFields)
  const flightFields = getSortedFlightFields(currentMediaPlanVersion.mediaPlanVersionFields)
  const filteredFlightFieldsToRedistributeBy = filterFlightFieldsById(flightFields, fieldToRedistributeId)
  const filteredFlightFieldsToRedistribute = filterFlightFieldsById(apportionedFlightFields, fieldToRedistributeById)
  const flightGroupFields = getSortedFlightGroupFields(currentMediaPlanVersion.mediaPlanVersionFields)

  return (
    <Form
      form={formInstance}
      name="redistribution-fields"
      className="redistribution-form"
    >
      <Row gutter={[40, 16]}>
        <Col span={12}>
          <Paragraph>Select a field to redistribute:</Paragraph>
          <FormItemRadioGroup
            name="fieldToRedistributeId"
            data={filteredFlightFieldsToRedistribute}
            onChange={setFieldValue}
            value={fieldToRedistributeId}
          />
        </Col>
        <Col span={12}>
          <Paragraph>Select a field to redistribute by:</Paragraph>
          <Tabs>
            <Tabs.TabPane tab="Flight group fields" key="item-1">
              <FormItemRadioGroup
                name="fieldToRedistributeById"
                data={flightGroupFields}
                containerHeight={containerHeight}
                onChange={(value) => {
                  setFieldValue(value, 'fieldToRedistributeById')
                  setFieldValue([], 'redistributeByValues')
                }}
                value={fieldToRedistributeById}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Flight level fields" key="item-2">
              <FormItemRadioGroup
                name="fieldToRedistributeById"
                data={filteredFlightFieldsToRedistributeBy}
                containerHeight={containerHeight}
                onChange={(value) => {
                  setFieldValue(value, 'fieldToRedistributeById')
                  setFieldValue([], 'redistributeByValues')
                }}
                value={fieldToRedistributeById}
              />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Form>
  )
}
