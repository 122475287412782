import React, { useCallback, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Button } from 'antd'
import { QuestionOutlined } from '@ant-design/icons'
import ModalComponent from './ModalComponent'

const TooltipWrapper = ({ isUnwrapped, children }) => (isUnwrapped ? children : <div className='tooltip'>{children}</div>)

interface IInfoModalComponentProps {
  text: string
  isUnwrapped?: boolean
}

const InfoModalComponent: React.FC<IInfoModalComponentProps> = ({ text, isUnwrapped }) => {
  const [visible, setVisible] = useState(false)
  const showModal = useCallback(() => setVisible(true), [])
  const hideModal = useCallback(() => setVisible(false), [])

  return (
    <TooltipWrapper isUnwrapped={isUnwrapped}>
      <Button
        className='ms-button'
        data-testid='tooltip'
        type='primary'
        onClick={showModal}
      >
        <QuestionOutlined />
      </Button>
      <ModalComponent
        onCancel={hideModal}
        visible={visible}
        width={900}
        footer={null}
        modalBody={<ReactMarkdown linkTarget="_blank" source={text} />}
      />
    </TooltipWrapper>
  )
}

export default InfoModalComponent
