import moment from 'moment'
import * as arrayHelpers from 'Helpers/arrayHelper'
import * as actionTypes from 'Constants/actionTypes'
import { ILinkedLookupListAction } from 'Actions/linkedLookupActions'
import { LinkedLookup } from 'Apis/generated/linkedLookupsApi'
import { linkedLookupsInitialValues } from 'Components/LinkedLookup/constants/entities'

export interface ILinkedLookupsState {
  linkedLookupList: LinkedLookup[]
}

const initialState: ILinkedLookupsState = {
  linkedLookupList: [linkedLookupsInitialValues]
}

export default function (state: ILinkedLookupsState = initialState, action: ILinkedLookupListAction): ILinkedLookupsState {
  switch (action.type) {
    case actionTypes.SET_LINKED_LOOKUPS_LIST: {
      return {
        ...state,
        linkedLookupList: action.payload
      }
    }
    case actionTypes.UNSET_LINKED_LOOKUPS_LIST: {
      return {
        ...state,
        linkedLookupList: initialState.linkedLookupList
      }
    }
    case actionTypes.ADD_LINKED_LOOKUPS_TO_LIST: {
      const currentLinkedLookups = state.linkedLookupList

      return {
        ...state,
        linkedLookupList: arrayHelpers.push(currentLinkedLookups, action.payload.linkedLookup)
      }
    }
    case actionTypes.REMOVE_LINKED_LOOKUPS_FROM_LIST: {
      const currentLinkedLookups = state.linkedLookupList
      const linkedLookupId = action.payload.id

      if (linkedLookupId <= 0) {
        if (currentLinkedLookups.length === 1) {
          return {
            ...state,
            linkedLookupList: initialState.linkedLookupList
          }
        }

        return {
          ...state,
          linkedLookupList: currentLinkedLookups.filter((item) => item.linkedLookupId !== linkedLookupId)
        }
      }

      const newLinkedLookups = currentLinkedLookups.map((item) =>
        item.linkedLookupId === linkedLookupId ? {
          ...item,
          deletedOn: moment().utc().startOf('day').toISOString()
        } : item
      )

      const hasOnlyDeletedItems = !newLinkedLookups.filter((item) => !item.deletedOn).length

      if (hasOnlyDeletedItems) {
        return {
          ...state,
          linkedLookupList: arrayHelpers.push(
            newLinkedLookups,
            linkedLookupsInitialValues
          )
        }
      }

      return {
        ...state,
        linkedLookupList: newLinkedLookups
      }
    }
    case actionTypes.UPDATE_LINKED_LOOKUPS_LIST: {
      const currentLinkedLookups = state.linkedLookupList
      const at = currentLinkedLookups.findIndex(l => l.linkedLookupId === action.payload.linkedLookupId)

      return {
        ...state,
        linkedLookupList: arrayHelpers.update(currentLinkedLookups, at, action.payload.linkedLookup)
      }
    }
    default:
      return state
  }
}
