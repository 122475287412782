import { message } from 'antd'
import {
  PostCustomExportsUploadTemplateApiArg,
  PutCustomExportsByIdApiArg,
  PutCustomExportsByIdApiResponse
} from 'Apis/generated/customExportsApi'
import { usePutCustomExportsByIdMutation, usePostCustomExportsUploadTemplateMutation } from 'Apis/enhancedCustomExportsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { oneOf } from 'Helpers/conditionsHelper'
import { UseMutationCommand } from '../../../redux/types'

export const useUpdateCustomExport = (): UseMutationCommand<
Omit<PutCustomExportsByIdApiArg, 'clientId'> & Omit<PostCustomExportsUploadTemplateApiArg, 'clientId'>,
PutCustomExportsByIdApiResponse
> => {
  const { data: currentClient } = useCurrentClient()
  const [uploadTemplate, { isLoading: uploadingTemplate }] = usePostCustomExportsUploadTemplateMutation()
  const [updateCustomExport, { isLoading: creatingCustomExport }] = usePutCustomExportsByIdMutation()

  return {
    isLoading: oneOf(creatingCustomExport, uploadingTemplate),
    execute: async ({ id, body, customExport }) => {
      try {
        let customExportTemplateLocationUrl: string = null

        if (body.file) {
          const { url } = await uploadTemplate({ body: { file: body.file }, clientId: currentClient.id }).unwrap()
          customExportTemplateLocationUrl = url
        }

        const response = await updateCustomExport({
          clientId: currentClient.id,
          id,
          customExport: {
            ...customExport,
            ...(customExportTemplateLocationUrl && { customExportTemplateLocationUrl })
          }
        }).unwrap()

        message.success('Custom export has been updated')

        return response
      } catch (error) {
        message.error(error.data.errors
          ? Object.entries(error.data.errors).map( ([key, value]) => `${key}: ${value} '\n'` )
          : error.data[0] ? error.data[0] : 'Something went wrong')

        throw error
      }
    }
  }
}
