import { mediaPlanService } from '@mindshare/services'

import { genericCalculationFailedErrorMessage } from 'Components/MediaPlanVersion/constants/entities/ICalculationResult'
import { IMediaPlanVersion } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { CalculationResults } from 'Apis/generated/mediaPlanVersionsApi'
import { FieldDataType } from 'Constants/enums/FieldDataType'

export const msQueuedDebounce = <T = any>(action: (...args: any[]) => Promise<T>): (...args: any[]) => Promise<T> => {
  let activeTask = null
  let pendingTasks = []
  const executeNext = () => {
    if (!activeTask) {
      while (pendingTasks.length > 1) {
        pendingTasks[0].cancel()
      }
      if (pendingTasks.length === 1) {
        pendingTasks[0].execute()
      }
    }
  }
  const addTask = (t) => {
    pendingTasks = [...pendingTasks, t]
    executeNext()
  }
  const removeTask = (t) => {
    pendingTasks = pendingTasks.filter((pt) => pt !== t)
    if (activeTask === t) activeTask = null
  }
  const createTask = (args, resolve, reject) => {
    const currentTask = {
      execute: async () => {
        try {
          activeTask = currentTask
          const result = await action(...args)
          resolve(result)
          removeTask(currentTask)
          executeNext()
        } catch (e) {
          reject(e)
        }
      },
      cancel: () => {
        resolve(undefined)
        removeTask(currentTask)
      }
    }
    return currentTask
  }
  const trigger = (...args) => new Promise<T>((resolve, reject) => addTask(createTask(args, resolve, reject)))
  return trigger
}


export const get = (path: string) => mediaPlanService.get(path)
export const postJson = (path: string, body) => mediaPlanService.postJson(path, body)
export const postData = (path: string, body) => mediaPlanService.postData(path, body)
export const putJson = (path: string, body) => mediaPlanService.putJson(path, body)
export const deleteJson = (path: string, body) => mediaPlanService.deleteJson(path, body)
export const getFile = (path: string) => mediaPlanService.getFile(path)

export const runCalculationNoSave = async (clientId: number, mediaPlanVersion: IMediaPlanVersion): Promise<CalculationResults> => {
  try {
    return await postJson(`mediaPlanVersions/runCalculations/nosave?clientId=${clientId}`, mediaPlanVersion)
  } catch (error) {
    return { compilationError: true, topLevelErrors: [genericCalculationFailedErrorMessage], fieldResults: [] }
  }
}

export const getFinanceDataForPlansList = async (fieldDataTypeId: number, clientId: string | number, geographyId: number, mediaId: number) => {
  let route: string = ''
  switch (fieldDataTypeId) {
    case FieldDataType.FINANCE_PRODUCT_HIERARCHY: route = 'products'; break
    case FieldDataType.FINANCE_STATION_HIERARCHY: route = 'stations'; break
    case FieldDataType.FINANCE_BOOKING_CATEGORY_LIST: route = 'bookingCategories'; break
    case FieldDataType.FINANCE_BUYING_AUDIENCE_LIST:
    case FieldDataType.FINANCE_TARGET_AUDIENCE_LIST: route = 'audiences'; break
  }
  const url = `financeData/${route}?clientId=${clientId}&clientGeographyHierarchyId=${geographyId}&clientMediaHierarchyId=${mediaId}`
  const financeDataForPlansList = await get(url)
  return financeDataForPlansList
}

export const getCostMasterDataForPlansList = async (clientId: string | number, geographyId: number, mediaId: number) => {
  const url = `costMasterData/buyingRouteHierarchy?clientId=${clientId}&clientGeographyHierarchyId=${geographyId}&clientMediaHierarchyId=${mediaId}`
  const costMasterDataForPlansList = await get(url)
  return costMasterDataForPlansList
}

export const getMediaPlans = async (
  clientId: string | number,
  geographyHierarchyId: number,
  mediaHierarchyId: number,
  businessHierarchyId: number,
  brandHierarchyId: number
) => {
  const url = `mediaPlans?clientId=${clientId}` +
    (geographyHierarchyId ? `&geographyHierarchyId=${geographyHierarchyId}` : '') +
    (mediaHierarchyId ? `&mediaHierarchyId=${mediaHierarchyId}` : '') +
    (businessHierarchyId ? `&businessHierarchyId=${businessHierarchyId}` : '') +
    (brandHierarchyId ? `&brandHierarchyId=${brandHierarchyId}` : '')

  const mediaPlans = await get(url)
  return mediaPlans
}

export const downloadExcel = (mediaPlanVersionId: number, clientId: number, expandHierarchies: boolean, displayFlightDates: boolean) =>
  getFile(`mediaPlanVersions/${mediaPlanVersionId}/excel?clientId=${clientId}&expandHierarchies=${expandHierarchies}&displayFlightDates=${displayFlightDates}`)

export const getMediaPlanVersionHistory = (mediaPlanId: number, clientId: number) =>
  get(`mediaPlans/${mediaPlanId}/mediaPlanVersions?clientId=${clientId}`)

export const downloadMediaPlanTemplate = (mediaPlanId: number, clientId: number) =>
  getFile(`mediaPlanTemplates/${mediaPlanId}/importExample?clientId=${clientId}`)

export const downloadFrontSheetExcel = (frontSheetId: number, clientId: number) =>
  getFile(`frontSheets/${frontSheetId}/excel?clientId=${clientId}`)
