import { msAuthHandler } from '@mindshare/services'

export type exportClaimType = 'exportRun' | 'exportSetup'

export const verifyExportClaims = async (exportClaim: exportClaimType, currentClientName: string) => {
  const clientAdminClaim = {
    clientAdmin: { value: `clientAdmin_${currentClientName.replace(/\W/g, '')}` }
  }
  const userPermissions = await msAuthHandler.verifyAuthorisation(clientAdminClaim)

  if (userPermissions.clientAdmin || userPermissions.adminAuthorised) {
    return true
  }

  const user = await msAuthHandler.getUser()
  const regExp = new RegExp(`^${exportClaim}_`)
  let allClaims: any = user.profile[process.env.APPLICATION_CLAIM_TYPE]

  allClaims = Array.isArray(allClaims) ? allClaims : [allClaims]
  return allClaims.some(claim => regExp.test(claim))
}
