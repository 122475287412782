/* eslint-disable functional/immutable-data */
import { MutableRefObject, useRef } from 'react'

export const useValueRef = <T extends any>(value: T): MutableRefObject<T> => {
  const ref = useRef(value)

  ref.current = value

  return ref
}
