import { baseApi as api } from '../apis/generated/hierarchiesApi'
import { queryCacheValidTime } from './queryCacheValidTime'

const enhancedRtkApi = api.enhanceEndpoints({
  endpoints: {
    hierarchies: { keepUnusedDataFor: queryCacheValidTime.hierarchies }
  }
})
export { enhancedRtkApi as baseApi }

export const {
  useHierarchiesQuery,
  useLazyHierarchiesQuery
} = enhancedRtkApi
