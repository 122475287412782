import { useCallback, useEffect, useState } from 'react'
import * as arrayHelpers from 'Helpers/arrayHelper'
import { useValueRef } from 'Hooks/useValueRef'
import { IFilterField, IDisplayField, ICustomExport, isExcelCustom, sampleCustomExport, ISortByField } from '../constants/entities'

type Fields = 'customExportDisplayFields' | 'customExportSortByFields' | 'customExportFilters'

type Field = IDisplayField | ISortByField | IFilterField

export interface UseCustomExport {
  values: ICustomExport

  changeDisplayCurrency: (currencyDisplayId: number) => void
  changeDisplayType: (displayTypeId: number) => void
  changeShouldDistinct: (shouldDistinct: boolean) => void
  changeFullHierarchies: (shouldUseFullHierarchies: boolean) => void
  changeName: (name: string) => void
  changeGeographyHierarchy: (geographyHierarchyId: number) => void
  changeExportLatestFlag: (latestFlagId: number) => void
  changeFieldDataTypeOperator: (fieldDataTypeOperatorId: number) => void
  changePlaningDate: (date: { startDate: string; endDate: string }) => void
  selectTemplate: (file: File) => void

  pushField: (to: Fields, field: Field ) => void
  removeField: (from: Fields, index: number, cb) => void
  moveField: (within: Fields, from: number, to: number, cb) => void
  updateField: (within: Fields, at: number, item: Partial<Field>) => void

  withTemplate: boolean
  template: File
}

interface UseCustomExportParams {
  getInitialValues: () => ICustomExport
  skip: boolean
}

export const useCustomExportModel = ({ getInitialValues, skip }: UseCustomExportParams): Readonly<UseCustomExport> => {
  const [customExport, setCustomExport] = useState<ICustomExport>(sampleCustomExport)
  const [template, setTemplate] = useState<File>(null)
  const init = useValueRef(getInitialValues)

  useEffect(() => {
    if (!skip) {
      setCustomExport(init.current())
    }
  }, [init, skip])

  const changeDisplayCurrency = useCallback((currencyDisplayId: number) => {
    setCustomExport((currentCustomExport) => ({
      ...currentCustomExport,
      customExportCurrencyDisplayId: currencyDisplayId
    }))
  }, [])

  const changeDisplayType = useCallback((displayTypeId: number) => {
    setCustomExport((currentCustomExport) => ({
      ...currentCustomExport,
      customExportDisplayTypeId: displayTypeId
    }))
  }, [])

  const changeShouldDistinct = useCallback((shouldDistinct: boolean) => {
    setCustomExport((currentCustomExport) => ({
      ...currentCustomExport,
      customExportDistinct: shouldDistinct
    }))
  }, [])

  const changeFullHierarchies = useCallback((shouldUseFullHierarchies: boolean) => {
    setCustomExport((currentCustomExport) => ({
      ...currentCustomExport,
      customExportFullHierarchies: shouldUseFullHierarchies
    }))
  }, [])

  const changeGeographyHierarchy = useCallback((geographyHierarchyId: number) => {
    setCustomExport((currentCustomExport) => ({
      ...currentCustomExport,
      geographyHierarchyId
    }))
  }, [])

  const changeName = useCallback((name: string) => {
    setCustomExport((currentCustomExport) => ({
      ...currentCustomExport,
      customExportName: name
    }))
  }, [])

  const changeExportLatestFlag = useCallback((latestFlagId: number) => {
    setCustomExport((currentCustomExport) => ({
      ...currentCustomExport,
      customExportLatestFlagId: latestFlagId
    }))
  }, [])

  const changeFieldDataTypeOperator = useCallback((fieldDataTypeOperatorId: number) => {
    setCustomExport((currentCustomExport) => ({
      ...currentCustomExport,
      fieldDataTypeOperatorId,
      customExportPlanningDateStartDate: '',
      customExportPlanningDateEndDate: ''
    }))
  }, [])

  const changePlaningDate = useCallback(({ startDate, endDate }) => {
    setCustomExport((currentCustomExport) => ({
      ...currentCustomExport,
      customExportPlanningDateStartDate: startDate,
      customExportPlanningDateEndDate: endDate
    }))
  }, [])

  const selectTemplate = useCallback((file: File) => {
    setTemplate(file)
  }, [])

  const pushField = useCallback((
    to: Fields,
    item: Field
  ) => {
    setCustomExport((currentCustomExport) => ({
      ...currentCustomExport,
      [to]: arrayHelpers.push<Field>(currentCustomExport[to], item)
    }))
  }, [])

  const removeField = useCallback((from: Fields, fieldIndex: number, cb) => {
    setCustomExport((currentCustomExport) => {
      const isLast = currentCustomExport[from].length === 1

      if (isLast) {
        return {
          ...currentCustomExport,
          [from]: sampleCustomExport[from]
        }
      }

      return ({
        ...currentCustomExport,
        [from]: arrayHelpers.remove<Field>(currentCustomExport[from], fieldIndex, cb)
      })
    })
  }, [])


  const moveField = useCallback((within: Fields, fromIndex: number, toIndex: number, cb) => {
    setCustomExport((currentCustomExport) => ({
      ...currentCustomExport,
      [within]: arrayHelpers.move<Field>(currentCustomExport[within], fromIndex, toIndex, cb)
    }))
  }, [])

  const updateField = useCallback((
    within: Fields,
    at: number,
    item: Partial<Field>) => {
    setCustomExport((currentCustomExport) => ({
      ...currentCustomExport,
      [within]: arrayHelpers.update<Field>(currentCustomExport[within], at, item)
    }))
  }, [])

  return {
    values: customExport,

    changeDisplayCurrency,
    changeDisplayType,
    changeShouldDistinct,
    changeFullHierarchies,
    changeName,
    changeGeographyHierarchy,
    changeExportLatestFlag,
    changeFieldDataTypeOperator,
    changePlaningDate,
    selectTemplate,

    pushField,
    removeField,
    moveField,
    updateField,

    withTemplate: isExcelCustom(customExport.customExportDisplayTypeId),
    template
  }
}
