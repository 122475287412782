import React, { useEffect, useState } from 'react'
import { Switch } from 'antd'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import AliasTable from './ClientFieldAliasTable'
import { PutClientMediaPlanFieldsByClientMediaPlanFieldIdApproveAliasApiArg } from 'Apis/generated/clientFieldAliasApi'

interface ClientFieldAliasSetupProps {
  clientId: number
  data: IClientMediaPlanField[]
  updateStatus: (data: PutClientMediaPlanFieldsByClientMediaPlanFieldIdApproveAliasApiArg) => void
  updateCache: (endpoint: string, fieldName: string, fieldId: number, approved: boolean) => void
}

export default function ClientFieldAliasSetup (props: ClientFieldAliasSetupProps) {
  const { clientId, data, updateStatus, updateCache } = props
  const [editedItems, setEditedItems] = useState<number[]>([])
  const [showApproved, setShowApproved] = useState(false)
  const [clientFieldAliases, setClientFieldAliases] = useState(data.filter(x => !x.approvedBy))

  useEffect(() => {
    const aliases = showApproved ? data : data.filter(x => !x.approvedBy || editedItems.find(y => y === x.clientMediaPlanFieldId))
    setClientFieldAliases(aliases)
  }, [data, showApproved, editedItems])

  const updateShowApproved = (show: boolean) => {
    setEditedItems([])
    setShowApproved(show)
  }

  const doUpdateStatus = async (clientMediaPlanFieldId: number, approve: boolean) => {
    setEditedItems([...editedItems, clientMediaPlanFieldId])
    updateCache('getClientMediaPlanFields', 'clientMediaPlanFieldId', clientMediaPlanFieldId, approve)
    updateStatus({ clientId, clientMediaPlanFieldId, approveAliasRequest: { approve } })
  }

  return (
    <div className="aliasTab clientAliasTab">
      <div>
        <h2>Client Field Alias Approvals {showApproved ? '- All' : ' - Unapproved'}</h2>

        <div className='aliasTab__toggle'>
          <span>Show approved</span>
          <Switch
            size='small'
            onClick={() => updateShowApproved(!showApproved)}
            data-testid='btn-toggle-calculation'
            aria-label='show approved'
            checked={showApproved}
          />
        </div>
      </div>

      <AliasTable data={clientFieldAliases} updateStatus={doUpdateStatus} />
    </div>
  )
}
