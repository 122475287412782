import { baseApi as api } from '../apis/generated/clientFieldAliasApi'

const enhancedRtkApi = api.enhanceEndpoints({
  endpoints: {
    getClientMediaPlanFields: { providesTags: ['clientAliasFields'] },
    putClientMediaPlanFieldsByClientMediaPlanFieldIdApproveAlias: { invalidatesTags: ['clientAliasFields'] }
  }
})
export { enhancedRtkApi as baseApi }

export const {
  useGetClientMediaPlanFieldsQuery,
  usePutClientMediaPlanFieldsByClientMediaPlanFieldIdApproveAliasMutation
} = enhancedRtkApi
