import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { Button, DatePicker } from 'antd'
import { useAppDispatch, useAppSelector } from '../store'
import { dateFormat, toMomentDate } from 'Helpers/calendarHelper'
import { setSelectedFlights, setSelectedDates, pasteFlight, setFlightPasteModalStartDate, copyFlight } from 'Actions/mediaPlansActions'
import ModalComponent from 'Components/ModalComponent'
import { selectFlightGroups, selectCopiedFlightData, selectCopiedFlight, selectPlanStartDate, selectSelectedFlights } from '../selectors'
import { validateDatesOverlapWithFlights } from 'Helpers/flightHelper'
import AlertConfirmationComponent from './AlertConfirmationComponent'
import Selection from '@simonwep/selection-js'
import { MouseEventButton } from 'Constants/enums/MouseEventButton'
import { KeyCode } from 'Constants/enums/KeyCode'
import { useFlightCommands } from './MediaPlanVersion/hooks/useFlightCommands'

export const FlightModalPasteComponent: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const flightPasteModalStartDate = useAppSelector(state => state.mediaPlans.flightPasteModalStartDate)
  const selectedFlights = useAppSelector(selectSelectedFlights)

  const { getMergeDateRange } = useFlightCommands(selectedFlights)
  const [pasteStartDate, setPasteStartDate] = useState<string>()
  const planStartDate = useAppSelector(state => selectPlanStartDate(state))
  const flightGroups = useAppSelector(state => selectFlightGroups(state))
  const copiedFlight = useAppSelector(state => selectCopiedFlight(state))
  const copiedFlightData = useAppSelector(state => selectCopiedFlightData(state))
  const [, selectedFlightIndex, selectedFlightGroupIndex] = (selectedFlights[0]?.split('-') ?? [])
  const isDateOverlappingWithExistingFlights =
    pasteStartDate &&
    copiedFlight &&
    copiedFlightData &&
    flightGroups[selectedFlightGroupIndex]?.flights &&
    validateDatesOverlapWithFlights(
      pasteStartDate,
      moment.utc(pasteStartDate).add(moment(copiedFlightData.flightEndDate).diff(copiedFlightData.flightStartDate, 'days'), 'days'),
      flightGroups[selectedFlightGroupIndex].flights
    )

  const startDateMoment = toMomentDate(pasteStartDate)


  const handlePasteFlight = useCallback(() => {
    if (!copiedFlight) {
      return
    }
    dispatch(setFlightPasteModalStartDate(''))
    const selectedFlightData = flightGroups[selectedFlightGroupIndex]?.flights?.[selectedFlightIndex]
    if (selectedFlightData &&
      (Number(selectedFlightIndex) !== copiedFlight.flightIndex || Number(selectedFlightGroupIndex) !== copiedFlight.flightGroupIndex)
    ) {
      dispatch(setFlightPasteModalStartDate(selectedFlightData.flightStartDate))
    }
  }, [dispatch, copiedFlight, selectedFlightIndex, selectedFlightGroupIndex, flightGroups])

  const handlePasteStartDateChange = useCallback((date: moment.Moment) => {
    setPasteStartDate(date && date.format(dateFormat))
  }, [setPasteStartDate])

  const closeFlightPasteModal = useCallback(() => {
    dispatch(setFlightPasteModalStartDate(null))
    setPasteStartDate(null)
  }, [dispatch])

  const confirmFlightPaste = useCallback(() => {
    if (!pasteStartDate) {
      return
    }
    dispatch(pasteFlight(pasteStartDate, Number(selectedFlightGroupIndex)))
    dispatch(setFlightPasteModalStartDate(null))
    setPasteStartDate(null)
    dispatch(copyFlight(null))
    dispatch(setSelectedFlights([]))
    dispatch(setSelectedDates([]))
  }, [dispatch, pasteStartDate, selectedFlightGroupIndex])

  useEffect(() => {
    if (typeof flightPasteModalStartDate === 'string' && !pasteStartDate) {
      setPasteStartDate(flightPasteModalStartDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightPasteModalStartDate])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const handlers = {
        [`ctrl+${KeyCode.V}`]: () => {
          return handlePasteFlight()
        }
      }
      const handlerKey = [(event.ctrlKey || event.metaKey) && 'ctrl', event.keyCode].filter(Boolean).join('+')
      const handler = handlers[handlerKey]

      if (handler) {
        handler()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [handlePasteFlight])

  useEffect(() => {
    let select = null
    if (flightGroups && flightGroups.length > 0 && flightGroups.some(d => d.flights && d.flights.length > 0)) {
      select = Selection.create({
        class: 'selection',
        selectables: ['.flight__gridItem'],
        boundaries: ['.msCalendar']
      }).on('beforestart', evt => {
        const isLeftClick = (evt.oe as MouseEvent).button === MouseEventButton.MOUSE_BUTTON_LEFT
        const emptyCell = (evt.oe.target as any)?.classList?.contains('emptyCell')
        return isLeftClick && emptyCell
      }).on('start', ({ inst, selected, oe }) => {
        if (!oe.ctrlKey && !oe.metaKey) {
          for (const el of selected) {
            el.classList.remove('selected')
            inst.removeFromSelection(el)
          }
          inst.clearSelection()
        }
      }).on('move', ({ changed: { removed, added } }) => {
        for (const el of added) {
          el.classList.add('selected')
        }
        for (const el of removed) {
          el.classList.remove('selected')
        }
      }).on('stop', ({ inst, selected }) => {
        const flightsSelected = selected.map(s => s.getAttribute('data-key')).filter(s => s)
        if (flightsSelected.length > 1) {
          const dateSelection = getMergeDateRange(flightsSelected[0], flightsSelected[flightsSelected.length - 1])
          dispatch(setSelectedDates(dateSelection))
          dispatch(setSelectedFlights([...flightsSelected]))
        }
        for (const el of selected) {
          el.classList.remove('selected')
        }
        inst.clearSelection()
      })
    }

    return () => select && select.destroy()
  }, [dispatch, flightGroups, getMergeDateRange, selectedFlights.length])

  return (
    <ModalComponent
      title='Paste Flight Options'
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type='link' onClick={closeFlightPasteModal}>Cancel</Button>
          <Button type='primary' onClick={confirmFlightPaste} disabled={!pasteStartDate}>Set Date</Button>
        </div>
      }
      modalBody={
        <div className="flight-modal-paste">
          <label>Select Flight Start Date</label>
          <DatePicker
            data-testid="flight-modal-paste-datepicker"
            defaultPickerValue={startDateMoment || toMomentDate(planStartDate)}
            placeholder="Enter date"
            format='DD-MMM-YYYY'
            value={startDateMoment}
            onChange={handlePasteStartDateChange}
          />

          <AlertConfirmationComponent
            showWarning={isDateOverlappingWithExistingFlights}
            description="There is already a flight there which will be removed if you proceed. Do you want to replace it?"
          />
        </div>
      }
      onCancel={closeFlightPasteModal}
      visible={typeof flightPasteModalStartDate === 'string'}
    />
  )
}
