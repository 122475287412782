import { MSColours } from 'mindshare.layout'
import { msAuthHandler } from '@mindshare/services'

import { IUserPermissions } from 'Constants/entities/IUserPermissions'

import menuItems, {
  clientSetupItem,
  importItem,
  templateListItem,
  reportItem,
  aliasSetup,
  customExportsItem
} from 'Constants/menuItems'
import { Client } from 'Apis/generated/clientsApi'
import { verifyExportClaims } from './verifyExportClaims'

export const authoriseMediaPlanningTool = async () => {
  const { userAuthorised } = await msAuthHandler.verifyAuthorisation()

  if (!userAuthorised) {
    msAuthHandler.handleForbidden()
  }
  return { userAuthorised }
}

export const renderSideNavMenuItemsBasedOnClaims = async (setSideNav, path: string, userPermissions: IUserPermissions, navigate, currentClientMptReportUrl: string, currentClientName: string) => {
  let enableTemplateList = false
  let enableClientSetup = false
  let enableImport = false
  let enableCustomExport = false
  let enableReport = false

  const newMenuItems = new Set()
  const isExportPermitted = await verifyExportClaims('exportSetup', currentClientName) || await verifyExportClaims('exportRun', currentClientName)

  if (userPermissions.template) {
    enableTemplateList = true
    newMenuItems.add(templateListItem)
  }

  if (userPermissions.submitSetUp) {
    enableTemplateList = true
    enableClientSetup = true
    newMenuItems.add(templateListItem)
    newMenuItems.add(clientSetupItem)
  }

  if (userPermissions.clientAdmin || userPermissions.adminAuthorised) {
    enableTemplateList = true
    enableClientSetup = true
    enableCustomExport = true
    newMenuItems.add(templateListItem)
    newMenuItems.add(clientSetupItem)
    newMenuItems.add(customExportsItem)
  }
  if (userPermissions.adminAuthorised) {
    enableImport = true
    newMenuItems.add(importItem)
    newMenuItems.add(aliasSetup)
  }
  if (isExportPermitted) {
    enableCustomExport = true
    newMenuItems.add(customExportsItem)
  }

  if (currentClientMptReportUrl != null) {
    enableReport = true
    newMenuItems.add(reportItem)
  }


  if (
    (!enableTemplateList && path.includes('template')) ||
    (!enableClientSetup && path.includes('client')) ||
    (!enableImport && path.includes('import')) ||
    (!enableReport && path.includes('report')) ||
    (!enableCustomExport && path.includes('custom-export'))
  ) {
    navigate('/')
  }

  setSideNav([...menuItems, ...newMenuItems])
}

export const setColours = (currentClient: Client) => {
  if (currentClient.agency?.primaryColor) {
    return {
      primary: currentClient.agency?.primaryColor,
      secondary: currentClient.agency?.secondaryColor,
      tertiary: currentClient.agency?.tertiaryColor,
      quaternary: currentClient.agency?.quaternaryColor,
      quinary: currentClient.agency?.quinaryColor
    }
  }
  return MSColours
}
