
import React, { FC } from 'react'
import { CustomExportFieldDataTypeOperator } from 'Apis/generated/customExportsApi'

interface IProps {
  operator: CustomExportFieldDataTypeOperator
}

export const NotSupportedOperator: FC<IProps> = ({ operator }) => (
  <div>
    {operator.fieldDataTypeOperator} is not supported
  </div>
)
