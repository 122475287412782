import { useMemo } from 'react'
import { IFlight } from '../constants/entities/IFlight'

interface IUseFlightSelectHelper {
  selectedFlights: string[]
  flightsDates: IFlight[]
}

export const useFlightSelectHelper = ({
  selectedFlights,
  flightsDates
}: IUseFlightSelectHelper) => {
  const firstSelectedFlight = useMemo(() => {
    const firstSelectedFlightId = selectedFlights?.[0]
    const [, selectedFlightIndex] = firstSelectedFlightId?.split('-') ?? []

    if (typeof selectedFlightIndex !== 'string') {
      return undefined
    }

    const selectedFlightData: IFlight = flightsDates[selectedFlightIndex]
    return selectedFlightData
  }, [flightsDates, selectedFlights])

  const lastSelectedFlight = useMemo(() => {
    const lastSelectedFlightId = selectedFlights?.[selectedFlights.length - 1]
    const [, selectedFlightIndex] = lastSelectedFlightId?.split('-') ?? []

    if (typeof selectedFlightIndex !== 'string') {
      return undefined
    }

    const selectedFlightData: IFlight = flightsDates[selectedFlightIndex]
    return selectedFlightData
  }, [flightsDates, selectedFlights])

  const firstSelectedFlightIndex = useMemo(
    () => (selectedFlights?.[0]?.split('-') ?? [])[1],
    [selectedFlights]
  )

  const lastSelectedFlightIndex = useMemo(
    () => (selectedFlights?.[selectedFlights.length - 1]?.split('-') ?? [])[1],
    [selectedFlights]
  )

  return useMemo(
    () => ({
      firstSelectedFlight,
      lastSelectedFlight,
      firstSelectedFlightIndex,
      lastSelectedFlightIndex
    }),
    [
      firstSelectedFlight,
      lastSelectedFlight,
      firstSelectedFlightIndex,
      lastSelectedFlightIndex
    ]
  )
}
