import React, { FunctionComponent, memo, useCallback, useMemo } from 'react'
import { useMindshareSelector } from 'mindshare.customhooks'
import { IMSHierarchies } from 'mindshare.layout'

import { RootState } from '../../redux/reducers'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import SubFlightFieldContainer from './SubFlightFieldContainer'
import { clearFlightData, copyFlight, deleteSubFlight, mergeSubFlight, setSelectedDates, setSelectedFlights } from 'Actions/mediaPlansActions'
import { useAppDispatch } from '../../store'
import { IClickableFieldProps, useClickableField } from 'Components/MediaPlanVersion/hooks'
import { IFlight } from 'Components/MediaPlanVersion/constants/entities/IFlight'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'

interface IProps {
  calculationMode: boolean
  flightGroupId: number
  flightGroupIndex: number
  unfilteredHierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  tokenHandler: (token: string) => void
  handleAddField: (fieldType: FieldLevelType, flightGroupIndex?: number) => void
  onAvailableFieldSelected: (mediaPlanFieldSelected: string, flightGroupIndex?: number) => void
  subFlightFields: IMediaPlanTemplateFields[]
  isCollapsed?: boolean
  compressedCalendarView: boolean
  clickableFieldProps: IClickableFieldProps
  isSelectedFlightCellEmpty: boolean
  selectedSubFlights: string[]
  isSubtotalGroup?: boolean
  flights?: IFlight[]
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
}

export const SubFlightsContainer: FunctionComponent<IProps> = ({
  calculationMode,
  flightGroupId,
  flightGroupIndex,
  unfilteredHierarchies,
  masteredListsData,
  tokenHandler,
  subFlightFields,
  isCollapsed,
  compressedCalendarView,
  clickableFieldProps,
  isSelectedFlightCellEmpty,
  selectedSubFlights,
  isSubtotalGroup,
  flights,
  financeListFieldsData
}: IProps) => {
  const dispatch = useAppDispatch()
  const planStartDate = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.parseData?.planStartDate || '')
  const planEndDate = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.parseData?.planEndDate || '')
  const startDayOfWeek = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.parseData?.startDayOfWeek || 'Monday') as string
  const { isFieldValidForClick } = useClickableField(clickableFieldProps)

  const handleDeleteSubFlight = useCallback(
    (currentFlightGroupIndex: number, flightIndex: number, subFlightIndex: number) =>
      dispatch(deleteSubFlight(currentFlightGroupIndex, flightIndex, subFlightIndex)),
    [dispatch]
  )

  const handleClearFlightData = useCallback(
    (currentFlightGroupIndex: number, flightIndex: number) =>
      dispatch(clearFlightData(currentFlightGroupIndex, flightIndex)),
    [dispatch]
  )

  const handleCopyFlight = useCallback(
    () =>
      dispatch(copyFlight()),
    [dispatch]
  )

  const handleMergeSubFlight = useCallback(
    (startDate: Date, enDate: Date, mergeByWeek: boolean, currentFlightGroupIndex: number, flightIndex: number, mergeByMonth: boolean, clientMediaPlanFieldId: number) => {
      dispatch(mergeSubFlight(startDate, enDate, mergeByWeek, startDayOfWeek, currentFlightGroupIndex, flightIndex, mergeByMonth, clientMediaPlanFieldId))
      dispatch(setSelectedFlights([]))
      dispatch(setSelectedDates([]))
      dispatch(copyFlight(null))
    },
    [dispatch, startDayOfWeek]
  )

  const sortedSubFlightFields = useMemo(
    () => subFlightFields && [...subFlightFields]
      .filter(c => c.mediaPlanVersionFieldId !== 0)
      .sort((a, b) => a.sortOrder - b.sortOrder),
    [subFlightFields]
  )

  if (sortedSubFlightFields.length) {
    return (
      <div className='flight__chosenItem -subFlight'>
        {
          sortedSubFlightFields.map((subFlight, key) => (
            <SubFlightFieldContainer
              key={key}
              valid={isFieldValidForClick(subFlight)}
              flightGroupId={flightGroupId}
              flightGroupIndex={flightGroupIndex}
              mediaPlanField={subFlight}
              calculationMode={calculationMode}
              unfilteredHierarchies={unfilteredHierarchies}
              masteredListsData={masteredListsData}
              planEndDate={planEndDate}
              planStartDate={planStartDate}
              tokenHandler={tokenHandler}
              deleteFlight={handleDeleteSubFlight}
              clearFlightData={handleClearFlightData}
              copySelectedFlight={handleCopyFlight}
              mergeSubFlightSelection={handleMergeSubFlight}
              isSelectedFlightCellEmpty={isSelectedFlightCellEmpty}
              isCollapsed={isCollapsed}
              compressedCalendarView={compressedCalendarView}
              selectedSubFlights={selectedSubFlights}
              isSubtotalGroup={isSubtotalGroup}
              flights={flights}
              financeListFieldsData={financeListFieldsData}
            />
          ))
        }
      </div>
    )
  }
  return null
}

export default memo(SubFlightsContainer)
