import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  SortableListItem,
  SortableListCell,
  DragIcon
} from 'Components/shared/SortableList'
import { SortDirectionSelector } from './SortDirectionSelector'
import { CustomExportFieldActions } from './CustomExportFieldActions'
import { CustomExportFieldSelector } from './CustomExportFieldSelector'

export const CustomExportSortByFieldItemComponent = ({
  fieldValue,
  fieldLabel,
  fieldIndex,
  handleUpdate,
  filteredOptions,
  item,
  canRemove,
  handleRemove,
  handlePush
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: item.itemRowId })

  const style: React.CSSProperties = {
    transition,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    ...(isDragging ? { position: 'relative' } : {})
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <SortableListItem>
        <SortableListCell>
          <CustomExportFieldSelector
            value={{ value: fieldValue, label: fieldLabel }}
            onChange={handleUpdate(fieldIndex)}
            options={filteredOptions}
          />
        </SortableListCell>
        <SortableListCell>
          <SortDirectionSelector
            value={item.sortAscending}
            onChange={handleUpdate(fieldIndex)}
          />
        </SortableListCell>
        <SortableListCell className='actions-cell'>
          <CustomExportFieldActions
            canRemove={canRemove}
            onRemove={handleRemove(fieldIndex)}
            onPush={handlePush}
          />
        </SortableListCell>
        <SortableListCell className='sortable-list__drag-icon'>
          <div ref={setActivatorNodeRef} {...listeners}>
            <DragIcon />
          </div>
        </SortableListCell>
      </SortableListItem>
    </div>
  )
}

export default CustomExportSortByFieldItemComponent
