import { createSelector } from 'reselect'
import { selectApp } from '../selectors'

export const selectCollapsedFlightGroups = createSelector(
  selectApp,
  app => app.collapsedFlightGroups
)

export const createCollapsedFlightGroupsSelector = () =>
  createSelector(
    selectCollapsedFlightGroups,
    (state, flightGroupId) => flightGroupId,
    (collapsedFlightGroups, flightGroupId) =>
      collapsedFlightGroups.includes(flightGroupId)
  )
