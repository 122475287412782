import React, { useCallback } from 'react'
import { Button, Space, Select } from 'antd'
import { MSClientHierarchySelection, TOP_LEVEL_HIERARCHIES } from 'mindshare.layout'

import { IMediaPlans } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import { MediaPlanTemplate } from 'Apis/generated/templatesApi'
import { useFilteredHierarchies } from 'Hooks/CustomHooks'
import ModalComponent from 'Components/ModalComponent'
import useMediaPlanHierarchyValidation from 'Hooks/useMediaPlanHierarchyValidation'
import { useAppSelector } from '../../store'
import { useClientSettings } from 'Components/Client/hooks/useClientSettings'

interface IMediaPlanCreateModalProps {
  visible: boolean
  hideModal: () => void
  mediaPlan: IMediaPlans
  mediaPlanTemplate: MediaPlanTemplate
  hierarchiesLoading: boolean
  selectMediaPlan: (plan: IMediaPlans) => void
  saveMediaPlan: () => void
  selectTemplate: (plan: IMediaPlans, templateId: number) => void
}

export const MediaPlanCreateModalContainer = ({
  visible,
  hideModal,
  mediaPlan,
  mediaPlanTemplate,
  hierarchiesLoading,
  selectMediaPlan,
  saveMediaPlan,
  selectTemplate
}: IMediaPlanCreateModalProps) => {
  const { data: clientSettings } = useClientSettings()
  const mediaPlanTemplates = useAppSelector(state => state.mediaPlanTemplates.mediaPlanTemplates)
  const { filteredHierarchies } = useFilteredHierarchies(
    mediaPlanTemplate.brandHierarchyId,
    mediaPlanTemplate.businessHierarchyId,
    mediaPlanTemplate.geographyHierarchyId,
    mediaPlanTemplate.mediaHierarchyId
  )
  const {
    hierarchyValidationPassed,
    requiredTemplateHierarchies
  } = useMediaPlanHierarchyValidation(mediaPlanTemplate, mediaPlan, visible)

  const hierarchiesDisabled = TOP_LEVEL_HIERARCHIES
    .reduce((map, name) => ({ ...map, [name]: !mediaPlanTemplate.mediaPlanTemplateId }), {})

  const onTemplateSelectionChange = useCallback((templateId: number) =>
    selectTemplate(mediaPlan, templateId)
  , [mediaPlan, selectTemplate])

  const onHierarchyFilterSelectionChange = useCallback((hierarchyname: string, id: number) =>
    selectMediaPlan({ ...mediaPlan, [`${hierarchyname}HierarchyId`]: id })
  , [mediaPlan, selectMediaPlan])

  return (
    <ModalComponent
      width='750px'
      title='Create Plan'
      visible={visible}
      onCancel={hideModal}
      wrapProps={{ 'data-testid': 'create-media-plan-modal' }}
      modalBody={(
        <>
          <h3 className='title'>Select a template</h3>
          <Select
            placeholder='Select a template'
            className='select-mediaplan-template'
            optionFilterProp='children'
            value={mediaPlanTemplate.mediaPlanTemplateId || undefined}
            onChange={onTemplateSelectionChange}
            showSearch={true}
            allowClear={true}
          >
            {mediaPlanTemplates.map(t => (
              <Select.Option
                key={t.mediaPlanTemplateId}
                value={t.mediaPlanTemplateId}
              >
                {t.templateName}
              </Select.Option>
            ))}
          </Select>

          <h3 className='title'>Select hierarchy values</h3>
          <div className='hierarchies-container' data-testid='media-plan-hierarchies-modal--body'>
            <MSClientHierarchySelection
              hierarchies={filteredHierarchies}
              selectedGeography={mediaPlan.geographyHierarchyId}
              selectedMedia={mediaPlan.mediaHierarchyId}
              selectedBusiness={mediaPlan.businessHierarchyId}
              selectedBrand={mediaPlan.brandHierarchyId}
              onChange={onHierarchyFilterSelectionChange}
              loading={hierarchiesLoading}
              required={requiredTemplateHierarchies}
              disabled={hierarchiesDisabled}
              testIdPrefix='hierarchy-select'
              showLabels={true}
            />

            <div>
              <label aria-label="hierarchy-dropdown__label--year">Year *</label>
              <Select
                placeholder='Select plan year *'
                className='select-mediaplan-year'
                onChange={(value: number) => selectMediaPlan({ ...mediaPlan, planYear: value })}
                optionFilterProp='children'
                disabled={!mediaPlanTemplate.mediaPlanTemplateId}
                value={mediaPlan.planYear || undefined}
                allowClear={true}
                showSearch={true}
              >
                {clientSettings.availablePlanYears?.map(year => (
                  <Select.Option key={year} value={year}>
                    {year}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </>
      )}
      footer={(
        <Space>
          <Button type='link' onClick={hideModal}>Cancel</Button>
          <Button
            type='primary'
            onClick={saveMediaPlan}
            disabled={!hierarchyValidationPassed}
            aria-label='Save Plan'
          >Save</Button>
        </Space>
      )}
    />
  )
}

export default MediaPlanCreateModalContainer
