import * as actionTypes from 'Constants/actionTypes'
import * as mediaPlanService from '../../services/mediaPlanService'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { ClientFieldValue } from 'Apis/generated/clientFieldAliasApi'
import { message } from 'antd'

interface ISubmitClientMediaPlanDataAction {
  type: actionTypes.SET_CLIENT_MEDIA_FIELD_DATA
  payload: IClientMediaPlanField[]
}

interface IClientMediaPlanDataReadyAction {
  type: actionTypes.CLIENT_MEDIA_FIELD_READY
  payload: IClientMediaPlanField[]
}


export type ClientMediaPlanDataAction =
  | ISubmitClientMediaPlanDataAction
  | IClientMediaPlanDataReadyAction


export const setClientMediaPlanFieldData = (data: IClientMediaPlanField[]): any => dispatch => {
  dispatch({
    type: actionTypes.SET_CLIENT_MEDIA_FIELD_DATA,
    payload: data
  })
}

export const submitClientMediaPlanFieldData = async (data: IClientMediaPlanField[], clientId: number, validate = true) => {
  const clientMediaPlanFieldData = data.map(c => ({ ...c, clientFieldValues: null }))

  try {
    await mediaPlanService.postJson(`clientMediaPlanFields?clientId=${clientId}&validate=${validate}`, clientMediaPlanFieldData)
    return null
  } catch (error) {
    if (error.httpResponseBody) {
      return error.httpResponseBody
    } else {
      return 'There was an internal error'
    }
  }
}

export const getClientMediaPlanFields = (clientId: number) => async (dispatch): Promise<void> => {
  const clientMediaPlanFields: IClientMediaPlanField[] = await mediaPlanService.get(`clientMediaPlanFields?clientId=${clientId}`)
  clientMediaPlanFields.forEach(c => {
    // eslint-disable-next-line functional/immutable-data
    c.isVisible = true
    // eslint-disable-next-line functional/immutable-data
    c.savedFieldLabel = c.fieldLabel
    // eslint-disable-next-line functional/immutable-data
    c.includeInSubtotals = c.isApportioned || c.includeInSubtotals
  })
  dispatch({ type: actionTypes.SET_CLIENT_MEDIA_FIELD_DATA, payload: clientMediaPlanFields })
}

export const saveClientDefinedListValues = async (data: ClientFieldValue[], mediaPlanFieldId: number, clientId: number) => {
  try {
    await mediaPlanService.postJson(`mediaPlanField/${mediaPlanFieldId}/clientDefinedListValues?clientId=${clientId}`, data)
    return null
  } catch (error) {
    if (error.httpResponseBody) {
      message.error(error.httpResponseBody)
      return error.httpResponseBody
    } else {
      return 'There was an internal error'
    }
  }
}

