import React, { FunctionComponent, useEffect, useState } from 'react'
import { useMindshareSelector } from 'mindshare.customhooks'
import { IMSHierarchies } from 'mindshare.layout'

import { getRedistributions, setCurrentRedistribution } from 'Actions/mediaPlansActions'
import { RedistributionCreateContainer } from 'Components/Redistribution/containers/RedistributionCreateContainer'
import { RedistributionContainer } from 'Components/Redistribution/containers/RedistributionContainer'
import { RootState } from '../../redux/reducers'
import { useAppDispatch } from '../../store'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  hideModal: () => void
  visible: boolean
  hierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  financeList: IMediaPlanVersionFinanceListFields
}

export const RedistributionModalContainer: FunctionComponent<IProps> = ({
  hideModal,
  visible,
  hierarchies,
  masteredListsData,
  financeList
}) => {
  const dispatch = useAppDispatch()
  const [createModalVisible, setCreateModalVisible] = useState(false)
  const mediaPlanId = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion?.parseData?.mediaPlanId || '')
  const currentClientId = useMindshareSelector((state: RootState) => state.app.currentClient.id) as number

  useEffect(() => {
    dispatch(getRedistributions(mediaPlanId, currentClientId))
  }, [dispatch, mediaPlanId, currentClientId])

  const showCreateModal = () => {
    setCreateModalVisible(true)
  }

  const hideCreateModal = () => {
    setCreateModalVisible(false)
  }

  const onCancel = () => {
    dispatch(setCurrentRedistribution(null))
    hideCreateModal()
    hideModal()
  }

  return (
    <>
      {createModalVisible
        ? <RedistributionCreateContainer hideCreateModal={hideCreateModal} hideModal={onCancel} visible={createModalVisible} hierarchies={hierarchies} masteredListsData={masteredListsData} financeList={financeList} />
        : <RedistributionContainer showCreateModal={showCreateModal} hideModal={onCancel} visible={visible} hierarchies={hierarchies} masteredListsData={masteredListsData} financeList={financeList} />}
    </>
  )
}
