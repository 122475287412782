import React from 'react'
import { Select } from 'antd'
import { IMediaPlanTemplateFields, getCorrectDisplayField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
const { Option } = Select

interface IFieldAvailable {
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  onAvailableFieldSelected: (selected: string) => void
}

export const FieldAvailableComponent: React.FunctionComponent<IFieldAvailable> = ({ onAvailableFieldSelected, availableMediaPlanFields }) => {
  return (
    <div data-testid="field-available">
      <Select
        placeholder='Select an available field'
        className='select-field'
        onChange={onAvailableFieldSelected}
        allowClear={true}
        showSearch={true}
        optionFilterProp='children'
      >
        {availableMediaPlanFields.map(a => (<Option key={JSON.stringify(a)} value={JSON.stringify(a)}>{getCorrectDisplayField(a)}</Option>))}
      </Select>
    </div>
  )
}

export default FieldAvailableComponent

