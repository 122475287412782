import { isClientHierarchyType } from 'mindshare.layout'
import { FieldDataTypeRead } from 'Apis/generated/fieldDataTypesApi'

export enum frontSheetValidationError {
  duplicateField,
  fieldLevelIdTooLow,
  missingField,
  laydownDuplicateField
}

export const isDropDownType = (dataTypeName: string) =>
  dataTypeName &&
  (dataTypeName === 'Client defined list' ||
    dataTypeName === 'Media partners' ||
    dataTypeName === 'Finance target audience list' ||
    dataTypeName === 'Finance buying audience list' ||
    dataTypeName === 'Finance booking category list')

export const isDateType = (dataTypeName: string) => dataTypeName?.toLowerCase().includes('date')

export const isClientDefinedListType = (dataTypeName: string) => dataTypeName?.toLowerCase().includes('client defined list')

export const isMediaPartnersType = (dataTypeName: string) => dataTypeName?.toLowerCase().includes('media partners')

export const isClientCampaignsType = (dataTypeName: string) => dataTypeName?.toLowerCase().includes('campaign list')

export const isClientAgenciesType = (dataTypeName: string) => dataTypeName?.toLowerCase().includes('agency list')

export const isUniqueStringType = (dataTypeName: string) => dataTypeName?.toLowerCase().includes('unique string')

export const isRichTextType = (dataTypeName: string) => dataTypeName && dataTypeName.includes('Rich text')

export const isFinanceTargetAudienceListType = (dataTypeName: string) => dataTypeName && dataTypeName.includes('Finance target audience list')

export const isFinanceBuyingAudienceListType = (dataTypeName: string) => dataTypeName && dataTypeName.includes('Finance buying audience list')

export const isFinanceBookingCategoryList = (dataTypeName: string) => dataTypeName && dataTypeName.includes('Finance booking category list')

export const isFinanceProductHierarchyType = (dataTypeName: string) => dataTypeName && dataTypeName.includes('Finance product hierarchy')

export const isFinanceStationHierarchyType = (dataTypeName: string) => dataTypeName && dataTypeName.includes('Finance station hierarchy')

export const isCostBuyingRouteHierarchyType = (dataTypeName: string) => dataTypeName && dataTypeName.includes('Cost buying route hierarchy')

export const usesDropDownInput = (dataTypeName: string) =>
  isClientDefinedListType(dataTypeName) ||
  isMediaPartnersType(dataTypeName) ||
  isClientCampaignsType(dataTypeName) ||
  isClientAgenciesType(dataTypeName) ||
  isUniqueStringType(dataTypeName) ||
  isFinanceTargetAudienceListType(dataTypeName) ||
  isFinanceBuyingAudienceListType(dataTypeName) ||
  isFinanceBookingCategoryList(dataTypeName)

export const usesTreeInput = (dataTypeName: string) =>
  isClientHierarchyType(dataTypeName)

export const fakeDateDataType = { fieldDataTypeId: 50 } as FieldDataTypeRead
