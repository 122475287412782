const text = `# Front Sheet List
This page will display all Front Sheets you have access to in modified date order (most recent first). Front Sheets can be searched for based on their Front Sheet ID, Front Sheet Name and Modified By.

## Create
Selecting Create Front Sheet will direct you to the plan selection to allow you to create a new Front Sheet.

## View
This will take you to the Front Sheet view page, where you are able to see the Front Sheet that has been created.

## Edit
This will take you to the same page as you are directed to when you create a new Front Sheet. From this page you can include any additional plans and make any amendments to the features that have previously been set up in the Front Sheet.

## Delete
This will delete the selected Front Sheet. Once you select delete, you must then confirm you actually want to delete the Front Sheet. Once it is deleted you will not be able to find that Front Sheet again.

## Download
Clicking download will export an excel version of your chosen Front Sheet to your computer. The excel export will include a sheet per view in your Front Sheet and a sheet for each plan included in the Front Sheet.
`

export default text
