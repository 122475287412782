import React, { useRef, FunctionComponent, useEffect, CSSProperties, useMemo, useCallback } from 'react'
import { IMSHierarchies } from 'mindshare.layout'
import FlightGroupHeaderContainer from 'Containers/MediaPlanVersion/FlightGroupHeaderContainer'
import FlightsHeaderContainer from 'Containers/MediaPlanVersion/FlightsHeaderContainer'
import { fieldsInPlanByLevel, IMediaPlanTemplateFields, getFieldId } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import FlightGroupsContainer from 'Containers/MediaPlanVersion/FlightGroupsContainer'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { FlightModalPasteComponent } from 'Components/FlightModalPasteComponent'
import { IStickyColumn } from 'Constants/entities/IStickyColumn'
import { sortOrderFlightGroup } from 'Components/MediaPlans/constants/entities/SortFlightGroupField'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { flightGroupId } from 'Constants/entities/FlightGroupIdField'
import HeaderFlightLevel from 'Components/MediaPlanVersion/HeaderFlightLevel'
import useStateDebounce from 'Hooks/useStateDebounce'
import { useAppSelector } from '../../store'
import { selectCalendarView, selectCurrentMediaPlanVersionFields, selectFlightGroups } from '../../selectors'
import { getExpandedHierarchiesFlightGroupsValues } from 'Components/Hierarchies/helpers/getExpandedHierarchiesValues'
import { getExpandedHierarchyFlightGroupsFields } from 'Components/Hierarchies/helpers/getExpandedHierarchiesFlightGroupFields'
import { useFilterDataWithExpandedHierarchies } from 'Components/MediaPlanVersion/hooks/useFilterDataWithExpandedHierarchies'
import { getExpandedHierarchyIdValue } from 'Components/Hierarchies/helpers/expandedHierarchies'
import { getExpandedHierarchyValueFieldName } from 'Components/MediaPlans/constants/entities/ExpandedHierarchyGroupField'
import { IMediaPlanVersionMasteredFieldsHelperValues, IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IClickableFieldProps } from 'Components/MediaPlanVersion/hooks'
import { SubtotalsGrandTotalRow } from 'Components/MediaPlanVersion/SubtotalsGrandTotalRow'
import { selectPlanStartDate, selectPlanEndDate } from '../../selectors/mediaPlansSelectors/currentMediaPlanVersionSelectors'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  planHierarchies: IMSHierarchies
  hierarchies: IMSHierarchies
  unfilteredHierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  calculationMode: boolean
  tokenHandler: (token: string) => void
  onAvailableFieldSelected: (c: string, flightGroupIndex?: number) => void
  handleAddField: (fieldType: FieldLevelType, flightGroupIndex?: number) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  displayFlightDates: boolean
  isExpandedHierarchiesMode: boolean
  collapsedViewField: string
  compressedCalendarView: boolean
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  financeList: IMediaPlanVersionFinanceListFields
  clickableFieldProps: IClickableFieldProps
  flightGroupsWithSubtotals: IFlightGroup[]
  isLoadingHierarchies: boolean
}

export const MediaPlanTableContainer: FunctionComponent<IProps> = ({
  planHierarchies,
  unfilteredHierarchies,
  masteredListsData,
  calculationMode,
  tokenHandler,
  onAvailableFieldSelected,
  handleAddField,
  removePlanField,
  displayFlightDates,
  isExpandedHierarchiesMode,
  collapsedViewField,
  compressedCalendarView,
  masteredDataHelperValues,
  hierarchies,
  financeList,
  clickableFieldProps,
  flightGroupsWithSubtotals,
  isLoadingHierarchies
}: IProps) => {
  const flightGroups = useAppSelector(selectFlightGroups)
  const calendarView = useAppSelector(selectCalendarView)
  const mediaPlanVersionFields = useAppSelector(selectCurrentMediaPlanVersionFields)
  const planStartDate = useAppSelector(selectPlanStartDate)
  const planEndDate = useAppSelector(selectPlanEndDate)
  const subtotals = useAppSelector((state) => state.mediaPlans.subtotals)
  const subtotalConfiguration = useAppSelector(state => state.mediaPlans.currentMediaPlanVersion?.mediaPlan?.subtotalConfiguration)

  const flightGroupFields = useMemo(() => fieldsInPlanByLevel(mediaPlanVersionFields, FieldLevelType.FLIGHT_GROUP),
    [mediaPlanVersionFields])
  const flightFields = useMemo(() => fieldsInPlanByLevel(mediaPlanVersionFields, FieldLevelType.FLIGHT),
    [mediaPlanVersionFields])

  const baseSortedFlightGroupFields = useMemo(() => (
    flightGroupFields &&
    [...flightGroupFields, sortOrderFlightGroup, flightGroupId]
      .sort((a, b) => a.sortOrder - b.sortOrder)
  ), [flightGroupFields])

  const expandedHierarchiesFlightGroupsValues = useMemo(() => (
    getExpandedHierarchiesFlightGroupsValues(flightGroupsWithSubtotals, unfilteredHierarchies)
  ), [flightGroupsWithSubtotals, unfilteredHierarchies])

  const sortedFlightGroupFields = useMemo(() => {
    if (!isExpandedHierarchiesMode) {
      return baseSortedFlightGroupFields
    }
    return getExpandedHierarchyFlightGroupsFields(baseSortedFlightGroupFields, unfilteredHierarchies, expandedHierarchiesFlightGroupsValues)
  }, [baseSortedFlightGroupFields, unfilteredHierarchies, isExpandedHierarchiesMode, expandedHierarchiesFlightGroupsValues])

  const flightGroupsWithExpandedHierarchyValues = useMemo(() => {
    if (!isExpandedHierarchiesMode) {
      return flightGroupsWithSubtotals
    }

    const flightGroupsWithExpandedValues = flightGroupsWithSubtotals.map((flightGroup, index) => {
      const flightGroupWithExpandedValues = { ...flightGroup }
      sortedFlightGroupFields.forEach(planField => {
        if (planField.isExpandedHierarchyField) {
          const expandedValueFieldName = getExpandedHierarchyValueFieldName(planField)
          // eslint-disable-next-line functional/immutable-data
          flightGroupWithExpandedValues[expandedValueFieldName] = getExpandedHierarchyIdValue(
            planField,
            index,
            expandedHierarchiesFlightGroupsValues
          )
        }
      })
      return flightGroupWithExpandedValues
    })

    return flightGroupsWithExpandedValues
  }, [flightGroupsWithSubtotals, expandedHierarchiesFlightGroupsValues, isExpandedHierarchiesMode, sortedFlightGroupFields])

  const flightsHeader = useRef<HTMLTableSectionElement>(null)
  const currentFlightGroupContainer = useRef<HTMLDivElement>(null)
  const laydownRef = useRef<HTMLTableCellElement>()
  const [stickyHeaders, setStickyHeaders, cleanUpDebounce] = useStateDebounce<IStickyColumn[]>(
    sortedFlightGroupFields.map(x => ({ id: getFieldId(x), width: undefined, sticky: false })), 1000)

  const toggleColumnSticky = useCallback((id: number) => {
    const newHeaderSizes = [...stickyHeaders]
    const elementInStickyHeaders = newHeaderSizes.find(x => x.id === id)
    const flightGroupColumn = sortedFlightGroupFields.find(column => getFieldId(column) === id)

    if (elementInStickyHeaders) {
      // eslint-disable-next-line functional/immutable-data
      elementInStickyHeaders.sticky = !elementInStickyHeaders.sticky
      return setStickyHeaders(newHeaderSizes, 0)
    }
    setStickyHeaders(
      [
        ...newHeaderSizes,
        {
          id: getFieldId(flightGroupColumn),
          width: undefined,
          sticky: true
        }
      ], 0
    )
  }, [setStickyHeaders, stickyHeaders, sortedFlightGroupFields])

  const setHeaderWidths = useCallback(
    (id: number, width: number) => {
      const newItems = [...stickyHeaders]
      const item = newItems.find(x => x.id === id)

      if (item && item.width !== width) {
        // eslint-disable-next-line functional/immutable-data
        item.width = width
        setStickyHeaders(newItems)
      }
    }, [setStickyHeaders, stickyHeaders])

  const getStickyStyle = useCallback(
    (type: StickyColumnType, id?: number): CSSProperties => {
      const toolbarWidth = 22
      let styleObj = {}

      switch (type) {
        case StickyColumnType.toolbar:
          styleObj = {
            left: '0px',
            zIndex: 2
          }
          break
        case StickyColumnType.flight:
        case StickyColumnType.toolbarTable: {
          const sticky = stickyHeaders.filter(x => x.sticky)
          const total = sticky.reduce((a, b) => a + b.width, 0)

          styleObj = {
            left: `${Math.round(toolbarWidth + total + sticky.length)}px`,
            zIndex: 1
          }
        }
          break
        case StickyColumnType.flightGroup: {
          const sticky = stickyHeaders.filter(x => x.sticky)
          const item = sticky.find(x => x.id === id)

          if (item) {
            const stickyFlightGroupFields = sortedFlightGroupFields.filter(x => sticky.find(y => y.id === getFieldId(x)))
            const itemIndex = stickyFlightGroupFields.findIndex(x => getFieldId(x) === id)
            const beforeFlights = stickyFlightGroupFields.filter((x, i) => i < itemIndex)
            const beforeItems = sticky.filter((x) => beforeFlights.find(y => getFieldId(y) === x.id))
            const total = beforeItems.reduce((a, b) => a + b.width, 0)

            styleObj = {
              left: `${Math.round(toolbarWidth + total + itemIndex)}px`,
              zIndex: 2
            }
          }
        }
          break
      }

      return styleObj
    }, [stickyHeaders, sortedFlightGroupFields])

  useEffect(() => {
    const inputs = currentFlightGroupContainer.current.getElementsByClassName('ant-select-selection-search-input') as HTMLCollectionOf<HTMLInputElement>
    for (const i in inputs) {
      if (inputs && Number(i) < inputs.length) {
        inputs[i].setAttribute('autocomplete', 'no')
      }
    }

    return cleanUpDebounce
  }, [cleanUpDebounce])

  const {
    filteredFlightGroups,
    filterFlightGroupData
  } = useFilterDataWithExpandedHierarchies(flightGroupsWithExpandedHierarchyValues, isExpandedHierarchiesMode)

  const filteredExpandedHierarchiesFlightGroupsValues = useMemo(() => {
    const filteredFlightGroupsIndices = filteredFlightGroups.map(filteredFlightGroup => flightGroupsWithSubtotals.findIndex(unfilteredFlightGroup => {
      return unfilteredFlightGroup.mediaPlanFlightGroupId === filteredFlightGroup.mediaPlanFlightGroupId
    }))

    return Object.fromEntries(
      Object.entries(expandedHierarchiesFlightGroupsValues)
        .filter((_, index) => filteredFlightGroupsIndices.includes(index))
        .map(([, value], index) => ([index, value]))
    )
  }, [flightGroupsWithSubtotals, filteredFlightGroups, expandedHierarchiesFlightGroupsValues])

  const shouldDisplayGrandTotal = calendarView !== 'table' && subtotalConfiguration?.includeGrandTotal
  const originalFlightGroups = useMemo(() => flightGroups.filter((f) => filteredFlightGroups.some((item) => f.mediaPlanFlightGroupId === item.mediaPlanFlightGroupId)).map((f) => ({ ...f, sortOrder: f.sortOrder })), [flightGroups, filteredFlightGroups])

  return (
    <div className='msCalendar' ref={currentFlightGroupContainer}>
      <table>
        <thead ref={flightsHeader} className='flights__header'>
          <tr>
            <FlightGroupHeaderContainer
              flightGroups={flightGroupsWithExpandedHierarchyValues}
              filterFlightGroupData={filterFlightGroupData}
              hierarchies={hierarchies}
              masteredListsData={masteredListsData}
              removePlanField={removePlanField}
              sortedFlightGroupFields={sortedFlightGroupFields}
              setHeaderWidths={setHeaderWidths}
              toggleColumnSticky={toggleColumnSticky}
              getStickyStyle={getStickyStyle}
              stickyHeaders={stickyHeaders}
              expandedHierarchiesFlightGroupsValues={expandedHierarchiesFlightGroupsValues}
              financeList={financeList}
            />
            {
              calendarView === 'table' && (
                <FlightsHeaderContainer
                  handleAddField={handleAddField}
                  getStickyStyle={getStickyStyle}
                  compressedCalendarView={compressedCalendarView}
                />
              )
            }
          </tr>
          {
            calendarView !== 'table' && (
              <>
                <tr>
                  <th className='flightHeader__title' ref={laydownRef} style={getStickyStyle(StickyColumnType.flight)}>
                    &nbsp;
                  </th>
                  <th rowSpan={4} className='flightHeader__view'>
                    <FlightsHeaderContainer
                      handleAddField={handleAddField}
                      compressedCalendarView={compressedCalendarView}
                    />
                  </th>
                </tr>
                <HeaderFlightLevel text='Months' style={getStickyStyle(StickyColumnType.flight)} />
                <HeaderFlightLevel text='Week#' style={getStickyStyle(StickyColumnType.flight)} />
                <HeaderFlightLevel text='Week Start' style={getStickyStyle(StickyColumnType.flight)} />
              </>
            )
          }
        </thead>

        <FlightGroupsContainer
          calculationMode={calculationMode}
          filteredFlightGroups={filteredFlightGroups}
          sortedFlightGroupFields={sortedFlightGroupFields}
          planHierarchies={planHierarchies}
          hierarchies={hierarchies}
          masteredListsData={masteredListsData}
          tokenHandler={tokenHandler}
          onAvailableFieldSelected={onAvailableFieldSelected}
          handleAddField={handleAddField}
          removePlanField={removePlanField}
          getStickyStyle={getStickyStyle}
          unfilteredHierarchies={unfilteredHierarchies}
          displayFlightDates={displayFlightDates}
          expandedHierarchiesFlightGroupsValues={filteredExpandedHierarchiesFlightGroupsValues}
          collapsedViewField={collapsedViewField}
          compressedCalendarView={compressedCalendarView}
          masteredDataHelperValues={masteredDataHelperValues}
          clickableFieldProps={clickableFieldProps}
          originalFlightGroups={originalFlightGroups}
          subtotals={subtotals}
          isLoadingHierarchies={isLoadingHierarchies}
          financeListFieldsData={financeList}
        />

        {shouldDisplayGrandTotal && (
          <SubtotalsGrandTotalRow
            sortedFlightGroupFields={sortedFlightGroupFields}
            getStickyStyle={getStickyStyle}
            subtotals={subtotals}
            flightFields={flightFields}
            planStartDate={planStartDate}
            planEndDate={planEndDate}
            compressedCalendarView={compressedCalendarView}
          />
        )}
      </table>
      <FlightModalPasteComponent />
    </div>
  )
}

export default MediaPlanTableContainer
