import { IClientHierarchy, IMSHierarchies, getClientHierarchyIdProp, getClientHierarchyList, getClientHierarchyValueProp, lookupHierarchyTreeBranch, getHierarchyList, HIERARCHY_ID_PROP, getHierarchyValueProp, IHierarchyName, IClientHierarchyName } from 'mindshare.layout'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { HIERARCHY_TYPES_MAP } from '../constants/entities/IHierarchies'
import { FieldDataType } from 'Constants/enums/FieldDataType'

export type IExpandedHierarchiesValues = {
  [hierarchyTypeId: number]: IClientHierarchy[]
}

export type IExpandedHierarchiesFlightGroupsValues = {
  [flightGroupIndex: number]: IExpandedHierarchiesValues
}

export const getExpandedHierarchiesFlightGroupsValues = (
  flightGroups: IFlightGroup[],
  hierarchies: IMSHierarchies
): IExpandedHierarchiesFlightGroupsValues => {
  if (!flightGroups) {
    return {}
  }

  return Object.fromEntries(Object.entries(flightGroups).map(([flightGroupIndex, flightGroupValues]) => {
    return [
      [flightGroupIndex],
      Object.fromEntries(Object.entries(HIERARCHY_TYPES_MAP).map(([hierarchyTypeId, hierarchyType]) => {
        const isMasteredHierarchyType = Number(hierarchyTypeId) === FieldDataType.FINANCE_STATION_HIERARCHY || Number(hierarchyTypeId) === FieldDataType.FINANCE_PRODUCT_HIERARCHY || Number(hierarchyTypeId) === FieldDataType.COST_BUYING_ROUTE_HIERARCHY
        const hierarchyField = isMasteredHierarchyType ? getHierarchyValueProp(hierarchyType as IHierarchyName) : getClientHierarchyValueProp(hierarchyType as IClientHierarchyName)
        const selectedNodeHierarchyId = flightGroupValues[hierarchyField]
        const hierarchyNodes = isMasteredHierarchyType ? lookupHierarchyTreeBranch(
          getHierarchyList(hierarchies, hierarchyType as IHierarchyName),
          HIERARCHY_ID_PROP,
          selectedNodeHierarchyId
        ) : lookupHierarchyTreeBranch(
          getClientHierarchyList(hierarchies, hierarchyType as IClientHierarchyName),
          getClientHierarchyIdProp(hierarchyType as IClientHierarchyName),
          selectedNodeHierarchyId
        )

        return [
          hierarchyTypeId,
          hierarchyNodes
        ]
      }))
    ]
  }))
}
