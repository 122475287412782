import * as React from 'react'
import { Tooltip } from 'antd'
import { ViewDisplayColumn } from 'Apis/generated/frontSheetsApi'
import { CSSProperties } from 'react'

interface FrontSheetCalendarGrandTotalRowProps {
  columns: ViewDisplayColumn[]
  getStickyStyle: (index: number) => CSSProperties
  grandTotal: string[]
}

const FrontSheetCalendarGrandTotalRow = ({ columns, getStickyStyle, grandTotal }: FrontSheetCalendarGrandTotalRowProps) => {
  const groupByCols = columns.filter((x) => x.columnType === 'GroupBy')
  return (
    <tr className="flightGroup__totals -grandTotal">
      {
        groupByCols.map((x, i) => <td key={`grandTotalIndex_${i}`} style={getStickyStyle(i)}>{i === 0 ? 'Grand Total' : ''}</td>)
      }
      {
        grandTotal.map((grandtotal, grandTotalIndex) => (
          <td
            data-testid={`flightGroupGrandtotalCell_${grandTotalIndex}`}
            style={getStickyStyle(grandTotalIndex + groupByCols.length)}
            key={`grandTotalIndex_${grandTotalIndex}`}
          >
            <Tooltip title={grandtotal} key={`grandTotalIdx_${grandTotalIndex}`}>
              <span>
                {grandtotal}
              </span>
            </Tooltip>
          </td>
        ))
      }
      <td colSpan={2}>&nbsp;</td>
    </tr>
  )
}

export default FrontSheetCalendarGrandTotalRow
