import React, { FunctionComponent, useCallback } from 'react'
import { IFlightDate } from 'Components/MediaPlanVersion/constants/entities/IFlightDate'
import { Button, Tooltip } from 'antd'
import { CloseCircleOutlined, CopyOutlined } from '@ant-design/icons'

interface IProps {
  collapse: boolean
  flight: IFlightDate
  flightGroupIndex: number
  duplicateFlight: (flight: IFlightDate, flightGroupIndex: number) => void
  removeFlight: (flight: IFlightDate, flightGroupIndex: number) => void
}

export const FlightTableButtonsComponent: FunctionComponent<IProps> = (props: IProps) => {
  const {
    collapse,
    flight,
    flightGroupIndex,
    duplicateFlight,
    removeFlight
  } = props

  const handleClick = useCallback(() => removeFlight(flight, flightGroupIndex), [removeFlight, flight, flightGroupIndex])

  const handleDuplicateClick = useCallback(() => duplicateFlight(flight, flightGroupIndex), [duplicateFlight, flight, flightGroupIndex])

  return (
    <div className='plus-button-container'>
      <div className='flightGroup__toolbar -header'>
        {
          !collapse &&
          (
            <>
              <Tooltip title="Remove flight">
                <Button
                  icon={<CloseCircleOutlined />}
                  size='large'
                  type='link'
                  tabIndex={-1}
                  onClick={handleClick}
                  data-testid='btn-remove-flight'
                />
              </Tooltip>
              <Tooltip title="Clone flight">
                <Button
                  icon={<CopyOutlined />}
                  size='large'
                  type='link'
                  tabIndex={-1}
                  onClick={handleDuplicateClick}
                  data-testid='btn-duplicated-flight'
                />
              </Tooltip>
            </>
          )
        }
      </div>
    </div>
  )
}

export default FlightTableButtonsComponent
