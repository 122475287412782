import { useCallback, useRef, useState } from 'react'

/**
 * Helper hook to debounce calls to useState - to help with renders where useState may be set multiple times within a short period of time
 * @param defaultValue useState default value
 * @param delay ms delay for callback debounce
*/
const useStateDebounce = <T>(
  defaultValue?: T,
  delay: number = 500): [T, (value: T, delayOverride?: number) => void, () => void] => {
  const [value, setValue] = useState<T>(defaultValue)
  const debounceValueRef = useRef<T>(null)
  const debounceIntervalRef = useRef<NodeJS.Timeout>(null)

  const setValueDebounced = useCallback((newValue: T, delayOverride?: number) => {
    // eslint-disable-next-line functional/immutable-data
    debounceValueRef.current = newValue
    clearInterval(debounceIntervalRef.current)

    if (delay === 0 || delayOverride === 0) {
      setValue(debounceValueRef.current)
    } else {
      // eslint-disable-next-line functional/immutable-data
      debounceIntervalRef.current = setTimeout(() => {
        setValue(debounceValueRef.current)
      }, delayOverride !== undefined ? delayOverride : delay)
    }
  }, [delay])

  const cleanUpDebounce = useCallback(() => {
    clearInterval(debounceIntervalRef.current)
  }, [])

  return [value, setValueDebounced, cleanUpDebounce]
}

export default useStateDebounce
