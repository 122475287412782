import React, { FunctionComponent } from 'react'
import { MSLayoutContentRow } from 'mindshare.layout'
import HierarchySelectionTabComponent from 'Components/HierarchySelectionTabComponent'
import { useMindshareSelector } from 'mindshare.customhooks'
import { Client } from 'Apis/generated/clientsApi'
import { RootState } from '../../redux/reducers'

const ClientAdminContainer: FunctionComponent = () => {
  const currentClient = useMindshareSelector((state: RootState) => state.app.currentClient) as Client


  return currentClient.id > 0 && (
    <>
      <MSLayoutContentRow>
        <h2>Client Admin</h2>
      </MSLayoutContentRow>
      <MSLayoutContentRow fillAvailableSpace={true} extraClass='client-admin-container'>
        <HierarchySelectionTabComponent clientId={currentClient.id} />
      </MSLayoutContentRow>
    </>
  )
}

export default ClientAdminContainer
