import React from 'react'
import { Button, Tooltip } from 'antd'
import { EditOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'

interface IProps {
  onRemove: () => void
  onAdd: () => void
  onEdit: () => void
  canEdit?: boolean
  editTooltipError?: string
}

export const LinkedLookupFieldActions: React.FC<IProps> = ({
  onAdd,
  onRemove,
  onEdit,
  canEdit = true,
  editTooltipError
}) => (
  <div className='icon-group'>
    <Tooltip title={editTooltipError ?? 'Edit connection'}>
      <Button
        data-testid='edit-field-connection'
        type='link'
        icon={<EditOutlined />}
        onClick={onEdit}
        disabled={!canEdit}
      />
    </Tooltip>
    <Tooltip title='Delete connection'>
      <Button
        className='minus-icon icon-btn'
        data-testid='delete-connection'
        icon={<MinusCircleOutlined />}
        onClick={onRemove}
      />
    </Tooltip>
    <Tooltip title='Add connection'>
      <Button
        className='plus-icon icon-btn'
        icon={<PlusCircleOutlined />}
        data-testid='plus-icon'
        onClick={onAdd}
      />
    </Tooltip>
  </div>
)

