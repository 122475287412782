import React, { useCallback, useEffect, useMemo } from 'react'
import { navigate, MSLayoutContentRow, LoadingComponent } from 'mindshare.layout'
import { Button, Popconfirm } from 'antd'
import isEqual from 'lodash/isEqual'

import { appPaths } from '../../../providers/AppRoutes'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useLinkedLookupsList } from 'Components/LinkedLookup/hooks/useLinkedLookupsList'
import { useLinkedLookupFields } from 'Components/LinkedLookup/hooks/useLinkedLookupFields'
import { useBatchUpdateLinkedLookup } from 'Components/LinkedLookup/hooks/useBatchUpdateLinkedLookup'
import { popoverMessage } from 'Constants/enums/PopoverMessage'
import fieldConnectionsText from 'Components/tooltipContent/Field Connections'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import { LinkedLookupListTable } from 'Components/LinkedLookup/components/LinkedLookupListTable'
import { oneOf } from 'Helpers/conditionsHelper'
import { useAppDispatch, useAppSelector } from '../../../store'
import { addToLinkedLookupList, removeFromLinkedLookupList, setLinkedLookupList, unsetLinkedLookupList, updateLinkedLookupList } from 'Actions/linkedLookupActions'
import { LinkedLookup } from 'Apis/generated/linkedLookupsApi'
import { linkedLookupsInitialValues } from '../constants/entities'
import { ILinkedLookupList } from '../constants/entities/ILinkedLookupList'

export const LinkedLookupListContainer: React.FC = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const linkedLookupsList = useAppSelector(state => state.linkedLookups.linkedLookupList)

  const { data: currentClient } = useCurrentClient()
  const { data: linkedLookups = [], isLoading } = useLinkedLookupsList()
  const { data: linkedLookupFields, isLoading: areLinkedLookupFieldsLoading } = useLinkedLookupFields()
  const { execute: batchUpdateLinkedLookup } = useBatchUpdateLinkedLookup()

  const linkedLookupsModel: ILinkedLookupList = {
    values: linkedLookupsList,
    addField: (linkedLookup: LinkedLookup) => dispatch(addToLinkedLookupList({ linkedLookup })),
    removeField: (id: number) => dispatch(removeFromLinkedLookupList({ id })),
    updateField: (
      linkedLookupId: number,
      linkedLookup: Partial<LinkedLookup>
    ) => dispatch(updateLinkedLookupList({ linkedLookupId, linkedLookup }))
  }

  useEffect(() => {
    // each time query gets loaded and linked lookups list in store is empty
    if (
      linkedLookups.length &&
      (
        !linkedLookupsList?.length ||
        isEqual(linkedLookupsList, [linkedLookupsInitialValues])
      )) {
      dispatch(setLinkedLookupList(linkedLookups))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, linkedLookups])

  const isWithUnsavedChanges = useMemo(() => (
    !isEqual(linkedLookups, linkedLookupsList)
  ), [linkedLookups, linkedLookupsList])

  const handlePrevious = useCallback(() => {
    dispatch(unsetLinkedLookupList())
    navigate(`${appPaths.clientDataTerms}`)
  }, [dispatch])

  const handleSubmit = useCallback(async () => {
    try {
      const savedLookups = await batchUpdateLinkedLookup({
        body: linkedLookupsList
      })
      dispatch(setLinkedLookupList(savedLookups))
    } catch (err) { }
  }, [dispatch, batchUpdateLinkedLookup, linkedLookupsList])

  return oneOf(isLoading, areLinkedLookupFieldsLoading) ? (
    <LoadingComponent
      appDataSuccess={!!currentClient.id}
      agencyLoadingGif={currentClient.agency?.agencyLoadingGifLocation}
    />
  ) : (
    <>
      <TitleAndButtonsComponent
        title='Field Connections'
        subtitle='Edit current field connections'
        tooltipText={fieldConnectionsText}
      />
      <MSLayoutContentRow fillAvailableSpace={true}>
        <LinkedLookupListTable
          currentlySavedlinkedLookups={linkedLookups}
          linkedLookupsModel={linkedLookupsModel}
          linkedLookupFields={linkedLookupFields}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow extraClass='bottom-button-wrapper'>
        <Popconfirm
          placement='bottom'
          title={popoverMessage.confirmUnsavedChangesWillBeLost}
          onConfirm={handlePrevious}
          okText='Yes'
          cancelText='No'
          disabled={!isWithUnsavedChanges}
        >
          <Button
            type='primary'
            style={{ margin: '0px 10px' }}
            onClick={!isWithUnsavedChanges ? handlePrevious : () => { }}
          >
            Back
          </Button>
        </Popconfirm>
        <Button
          type='primary'
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </MSLayoutContentRow>
    </>
  )
}

export default LinkedLookupListContainer
