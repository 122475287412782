import React, { useMemo, useCallback } from 'react'
import { Select, Tooltip } from 'antd'

import { compare } from 'Helpers/sortHelper'

export const DependentOnFieldSelectComponent = ({
  value,
  options,
  disabled = false,
  onChange = null
}) => {
  const renderOptions = useCallback(() => [...options]
    .sort((a, b) => compare(a.fieldLabel, b.fieldLabel))
    .map(c =>
      <Select.Option
        key={c.mediaPlanFieldId}
        value={c.mediaPlanFieldId}
      >
        {c.fieldLabel}
      </Select.Option>
    ), [options])

  const Options = useMemo(() =>
    renderOptions()
  , [renderOptions])

  return (
    <Tooltip title={`${disabled ? 'Cannot change field once record is created' : ''}`}>
      <Select
        className='select select--fluid'
        placeholder='Select a field'
        onChange={onChange}
        data-testid='linked-lookup-dependent-on'
        optionFilterProp='children'
        allowClear
        showSearch
        value={value}
        disabled={disabled}
      >
        {Options}
      </Select>
    </Tooltip>
  )
}
