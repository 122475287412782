import React, { ClipboardEvent } from 'react'
interface IProps {
  handlePasteEvent: (event: ClipboardEvent<HTMLDivElement>) => void
}

export const PasteComponent: React.FC<IProps> = ({ children, handlePasteEvent }) => (
  <div className='paste-container' onPaste={handlePasteEvent}>
    {children}
  </div>
)

export default PasteComponent

