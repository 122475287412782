import { IMediaPlanTemplateFieldsExtended } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

export const flightEndDate: IMediaPlanTemplateFieldsExtended = {
  mediaPlanVersionFieldId: 35036,
  mediaPlanTemplateId: 0,
  isInPlan: true,
  clientMediaPlanFieldId: 11824,
  fieldLabel: 'Flight End Date',
  templateFieldAvailabilityId: 10,
  templateFieldTypeId: 10,
  functionId: null,
  defaultValue: '2020-04-24',
  calculation: null,
  isComplexCalculation: false,
  requiredHierarchyLevelId: 0,
  includeLevelsBelowHierarchy: false,
  isEditable: true,
  isFooter: false,
  sortOrder: -10000,
  approvedBy: null,
  approvedOn: null,
  token: '{Plan.CampaignStartDate}',
  clientMediaPlanField: {
    clientMediaPlanFieldId: 11824,
    mediaPlanFieldLevelId: 521,
    mediaPlanFieldId: 0,
    fieldLabel: 'Campaign Start Date',
    isMandatory: false,
    isDisabled: false,
    sortOrder: 1340,
    isApportionmentField: false,
    approvedOn: null,
    isApportioned: false,
    columnName: '',
    isVisible: true,
    key: 0,
    mediaPlanField: {
      mediaPlanFieldLevelId: 521,
      mediaPlanFieldId: Number.MAX_SAFE_INTEGER,
      fieldLabel: 'Campaign Start Date',
      fieldDataTypeId: 50,
      columnName: 'FlightEndDate',
      fieldLevelId: 3,
      fieldDescription: 'Start date of the campaign',
      isMandatory: false,
      sortOrder: 1420,
      defaultValue: '2020-01-01',
      isApportionable: false,
      isDefaultApportioned: false,
      isDefaultApportionmentField: false,
      isSelected: false,
      isVisible: false,
      key: 0,
      fieldDataType: {
        fieldDataTypeId: 50,
        dataTypeName: 'Date',
        dataType: 'Date',
        label: 'Dates with or without time.',
        isHierarchy: false
      },
      fieldLevel: {
        fieldLevelId: 3,
        fieldLevelName: 'Flight'
      },
      fieldTags: []
    },
    clientFieldValues: []
  }
}

export const flightStartDate: IMediaPlanTemplateFieldsExtended = {
  mediaPlanVersionFieldId: 35037,
  mediaPlanTemplateId: 0,
  isInPlan: true,
  clientMediaPlanFieldId: 11823,
  fieldLabel: 'Flight Start Date',
  templateFieldAvailabilityId: 10,
  templateFieldTypeId: 10,
  functionId: null,
  defaultValue: '2020-04-24',
  calculation: null,
  isComplexCalculation: false,
  requiredHierarchyLevelId: 0,
  includeLevelsBelowHierarchy: false,
  isEditable: true,
  isFooter: false,
  sortOrder: -10000,
  approvedBy: null,
  approvedOn: null,
  token: '{Plan.CampaignStartDate}',
  clientMediaPlanField: {
    clientMediaPlanFieldId: 11823,
    mediaPlanFieldLevelId: 521,
    mediaPlanFieldId: 0,
    fieldLabel: 'Campaign Start Date',
    isMandatory: false,
    isDisabled: false,
    sortOrder: 1340,
    isApportionmentField: false,
    approvedOn: null,
    isApportioned: false,
    columnName: '',
    isVisible: true,
    key: 0,
    mediaPlanField: {
      mediaPlanFieldLevelId: 521,
      mediaPlanFieldId: Number.MAX_SAFE_INTEGER,
      fieldLabel: 'Campaign Start Date',
      fieldDataTypeId: 50,
      columnName: 'FlightStartDate',
      fieldLevelId: 3,
      fieldDescription: 'Start date of the campaign',
      isMandatory: false,
      sortOrder: 1420,
      defaultValue: '2020-01-01',
      isApportionable: false,
      isDefaultApportioned: false,
      isDefaultApportionmentField: false,
      isSelected: false,
      isVisible: false,
      key: 0,
      fieldDataType: {
        fieldDataTypeId: 50,
        dataTypeName: 'Date',
        dataType: 'Date',
        label: 'Dates with or without time.',
        isHierarchy: false
      },
      fieldLevel: {
        fieldLevelId: 3,
        fieldLevelName: 'Flight'
      },
      fieldTags: []
    },
    clientFieldValues: []
  }
}
