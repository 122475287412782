import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Button, Input, Popconfirm, Select, TabPaneProps, Tooltip } from 'antd'
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons'
import { navigate, MSLayoutContentRow, MSTabs, MSTabPane, IMSHierarchies } from 'mindshare.layout'

import FrontSheetAggregatedTableComponent from './FrontSheetAggregatedTableComponent'
import FrontSheetGroupByTableComponent from './FrontSheetGroupByTableComponent'
import TemplateTitleComponent from 'Components/TemplateTitleComponent'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import {
  IButtonActionsAggregate,
  IButtonActionsGroupBy,
  IButtonActionsTabs,
  IFrontSheetView
} from 'Components/FrontSheets/constants/entities/IFrontSheets'
import { frontSheetValidationError } from 'Components/shared/constants/entities/IFieldMetaData'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { FrontSheetColumnType } from 'Constants/enums/FrontSheetColumnType'
import { popoverMessage } from 'Constants/enums/PopoverMessage'
import { FrontSheet, FrontSheetViewAggregate, FrontSheetViewGroupBy } from 'Apis/generated/frontSheetsApi'
import { FieldLevel } from 'Apis/generated/mediaPlanFieldsApi'

interface IButtonActions {
  aggregate: IButtonActionsAggregate
  groupBy: IButtonActionsGroupBy
  navigateBack: () => void
  save: () => void
  tabs: IButtonActionsTabs
}

interface IProps {
  aggregateData: FrontSheetViewAggregate[]
  aggregatedClientMediaPlanFields: IClientMediaPlanField[]
  buttonActions: IButtonActions
  clientMediaPlanFields: IClientMediaPlanField[]
  clientId: number
  currentFrontSheet: FrontSheet
  currentTabView: number
  editingViewName: boolean
  fieldLevels: FieldLevel[]
  groupByData: FrontSheetViewGroupBy[]
  hierarchies: IMSHierarchies
  newViewName: string
  saveInProgress: boolean
  saveSuccess: boolean
  setCurrentTabView: Dispatch<SetStateAction<number>>
  setNewViewName: Dispatch<SetStateAction<string>>
}

export const FrontSheetSelectFieldsComponent: React.FC<IProps> = ({
  aggregateData,
  aggregatedClientMediaPlanFields,
  buttonActions,
  clientMediaPlanFields,
  clientId,
  currentFrontSheet,
  currentTabView,
  editingViewName,
  fieldLevels,
  groupByData,
  hierarchies,
  newViewName,
  saveInProgress,
  saveSuccess,
  setCurrentTabView,
  setNewViewName
}) => {
  const [groupByValid, setGroupByValid] = useState(false)
  const [aggregateValid, setAggregateValid] = useState(false)
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    setIsValid(groupByValid && aggregateValid)
  }, [groupByValid, aggregateValid])

  const laydownFieldChange = useCallback((val: string) => {
    const newField = clientMediaPlanFields.find(x => x.clientMediaPlanFieldId === Number(val)) || {} as IClientMediaPlanField
    buttonActions.tabs.updateViewField(newField)
  }, [buttonActions.tabs, clientMediaPlanFields])

  return (
    <>
      <MSLayoutContentRow>
        <TemplateTitleComponent
          disabled={true}
          inputLabel='Front Sheet Name'
          inputValue={currentFrontSheet.frontSheetName}
          onTemplateNameChange={null}
          placeholder='Enter Front Sheet Title'
          tags={null}
          title={null}
          tooltip='createEditFrontSheetViewEditor'
          leftAlignNumbersInExcelExport={currentFrontSheet.leftAlignNumbersInExcelExport}
          onLeftAlignExportChange={null}
          hideSortOrderInExcelExport={currentFrontSheet.hideSortOrderInExcelExport}
          onHideSortOrderExportChange={null}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow>
        Select Fields

        <MSTabs
          className='frontSheets__tabs'
          onChange={(key) => {
            setCurrentTabView(Number(key))
          }}
          onEdit={(targetKey, action) => action === 'add' && buttonActions.tabs.addView()}
          type='editable-card'
        >
          {currentFrontSheet.frontSheetViews
            .map((frontSheetView, tabIndex) => (
              <FrontSheetSelectFieldsTab
                key={tabIndex}
                buttons={buttonActions}
                deleteDisabled={currentFrontSheet.frontSheetViews.length < 2}
                groupByFields={clientMediaPlanFields}
                aggregateFields={aggregatedClientMediaPlanFields}
                {...{
                  frontSheetView,
                  clientMediaPlanFields,
                  aggregateData,
                  groupByData,
                  fieldLevels,
                  hierarchies,
                  setGroupByValid,
                  setAggregateValid,
                  laydownFieldChange
                }}
                tab={(
                  <FrontSheetSelectFieldsTabHeader
                    tabIndex={tabIndex}
                    frontSheetViewName={frontSheetView.frontSheetViewName}
                    editViewName={buttonActions.tabs.editViewName}
                    updateViewName={buttonActions.tabs.updateViewName}
                    {...{
                      currentTabView,
                      editingViewName,
                      newViewName,
                      setNewViewName
                    }}
                  />
                )}
              />
            ))}
        </MSTabs>
      </MSLayoutContentRow>

      <MSLayoutContentRow>
        <div className="front-sheet-view-buttons-row">
          <div>
            <Button
              onClick={buttonActions.navigateBack}
              type='primary'
            >
              Back to Plan Section
            </Button>
          </div>
          <div>
            <Button
              disabled={!saveSuccess}
              onClick={() => navigate(`/view-front-sheet/${currentFrontSheet.frontSheetId}`, false, { clientId })}
              type='primary'
            >
              View Front Sheet
            </Button>
            <Button
              disabled={saveInProgress || saveSuccess || !isValid}
              loading={saveInProgress}
              onClick={buttonActions.save}
              type='primary'
            >
              Save
            </Button>
          </div>
        </div>
      </MSLayoutContentRow>
    </>
  )
}

export default FrontSheetSelectFieldsComponent


const FrontSheetSelectFieldsTabHeader = ({
  currentTabView,
  tabIndex,
  frontSheetViewName,
  editingViewName,
  newViewName,
  setNewViewName,
  updateViewName,
  editViewName
}) => {
  return editingViewName && currentTabView === tabIndex ? (
    <>
      <Input
        data-testid='view-name-input'
        onChange={(e) => {
          e.preventDefault()
          setNewViewName(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === ' ') {
            const index = (e.target as any).selectionStart
            const newString = newViewName.substr(0, index) + ' ' + newViewName.substr(index, newViewName.length)
            setNewViewName(newString)
          }
        }}
        value={newViewName}
        defaultValue={frontSheetViewName}
      />
      <CheckCircleOutlined
        data-testid='confirm-view-name'
        onClick={updateViewName}
      />
    </>
  ) : (
    <span>
      {frontSheetViewName} {currentTabView === tabIndex && (
        <Tooltip title='Edit front sheet view name'>
          <EditOutlined data-testid='edit-view-name' onClick={editViewName} />
        </Tooltip>
      )}
    </span>
  )
}

interface IFrontSheetSelectFieldsTab extends TabPaneProps {
  frontSheetView: IFrontSheetView
  deleteDisabled: boolean
  buttons: IButtonActions
  clientMediaPlanFields: IClientMediaPlanField[]
  groupByFields: IClientMediaPlanField[]
  aggregateFields: IClientMediaPlanField[]
  aggregateData: FrontSheetViewAggregate[]
  groupByData: FrontSheetViewGroupBy[]
  fieldLevels: FieldLevel[]
  hierarchies: IMSHierarchies
  setGroupByValid: (isValid: boolean) => void
  setAggregateValid: (isValid: boolean) => void
  laydownFieldChange: (val: string) => void
}
const FrontSheetSelectFieldsTab = ({
  frontSheetView,
  deleteDisabled,
  buttons,
  clientMediaPlanFields,
  groupByFields,
  aggregateFields,
  aggregateData,
  groupByData,
  fieldLevels,
  hierarchies,
  setGroupByValid,
  setAggregateValid,
  laydownFieldChange,
  ...props
}: IFrontSheetSelectFieldsTab) => {
  const laydownField = aggregateFields.find(x => x.clientMediaPlanFieldId === frontSheetView.laydownFieldId)
  const subtotalCutOffClientMediaPlanFieldId = groupByData?.find(x => x.subtotalCutOff)?.clientMediaPlanFieldId
  const subtotalCutOff = clientMediaPlanFields.find(x => x.clientMediaPlanFieldId === subtotalCutOffClientMediaPlanFieldId)
  const groupByFieldValues = groupByData?.map(x => clientMediaPlanFields.find(y => y.clientMediaPlanFieldId === x.clientMediaPlanFieldId))
  const aggregateFieldValues = aggregateData?.map(x => aggregateFields.find(y => y.clientMediaPlanFieldId === x.clientMediaPlanFieldId))

  const validateField = useCallback((field: IClientMediaPlanField, columnType: FrontSheetColumnType, _laydownField?: IClientMediaPlanField): frontSheetValidationError => {
    let validationReason: frontSheetValidationError

    if (!field) {
      validationReason = frontSheetValidationError.missingField
    } else if ([...(groupByFieldValues || []), ...(aggregateFieldValues || [])]
      .filter(x => x?.mediaPlanField?.columnName?.toLowerCase() === field.mediaPlanField?.columnName?.toLowerCase()).length > 1) {
      validationReason = frontSheetValidationError.duplicateField
    } else if (columnType === FrontSheetColumnType.GROUPBY && _laydownField &&
      field.mediaPlanField?.columnName === _laydownField.mediaPlanField?.columnName) {
      validationReason = frontSheetValidationError.laydownDuplicateField
    } else if (columnType === FrontSheetColumnType.AGGREGATE &&
      subtotalCutOff &&
      field.mediaPlanField.fieldLevelId < subtotalCutOff.mediaPlanField.fieldLevelId) {
      // if there is a sub total cut off, then make sure the sub total cut off is the same level or below the aggregate field level
      validationReason = frontSheetValidationError.fieldLevelIdTooLow
    }

    return validationReason
  }, [aggregateFieldValues, groupByFieldValues, subtotalCutOff])

  return (
    <MSTabPane {...props}>
      <div className='front-sheet-top-button-wrapper'>
        <div className='laydown-select' data-testid='laydown-select'>
          <span>Laydown field:</span>
          <Select
            allowClear={true}
            placeholder='Select laydown field'
            className='ms-select front-sheet-select-fields__select front-sheet-select-fields__select--laydown'
            data-testid='laydown-field-select'
            onChange={laydownFieldChange}
            optionFilterProp='children'
            showSearch={true}
            value={laydownField?.fieldLabel}
          >
            {aggregateFields.map((field) =>
              field.mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT &&
              <Select.Option
                key={field.clientMediaPlanFieldId}
                value={field.clientMediaPlanFieldId}
              >
                {field.fieldLabel}
              </Select.Option>
            )}
          </Select>
        </div>

        <Popconfirm
          cancelText='No'
          disabled={deleteDisabled}
          onConfirm={buttons.tabs.deleteView}
          okText='Yes'
          title={popoverMessage.confirmDeleteView}
        >
          <Button
            data-testid='delete-view'
            disabled={deleteDisabled}
          >Delete view</Button>
        </Popconfirm>
      </div>

      <div className='front-sheet-table-wrapper'>
        <FrontSheetGroupByTableComponent
          buttonActions={buttons.groupBy}
          clientMediaPlanFields={groupByFields}
          fieldLevels={fieldLevels}
          groupByData={groupByData}
          hierarchies={hierarchies}
          laydownField={laydownField}
          validateField={validateField}
          setGroupByValid={setGroupByValid}
        />
        <FrontSheetAggregatedTableComponent
          aggregatedClientMediaPlanFields={aggregateFields}
          aggregatedData={aggregateData}
          buttonActions={buttons.aggregate}
          fieldLevels={fieldLevels}
          subtotalCutOff={subtotalCutOff}
          validateField={validateField}
          setAggregateValid={setAggregateValid}
        />
      </div>
    </MSTabPane>
  )
}
