import DOMPurify from 'dompurify'


const defaultDOMPurifyOptions: DOMPurify.Config = {
  ALLOWED_TAGS: ['div', 'b', 'i', 'em', 'ins', 'del', 'strong', 'a', 'p', 'span', 'style'],
  ALLOWED_ATTR: ['href', 'src', 'title', 'style']
}

export const sanitizeHTMLString = (dirty: string, options?: DOMPurify.Config) => (
  DOMPurify.sanitize(
    dirty,
    options || defaultDOMPurifyOptions
  ).toString()
)
