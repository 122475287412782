import { v4 as uuid } from 'uuid'
import { CustomExportDisplayField } from 'Apis/generated/customExportsApi'

export type IDisplayField =
  | Omit<
  CustomExportDisplayField,
  'customExportControlFieldId'
  > & { itemRowId?: string }
  | Omit<
  CustomExportDisplayField,
  'clientMediaPlanFieldId'
  > & { itemRowId?: string }

export const initialDisplayField: IDisplayField = {
  customExportId: 0,
  customExportDisplayFieldId: 0,
  displayOrder: 0,
  clientMediaPlanFieldId: null,
  customExportControlFieldId: null,
  itemRowId: uuid()
}
