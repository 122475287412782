
import { useMemo } from 'react'
import { useLazyGetUserPermissionsQuery } from 'Apis/enhancedUserPermissionsApi'
import { IUserPermissions } from 'Constants/entities/IUserPermissions'

export const initialPermissions: IUserPermissions = {
  adminAuthorised: false,
  clientAdmin: false,
  exportRun: false,
  exportSetup: false,
  submitSetUp: false,
  template: false,
  userAuthorised: false
}

export const useUserPermissions = () => {
  const [getUserPermissions, getResult] = useLazyGetUserPermissionsQuery()

  return useMemo(
    () => ({ getUserPermissions, data: getResult.data as IUserPermissions }),
    [getUserPermissions, getResult.data]
  )
}
