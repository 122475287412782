import React from 'react'
import { Button, Tooltip } from 'antd'
import { IWizardStep } from 'Hooks/useWizardComponent'

interface IWizardFooterComponentProps {
  nextBtnConfig: IWizardStep['nextBtnConfig'] & { onClick: () => void }
  prevBtnConfig: IWizardStep['prevBtnConfig'] & { onClick: () => void }
}

const WizardFooterComponent: React.FC<IWizardFooterComponentProps> = ({ nextBtnConfig, prevBtnConfig }) => (
  <>
    {prevBtnConfig && <Button onClick={prevBtnConfig.onClick}>{prevBtnConfig.label}</Button>}
    {nextBtnConfig && (
      <Tooltip title={nextBtnConfig.disabled ? nextBtnConfig.tooltipText : ''}>
        <Button
          disabled={nextBtnConfig.disabled}
          onClick={nextBtnConfig.onClick}
          type='primary'
        >
          {nextBtnConfig.label}
        </Button>
      </Tooltip>
    )}
  </>
)

export default React.memo(WizardFooterComponent)
