import { baseApi as api } from '../apis/generated/fieldLevelsApi'
import { queryCacheValidTime } from './queryCacheValidTime'

const enhancedRtkApi = api.enhanceEndpoints({
  endpoints: {
    getFieldLevels: { keepUnusedDataFor: queryCacheValidTime.getFieldLevels }
  }
})
export { enhancedRtkApi as baseApi }

export const {
  useGetFieldLevelsQuery,
  useLazyGetFieldLevelsQuery
} = enhancedRtkApi
