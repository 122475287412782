import { useEffect, useState } from 'react'
import { verifyExportClaims } from 'Helpers/verifyExportClaims'

export const useClaimVerification = ({ clientName, claimName }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(false)

  useEffect(() => {
    (async () => {
      if (clientName && claimName) {
        setIsLoading(true)
        const hasExportSetupClaim = await verifyExportClaims(claimName, clientName)

        setData(hasExportSetupClaim)
        setIsLoading(false)
      }
    })()
  }, [setIsLoading, claimName, clientName])

  return {
    data,
    isLoading
  }
}
