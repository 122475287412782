import { useMemo } from 'react'
import { getClientHierarchyTypeDetails, getHierarchyTypeDetails, IMSHierarchies } from 'mindshare.layout'
import { getHierarchyListBySpecificLevel } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { getFieldDataType, IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { isFinanceProductHierarchyType, isFinanceStationHierarchyType, isCostBuyingRouteHierarchyType } from 'Components/shared/constants/entities/IFieldMetaData'

export const useMediaPlanFieldHierarchies = ({
  currentField,
  hierarchies
}: {
  currentField: IMediaPlanTemplateFields
  hierarchies: Partial<IMSHierarchies>
}) => {
  const { dataTypeName } = getFieldDataType(currentField)

  const { includeLevelsBelowHierarchy, requiredHierarchyLevelId } = currentField
  const isMasteredHierarchyType = isFinanceProductHierarchyType(dataTypeName) || isFinanceStationHierarchyType(dataTypeName) || isCostBuyingRouteHierarchyType(dataTypeName)
  const hierarchyTypeDetails = isMasteredHierarchyType ? getHierarchyTypeDetails(dataTypeName, hierarchies) : getClientHierarchyTypeDetails(dataTypeName, hierarchies)

  return useMemo(() => ({
    ...hierarchyTypeDetails,
    hierarchyList: getHierarchyListBySpecificLevel(
      hierarchyTypeDetails.hierarchyList,
      hierarchyTypeDetails.hierarchyLevels,
      requiredHierarchyLevelId,
      includeLevelsBelowHierarchy
    )
  }), [hierarchyTypeDetails, includeLevelsBelowHierarchy, requiredHierarchyLevelId])
}
