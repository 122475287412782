import { createSelector } from 'reselect'
import { selectFlightGroupSelection } from './mediaPlansSelectors'

export const createFlightGroupSelectionSelector = () => createSelector(
  selectFlightGroupSelection,
  (state, params) => params.mediaPlanFlightGroupId,
  (state, params) => params.mediaPlanFieldColumnName,
  (selectedFlightGroups, mediaPlanFlightGroupId: number, mediaPlanFieldColumnName: string) => {
    if (!selectedFlightGroups) return

    const flightGroupData = selectedFlightGroups[mediaPlanFlightGroupId] ?? null

    if (!flightGroupData) return

    return {
      isSelected: Boolean(flightGroupData.hasOwnProperty('mediaPlanFlightGroupId') || flightGroupData.hasOwnProperty(mediaPlanFieldColumnName)),
      flightGroupSelection: selectedFlightGroups
    }
  }
)
