
import React from 'react'
import styled from 'styled-components'
import { animated, config, useSpring, interpolate } from 'react-spring'

const svgWithStyles = styled.svg`
  position: absolute;
  will-change: transform;
  width:10px;
  height:10px;
  top: 50vh;
  left: 50vw;
  z-index: 10000;
`

const SvgAnimated = animated(svgWithStyles)

export const ShapeComponent = ({ initialX, initialY, initialHorizontalPosition, initialVerticalPosition, color, setDone }) => {
  const { horizontal, upwards, opacity } = useSpring({
    config: config.default,
    from: {
      horizontal: initialHorizontalPosition,
      upwards: initialVerticalPosition,
      opacity: 100
    },
    to: {
      horizontal: 0,
      upwards: 0,
      opacity: 0
    },
    onRest: () => setDone(false)
  }) as { horizontal: number; upwards: number; opacity: number }

  let totalUpwards = 0
  let totalHorizontal = 0
  const startTime = new Date().getTime() / 1000
  let lastTime = startTime
  const gravity = 30

  return (
    <SvgAnimated
      style={{
        opacity,
        transform: interpolate([upwards, horizontal, opacity], (v, h) => {
          const currentTime = new Date().getTime() / 1000
          const duration = currentTime - lastTime
          const totalDuration = currentTime - startTime
          const verticalTraveled = v * duration
          const horizontalTraveled = h * duration
          totalUpwards += verticalTraveled
          totalHorizontal += horizontalTraveled
          lastTime = currentTime
          const totalGravity = gravity * totalDuration
          const finalX = initialX + totalHorizontal
          const finalY = initialY - totalUpwards + totalGravity
          return `translate3d(${finalX}px, ${finalY}px, 0)`
        })
      }}
    >
      <SelectShape currentColor={color} />
    </SvgAnimated>
  )
}

export default ShapeComponent

const randomInRange = (min, max) => Math.random() * (max - min) + min
const flipCoin = () => Math.round(Math.random()) === 1

const Circle = ({ shapeColor }) => (
  <circle cx='5' cy='5' r='5' fill={shapeColor} />
)
const Triangle = ({ shapeColor, size = 10 }) => {
  const flipped = flipCoin()
  return (
    <polygon
      points={`${size / 2},0 ${size},${randomInRange(
        flipped ? size / 2 : 0,
        size
      )} 0,${randomInRange(flipped ? 0 : size / 2, size)}`}
      fill={shapeColor}
    />
  )
}

const SelectShape = ({ currentColor }) => {
  const shapes = [Triangle, Circle]
  const Result = shapes[Math.floor(randomInRange(0, shapes.length))]
  return <Result shapeColor={currentColor} />
}
