import React, { useState } from 'react'
import MDEditor, { MDEditorProps } from '@uiw/react-md-editor'


export interface IMsMarkdownEditor extends MDEditorProps {
  value: string
  onChange?: (val: string) => void
}

export const MsMarkdownEditorComponent: React.FunctionComponent<IMsMarkdownEditor> = (props) => {
  const [focus, setFocus] = useState(false)
  return (
    <div
      className='ms-md-editor'
      onClick={() => !focus && setFocus(true)}>
      <MDEditor
        height={350}
        preview={focus ? 'live' : 'preview'}
        highlightEnable={false} // TODO: currently in beta - test newer version to enable
        {...props}
      />
    </div>
  )
}

export default MsMarkdownEditorComponent
