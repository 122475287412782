import { useCallback, useMemo, useState } from 'react'

type UseClipboard<T> = Readonly<{
  read: () => T | undefined
  write: (data: T) => void
  clear: () => void
  isEmpty: boolean
}>

export const useClipboard = <T>(): UseClipboard<T> => {
  const [data, setData] = useState<T | undefined>()

  const read = useCallback(() => data, [data])

  const write = useCallback((newData: T) => setData(newData), [])

  const clear = useCallback(() => setData(undefined), [])

  return useMemo(() => ({
    read,
    write,
    clear,
    isEmpty: !data
  }), [data, read, write, clear])
}
