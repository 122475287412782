import React from 'react'
import { Checkbox } from 'antd'

const CheckboxComponent = (props) => (
  <Checkbox
    checked={props.checked}
    onChange={(e) => props.Toggle(e)}
    disabled={props.disabled}
    data-testid={props.dataTestId}
  >
    {props.label}
  </Checkbox>
)

export default CheckboxComponent
