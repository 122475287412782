import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCustomExportsLatestFlags: build.query<
      GetCustomExportsLatestFlagsApiResponse,
      GetCustomExportsLatestFlagsApiArg
    >({
      query: (queryArg) => ({
        url: `/customExports/latestFlags`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    getCustomExportsDisplayTypes: build.query<
      GetCustomExportsDisplayTypesApiResponse,
      GetCustomExportsDisplayTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/customExports/displayTypes`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    getCustomExportsControlFields: build.query<
      GetCustomExportsControlFieldsApiResponse,
      GetCustomExportsControlFieldsApiArg
    >({
      query: (queryArg) => ({
        url: `/customExports/controlFields`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    getCustomExportsCurrencyDisplays: build.query<
      GetCustomExportsCurrencyDisplaysApiResponse,
      GetCustomExportsCurrencyDisplaysApiArg
    >({
      query: (queryArg) => ({
        url: `/customExports/currencyDisplays`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    getCustomExportsClientMediaPlanFields: build.query<
      GetCustomExportsClientMediaPlanFieldsApiResponse,
      GetCustomExportsClientMediaPlanFieldsApiArg
    >({
      query: (queryArg) => ({
        url: `/customExports/clientMediaPlanFields`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    getCustomExportsFieldDataTypeOperators: build.query<
      GetCustomExportsFieldDataTypeOperatorsApiResponse,
      GetCustomExportsFieldDataTypeOperatorsApiArg
    >({
      query: (queryArg) => ({
        url: `/customExports/fieldDataTypeOperators`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    getCustomExports: build.query<
      GetCustomExportsApiResponse,
      GetCustomExportsApiArg
    >({
      query: (queryArg) => ({
        url: `/customExports`,
        params: {
          clientId: queryArg.clientId,
          geographyHierarchyId: queryArg.geographyHierarchyId,
        },
      }),
    }),
    postCustomExports: build.mutation<
      PostCustomExportsApiResponse,
      PostCustomExportsApiArg
    >({
      query: (queryArg) => ({
        url: `/customExports`,
        method: "POST",
        body: queryArg.customExport,
        params: { clientId: queryArg.clientId },
      }),
    }),
    getCustomExportsById: build.query<
      GetCustomExportsByIdApiResponse,
      GetCustomExportsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/customExports/${queryArg.id}`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    putCustomExportsById: build.mutation<
      PutCustomExportsByIdApiResponse,
      PutCustomExportsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/customExports/${queryArg.id}`,
        method: "PUT",
        body: queryArg.customExport,
        params: { clientId: queryArg.clientId },
      }),
    }),
    postCustomExportsUploadTemplate: build.mutation<
      PostCustomExportsUploadTemplateApiResponse,
      PostCustomExportsUploadTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/customExports/uploadTemplate`,
        method: "POST",
        body: queryArg.body,
        params: { clientId: queryArg.clientId },
      }),
    }),
    postCustomExportsByCustomExportIdRun: build.mutation<
      PostCustomExportsByCustomExportIdRunApiResponse,
      PostCustomExportsByCustomExportIdRunApiArg
    >({
      query: (queryArg) => ({
        url: `/customExports/${queryArg.customExportId}/run`,
        method: "POST",
        body: queryArg.customExportRun,
        params: { clientId: queryArg.clientId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetCustomExportsLatestFlagsApiResponse =
  /** status 200 Returns list of Latest Flag */ CustomExportLatestFlag[];
export type GetCustomExportsLatestFlagsApiArg = {
  clientId: number;
};
export type GetCustomExportsDisplayTypesApiResponse =
  /** status 200 Returns list of display types */ CustomExportDisplayType[];
export type GetCustomExportsDisplayTypesApiArg = {
  clientId: number;
};
export type GetCustomExportsControlFieldsApiResponse =
  /** status 200 Returns list of control fields for mediaplan export */ CustomExportControlFieldRead[];
export type GetCustomExportsControlFieldsApiArg = {
  clientId: number;
};
export type GetCustomExportsCurrencyDisplaysApiResponse =
  /** status 200 Returns list of currency display */ CustomExportCurrencyDisplay[];
export type GetCustomExportsCurrencyDisplaysApiArg = {
  clientId: number;
};
export type GetCustomExportsClientMediaPlanFieldsApiResponse =
  /** status 200 Returns list of deduplicated Client Media Plan Fields */ ClientMediaPlanFieldRead[];
export type GetCustomExportsClientMediaPlanFieldsApiArg = {
  clientId: number;
};
export type GetCustomExportsFieldDataTypeOperatorsApiResponse =
  /** status 200 Returns list of Field DataType Operators */ CustomExportFieldDataTypeOperator[];
export type GetCustomExportsFieldDataTypeOperatorsApiArg = {
  clientId: number;
};
export type GetCustomExportsApiResponse =
  /** status 200 Returns all custom exports for the given geography */ CustomExportList[];
export type GetCustomExportsApiArg = {
  clientId: number;
  geographyHierarchyId?: number;
};
export type PostCustomExportsApiResponse =
  /** status 201 Creates a new custom export */ number;
export type PostCustomExportsApiArg = {
  clientId: number;
  customExport: CustomExport;
};
export type GetCustomExportsByIdApiResponse =
  /** status 200 Returns a custom export by id */ CustomExport;
export type GetCustomExportsByIdApiArg = {
  clientId: number;
  id?: number;
};
export type PutCustomExportsByIdApiResponse = /** status 200 OK */ void;
export type PutCustomExportsByIdApiArg = {
  clientId: number;
  id?: number;
  customExport: CustomExport;
};
export type PostCustomExportsUploadTemplateApiResponse =
  /** status 200 OK */ CustomExportTemplateUploadResult;
export type PostCustomExportsUploadTemplateApiArg = {
  clientId: number;
  body: {
    file: Blob;
  };
};
export type PostCustomExportsByCustomExportIdRunApiResponse = unknown;
export type PostCustomExportsByCustomExportIdRunApiArg = {
  clientId: number;
  customExportId?: number;
  customExportRun: CustomExportRun;
};
export type CustomExportLatestFlag = {
  customExportLatestFlagId?: number;
  customExportLatestFlagName?: string | null;
};
export type CustomExportDisplayType = {
  customExportDisplayTypeId?: number;
  customExportDisplayTypeName?: string | null;
};
export type MappingDestinationOptions = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 100 | 200;
export type FieldDataType = {
  fieldDataTypeId?: number;
  dataTypeName?: string | null;
  dataType?: string | null;
  label?: string | null;
  mappingDestination?: MappingDestinationOptions;
};
export type FieldDataTypeRead = {
  fieldDataTypeId?: number;
  dataTypeName?: string | null;
  dataType?: string | null;
  label?: string | null;
  isLookupType?: boolean;
  isClientDefinedListOrUniqueString?: boolean;
  isHierarchy?: boolean;
  isClientHierarchy?: boolean;
  isClientList?: boolean;
  isFinance?: boolean;
  isFinanceAudience?: boolean;
  isCostDatabase?: boolean;
  isParameterizedByGeographyAndMedia?: boolean;
  isNumeric?: boolean;
  isLongInt?: boolean;
  isDecimalType?: boolean;
  isString?: boolean;
  isRichText?: boolean;
  isDate?: boolean;
  cSharpDataType?: string | null;
  mappingDestination?: MappingDestinationOptions;
};
export type CustomExportControlField = {
  customExportControlFieldId?: number;
  fieldDataTypeId?: number;
  fieldLabel?: string | null;
  columnName?: string | null;
  isDisplayOrFilter?: boolean | null;
  fieldDataType?: FieldDataType;
};
export type CustomExportControlFieldRead = {
  customExportControlFieldId?: number;
  fieldDataTypeId?: number;
  fieldLabel?: string | null;
  columnName?: string | null;
  isDisplayOrFilter?: boolean | null;
  fieldDataType?: FieldDataTypeRead;
};
export type CustomExportCurrencyDisplay = {
  customExportCurrencyDisplayId?: number;
  customExportCurrencyDisplayName?: string | null;
};
export type FieldLevel = {
  fieldLevelId?: number;
  fieldLevelName?: string | null;
};
export type FieldTag = {
  fieldTagId?: number;
  fieldTagName?: string | null;
  fieldTagDescription?: string | null;
};
export type MediaPlanFieldLevel = {
  mediaPlanFieldId?: number;
  mediaPlanFieldLevelId?: number;
  fieldLabel?: string | null;
  fieldDataTypeId?: number;
  columnName?: string | null;
  fieldLevelId?: number;
  fieldDescription?: string | null;
  isMandatory?: boolean;
  sortOrder?: number;
  defaultValue?: string | null;
  isApportionable?: boolean;
  isDefaultApportionmentField?: boolean;
  isDefaultApportioned?: boolean;
  fieldDataType?: FieldDataType;
  fieldLevel?: FieldLevel;
  fieldTags?: FieldTag[] | null;
};
export type MediaPlanFieldLevelRead = {
  mediaPlanFieldId?: number;
  mediaPlanFieldLevelId?: number;
  fieldLabel?: string | null;
  fieldDataTypeId?: number;
  columnName?: string | null;
  fieldLevelId?: number;
  fieldDescription?: string | null;
  isMandatory?: boolean;
  sortOrder?: number;
  defaultValue?: string | null;
  isApportionable?: boolean;
  isDefaultApportionmentField?: boolean;
  isDefaultApportioned?: boolean;
  fieldDataType?: FieldDataTypeRead;
  fieldLevel?: FieldLevel;
  fieldTags?: FieldTag[] | null;
};
export type ClientFieldValue = {
  clientFieldValueId?: number;
  mediaPlanFieldId?: number;
  valueDisplayName?: string | null;
  isDisabled?: boolean;
  modifiedBy?: string | null;
  modifiedOn?: string;
  sortOrder?: number;
};
export type ClientMediaPlanField = {
  clientMediaPlanFieldId?: number;
  mediaPlanFieldId?: number;
  mediaPlanFieldLevelId?: number;
  fieldLabel?: string | null;
  isMandatory?: boolean;
  isDisabled?: boolean;
  sortOrder?: number;
  submittedBy?: string | null;
  submittedOn?: string;
  approvedBy?: string | null;
  approvedOn?: string | null;
  isApportioned?: boolean;
  isApportionmentField?: boolean;
  mediaPlanField?: MediaPlanFieldLevel;
  clientFieldValues?: ClientFieldValue[] | null;
};
export type ClientMediaPlanFieldRead = {
  clientMediaPlanFieldId?: number;
  mediaPlanFieldId?: number;
  mediaPlanFieldLevelId?: number;
  fieldLabel?: string | null;
  isMandatory?: boolean;
  isDisabled?: boolean;
  sortOrder?: number;
  submittedBy?: string | null;
  submittedOn?: string;
  approvedBy?: string | null;
  approvedOn?: string | null;
  isApportioned?: boolean;
  isApportionmentField?: boolean;
  mediaPlanField?: MediaPlanFieldLevelRead;
  clientFieldValues?: ClientFieldValue[] | null;
};
export type CustomExportFieldDataTypeOperator = {
  fieldDataTypeOperatorId?: number;
  fieldDataTypeId?: number;
  fieldDataTypeOperator?: string | null;
};
export type CustomExportList = {
  customExportId?: number;
  customExportName?: string | null;
  geographyHierarchyId?: number;
  createdOn?: string | null;
  createdBy?: string | null;
  modifiedOn?: string | null;
  modifiedBy?: string | null;
};
export type CustomExportFilterDefaultValue = {
  customExportFilterDefaultValueId?: number;
  customExportFilterId?: number;
  defaultValue?: string | null;
};
export type CustomExportFilter = {
  customExportFilterId?: number;
  customExportId?: number;
  clientMediaPlanFieldId?: number | null;
  customExportControlFieldId?: number | null;
  fieldDataTypeOperatorId?: number;
  sortOrder?: number;
  customExportFilterDefaultValue?: CustomExportFilterDefaultValue[] | null;
};
export type CustomExportDisplayField = {
  customExportDisplayFieldId?: number;
  customExportId?: number;
  clientMediaPlanFieldId?: number | null;
  customExportControlFieldId?: number | null;
  displayOrder?: number;
};
export type CustomExportSortByField = {
  customExportSortByFieldId?: number;
  customExportId?: number;
  clientMediaPlanFieldId?: number | null;
  customExportControlFieldId?: number | null;
  sortAscending?: boolean;
  sortOrder?: number;
};
export type CustomExport = {
  customExportId?: number;
  customExportCurrencyDisplayId?: number;
  customExportDisplayTypeId?: number;
  customExportLatestFlagId?: number;
  fieldDataTypeOperatorId?: number;
  geographyHierarchyId?: number;
  customExportName?: string | null;
  customExportDistinct?: boolean;
  customExportFullHierarchies?: boolean;
  customExportTemplateLocationUrl?: string | null;
  customExportPlanningDateStartDate?: string;
  customExportPlanningDateEndDate?: string;
  customExportFilters?: CustomExportFilter[] | null;
  customExportDisplayFields?: CustomExportDisplayField[] | null;
  customExportSortByFields?: CustomExportSortByField[] | null;
  createdOn?: string | null;
  createdBy?: string | null;
  modifiedOn?: string | null;
  modifiedBy?: string | null;
  deletedOn?: string | null;
  deletedBy?: string | null;
};
export type CustomExportTemplateUploadResult = {
  url?: string | null;
};
export type CustomExportFilterRun = {
  customExportFilterId?: number;
  fieldDataTypeOperatorId?: number;
  customExportFilterDefaultValue?: CustomExportFilterDefaultValue[] | null;
};
export type CustomExportRun = {
  customExportId?: number;
  customExportLatestFlagId?: number;
  fieldDataTypeOperatorId?: number;
  customExportPlanningDateStartDate?: string;
  customExportPlanningDateEndDate?: string;
  customExportFilters?: CustomExportFilterRun[] | null;
};
export const {
  useGetCustomExportsLatestFlagsQuery,
  useLazyGetCustomExportsLatestFlagsQuery,
  useGetCustomExportsDisplayTypesQuery,
  useLazyGetCustomExportsDisplayTypesQuery,
  useGetCustomExportsControlFieldsQuery,
  useLazyGetCustomExportsControlFieldsQuery,
  useGetCustomExportsCurrencyDisplaysQuery,
  useLazyGetCustomExportsCurrencyDisplaysQuery,
  useGetCustomExportsClientMediaPlanFieldsQuery,
  useLazyGetCustomExportsClientMediaPlanFieldsQuery,
  useGetCustomExportsFieldDataTypeOperatorsQuery,
  useLazyGetCustomExportsFieldDataTypeOperatorsQuery,
  useGetCustomExportsQuery,
  useLazyGetCustomExportsQuery,
  usePostCustomExportsMutation,
  useGetCustomExportsByIdQuery,
  useLazyGetCustomExportsByIdQuery,
  usePutCustomExportsByIdMutation,
  usePostCustomExportsUploadTemplateMutation,
  usePostCustomExportsByCustomExportIdRunMutation,
} = injectedRtkApi;
