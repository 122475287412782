import { IMSHierarchies } from 'mindshare.layout'

import { UseQueryCommand } from '../redux/types'
import { useHierarchiesQuery } from 'Apis/enhancedHierarchiesApi'
import { queryCacheValidTime } from 'Apis/queryCacheValidTime'

const initialHierarchies: IMSHierarchies = {
  clientBrandHierarchies: [],
  clientBusinessHierarchies: [],
  clientGeographyHierarchies: [],
  clientMediaHierarchies: [],
  clientBrandHierarchyLevels: [],
  clientBusinessHierarchyLevels: [],
  clientGeographyHierarchyLevels: [],
  clientMediaHierarchyLevels: [],
  financeProductHierarchies: [],
  financeProductHierarchyLevels: [],
  financeStationHierarchies: [],
  financeStationHierarchyLevels: [],
  costBuyingRouteHierarchies: [],
  costBuyingRouteHierarchyLevels: []
}

export const useHierarchies = (clientId: number): UseQueryCommand<IMSHierarchies> => {
  const { data = initialHierarchies, isLoading } = useHierarchiesQuery({ clientId }, { refetchOnMountOrArgChange: queryCacheValidTime.hierarchies } ) as { data: IMSHierarchies; isLoading: boolean }

  return {
    isLoading,
    data
  }
}
