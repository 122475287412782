import React, { useCallback } from 'react'
import { Select } from 'antd'
import { useFinanceDataAudiences } from 'Components/FinanceData/hooks/useFinanceDataAudiences'
import { useFinanceDataBookingCategories } from 'Components/FinanceData/hooks/useFinanceDataBookingCategories'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { IMasteredHierachiesHelperValues, ITemplateGetFinanceListFields, IGetMasteredHierachiesFields } from 'Components/TemplateFieldSelection/constants/entities/ITemplateMasteredFieldsHelperValues'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { HIERARCHY_ID_PROP, HIERARCHY_TYPES, HIERARCHY_VALUE_PROP, IClientHierarchy, IClientHierarchyLevel } from 'mindshare.layout'
import { useFinanceDataProducts } from 'Components/FinanceData/hooks/useFinanceDataProducts'
import { useFinanceDataStations } from 'Components/FinanceData/hooks/useFinanceDataStations'
import { useGetCostMasterDataBuyingRouteHierarchy } from 'Components/CostMasterData/hooks/useGetCostMasterDataBuyingRouteHierarchy'

interface IMasteredFieldsDependentProps {
  geographyHierarchyId: number
  mediaHierarchyId: number
  currentField: IMediaPlanFieldRow
}

export const useGetTemplateFinanceListFields = ({ geographyHierarchyId, mediaHierarchyId, currentField }): ITemplateGetFinanceListFields => {
  const { data: financeDataAudiences = [] } = useFinanceDataAudiences({
    clientGeographyHierarchyId: geographyHierarchyId,
    clientMediaHierarchyId: mediaHierarchyId,
    shouldSkip: (currentField.mediaPlanField.fieldDataType?.fieldDataTypeId !== FieldDataType.FINANCE_BUYING_AUDIENCE_LIST &&
        currentField.mediaPlanField.fieldDataType?.fieldDataTypeId !== FieldDataType.FINANCE_TARGET_AUDIENCE_LIST)
  })
  const {
    data: financeDataBookingCategories = []
  } = useFinanceDataBookingCategories({
    clientGeographyHierarchyId: geographyHierarchyId,
    clientMediaHierarchyId: mediaHierarchyId,
    shouldSkip: currentField.mediaPlanField.fieldDataType?.fieldDataTypeId !== FieldDataType.FINANCE_BOOKING_CATEGORY_LIST
  })

  const financeTargetAudiences = financeDataAudiences.filter((item) => !item.isBuyingAudience)
  const financeBuyingAudiences = financeDataAudiences.filter((item) => item.isBuyingAudience)

  const financeDataAudiencesDefaultValue = financeDataAudiences && financeDataAudiences
    .find((f) => f.financeAudienceId === Number(currentField.defaultValue))?.audienceName
  const financeDataBookingCategoriesDefaultValue = financeDataBookingCategories && financeDataBookingCategories
    .find((f) => f.financeBookingCategoryId === Number(currentField.defaultValue))?.bookingCategoryName

  return {
    FinanceTargetAudienceList: {
      data: financeTargetAudiences,
      renderOptions: () => (
        financeTargetAudiences.map(f => (
          <Select.Option
            key={f.financeAudienceId}
            value={f.financeAudienceId}
          >
            {f.audienceName}
          </Select.Option>
        ))
      ),
      defaultValue: financeDataAudiencesDefaultValue
    },
    FinanceBuyingAudienceList: {
      data: financeBuyingAudiences,
      renderOptions: () => (
        financeBuyingAudiences.map(f => (
          <Select.Option
            key={f.financeAudienceId}
            value={f.financeAudienceId}
          >
            {f.audienceName}
          </Select.Option>
        ))
      ),
      defaultValue: financeDataAudiencesDefaultValue
    },
    FinanceBookingCategoryList: {
      data: financeDataBookingCategories,
      renderOptions: () => (
        financeDataBookingCategories.map(f => (
          <Select.Option
            key={f.financeBookingCategoryId}
            value={f.financeBookingCategoryId}
          >
            {f.bookingCategoryName}
          </Select.Option>
        ))
      ),
      defaultValue: financeDataBookingCategoriesDefaultValue
    }
  }
}

const masteredHierarchiesCommonProps = {
  idProp: HIERARCHY_ID_PROP,
  valueProp: HIERARCHY_VALUE_PROP
}

const emptyHierarchyList = []

export const useGetTemplateMasteredHierarchiesFields = ({ geographyHierarchyId, mediaHierarchyId, currentField }: IMasteredFieldsDependentProps): IGetMasteredHierachiesFields => {
  const { data: financeProductHierarchies } = useFinanceDataProducts({
    clientGeographyHierarchyId: geographyHierarchyId,
    clientMediaHierarchyId: mediaHierarchyId,
    shouldSkip: currentField.mediaPlanField.fieldDataType?.fieldDataTypeId !== FieldDataType.FINANCE_PRODUCT_HIERARCHY
  })

  const { data: financeStationHierarchies } = useFinanceDataStations({
    clientGeographyHierarchyId: geographyHierarchyId,
    clientMediaHierarchyId: mediaHierarchyId,
    shouldSkip: currentField.mediaPlanField.fieldDataType?.fieldDataTypeId !== FieldDataType.FINANCE_STATION_HIERARCHY
  })

  const { data: costBuyingRouteHierarchies } = useGetCostMasterDataBuyingRouteHierarchy({
    clientGeographyHierarchyId: geographyHierarchyId,
    clientMediaHierarchyId: mediaHierarchyId,
    shouldSkip: currentField.mediaPlanField.fieldDataType?.fieldDataTypeId !== FieldDataType.COST_BUYING_ROUTE_HIERARCHY
  })

  const getMasteredHierarchiesValues = useCallback((key: number): IMasteredHierachiesHelperValues => {
    switch (key) {
      case FieldDataType.FINANCE_PRODUCT_HIERARCHY: return {
        hierarchyList: financeProductHierarchies?.hierarchies as IClientHierarchy[],
        hierarchyLevels: financeProductHierarchies?.hierarchyLevels as IClientHierarchyLevel[],
        hierarchyType: HIERARCHY_TYPES[0],
        isLoaded: !!financeProductHierarchies?.hierarchies,
        ...masteredHierarchiesCommonProps
      }
      case FieldDataType.FINANCE_STATION_HIERARCHY: return {
        hierarchyList: financeStationHierarchies?.hierarchies as IClientHierarchy[],
        hierarchyLevels: financeStationHierarchies?.hierarchyLevels as IClientHierarchyLevel[],
        hierarchyType: HIERARCHY_TYPES[1],
        isLoaded: !!financeStationHierarchies?.hierarchies,
        ...masteredHierarchiesCommonProps
      }
      case FieldDataType.COST_BUYING_ROUTE_HIERARCHY: return {
        hierarchyList: costBuyingRouteHierarchies?.hierarchies as IClientHierarchy[],
        hierarchyLevels: costBuyingRouteHierarchies?.hierarchyLevels as IClientHierarchyLevel[],
        hierarchyType: HIERARCHY_TYPES[2],
        isLoaded: !!costBuyingRouteHierarchies?.hierarchies,
        ...masteredHierarchiesCommonProps
      }
    }
    return {
      hierarchyList: emptyHierarchyList,
      hierarchyLevels: emptyHierarchyList,
      hierarchyType: null,
      isLoaded: true,
      ...masteredHierarchiesCommonProps
    }
  }, [financeProductHierarchies, financeStationHierarchies, costBuyingRouteHierarchies])

  const isMasteredHierarchyDisabled = !geographyHierarchyId || !mediaHierarchyId

  return {
    getMasteredHierarchiesValues,
    isMasteredHierarchyDisabled
  }
}
