import { useMemo } from 'react'
import { getFilteredChosenFlightFields, IMediaPlanMetaField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

export const useSubtotalFlightFields = ({
  flightFields,
  isCollapsed,
  collapsedViewField
}:
{
  flightFields: IMediaPlanMetaField[]
  isCollapsed: boolean
  collapsedViewField: string
}) => {
  const filteredFlightFields = useMemo(() => getFilteredChosenFlightFields(flightFields, isCollapsed, collapsedViewField), [flightFields, isCollapsed, collapsedViewField])
  const subtotalFlightFields = useMemo(() => filteredFlightFields.length ? filteredFlightFields : (flightFields.length ? [flightFields[0]] : []), [flightFields, filteredFlightFields])

  return subtotalFlightFields
}
