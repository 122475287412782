import React, { FunctionComponent, memo } from 'react'
import classNames from 'classnames'
import { Tooltip } from 'antd'

import { getCompressedFlightWidth } from 'Helpers/calendarHelper'
import { formatDate } from 'Helpers/formatDate'
import { useFlightGroupFlights } from 'Components/MediaPlanVersion/hooks/useFlightGroupFlights'
import { IFlight } from 'Components/MediaPlanVersion/constants/entities/IFlight'
import EmptyCellComponent from 'Components/MediaPlanVersion/EmptyCellComponent'
import MediaPlanInputComponent from 'Components/MediaPlanInputComponent'

interface IFlightDatesContainerProps {
  compressedCalendarView: boolean
  flightGroupId: number
  flightGroupIndex: number
}

interface IFlightDateComponentProps extends Pick<IFlightDatesContainerProps, 'compressedCalendarView' | 'flightGroupId'> {
  flightDate: IFlight
  dataKey: string
}

const emptyFlightDatesMediaPlanField = { fieldLabel: 'Flight Dates' }

const FlightDateComponent: FunctionComponent<IFlightDateComponentProps> = memo(({ compressedCalendarView, dataKey, flightDate, flightGroupId }) => {
  const width = compressedCalendarView ? getCompressedFlightWidth(flightDate.width) : flightDate.width
  const text = flightDate.merge ? `${formatDate(flightDate.flightStartDate, 'MMM DD')} to ${formatDate(flightDate.flightEndDate, 'MMM DD')}` : ''

  return (
    <Tooltip title={text}>
      <div
        className={classNames(
          'flight__gridItem',
          { '-compressed': compressedCalendarView }
        )}
        data-key={dataKey}
        style={{ width }}
      >
        {!flightDate.merge
          ? <EmptyCellComponent fieldLabel='' />
          : <MediaPlanInputComponent
            id={flightGroupId}
            mediaPlanField={emptyFlightDatesMediaPlanField}
            newValue={text}
            readOnly
            disabled
          />
        }
      </div>
    </Tooltip>
  )
})

const FlightDatesContainer: FunctionComponent<IFlightDatesContainerProps> = ({ compressedCalendarView, flightGroupId, flightGroupIndex }) => {
  const availableFlights = useFlightGroupFlights(flightGroupIndex)
  if (!availableFlights?.some(f => f.merge)) return null

  return (
    <div className='flight__grid -readonly'>
      {
        availableFlights?.map((flightDate, index) => {
          const key = `FlightDatesContainer-${index}-${flightGroupIndex}`
          return (
            <FlightDateComponent
              compressedCalendarView={compressedCalendarView}
              dataKey={key}
              flightDate={flightDate}
              flightGroupId={flightGroupId}
              key={key}
            />
          )
        })
      }
    </div>
  )
}

export default memo(FlightDatesContainer)
