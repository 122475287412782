import { isFieldOfSameLevel, isFieldOfHigherLevel, isFieldOfSameOrHigherLevel } from 'Components/LinkedLookup/helpers/fieldHelper'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { IMediaPlanVersionGetMasteredDataValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { getFieldColumnName, IMediaPlanVersionField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

export const getPlanMasteredDataVariables = ({
  mediaPlanVersionFields,
  masteredHierarchyFields,
  parseData
}: {
  mediaPlanVersionFields: IMediaPlanVersionField[]
  masteredHierarchyFields: IMediaPlanVersionField[]
  parseData: any
}): IMediaPlanVersionGetMasteredDataValues => {
  const geographyHierarchyFields = mediaPlanVersionFields.filter(
    item =>
      item.clientMediaPlanField.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.GEOGRAPHY_HIERARCHY && item.isInPlan && masteredHierarchyFields.some((field) => isFieldOfSameOrHigherLevel(item, field))
  )

  const mediaHierarchyFields = mediaPlanVersionFields.filter(
    item =>
      item.clientMediaPlanField.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.MEDIA_HIERARCHY && item.isInPlan && masteredHierarchyFields.some((field) => isFieldOfSameOrHigherLevel(item, field))
  )

  const getFieldValuesByLevelId = (field) => {
    const columnName = getFieldColumnName(field)
    const currentFieldLevelId = field?.clientMediaPlanField.mediaPlanField.fieldLevelId

    switch (currentFieldLevelId) {
      case FieldLevelType.PLAN: {
        return parseData[columnName]
      }

      case FieldLevelType.FLIGHT_GROUP: {
        let fieldValue
        parseData.flightGroups.forEach((flightGroup) => {
          fieldValue = flightGroup[columnName]
        })

        return fieldValue
      }

      case FieldLevelType.FLIGHT: {
        let fieldValue
        parseData.flightGroups.forEach((flightGroup) =>
          flightGroup.flights.forEach((flight) => {
            fieldValue = flight?.[columnName]
          })
        )

        return fieldValue
      }

      case FieldLevelType.SUB_FLIGHT: {
        let fieldValue
        parseData.flightGroups.forEach(flightGroup => {
          flightGroup.flights.forEach((flight) => {
            flight.subFlights.forEach(subFlight => {
              fieldValue = subFlight?.[columnName]
            }
            )
          })
        })

        return fieldValue
      }

      default: {
        return undefined
      }
    }
  }

  const getHierarchyField = (hierarchyFields: IMediaPlanVersionField[]): IMediaPlanVersionField => {
    const field = hierarchyFields.find((item) => {
      const fieldValue = getFieldValuesByLevelId(item)
      return fieldValue && masteredHierarchyFields.some((currentField) => isFieldOfSameLevel(item, currentField))
    }) || hierarchyFields.find((item) => {
      const fieldValue = getFieldValuesByLevelId(item)
      return fieldValue && masteredHierarchyFields.some((currentField) => isFieldOfHigherLevel(item, currentField))
    })

    return field
  }

  const getGeographyHierarchyFieldId = () => {
    const geographyHierarchyField = getHierarchyField(geographyHierarchyFields)
    const geographyHierarchyId = getFieldValuesByLevelId(geographyHierarchyField)

    return geographyHierarchyId
  }

  const getMediaHierarchyFieldId = () => {
    const mediaHierarchyField = getHierarchyField(mediaHierarchyFields)
    const mediaHierarchyId = getFieldValuesByLevelId(mediaHierarchyField)

    return mediaHierarchyId
  }

  return {
    getGeographyHierarchyFieldId,
    getMediaHierarchyFieldId
  }
}
