const text = `# Template List

A list of all templates available for the current client.

## Edit template

Takes you through to the edit template page.

## Duplicate template

Will make a duplicate of the selected template with a template name of 'Copy of (original template name)'.

Once duplicated there is no link between the 2 templates so changes made in one will not have an effect on the other.

## Delete template

You will be prompted to provide confirmation that you want to delete the selected template.

Deleting a template means it cannot be selected to create a new plan with but will not change any plans that were created using this template already.

## Create new template

Will take you through to the create template page.

`

export default text
