import React, { useCallback, useEffect, useState } from 'react'
import { useMindshareSelector } from 'mindshare.customhooks'
import { navigate, MSLayoutContentRow, LoadingContainer } from 'mindshare.layout'
import { getDataImports, cleanUpImportListContainer, getDataImport, initialiseImportListContainer } from 'Actions/importActions'
import ImportListComponent from 'Components/Import/ImportListComponent'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import { DataImport } from 'Apis/generated/dataImportsApi'
import { RootState } from 'Reducers/index'
import { useAgencyLoadingGif } from 'Hooks/useAgencyLoadingGif'
import { useAppDispatch, useAppSelector } from '../../store'

export const ImportListContainer: React.FC = (): React.ReactElement => {
  const currentClientId = useAppSelector((state) => state.app.currentClient.id)
  const currentClientDisplayName = useAppSelector((state) => state.app.currentClient.displayName)
  const dataImports = useMindshareSelector((state: RootState) => state.imports.dataImports) as DataImport[]
  const dispatch = useAppDispatch()
  const [clientSelected] = useState(currentClientId)

  useEffect(() => () => dispatch(cleanUpImportListContainer()), [dispatch])

  useEffect(() => {
    dispatch(getDataImports(currentClientId))

    if (clientSelected && currentClientId !== clientSelected) {
      dispatch(cleanUpImportListContainer())
    }
  }, [dispatch, clientSelected, currentClientId])

  const createNewImport = useCallback(() => navigate('/create-import'), [])
  const editImport = useCallback((importId: number) => {
    dispatch(getDataImport(importId, currentClientId))
    navigate(`/edit-import/${importId}`)
  }, [dispatch, currentClientId])

  return (
    <>
      <TitleAndButtonsComponent
        buttons={[{
          buttonClick: createNewImport,
          buttonText: 'Create New Import'
        }]}
        subtitle='Your Imports'
        title='Imports'
      />

      <MSLayoutContentRow fillAvailableSpace={true}>
        <ImportListComponent
          currentClientDisplayName={currentClientDisplayName}
          createNewImport={createNewImport}
          dataImports={dataImports}
          editImport={editImport}
        />
      </MSLayoutContentRow>
    </>
  )
}

export const ImportListLoadingContainer: React.FC = () => {
  const agencyLoadingGifLocation = useAgencyLoadingGif()
  const clientId = useAppSelector((state) => state.app.currentClient.id)
  const dispatch = useAppDispatch()
  return (
    <LoadingContainer
      appDataSuccess={!!clientId}
      agencyLoadingGif={agencyLoadingGifLocation}
      initialiseContainer={(loadingComplete) => initialiseImportListContainer(loadingComplete, dispatch, clientId)}
    >
      <ImportListContainer />
    </LoadingContainer>
  )
}

export default ImportListLoadingContainer
