import React, { FunctionComponent } from 'react'

import { CollapsedViewComponent } from 'Components/MediaPlanVersion/CollapsedViewComponent'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { compare } from 'Helpers/sortHelper'

interface IProps {
  fields: IMediaPlanTemplateFields[]
  collapsedViewField: string
  setUserPreferences: (values) => void
}

export const CollapsedViewContainer: FunctionComponent<IProps> = ({ fields, collapsedViewField, setUserPreferences }) => {
  const flightFieldsInPlan = (field: IMediaPlanTemplateFields): boolean => {
    const mediaPlanField = field.clientMediaPlanField.mediaPlanField

    return (
      field.isInPlan &&
      (mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT)
    )
  }

  const fieldOptions = (field: IMediaPlanTemplateFields) => ({
    name: field.clientMediaPlanField.mediaPlanField.columnName,
    label: field.fieldLabel,
    id: field.clientMediaPlanFieldId
  })

  const flightFields = fields?.filter(flightFieldsInPlan) || []
  const options = flightFields.map(fieldOptions)
  const sortedOptions = [...options].sort((a, b) => compare(a.label, b.label, true))

  const handleChange = (value: string) => {
    setUserPreferences(currentValues => ({ ...currentValues, collapsedViewField: value }))
  }

  return (
    <CollapsedViewComponent
      options={sortedOptions}
      setSelectedField={handleChange}
      selectedField={collapsedViewField}
    />
  )
}

export default CollapsedViewContainer
