import React, { FunctionComponent } from 'react'
import { Form, FormInstance } from 'antd'
import { useMindshareSelector } from 'mindshare.customhooks'
import { redistributionTooltipMessage } from 'Constants/enums/TooltipMessages'
import { RootState } from '../../../redux/reducers'
import { FormItemInput } from '../form-elements/FormItemInput'
import { checkUniqueField } from 'Helpers/redistributionHelpers'

interface IRedistributionFieldValues {
  redistributionName: string
}

interface IProps {
  formInstance: FormInstance<IRedistributionFieldValues>
  onChange: (value: any, name: string) => void
}

export const RedistributionConfigNameSetupForm: FunctionComponent<IProps> = ({ formInstance, onChange }) => {
  const redistributions = useMindshareSelector((state: RootState) => state.mediaPlans.redistributions)

  return (
    <Form
      form={formInstance}
      name="redistribution-config"
      className="redistribution-form"
    >
      <div className="field-wrapper">
        <FormItemInput name="redistributionName" label="Redistribution config name:" onChange={onChange} formItemProps={{
          rules: [
            { required: true, message: redistributionTooltipMessage.missingConfigName },
            {
              validator: (_, value) =>
                checkUniqueField(redistributions, value, 'redistributionName') ? Promise.resolve() : Promise.reject(new Error(redistributionTooltipMessage.duplicatedConfigName))
            }
          ]
        }}/>
      </div>
    </Form>
  )
}
