import React from 'react'
import { Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { formatReplaceUnderscoresInString } from 'mindshare.layout'
import { IMediaPlanTemplateFields, getCorrectDisplayField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { Function } from 'Apis/generated/functionsApi'
import { FieldLevel } from 'Apis/generated/mediaPlanFieldsApi'
import InfoModalComponent from 'Components/InfoModalComponent'
import templateExampleText from 'Components/tooltipContent/Template Example'
import mediaPlanInputText from 'Components/tooltipContent/Media Plan Version'
import CalculationComponent from 'Components/CalculationComponent'


interface IHeaderExampleComponent {
  calculationMode: boolean
  setCalculationMode: React.Dispatch<React.SetStateAction<boolean>>
  enableToggle: boolean
  planInfo: JSX.Element
  headerTitle: string
  selectedCalculatedMediaField: IMediaPlanTemplateFields
  mediaPlanTemplateFields: IMediaPlanTemplateFields[]
  calculationFunctions: Function[]
  fieldLevels: FieldLevel[]
  expressionHandler: (value: string) => void
  fieldCalculatedHandler: (c: string) => void
  handleCalculation: (mediaPlanTemplate: IMediaPlanTemplateFields) => void
  onFunctionSelected: (functionId: number) => void
}

export const TemplateExampleHeaderComponent: React.FunctionComponent<IHeaderExampleComponent> = ({
  planInfo,
  calculationMode,
  setCalculationMode,
  enableToggle,
  headerTitle,
  mediaPlanTemplateFields,
  calculationFunctions,
  handleCalculation,
  selectedCalculatedMediaField,
  fieldCalculatedHandler,
  expressionHandler,
  fieldLevels,
  onFunctionSelected
}: IHeaderExampleComponent) => {
  return mediaPlanTemplateFields ? (
    <div className='template-example-header-container'>
      <h1>{formatReplaceUnderscoresInString(headerTitle)}{planInfo}</h1>

      <div className='template-example-header-subtitle'>
        <div className='template-example-header-buttons'>
          Calculations {enableToggle && (
            <Button
              icon={<DownOutlined />}
              type='link'
              className='btn-toggle-mode'
              data-testid='btn-toggle-calculation'
              aria-label='calculations button'
              onClick={ () => setCalculationMode(!calculationMode)}
            />
          )}
        </div>
      </div>

      {calculationMode && (
        <CalculationComponent
          expression={selectedCalculatedMediaField && selectedCalculatedMediaField.calculation}
          expressionHandler={ () => {}}
          handleCalculation={handleCalculation}
          selectedCalculatedMediaField={selectedCalculatedMediaField}
          setExpression={expressionHandler}
          updatingFromTemplate={false}

          mediaPlanTemplateFields={mediaPlanTemplateFields}
          fieldLevels={fieldLevels}
          onChangeField={fieldCalculatedHandler}
          getFieldLabel={getCorrectDisplayField}

          calculationFunctions={calculationFunctions}
          onFunctionSelected={onFunctionSelected}
        />
      )}

      <InfoModalComponent
        text={headerTitle === 'Template Example' ? templateExampleText : mediaPlanInputText}
      />
    </div>
  ) : null
}

export default TemplateExampleHeaderComponent
