import React, { useCallback, FunctionComponent, useState, useEffect, useMemo } from 'react'
import { Button, Row, Col, Space, message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useMindshareSelector } from 'mindshare.customhooks'
import { IMSHierarchies } from 'mindshare.layout'
import { RootState } from '../../../redux/reducers'
import { ISelectedRedistributeByField } from 'Components/Redistribution/constants/entities/IRedistribution'
import { useRedistributionFormValues } from 'Components/Redistribution/hooks/useRedistributionFormValues'
import { RedistributionPercentageSetup } from 'Components/Redistribution/components/index'
import { useRedistributionOnSubmit } from '../hooks/useRedistributionOnSubmit'
import { RedistributionListComponent } from '../components/RedistributionListComponent'
import { useRedistributionInitialValues } from '../hooks/useRedistributionInitialValues'
import { ApplyRedistributionComponent } from '../components/ApplyRedistributionComponent'
import { setCurrentRedistribution } from 'Actions/mediaPlansActions'
import { useAppDispatch } from '../../../store'
import { Redistribution } from 'Apis/generated/redistributionsApi'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { DraggableModalContainer } from 'Containers/MediaPlanVersion/DraggableModalContainer'

interface IProps {
  showCreateModal: () => void
  hideModal: () => void
  visible: boolean
  hierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  financeList: IMediaPlanVersionFinanceListFields
}

enum RedistributionSteps {
  SELECT_CONFIG,
  PERCENTAGE_SETUP,
  APPLY_REDISTRIBUTION
}

export const RedistributionContainer: FunctionComponent<IProps> = ({
  hideModal,
  showCreateModal,
  visible,
  hierarchies,
  masteredListsData,
  financeList
}) => {
  const currentRedistribution = useMindshareSelector(
    (state: RootState) => state.mediaPlans.currentRedistribution || {}
  ) as Redistribution
  const selectedRedistributeByFields = useMindshareSelector((state: RootState) => state.mediaPlans.selectedRedistributeByFields) as ISelectedRedistributeByField[]

  const [step, setStep] = useState(RedistributionSteps.SELECT_CONFIG)
  const isEditMode = !!currentRedistribution.redistributionId

  const initialValues = useRedistributionInitialValues(currentRedistribution)
  const {
    formValues,
    resetForm,
    updateFieldValue,
    removeFieldValue,
    addFieldValue
  } = useRedistributionFormValues(initialValues, isEditMode)
  const onSubmit = useRedistributionOnSubmit()
  const [amountToRedistribute, setAmountToRedistribute] = useState(null)
  const dispatch = useAppDispatch()

  const fieldsAmount = useMemo(() => selectedRedistributeByFields.reduce((acc, item) => acc + Number(item.value), 0) as number, [selectedRedistributeByFields])

  const handleNextStep = useCallback(() => {
    setStep(step + 1)
  }, [step])

  const handlePrevStep = () => {
    setStep(step - 1)
  }

  useEffect(() => {
    if (fieldsAmount > 0) {
      setAmountToRedistribute(fieldsAmount)
    } else {
      setAmountToRedistribute(null)
    }
  }, [fieldsAmount])

  const handleAmountChange = (value: number) => {
    setAmountToRedistribute(value)
  }

  const hideModalForm = useCallback(() => {
    resetForm()
    dispatch(setCurrentRedistribution(null))
    hideModal()
  }, [hideModal, resetForm, dispatch])

  const handleSubmit = useCallback(async () => {
    const result = await onSubmit({
      ...formValues,
      redistributionId: currentRedistribution.redistributionId
    })

    if (result) {
      message.success('Redistribution updated successfully')
      handleNextStep()
    }
  }, [
    formValues,
    onSubmit,
    currentRedistribution.redistributionId,
    handleNextStep
  ])

  const renderFormSteps = () => {
    switch (step) {
      case RedistributionSteps.SELECT_CONFIG:
        return (
          <RedistributionListComponent showCreateModal={showCreateModal} hierarchies={hierarchies} financeList={financeList} masteredListsData={masteredListsData} />
        )
      case RedistributionSteps.PERCENTAGE_SETUP:
        return (
          <RedistributionPercentageSetup
            updateFieldValue={updateFieldValue}
            removeFieldValue={removeFieldValue}
            addFieldValue={addFieldValue}
            hierarchies={hierarchies}
            masteredListsData={masteredListsData}
            financeList={financeList}
            {...formValues}
          />
        )

      default:
        return null
    }
  }

  const title = (
    <div className='draggable-container'>
      Redistribute
    </div>
  )

  return (
    <>
      {step === RedistributionSteps.APPLY_REDISTRIBUTION ? (
        <ApplyRedistributionComponent
          hideModal={hideModalForm}
          goBack={handlePrevStep}
          amountToRedistribute={amountToRedistribute}
          onChange={handleAmountChange}
          formValues={formValues}
        />
      ) : (
        <DraggableModalContainer
          width={800}
          footer={null}
          onCancel={hideModal}
          title={title}
          visible={visible}
          className='redistribution-modal'
          modalBody={(
            <>
              {renderFormSteps()}
              <Row justify='space-between' className='modal-footer'>
                <Col>
                  <Space>
                    <Button aria-label='Cancel' onClick={hideModalForm}>
                  Cancel
                    </Button>
                    {step === RedistributionSteps.SELECT_CONFIG ? (
                      <Button
                        aria-label='Cancel'
                        onClick={handleNextStep}
                        type='primary'
                        disabled={isEmpty(currentRedistribution)}
                      >
                    Select
                      </Button>
                    ) : (
                      <>
                        <Button
                          aria-label='Back'
                          onClick={handlePrevStep}
                          type='primary'
                        >
                      Back
                        </Button>
                        <Button
                          aria-label='Last Step'
                          type='primary'
                          htmlType='submit'
                          onClick={handleSubmit}
                        >
                      Select Flights...
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </>
          )}
        />
      )}
    </>
  )
}
