import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { navigate, MSLayoutContentRow, LoadingComponent, MSTable } from 'mindshare.layout'
import { Button, Popconfirm, Tooltip, Checkbox } from 'antd'
import { EditOutlined, CopyOutlined } from '@ant-design/icons'
import isEqual from 'lodash/isEqual'
import { DataNode } from 'rc-tree/lib/interface'

import { appPaths } from '../../../providers/AppRoutes'
import { popoverMessage } from 'Constants/enums/PopoverMessage'
import { CLIENT_HIERARCHY_TYPES_MAP, expandHierarchies } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { LINKED_LOOKUP_TYPES } from 'Components/LinkedLookup/constants/enums/LinkedLookupTypes'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useHierarchies } from 'Hooks/useHierarchies'
import { oneOf } from 'Helpers/conditionsHelper'
import { createLinkedLookupDto } from 'Components/LinkedLookup/dtos/createLinkedLookupDto'
import { useUpdateLinkedLookup } from 'Components/LinkedLookup/hooks/useUpdateLinkedLookup'
import { useCurrentLinkedLookup } from 'Components/LinkedLookup/hooks/useCurrentLinkedLookup'
import { getFieldValues, formatFieldValue, getFieldLevel } from 'Components/LinkedLookup/helpers/fieldHelper'
import { BreadcrumbItem } from 'Components/shared/BreadcrumbItem'
import { LinkedLookupEditFieldModalContainer } from 'Components/LinkedLookup/containers/LinkedLookupEditFieldModalContainer'
import { useLinkedLookupConnectionValues } from 'Components/LinkedLookup/hooks/useLinkedLookupConnectionValues'
import { useMediaPlanFields } from 'Components/LinkedLookup/hooks/useMediaPlanFields'
import fieldConnectionsText from 'Components/tooltipContent/Field Connections'
import InfoModalComponent from 'Components/InfoModalComponent'
import { LinkedLookupCopyModalContainer } from 'Components/LinkedLookup/containers/LinkedLookupCopyModalContainer'
import { useMasteredListFieldsData } from 'Hooks/useMasteredListFieldsData'
import { useGetClientMediaPlanFieldsQuery } from 'Apis/enhancedClientFieldAliasApi'
import { getDisplayLabel } from 'Components/Client/constants/entities/IClientMediaPlanField'

interface IProps {
  linkedLookupId?: number
}

export interface TreeDataNode extends DataNode {
  value: number
}

export const LinkedLookupFieldContainer: React.FC<IProps> = ({ linkedLookupId }): React.ReactElement => {
  const { data: currentClient } = useCurrentClient()
  const { data: currentLinkedLookup } = useCurrentLinkedLookup({ id: linkedLookupId })
  const { data: mediaPlanFields = [], isLoading: areMediaPlanFieldsLoading } = useMediaPlanFields()
  const masteredListsData = useMasteredListFieldsData(currentClient?.id)
  const { data: hierarchies } = useHierarchies(currentClient?.id)
  const { execute: updateLinkedLookup } = useUpdateLinkedLookup()
  const linkedLookupModel = useLinkedLookupConnectionValues({
    getInitialValues: () => currentLinkedLookup,
    skip: !currentLinkedLookup
  })
  const [editFieldModalVisible, setEditFieldModalVisible] = useState(false)
  const [currentFieldId, setCurrentFieldId] = useState<number>()
  const [originalLinkedLookupModel, setOriginalLinkedLookupModel] = useState(linkedLookupModel)
  const [copyModalVisible, setCopyModalVisible] = useState(false)
  const { data: clientMediaPlanFields = [] } = useGetClientMediaPlanFieldsQuery({ clientId: currentClient.id })

  useEffect(() => {
    if (linkedLookupModel?.values?.linkedLookupValues && !originalLinkedLookupModel?.values?.linkedLookupValues) {
      setOriginalLinkedLookupModel(linkedLookupModel)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedLookupModel])

  const isWithUnsavedChanges = useMemo(
    () => (
      !!originalLinkedLookupModel?.values?.linkedLookupValues &&
      !isEqual(originalLinkedLookupModel, linkedLookupModel)
    ),
    [originalLinkedLookupModel, linkedLookupModel]
  )

  const referencedField = useMemo(
    () =>
      mediaPlanFields.find(
        field =>
          field.mediaPlanFieldId ===
          currentLinkedLookup?.referencedMediaPlanFieldId
      ),
    [
      mediaPlanFields,
      currentLinkedLookup?.referencedMediaPlanFieldId
    ]
  )
  const referencedClientMediaPlanField = useMemo(
    () =>
      [...clientMediaPlanFields]
        .sort((f1, f2) => getFieldLevel(f1) - getFieldLevel(f2))
        .find(
          f =>
            f.mediaPlanFieldId === referencedField?.mediaPlanFieldId && f.fieldLabel
        ),
    [clientMediaPlanFields, referencedField?.mediaPlanFieldId]
  )
  const referencedFieldLabel = referencedClientMediaPlanField ? getDisplayLabel(referencedClientMediaPlanField) : referencedField?.fieldLabel

  const dependentField = useMemo(
    () =>
      mediaPlanFields.find(
        field =>
          field.mediaPlanFieldId ===
          currentLinkedLookup?.dependentMediaPlanFieldId
      ),
    [
      mediaPlanFields,
      currentLinkedLookup?.dependentMediaPlanFieldId
    ]
  )
  const dependentClientMediaPlanField = useMemo(
    () =>
      [...clientMediaPlanFields]
        .sort((f1, f2) => getFieldLevel(f1) - getFieldLevel(f2))
        .find(
          f =>
            f.mediaPlanFieldId === dependentField?.mediaPlanFieldId && f.fieldLabel
        ),
    [clientMediaPlanFields, dependentField?.mediaPlanFieldId]
  )
  const dependentFieldLabel = dependentClientMediaPlanField ? getDisplayLabel(dependentClientMediaPlanField) : dependentField?.fieldLabel

  const dataSource = getFieldValues(dependentField, masteredListsData, hierarchies)
  const referencedFieldValues = getFieldValues(referencedField, masteredListsData, hierarchies)
  const typeIsHierarchy = dependentField?.fieldDataType?.isHierarchy
  const dataTypeName = dependentField?.fieldDataType?.dataTypeName
  const availableData = typeIsHierarchy
    ? expandHierarchies(
      { hierarchyList: dataSource },
      dataTypeName
    )
    : dataSource

  const [selectedValues, setSelectedValues] = useState<TreeDataNode[]>([])
  const filteredAvailableData = useMemo(() => availableData?.filter(d =>
    !selectedValues?.some(s => d.value === s.value)
  ), [availableData, selectedValues])

  useEffect(() => {
    if (typeIsHierarchy && availableData?.length && !selectedValues.length) {
      const selectedData = availableData?.filter(d =>
        linkedLookupModel.values.linkedLookupValues?.some(l => d.value === l.dependentValueId)
      )
      setSelectedValues(selectedData)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableData?.length, linkedLookupModel.values.linkedLookupValues])

  const handlePrevious = useCallback(() => {
    navigate(`${appPaths.fieldConnections}`)
  }, [])

  const handleSubmit = async () => {
    const linkedLookupDto = createLinkedLookupDto(linkedLookupModel.values)

    try {
      await updateLinkedLookup({
        id: linkedLookupModel.values.linkedLookupId,
        linkedLookup: linkedLookupDto
      })
      navigate(appPaths.fieldConnections)
    } catch (error) {}
  }

  const handleChange = useCallback((checked, item) => {
    if (!checked) {
      setSelectedValues([...selectedValues, item])
      linkedLookupModel.setFieldValue([], item.value, 'linkedLookupValues')
    } else {
      const values = linkedLookupModel.values.linkedLookupValues.filter((l) => l.dependentValueId !== item.value)

      setSelectedValues(selectedValues.filter((v) => v.value !== item.value))
      linkedLookupModel.setFieldValue(values, item.value, 'linkedLookupValues')
    }
  }, [selectedValues, linkedLookupModel])

  const columns = [
    {
      title: dependentFieldLabel,
      dataIndex: 'dependentField',
      key: 'dependentField',
      render: (text, row) => <>{row.title}</>
    },
    {
      title: 'Inherit From Parent',
      width: 300,
      dataIndex: 'inheritFromParent',
      key: 'inheritFromParent',
      render: (checked: boolean, row) => {
        if (!typeIsHierarchy) return null

        const parentRootId = row.data.parentId
        const hasParent = parentRootId && parentRootId !== row.data.id
        const isChecked = !!filteredAvailableData?.find(value => value.value === row.value)

        return hasParent ? (
          <Checkbox
            className='linked-lookup-edit-checkbox'
            data-test-id='inherit-from-parent'
            onChange={e => {
              handleChange(
                e.target.checked,
                row
              )
            }}
            checked={isChecked} />
        )
          : <></>
      }
    },
    {
      title: 'Valid For',
      dataIndex: 'validFor',
      key: 'validFor',
      render: (text, row) => {
        const linkedValues = linkedLookupModel.values.linkedLookupValues?.filter((item) => item.dependentValueId === row.value) || []
        const hierarchyType = CLIENT_HIERARCHY_TYPES_MAP[referencedField?.fieldDataType.fieldDataTypeId]
        const displayValues = formatFieldValue(referencedField, linkedValues, masteredListsData, hierarchies, hierarchyType)
        const areAllHierarchiesSelected = referencedFieldValues?.filter(
          d => linkedValues.find(s => s.referencedValueId === d.value)
        ).length === referencedFieldValues?.length
        const areAllValuesSelected = !linkedValues.length || (typeIsHierarchy ? areAllHierarchiesSelected : linkedValues.length === referencedFieldValues?.length)

        if (typeIsHierarchy) {
          const parentRootId = row.data.parentId
          const hasParent = parentRootId && (parentRootId !== row.data.id)
          const isInherited = hasParent && !!filteredAvailableData?.find(value => value.value === row.value)

          if (isInherited) {
            return <div>Same as Parent</div>
          }
        }

        return (
          <>
            <Button
              data-testid='edit-values-modal'
              icon={<EditOutlined />}
              onClick={() => {
                setEditFieldModalVisible(true)
                setCurrentFieldId(row.value)
              }}
              type='link'
            />
            {areAllValuesSelected ? 'All values' : displayValues}
          </>
        )
      }
    },
    {
      title: '',
      width: 120,
      dataIndex: 'copyTo',
      key: 'copyTo',
      render: (text, row) => {
        const parentRootId = row.data?.parentId
        const hasParent = parentRootId && (parentRootId !== row.data.id)
        const canCopy = !hasParent || selectedValues?.find(value => value.value === row.value)

        return canCopy ? (
          <Tooltip title='Copy to'>
            <Button
              data-testid='copy-to-button'
              icon={<CopyOutlined />}
              onClick={() => {
                setCopyModalVisible(true)
                setCurrentFieldId(row.value)
              }}
              type='link'
            />
          </Tooltip>
        ) : <></>
      }
    }
  ]

  return (
    <>
      {oneOf(!currentLinkedLookup, areMediaPlanFieldsLoading)
        ? (
          <LoadingComponent
            appDataSuccess={!!currentClient.id}
            agencyLoadingGif={currentClient.agency?.agencyLoadingGifLocation}
          />
        ) : (
          <>
            <MSLayoutContentRow extraClass='linked-lookup-field-container'>
              <div className='header-info'>
                <BreadcrumbItem
                  testId='header-type'
                  label='Type'
                  text={
                    linkedLookupModel.values.enforced
                      ? LINKED_LOOKUP_TYPES.Enforced
                      : LINKED_LOOKUP_TYPES.Encouraged
                  }
                />
                <BreadcrumbItem
                  testId='header-dependent-on'
                  label='Dependent On'
                  text={referencedFieldLabel}
                />
              </div>
              <InfoModalComponent text={fieldConnectionsText} />
            </MSLayoutContentRow>

            <MSLayoutContentRow fillAvailableSpace={true}>
              <div className='list-table linked-lookup-list-table'>
                <MSTable
                  rowKey='value'
                  columns={columns}
                  dataSource={dataSource}
                  dynamicPagination={{ minRows: 6 }}
                  expandable={{
                    expandIcon: ({ expanded, onExpand, record }: {
                      expanded: boolean
                      onExpand?: (expanded: boolean, record: any) => void
                      record: any
                    }) => {
                      if (typeIsHierarchy) {
                        if (!record.children.length) {
                          return null
                        }

                        const iconClassName = `ant-table-row-expand-icon ant-table-row-expand-icon-${expanded ? 'expanded' : 'collapsed'}`

                        return (
                          <button className={iconClassName} onClick={(e) => onExpand(record, e)} />
                        )
                      }
                    }
                  }}
                />
              </div>
            </MSLayoutContentRow>

            <MSLayoutContentRow extraClass='bottom-button-wrapper'>
              <Popconfirm
                placement='bottom'
                title={popoverMessage.confirmUnsavedChangesWillBeLost}
                onConfirm={handlePrevious}
                okText='Yes'
                cancelText='No'
                disabled={!isWithUnsavedChanges}
              >
                <Button
                  type='primary'
                  style={{ margin: '0px 10px' }}
                  onClick={!isWithUnsavedChanges ? handlePrevious : () => {}}
                >
                Back
                </Button>
              </Popconfirm>
              <Button type='primary' onClick={handleSubmit}>
              Submit
              </Button>
            </MSLayoutContentRow>
          </>
        )}

      {editFieldModalVisible && <LinkedLookupEditFieldModalContainer
        visible={editFieldModalVisible}
        hideModal={() => {
          setEditFieldModalVisible(false)
          setCurrentFieldId(null)
        }}
        currentFieldId={currentFieldId}
        dataSource={referencedFieldValues}
        linkedLookupModel={linkedLookupModel}
        referencedField={referencedField}
      />}
      {copyModalVisible && <LinkedLookupCopyModalContainer
        visible={copyModalVisible}
        hideModal={() => {
          setCopyModalVisible(false)
        }}
        dataSource={dataSource}
        currentFieldId={currentFieldId}
        linkedLookupModel={linkedLookupModel}
      />}
    </>
  )
}

export default LinkedLookupFieldContainer
