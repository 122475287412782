import { baseApi as api } from '../apis/generated/linkedLookupsApi'

const enhancedRtkApi = api.enhanceEndpoints({
  endpoints: {
    getLinkedLookups: { providesTags: ['linkedLookupsList'] },
    getLinkedLookupsById: { providesTags: ['linkedLookupItem'] },
    postLinkedLookups: { invalidatesTags: ['linkedLookupItem'] },
    postLinkedLookupsBatchUpdate: { invalidatesTags: ['linkedLookupsList', 'linkedLookupItem'] },
    putLinkedLookupsById: { invalidatesTags: ['linkedLookupsList', 'linkedLookupItem'] }
  }
})
export { enhancedRtkApi as baseApi }

export const {
  useGetLinkedLookupsQuery,
  useGetLinkedLookupsByIdQuery,
  usePostLinkedLookupsMutation,
  usePostLinkedLookupsBatchUpdateMutation,
  usePutLinkedLookupsByIdMutation
} = enhancedRtkApi
