import React from 'react'
import { DndContext } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { ITemplatePlanFieldItemProps, TemplatePlanFieldItemComponent } from './TemplatePlanFieldItemComponent'

export interface ISortableTemplatePlanFieldWrapper {
  itemList: IMediaPlanFieldRow[]
  itemProps: Omit<Omit<ITemplatePlanFieldItemProps, 'item'>, 'length'>
  onSortEnd: (currentData: DragEndEvent) => void
}

export const SortableTemplatePlanFieldWrapper = ({
  itemList,
  onSortEnd,
  itemProps
}: ISortableTemplatePlanFieldWrapper) => (
  <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onSortEnd}>
    <SortableContext
      items={itemList.map((i) => i.itemRowId)}
      strategy={verticalListSortingStrategy}
    >
      <div className='sortable-wrapper'>
        {itemList.map((item, i) => (
          <TemplatePlanFieldItemComponent
            key={item.itemRowId || i}
            item={item}
            {...itemProps}
          />
        ))}
      </div>
    </SortableContext>
  </DndContext>
)
