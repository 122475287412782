import * as actionTypes from 'Constants/actionTypes'
import { AppAction } from 'Actions/appActions'
import { Client } from 'Apis/generated/clientsApi'
import { sortOrderFlightGroup } from 'Components/MediaPlans/constants/entities/SortFlightGroupField'
import { flightGroupId } from 'Constants/entities/FlightGroupIdField'

export interface IAppState {
  initialisationError: boolean
  userForbidden: boolean
  clients: Client[]
  currentClient: Client
  clientsLoaded: boolean
  collapsedFlightGroups: number[]
}

const initialState: IAppState = {
  initialisationError: false,
  userForbidden: false,
  clients: [],
  clientsLoaded: false,
  currentClient: {
    isWavemakerOsEnabled: true,
    id: 0,
    displayName: '',
    logo: '',
    name: '',
    mptReportUrl: ''
  },
  collapsedFlightGroups: [sortOrderFlightGroup.mediaPlanVersionFieldId, flightGroupId.mediaPlanVersionFieldId]
}

export default function (state: IAppState = initialState, action: AppAction): IAppState {
  switch (action.type) {
    case actionTypes.SHOW_ERROR_PAGE: {
      return {
        ...state,
        initialisationError: true
      }
    }
    case actionTypes.USER_FORBIDDEN: {
      return {
        ...state,
        userForbidden: true
      }
    }
    case actionTypes.GET_ALL_CLIENTS: {
      return {
        ...state,
        clients: action.payload
      }
    }
    case actionTypes.SET_CURRENT_CLIENT: {
      return {
        ...state,
        currentClient: action.payload
      }
    }
    case actionTypes.CLIENTS_LOADED: {
      return {
        ...state,
        clientsLoaded: action.payload
      }
    }
    case actionTypes.ADD_TO_COLLAPSED_FLIGHT_GROUPS: {
      return {
        ...state,
        collapsedFlightGroups: [...state.collapsedFlightGroups, action.payload]
      }
    }
    case actionTypes.REMOVE_FROM_COLLAPSED_FLIGHT_GROUPS: {
      return {
        ...state,
        collapsedFlightGroups: state.collapsedFlightGroups.filter(c => c !== action.payload)
      }
    }
    case actionTypes.EXPAND_ALL_FLIGHT_GROUPS: {
      return {
        ...state,
        collapsedFlightGroups: [sortOrderFlightGroup.mediaPlanVersionFieldId, flightGroupId.mediaPlanVersionFieldId]
      }
    }
    case actionTypes.COLLAPSE_ALL_FLIGHT_GROUPS: {
      return {
        ...state,
        collapsedFlightGroups: [...action.payload, sortOrderFlightGroup.mediaPlanVersionFieldId, flightGroupId.mediaPlanVersionFieldId]
      }
    }
    default:
      return state
  }
}
