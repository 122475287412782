import { MediaPlanTemplate, MediaPlanTemplateWithMediaTemplateFields, MediaPlanTemplateField } from 'Apis/generated/templatesApi'

interface IMediaPlanTemplateFieldExtended extends Omit<MediaPlanTemplateField, 'defaultValue'> {
  defaultValue?: string | number
}
export interface IMediaPlanTemplateWithMediaTemplateFieldsExtended extends Omit<MediaPlanTemplateWithMediaTemplateFields, 'mediaPlanTemplateFields'> {
  mediaPlanTemplateFields?: IMediaPlanTemplateFieldExtended[]
}

export const createMediaPlanTemplate = (
  mediaPlanTemplateProps: Partial<IMediaPlanTemplateWithMediaTemplateFieldsExtended> = {}
): MediaPlanTemplate => ({
  ...mediaPlanTemplateProps
})
