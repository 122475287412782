import React, { useState, FunctionComponent } from 'react'
import { Button } from 'antd'
import { sortFlightGroups } from 'Actions/mediaPlansActions'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { ISortItem } from 'Components/MediaPlanVersion/constants/entities/ISortItem'
import MultipleSortContainer from 'Containers/MultipleSortContainer'
import { compare } from 'Helpers/sortHelper'
import { ISortColumn } from 'Components/MediaPlanVersion/constants/entities/ISortColumn'
import { useAppDispatch } from '../../store'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMSHierarchies } from 'mindshare.layout'
import { SubtotalConfigurationField } from 'Apis/generated/mediaPlanVersionsApi'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { DraggableModalContainer } from 'Containers/MediaPlanVersion/DraggableModalContainer'

interface IProps {
  fields: IMediaPlanTemplateFields[]
  hideModal: () => void
  visible: boolean
  hierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  financeList: IMediaPlanVersionFinanceListFields
  subtotalConfigurationFields: SubtotalConfigurationField[]
}

export const FlightGroupSortModalContainer: FunctionComponent<IProps> = ({
  fields,
  hideModal,
  visible,
  hierarchies,
  masteredListsData,
  financeList,
  subtotalConfigurationFields
}) => {
  const dispatch = useAppDispatch()
  const [sortItems, setSortItems] = useState<ISortItem[]>([])

  const fieldIsFlightGroupAndInPlan = (field: IMediaPlanTemplateFields): boolean => {
    const mediaPlanField = field.clientMediaPlanField.mediaPlanField
    return field.isInPlan && mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT_GROUP
  }

  const fieldToSortColumn = (field: IMediaPlanTemplateFields): ISortColumn => {
    return {
      name: field.clientMediaPlanField.mediaPlanField.columnName,
      label: field.fieldLabel
    }
  }

  const applySorts = () => {
    dispatch(sortFlightGroups(sortItems, hierarchies, masteredListsData, financeList))
    hideModal()
  }

  const flightGroupFieldsInPlan = fields?.filter(fieldIsFlightGroupAndInPlan) || []
  const filteredFlightGroupFields = flightGroupFieldsInPlan.filter((field) => !subtotalConfigurationFields?.some((f) => field.clientMediaPlanFieldId === f.clientMediaPlanFieldId))
  const columns = filteredFlightGroupFields.map(fieldToSortColumn)
  const sortedColumns = [...columns].sort((a, b) => compare(a.label, b.label, true))

  const body = (
    <MultipleSortContainer
      columns={sortedColumns}
      sortItems={sortItems}
      setSortItems={setSortItems}
    />
  )

  const footer = (
    <>
      <Button type='link' onClick={hideModal}>Cancel</Button>
      <Button type='primary' onClick={applySorts}>Apply Sorting</Button>
    </>
  )

  const title = (
    <div className='draggable-container'>
      Sort Flight Groups
    </div>
  )

  if (!visible) {
    return null
  }

  return (
    <DraggableModalContainer
      footer={footer}
      onCancel={hideModal}
      modalBody={body}
      title={title}
      visible={visible}
    />
  )
}

export default FlightGroupSortModalContainer
