import React from 'react'
import { Typography, List, Collapse } from 'antd'
import { CheckOutlined, WarningOutlined } from '@ant-design/icons'
import { defaultContainerHeight } from 'Components/Redistribution/constants/redistribution'
import { TemplateSyncFieldResultComponent } from 'Components/TemplateSync/TemplateSyncFieldResultComponent'
import { TemplateSyncUpdateResult, TemplateSyncOption } from 'Apis/generated/templateSyncApi'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { FieldColour } from 'Components/TemplateSync/enums/fieldResult'

const { Panel } = Collapse
const { Text } = Typography

interface ITemplateSyncResultListProps {
  templateUpdateResult: TemplateSyncUpdateResult
  templateSyncOptions: TemplateSyncOption[]
  clientMediaPlanFields: IClientMediaPlanField[]
}

export const TemplateSyncResultListComponent: React.FC<ITemplateSyncResultListProps> = ({
  templateUpdateResult,
  templateSyncOptions,
  clientMediaPlanFields
}) => {
  return (
    <List
      data-testid='template-sync-update-result-list'
      bordered={true}
      dataSource={templateUpdateResult?.templateUpdateResults}
      style={{ height: defaultContainerHeight }}
      renderItem={(item) => {
        const option = templateSyncOptions.find(
          o => item.templateSyncOptionId === o.templateSyncOptionId
        )

        return (
          <List.Item key={item.templateSyncOptionId}>
            <Collapse
              className='collapsed-view'
              collapsible={option?.isPerField && item.fieldSyncResults?.length ? 'header' : 'disabled'}
              bordered={false}
              ghost={true}
              destroyInactivePanel={true}
            >
              <Panel
                header={
                  <div
                    className='collapsed-view__header'
                    data-testid='collapsed-view__header'
                  >
                    <div>
                      {item.success ? (
                        <CheckOutlined
                          className='collapsed-view__icon success'
                        />
                      ) : (
                        <WarningOutlined
                          className='collapsed-view__icon warning'
                        />
                      )}
                      <Text>{option?.templateSyncOptionName}</Text>
                    </div>
                    <Text
                      style={{
                        color: `${item.success ? FieldColour.Success : FieldColour.Failure}`
                      }}
                    >
                      {templateUpdateResult?.status}
                    </Text>
                  </div>
                }
                key={item.templateSyncOptionId}
                showArrow={false}
              >
                {option?.isPerField && item.fieldSyncResults?.length && (
                  <TemplateSyncFieldResultComponent
                    fieldSyncResults={item.fieldSyncResults}
                    clientMediaPlanFields={clientMediaPlanFields}
                  />
                )}
              </Panel>
            </Collapse>
          </List.Item>
        )
      }}
    />
  )
}

export default TemplateSyncResultListComponent
