import React, { useCallback } from 'react'
import { Button } from 'antd'
import { CloseOutlined, FormatPainterOutlined } from '@ant-design/icons'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { MSNumberInput } from 'mindshare.layout'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { WizardProgress } from 'Components/GoalSeek/constants/entities/IGoalSeekData'

interface IProps {
  sendGoalSeekData: () => void
  variableField: IMediaPlanTemplateFields
  setGoalSeekModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  updateGoalValue: React.Dispatch<React.SetStateAction<number>>
  goalField: IMediaPlanTemplateFields
  goal: number
  wizardProgress: number
  style?: React.CSSProperties
}

const GoalSeekModalComponent: React.FC<IProps> = ({
  sendGoalSeekData,
  variableField,
  setGoalSeekModalVisible,
  updateGoalValue,
  goalField,
  goal,
  wizardProgress,
  style
}) => {
  const { attributes, listeners, setNodeRef, transform } =
    useDraggable({
      id: 'draggable'
    })

  const customStyle = {
    transform: transform && CSS.Translate.toString(transform)
  }

  const hideModal = useCallback(() => {
    setGoalSeekModalVisible(false)
  }, [setGoalSeekModalVisible])

  return (
    <div
      className='goal-seek-modal-component'
      data-testid='goal-seek-modal'
      role='goal-seek-modal'
      ref={setNodeRef}
      style={
        {
          ...customStyle,
          ...style
        }}
    >
      <Button
        className='goal-seek-close'
        data-testid='close-goal-seek-modal-button'
        icon={<CloseOutlined />}
        onClick={hideModal}
      />
      <div className='draggable-container' {...listeners} {...attributes}>
        <div className={`goal-seek-row ${wizardProgress === WizardProgress.SetGoalCell ? 'goal-seek-row-highlight' : ''}`}>
          <span data-testid='goal-seek-modal-goal-field-label'><span className='goal-seek-number'>1.</span> Set cell: {goalField?.fieldLabel || '?'}</span>
        </div>
      </div>
      <div className={`goal-seek-row ${wizardProgress === WizardProgress.SetGoalValue ? 'goal-seek-row-highlight' : ''}`}>
        <span><span className='goal-seek-number'>2.</span> To value:</span>
        <MSNumberInput
          className='goal-seek-input'
          data-testid='goal-seek-modal-goal-value-input'
          disabled={wizardProgress < 1}
          onChange={updateGoalValue}
          value={goal}
        />
      </div>
      <div className={`goal-seek-row ${wizardProgress === WizardProgress.SetTargetCell ? 'goal-seek-row-highlight' : ''}`}>
        <span data-testid='goal-seek-modal-variable-field-label'><span className='goal-seek-number'>3.</span> By changing cell: {variableField?.fieldLabel || '?'}</span>
      </div>
      <div className='goal-seek-row goal-seek-buttons'>
        <Button
          data-testid='send-goal-seek-data'
          disabled={wizardProgress !== WizardProgress.Submit}
          onClick={sendGoalSeekData}
          type='primary'
        >
          OK <FormatPainterOutlined />
        </Button>
        <Button
          data-testid='close-goal-seek-modal'
          onClick={hideModal}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default GoalSeekModalComponent
