import { Tooltip } from 'antd'
import { IFrontSheetsCalendarFlightGroup } from 'Components/FrontSheets/constants/entities/IFrontSheets'
import * as React from 'react'
import { CSSProperties } from 'react'
import { ViewDisplayColumn } from 'Apis/generated/frontSheetsApi'

interface FrontSheetCalendarSubTotalRowProps {
  columns: ViewDisplayColumn[]
  flightGroupBlock: IFrontSheetsCalendarFlightGroup
  flightGroupBlockIdx: number
  getStickyStyle: (index: number) => CSSProperties
}

const FrontSheetCalendarSubTotalRow = ({ columns, flightGroupBlock, flightGroupBlockIdx, getStickyStyle }: FrontSheetCalendarSubTotalRowProps) => {
  const groupByCols = columns.filter((x) => x.columnType === 'GroupBy')
  return (
    <tr className="flightGroup__totals">
      {
        groupByCols.map((x, i) => <td key={`subTotalIndex_${flightGroupBlockIdx}_${i}`} style={getStickyStyle(i)}>{i === 0 ? 'Subtotal' : ''}</td>)
      }
      {
        flightGroupBlock.subTotals.map((subTotal, subTotalIndex) => (
          <td
            data-testid={`flightGroupSubtotalCell_${flightGroupBlockIdx}_${subTotalIndex}`}
            style={getStickyStyle(subTotalIndex + groupByCols.length)}
            key={`subTotalIndex_${flightGroupBlockIdx}_${subTotalIndex + groupByCols.length}`}
          >
            <Tooltip title={subTotal} key={`subTotalIdx_${subTotalIndex}`}>
              <span>
                {subTotal}
              </span>
            </Tooltip>
          </td>
        ))
      }
      <td colSpan={2}>&nbsp;</td>
    </tr>
  )
}

export default FrontSheetCalendarSubTotalRow
