import { Button, Tooltip } from 'antd'
import React from 'react'
import classNames from 'classnames'
import { DragOutlined } from '@ant-design/icons'

export const SortableListCell = ({
  children,
  className = '',
  ...props
}) => (
  <div className={classNames('sortable-list__cell', className)} {...props}>
    {children}
  </div>
)

export const SortableListRow = ({ children }) => (
  <div className='sortable-list__row'>
    {children}
  </div>
)

export const SortableListHeader = ({ children }) => (
  <div className='sortable-list__header'>
    {children}
  </div>
)

export const SortableListItem = ({ children }) => (
  <div className='sortable-list__row' data-testid='sortable-list-row'>
    {children}
  </div>
)

export const SortableListBody = ({
  children
}) => (
  <div className='sortable-list__body'>
    {children}
  </div>
)

export const DragIcon = ({
  title = 'Drag',
  disabled = false
}) => (
  <Tooltip title={title}>
    <Button
      disabled={disabled}
      data-testid='drag-handle'
      icon={<DragOutlined />}
      className='sortable-list__drag-button'
    />
  </Tooltip>
)
