import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMediaPlanFieldsByLookUpType: build.query<
      GetMediaPlanFieldsByLookUpTypeApiResponse,
      GetMediaPlanFieldsByLookUpTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/mediaPlanFieldsByLookUpType`,
        params: {
          clientId: queryArg.clientId,
          includeOnlyClientDefinedList: queryArg.includeOnlyClientDefinedList,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetMediaPlanFieldsByLookUpTypeApiResponse =
  /** status 200 Returns list of Media Plan Fields with Client Values with LookUp Types */ MediaPlanFieldRead[];
export type GetMediaPlanFieldsByLookUpTypeApiArg = {
  clientId: number;
  includeOnlyClientDefinedList?: boolean;
};
export type MappingDestinationOptions = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 100 | 200;
export type FieldDataType = {
  fieldDataTypeId?: number;
  dataTypeName?: string | null;
  dataType?: string | null;
  label?: string | null;
  mappingDestination?: MappingDestinationOptions;
};
export type FieldDataTypeRead = {
  fieldDataTypeId?: number;
  dataTypeName?: string | null;
  dataType?: string | null;
  label?: string | null;
  isLookupType?: boolean;
  isClientDefinedListOrUniqueString?: boolean;
  isHierarchy?: boolean;
  isClientHierarchy?: boolean;
  isClientList?: boolean;
  isFinance?: boolean;
  isFinanceAudience?: boolean;
  isCostDatabase?: boolean;
  isParameterizedByGeographyAndMedia?: boolean;
  isNumeric?: boolean;
  isLongInt?: boolean;
  isDecimalType?: boolean;
  isString?: boolean;
  isRichText?: boolean;
  isDate?: boolean;
  cSharpDataType?: string | null;
  mappingDestination?: MappingDestinationOptions;
};
export type ClientFieldValue = {
  clientFieldValueId?: number;
  mediaPlanFieldId?: number;
  valueDisplayName?: string | null;
  isDisabled?: boolean;
  modifiedBy?: string | null;
  modifiedOn?: string;
  sortOrder?: number;
};
export type MediaPlanField = {
  mediaPlanFieldId?: number;
  fieldLabel?: string | null;
  fieldDataTypeId?: number;
  columnName?: string | null;
  fieldDescription?: string | null;
  defaultValue?: string | null;
  isDisabled?: boolean;
  isApportionable?: boolean;
  fieldDataType?: FieldDataType;
  clientFieldValues?: ClientFieldValue[] | null;
};
export type MediaPlanFieldRead = {
  mediaPlanFieldId?: number;
  fieldLabel?: string | null;
  fieldDataTypeId?: number;
  columnName?: string | null;
  fieldDescription?: string | null;
  defaultValue?: string | null;
  isDisabled?: boolean;
  isApportionable?: boolean;
  fieldDataType?: FieldDataTypeRead;
  clientFieldValues?: ClientFieldValue[] | null;
};
export const {
  useGetMediaPlanFieldsByLookUpTypeQuery,
  useLazyGetMediaPlanFieldsByLookUpTypeQuery,
} = injectedRtkApi;
