import React, { useState, useEffect, useCallback } from 'react'
import { Switch } from 'antd'
import { IMSHierarchies } from 'mindshare.layout'
import { FieldLevel } from 'Apis/generated/mediaPlanFieldsApi'
import { IMediaPlanTemplateFields, getCorrectDisplayField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import mediaPlanInputText from 'Components/tooltipContent/Media Plan Version'
import templateExampleText from 'Components/tooltipContent/Template Example'
import InfoModalComponent from 'Components/InfoModalComponent'
import PlanInfoDownloadActionsComponent from 'Components/MediaPlanVersion/PlanInfoDownloadActionsComponent'
import CalculationComponent from 'Components/CalculationComponent'
import MediaPlanBreadcrumbs from './MediaPlanBreadcrumbs'
import { IMediaPlans } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import { PlanningStage } from 'Apis/generated/clientSettingsApi'
import { Function } from 'Apis/generated/functionsApi'
import { IMediaPlanVersion } from './constants/entities/IMediaPlanVersion'

interface IProps {
  calculationFunctions: Function[]
  calculationMode: boolean
  clientId: number
  displayFlightDates: boolean
  enableToggle: boolean
  updatingFromTemplate: boolean
  expandHierarchies: boolean
  expressionHandler: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  fieldCalculatedHandler: (c: string) => void
  fieldLevels: FieldLevel[]
  handleCalculation: (mediaPlanTemplate: IMediaPlanTemplateFields) => void
  hasUnsavedChanges: boolean
  headerTitle: string
  mediaPlanTemplateFields: IMediaPlanTemplateFields[]
  onFunctionSelected: (functionId) => void
  setUpdatePlanModalVisible: (arg: boolean) => void
  hierarchies: IMSHierarchies
  mediaPlan: IMediaPlans
  mediaPlanTemplateLink: { name: string; path: string }
  clientPlanningStages: PlanningStage[]
  planningStageId: number
  selectedCalculatedMediaField: IMediaPlanTemplateFields
  setCalculationMode: React.Dispatch<React.SetStateAction<boolean>>
  preserveApprovedPlans: boolean
  mediaPlanVersion: IMediaPlanVersion
}

export const CalculationHeaderComponent: React.FunctionComponent<IProps> = ({
  calculationFunctions,
  calculationMode,
  clientId,
  displayFlightDates,
  enableToggle,
  expandHierarchies,
  updatingFromTemplate,
  expressionHandler,
  fieldCalculatedHandler,
  fieldLevels,
  handleCalculation,
  hasUnsavedChanges,
  headerTitle,
  mediaPlanTemplateFields,
  onFunctionSelected,
  setUpdatePlanModalVisible,
  hierarchies,
  mediaPlan,
  mediaPlanTemplateLink,
  clientPlanningStages,
  planningStageId,
  selectedCalculatedMediaField,
  setCalculationMode,
  preserveApprovedPlans,
  mediaPlanVersion
}: IProps) => {
  const [expression, setExpression] = useState<string>(selectedCalculatedMediaField && selectedCalculatedMediaField.calculation)
  useEffect(() => {
    setExpression(selectedCalculatedMediaField?.calculation)
  }, [selectedCalculatedMediaField.clientMediaPlanFieldId, selectedCalculatedMediaField.calculation])
  const handleClick = useCallback(() => {
    setCalculationMode(!calculationMode)
  }, [setCalculationMode, calculationMode])

  return (
    <div className='template-example-header-container'>
      <div className="header-plan-info">
        <MediaPlanBreadcrumbs
          hierarchies={hierarchies}
          mediaPlan={mediaPlan}
          mediaPlanVersion={mediaPlanVersion}
          clientPlanningStages={clientPlanningStages}
          planningStageId={planningStageId}
          setUpdatePlanModalVisible={setUpdatePlanModalVisible}
        />
      </div>

      <h1>{headerTitle}</h1>

      <h5 className='header-template-info'>
        <b>Template: </b>
        <a
          data-testid='header-template-link'
          href={mediaPlanTemplateLink.path}
          target='_blank'
          rel='noreferrer'
        >{mediaPlanTemplateLink.name}</a>
      </h5>

      <div className='calculations-container'>
        <div className='template-example-header-subtitle'>
          <div className='template-example-header-buttons'>
            <span>Calculations</span> {enableToggle && (
              <Switch
                size='small'
                data-testid='btn-toggle-calculation'
                aria-label='calculations switch'
                onClick={handleClick}
              />
            )}
          </div>
        </div>

        {calculationMode && (
          <CalculationComponent
            expression={expression}
            expressionHandler={expressionHandler}
            handleCalculation={handleCalculation}
            selectedCalculatedMediaField={selectedCalculatedMediaField}
            setExpression={setExpression}
            updatingFromTemplate={updatingFromTemplate}

            mediaPlanTemplateFields={mediaPlanTemplateFields}
            fieldLevels={fieldLevels}
            onChangeField={fieldCalculatedHandler}
            getFieldLabel={getCorrectDisplayField}

            calculationFunctions={calculationFunctions}
            onFunctionSelected={onFunctionSelected}
            preserveApprovedPlans={preserveApprovedPlans}
            planningStageId={planningStageId}
          />
        )}
      </div>

      {headerTitle === 'Template Example' ? (
        <InfoModalComponent
          text={templateExampleText}
        />
      ) : (
        <PlanInfoDownloadActionsComponent
          clientId={clientId}
          displayFlightDates={displayFlightDates}
          expandHierarchies={expandHierarchies}
          hasUnsavedChanges={hasUnsavedChanges}
          mediaPlan={mediaPlan}
          mediaPlanVersionFields={mediaPlanVersion.mediaPlanVersionFields}
          text={mediaPlanInputText}
        />
      )}
    </div>
  )
}

export default CalculationHeaderComponent
