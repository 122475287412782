import React from 'react'
import { Button, Tooltip } from 'antd'

import { mediaPlanTooltipMessage } from 'Constants/enums/TooltipMessages'
import PlanningStageComponent from 'Components/PlanningStageComponent'
import { IMediaPlanVersion } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { PlanningStage } from 'Apis/generated/clientSettingsApi'
import { CalculationFieldResult } from 'Apis/generated/mediaPlanVersionsApi'
import { PlanningStageOptions } from 'Constants/enums/PlanningStageOptions'

export interface IFooter {
  updatePlan: () => Promise<void>
  planningStageId: number
  currentMediaPlanVersion: IMediaPlanVersion
  clientPlanningStages: PlanningStage[]
  handleUpdatePlanStage: (selectedPlanningState: number, planningStageName: string) => Promise<CalculationFieldResult[] | undefined>
  saveCurrentMediaPlanVersion: () => Promise<void>
  approvalDisabled: boolean
  calculationIssues: boolean
  versionControlDisabled: boolean
  preserveApprovedPlans: boolean
}

interface IProps extends IFooter {
  calculationsRunning: boolean
}

export const MediaPlanVersionFooter: React.FunctionComponent<IProps> = ({
  currentMediaPlanVersion,
  planningStageId,
  clientPlanningStages,
  handleUpdatePlanStage,
  updatePlan,
  saveCurrentMediaPlanVersion,
  approvalDisabled,
  calculationIssues,
  calculationsRunning,
  versionControlDisabled,
  preserveApprovedPlans
}) => {
  const shouldPreserveApprovedPlans = preserveApprovedPlans && planningStageId === PlanningStageOptions.Approved
  const planningStageDisabled = approvalDisabled || calculationIssues || calculationsRunning || shouldPreserveApprovedPlans
  const planningStageTooltip = calculationIssues ? mediaPlanTooltipMessage.calculationIssues : calculationsRunning ? mediaPlanTooltipMessage.calculationsRunning : shouldPreserveApprovedPlans ? mediaPlanTooltipMessage.preserveApprovedPlans : ''
  const saveButtonsDisabled = versionControlDisabled || calculationsRunning
  const updateCurrentVersionDisabled = saveButtonsDisabled || shouldPreserveApprovedPlans
  const saveButtonsTooltip = calculationsRunning ? mediaPlanTooltipMessage.calculationsRunning : ''
  const updateCurrentVersionButtonTooltip = shouldPreserveApprovedPlans ? mediaPlanTooltipMessage.preserveApprovedPlans : ''

  return (
    <div className='mediaplan-button-container'>
      <div className='plan-stage-save-container'>
        <Tooltip title={planningStageTooltip}>
          <div className='plan-stage-save-inner' data-testid='plan-stage-buttons'>
            <PlanningStageComponent
              currentMediaPlan={currentMediaPlanVersion}
              currentPlanningStageId={planningStageId}
              disabled={planningStageDisabled}
              handleUpdatePlanStage={handleUpdatePlanStage}
              planningStages={clientPlanningStages}
            />
          </div>
        </Tooltip>
        <Tooltip title={saveButtonsTooltip}>
          <div className='plan-stage-save-inner' data-testid='plan-save-buttons'>
            <Tooltip title={updateCurrentVersionButtonTooltip}>
              <Button className='ant-btn-primary' onClick={updatePlan} disabled={updateCurrentVersionDisabled}>Update Current Version</Button>
            </Tooltip>
            <Button className='ant-btn-secondary' onClick={saveCurrentMediaPlanVersion} disabled={saveButtonsDisabled}>Save as New Version</Button>
          </div>
        </Tooltip>
      </div>
    </div>
  )
}
export default MediaPlanVersionFooter
