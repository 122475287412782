import { useMemo } from 'react'
import { useLinkedLookupsList } from 'Components/LinkedLookup/hooks/useLinkedLookupsList'
import { getLinkedLookupVariables } from 'Components/LinkedLookup/helpers/linkedLookupHelper'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { ILinkedLookupFieldsHelperValues } from 'Components/LinkedLookup/constants/entities/ILinkedLookupHelperValues'

export const useTemplateLinkedLookups = ({ templateFieldRows, templateFieldRowsForTab }: {
  templateFieldRows: IMediaPlanFieldRow[]
  templateFieldRowsForTab: IMediaPlanFieldRow[]
}): ILinkedLookupFieldsHelperValues => {
  const { data: linkedLookups = [] } = useLinkedLookupsList()

  const mediaPlanFieldsWithLookups = useMemo(() => templateFieldRowsForTab.filter((item) =>
    linkedLookups.some((l) => item.mediaPlanField.mediaPlanFieldId === l.dependentMediaPlanFieldId)
  ), [templateFieldRowsForTab, linkedLookups])

  const linkedLookupValues = useMemo(
    () => {
      return mediaPlanFieldsWithLookups.reduce((lookupValues: ILinkedLookupFieldsHelperValues, currentField) => ({
        ...lookupValues,
        [currentField.mediaPlanField.mediaPlanFieldId]:
          getLinkedLookupVariables(
            { currentField, templateFieldRows, linkedLookups }
          )
      }), {})
    },
    [mediaPlanFieldsWithLookups, templateFieldRows, linkedLookups]
  )

  return linkedLookupValues
}
