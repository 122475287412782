import { v4 as uuid } from 'uuid'
import omit from 'lodash/omit'
import { GetCustomExportsByIdApiResponse, PostCustomExportsByCustomExportIdRunApiArg } from 'Apis/generated/customExportsApi'
import { ICustomExport, initialDisplayField, initialSortByField, isEmptyField } from '../constants/entities'

export const createCustomExportDto = (customExport: ICustomExport): ICustomExport => ({
  ...customExport,
  customExportFilters: customExport.customExportFilters && customExport.customExportFilters.filter(isEmptyField).map((item) => omit(item, 'itemRowId')),
  customExportDisplayFields: customExport.customExportDisplayFields?.filter(isEmptyField).map((item) => omit(item, 'itemRowId')),
  customExportSortByFields: customExport.customExportSortByFields?.filter(isEmptyField).map((item) => omit(item, 'itemRowId'))
})

export const createCustomExportFromResponse = (customExportApiResponse: GetCustomExportsByIdApiResponse): ICustomExport => ({
  customExportId: customExportApiResponse.customExportId,
  customExportCurrencyDisplayId: customExportApiResponse.customExportCurrencyDisplayId,
  customExportDisplayFields: (customExportApiResponse.customExportDisplayFields?.length
    ? customExportApiResponse.customExportDisplayFields.map((item) => ({ ...item, itemRowId: uuid() }))
    : [{ ...initialDisplayField, customExportId: customExportApiResponse.customExportId, itemRowId: uuid() }])
    .slice()
    .sort((a, b) => a.displayOrder - b.displayOrder),
  customExportDisplayTypeId: customExportApiResponse.customExportDisplayTypeId,
  customExportDistinct: customExportApiResponse.customExportDistinct,
  customExportFilters: [...customExportApiResponse.customExportFilters].map((item) => ({ ...item, itemRowId: uuid() })).sort((a, b) => a.sortOrder - b.sortOrder),
  customExportFullHierarchies: customExportApiResponse.customExportFullHierarchies,
  customExportLatestFlagId: customExportApiResponse.customExportLatestFlagId,
  customExportName: customExportApiResponse.customExportName,
  customExportPlanningDateEndDate: customExportApiResponse.customExportPlanningDateEndDate,
  customExportPlanningDateStartDate: customExportApiResponse.customExportPlanningDateStartDate,
  customExportSortByFields: (customExportApiResponse.customExportSortByFields?.length
    ? customExportApiResponse.customExportSortByFields.map((item) => ({ ...item, itemRowId: uuid() }))
    : [{ ...initialSortByField, customExportId: customExportApiResponse.customExportId, itemRowId: uuid() }])
    .slice()
    .sort((a, b) => a.sortOrder - b.sortOrder),
  customExportTemplateLocationUrl: customExportApiResponse.customExportTemplateLocationUrl,
  fieldDataTypeOperatorId: customExportApiResponse.fieldDataTypeOperatorId,
  geographyHierarchyId: customExportApiResponse.geographyHierarchyId,
  createdOn: customExportApiResponse.createdOn,
  createdBy: customExportApiResponse.createdBy
})

export const createRunCustomExportDto = ({
  customExportId,
  customExportLatestFlagId,
  fieldDataTypeOperatorId,
  customExportPlanningDateStartDate,
  customExportPlanningDateEndDate,
  customExportFilters
}: ICustomExport): PostCustomExportsByCustomExportIdRunApiArg['customExportRun'] => ({
  customExportId,
  customExportLatestFlagId,
  fieldDataTypeOperatorId,
  customExportPlanningDateStartDate,
  customExportPlanningDateEndDate,
  customExportFilters
})
