import React, { FunctionComponent } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { MSTable, getColumnSearchProps } from 'mindshare.layout'
import { IMediaPlanVersionField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { compare } from 'Helpers/sortHelper'

interface IProps {
  mediaPlanVersionFields: IMediaPlanVersionField[]
}

export const PlanInfoTableComponent: FunctionComponent<IProps> = ({ mediaPlanVersionFields }) => {
  const columns: Array<ColumnProps<IMediaPlanVersionField>> = [
    {
      key: 'MediaPlanFieldName',
      title: 'Field Name',
      dataIndex: 'fieldLabel',
      sorter: (a, b) => compare(a.clientMediaPlanField.mediaPlanField.fieldLabel, b.clientMediaPlanField.mediaPlanField.fieldLabel),
      ...getColumnSearchProps('fieldLabel', 'Field Name')
    },
    {
      key: 'MediaPlanFieldDefinition',
      title: 'Definition',
      dataIndex: ['clientMediaPlanField', 'mediaPlanField', 'fieldDescription']
    }
  ]

  return (
    <div data-testid='field-definitions-table' className='list-table field-definitions-table'>
      <MSTable
        rowKey='clientMediaPlanFieldId'
        columns={columns}
        dataSource={mediaPlanVersionFields}
        dynamicPagination={true}
      />
    </div>
  )
}

export default PlanInfoTableComponent
