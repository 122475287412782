import { baseApi as api } from '../apis/generated/clientSettingsApi'
import { queryCacheValidTime } from './queryCacheValidTime'

const enhancedRtkApi = api.enhanceEndpoints({
  endpoints: {
    getClientSettings: { keepUnusedDataFor: queryCacheValidTime.getClientSettings, providesTags: ['clientSettings'] },
    postClientSettings: { invalidatesTags: ['clientSettings'] }

  }
})
export { enhancedRtkApi as baseApi }

export const {
  useGetClientSettingsQuery,
  useLazyGetClientSettingsQuery,
  usePostClientSettingsMutation
} = enhancedRtkApi
