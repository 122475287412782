import React from 'react'
import { Provider } from 'react-redux'
import { MSLayoutErrorBoundary, MSRouterComponent } from 'mindshare.layout'
import App from './App'
import store from './store'

const ProviderWrapper = () => (
  <Provider store={store}>
    <MSLayoutErrorBoundary>
      <MSRouterComponent>
        <App />
      </MSRouterComponent>
    </MSLayoutErrorBoundary>
  </Provider>
)

export default ProviderWrapper
