import { baseApi as api } from '../apis/generated/functionsApi'
import { queryCacheValidTime } from './queryCacheValidTime'

const enhancedRtkApi = api.enhanceEndpoints({
  endpoints: {
    getFunctions: { keepUnusedDataFor: queryCacheValidTime.getFunctions }
  }
})
export { enhancedRtkApi as baseApi }

export const {
  useGetFunctionsQuery,
  useLazyGetFunctionsQuery
} = enhancedRtkApi
