import { GoalSeekRequest } from 'Apis/generated/mediaPlanVersionsApi'

export enum WizardProgress {
  SetGoalCell = 0,
  SetGoalValue = 1,
  SetTargetCell = 2,
  Submit = 3
}

export interface IGoalSeekData extends GoalSeekRequest {
  wizardProgress?: WizardProgress
  modalVisible: boolean
}
