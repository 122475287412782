export enum CurrencyDisplay {
  Local = 10,
  Global = 20,
  Both = 30
}

export enum DisplayTypes {
  ExcelCustom = 10,
  Excel = 20,
  Csv = 30
}

export enum SortDirection {
  ASC = 1,
  DESC = 0
}
