import React, { useState, ReactNode, useEffect, useCallback } from 'react'
import { Button, Card, Select, DatePicker, Popconfirm, Dropdown, Menu, Badge } from 'antd'
import {
  ArrowsAltOutlined,
  PlusCircleOutlined,
  ShrinkOutlined,
  SnippetsOutlined,
  SortAscendingOutlined,
  DownOutlined,
  MergeCellsOutlined,
  SettingOutlined,
  RiseOutlined,
  OrderedListOutlined,
  GroupOutlined
} from '@ant-design/icons'
import moment from 'moment'
import { useMindshareSelector } from 'mindshare.customhooks'
import { IMSHierarchies } from 'mindshare.layout'

import { RootState } from '../../redux/reducers'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { setMomentLocale, CalendarView } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { findByFieldLevelId, IMediaPlanVersionField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import FlightGroupSortModalContainer from 'Containers/MediaPlanVersion/FlightGroupSortModalContainer'
import ModalComponent from '../ModalComponent'
import { popoverMessage } from 'Constants/enums/PopoverMessage'
import { RedistributionModalContainer } from 'Containers/MediaPlanVersion/RedistributionModalContainer'
import { dateFormat } from 'Helpers/calendarHelper'
import { MediaPlanVersionFooter, IFooter } from 'Components/MediaPlanVersion/MediaPlanVersionFooter'
import { SettingsModalContainer } from 'Containers/MediaPlanVersion/SettingsModalContainer'
import { ISettings } from 'Constants/entities/IMapSettings'
import { updateUserPreferences, getUserPreferences } from 'Helpers/userPreferenceHelper'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { SubtotalsModalContainer } from 'Containers/MediaPlanVersion/SubtotalsModalContainer'
import { useAppSelector } from '../../store'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

const { Option } = Select
const { RangePicker } = DatePicker

interface IToolbar {
  calculationMode: boolean
  disabledCalendar: boolean
  fields?: IMediaPlanVersionField[]
  inputCalendarView?: CalendarView
  inputDayOfWeek?: string
  inputEndDate?: string
  inputStartDate?: string
  handleAddField: (fieldType: FieldLevelType) => void
  handleSetCalendarDate?: (planStartDate: string, planEndDate: string, calendarView: CalendarView, dateView: string) => void
  handleUpdateFromTemplate?: () => void
  openByDefault: boolean
  planStartYear?: number
  setCollapsedAllFlightGroups: () => void
  setExpandAllFlightGroups: () => void
  collapseMandatoryFlightGroups?: () => void
  collapseCalculatedFlightGroups?: () => void
  setGoalSeekModalVisible?: React.Dispatch<React.SetStateAction<boolean>>
  setRedistributionModalVisible?: React.Dispatch<React.SetStateAction<boolean>>
  isTemplate?: boolean
  footerProps?: IFooter
  mediaPlanTemplateId?: number
  userSettings?: ISettings
  hierarchies?: IMSHierarchies
  masteredListsData?: IMasteredListsData
  financeList?: IMediaPlanVersionFinanceListFields
}

export const MediaPlanVersionToolbarComponent: React.FunctionComponent<IToolbar> = ({
  calculationMode,
  disabledCalendar,
  fields,
  handleAddField,
  handleSetCalendarDate,
  handleUpdateFromTemplate,
  inputDayOfWeek,
  inputCalendarView,
  inputStartDate,
  inputEndDate,
  openByDefault,
  planStartYear,
  setCollapsedAllFlightGroups,
  setExpandAllFlightGroups,
  collapseMandatoryFlightGroups,
  collapseCalculatedFlightGroups,
  setGoalSeekModalVisible,
  isTemplate,
  setRedistributionModalVisible,
  footerProps,
  mediaPlanTemplateId,
  userSettings,
  hierarchies,
  masteredListsData,
  financeList
}: IToolbar) => {
  const enableWeekMode = useMindshareSelector((state: RootState) => state.mediaPlans.weekViewEnable)
  const hasSubFlightFields = useMindshareSelector((state: RootState) =>
    findByFieldLevelId(
      state.mediaPlans.currentMediaPlanVersion?.mediaPlanVersionFields || [],
      FieldLevelType.SUB_FLIGHT
    ).length > 0
  ) as boolean
  const redistributeModalVisible = useMindshareSelector((state: RootState) => state.mediaPlans.redistributeModalVisible) as boolean
  const calculationsRunning = useMindshareSelector((state: RootState) => state.mediaPlans.calculationsRunning) as boolean
  const subtotalConfigurationFields = useAppSelector(state => state.mediaPlans.currentMediaPlanVersion?.mediaPlan?.subtotalConfiguration?.subtotalConfigurationFields)

  const [planViewModalVisible, setPlanViewModalVisible] = useState<boolean>()
  const [sortModalVisible, setSortModalVisible] = useState<boolean>()
  const [subtotalsModalVisible, setSubtotalsModalVisible] = useState<boolean>()
  const [settingsModalVisible, setSettingsModalVisible] = useState<boolean>()
  const [calendarView, setCalendarView] = useState<CalendarView>()
  const [dayOfWeek, setDayOfWeek] = useState<string>('Monday')
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const { updatePlan, planningStageId } = footerProps || {}
  const { settings, updateSetting } = userSettings || {}
  const {
    collapseCalculated,
    collapseNonMandatory,
    collapseRows,
    collapsedViewField,
    displayFlightDates,
    expandHierarchies,
    compressedCalendarView
  } = getUserPreferences(settings, mediaPlanTemplateId)

  const modal = {
    goalSeek: {
      showModal: () => setGoalSeekModalVisible(true)
    },
    planView: {
      onChange: (dates: moment.Moment[]) => {
        if (dates[0] && dates[1]) {
          const start = dates[0].format(dateFormat)
          const end = dates[1].format(dateFormat)
          setStartDate(start)
          setEndDate(end)
        }
      },
      saveChanges: () => {
        handleSetCalendarDate(startDate, endDate, calendarView, dayOfWeek)
        modal.planView.hideModal()
      },
      selectedDayWeek: (valueSelected: string) => setDayOfWeek(valueSelected),
      showModal: () => setPlanViewModalVisible(true),
      hideModal: () => setPlanViewModalVisible(false)
    },
    sort: {
      showModal: () => setSortModalVisible(true),
      hideModal: () => setSortModalVisible(false)
    },
    redistribute: {
      showModal: () => setRedistributionModalVisible(true),
      hideModal: () => setRedistributionModalVisible(false)
    },
    subtotals: {
      showModal: () => setSubtotalsModalVisible(true),
      hideModal: () => setSubtotalsModalVisible(false)
    },
    settings: {
      showModal: () => setSettingsModalVisible(true),
      hideModal: () => setSettingsModalVisible(false)
    }
  }

  const planViewModalBody = (
    <div className='calendar-date-container'>
      <div>
        <label>Select a display type</label>
        <Button disabled={!enableWeekMode} onClick={() => setCalendarView('week')} type={calendarView === 'week' ? 'primary' : 'default'}>Week</Button>
        <Button disabled={hasSubFlightFields} onClick={() => setCalendarView('table')} type={calendarView === 'table' ? 'primary' : 'default'} data-testid='table-button'>Table</Button>
        <Button onClick={() => {
          setCalendarView('week-iso')
          modal.planView.selectedDayWeek('Monday')
        }} type={calendarView === 'week-iso' ? 'primary' : 'default'}>Week ISO</Button>
        <Button onClick={() => {
          setCalendarView('week-broadcast')
          modal.planView.selectedDayWeek('Monday')
        }} type={calendarView === 'week-broadcast' ? 'primary' : 'default'}>Week Broadcast</Button>
      </div>
      <div className='border-separator' />
      <div>
        <label>Set start day for the week</label>
        <Select
          data-testid='select-day'
          onChange={modal.planView.selectedDayWeek} value={dayOfWeek} disabled={calendarView === 'week-iso' || calendarView === 'week-broadcast'}>
          <Option value='Monday'>Monday</Option>
          <Option value='Tuesday'>Tuesday</Option>
          <Option value='Wednesday'>Wednesday</Option>
          <Option value='Thursday'>Thursday</Option>
          <Option value='Friday'>Friday</Option>
          <Option value='Saturday'>Saturday</Option>
          <Option value='Sunday'>Sunday</Option>
        </Select>
        <label>Set start date and end date for your plan</label>
        <RangePicker
          ranges={{
            Today: [moment(), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')]
          }}
          format='DD/MMM/YYYY'
          onChange={modal.planView.onChange}
          value={[moment.utc(startDate), moment.utc(endDate)]}
        />
      </div>
    </div>
  )

  const planViewModalFooter: ReactNode = (
    <>
      <Button type='link' onClick={modal.planView.hideModal}>Cancel</Button>
      <Button type='primary' onClick={modal.planView.saveChanges} disabled={!calendarView} data-testid='set-date-button'>Set Date</Button>
    </>
  )

  useEffect(() => {
    if (openByDefault) {
      setMomentLocale('Monday')
      setPlanViewModalVisible(true)
    }
  }, [openByDefault])

  useEffect(() => {
    if (inputEndDate) {
      setEndDate(inputEndDate)
    } else if (planStartYear) {
      const result = moment(new Date(planStartYear, 0, 1)).endOf('year')
      const endCalculatedDate = moment.utc(result.year() + '-' + (result.month() + 1) + '-' + result.date(), dateFormat)
      setEndDate(endCalculatedDate.toDate().toISOString())
    }

    if (inputStartDate) {
      setStartDate(inputStartDate)
    } else if (planStartYear) {
      const result = moment(new Date(planStartYear, 0, 1)).startOf('year')
      const startCalculatedDate = moment.utc(result.year() + '-' + (result.month() + 1) + '-' + result.date(), dateFormat)
      setStartDate(startCalculatedDate.toDate().toISOString())
    }

    if (inputDayOfWeek) {
      setDayOfWeek(inputDayOfWeek)
    }
    setCalendarView(inputCalendarView)
  }, [inputEndDate, inputStartDate, inputCalendarView, inputDayOfWeek, planStartYear])

  const addFieldMenu = () => (
    <Menu className="toolbar__context-menu">
      <Menu.Item disabled={calculationMode} onClick={() => handleAddField(FieldLevelType.PLAN)}>
        <PlusCircleOutlined />
        Add plan level field
      </Menu.Item>
      <Menu.Item disabled={calculationMode} onClick={() => handleAddField(FieldLevelType.FLIGHT_GROUP)}>
        <PlusCircleOutlined />
        Add flight group level field
      </Menu.Item>
    </Menu>
  )

  const expandCollapseMenu = () => (
    <Menu className="toolbar__context-menu">
      <Menu.Item onClick={() => setCollapsedAllFlightGroups()} data-testid='collapse-all'>
        <ShrinkOutlined />
        Collapse all
      </Menu.Item>
      <Menu.Item onClick={() => setExpandAllFlightGroups()} data-testid='expand-all'>
        <ArrowsAltOutlined />
        Expand all
      </Menu.Item>
      <Menu.Item disabled={!!isTemplate} onClick={collapseCalculatedFlightGroups} data-testid='collapse-calculated'>
        <ShrinkOutlined />
        Collapse calculated
      </Menu.Item>
      <Menu.Item disabled={!!isTemplate} onClick={collapseMandatoryFlightGroups} data-testid='collapse-mandatory'>
        <ShrinkOutlined />
        Collapse non mandatory
      </Menu.Item>
    </Menu>
  )

  const actionsMenu = () => (
    <Menu className="toolbar__context-menu">
      <Menu.Item disabled={!handleUpdateFromTemplate} onClick={() => undefined} data-testid='plan-update-from-template-button'>
        <Popconfirm
          disabled={!handleUpdateFromTemplate}
          cancelText='No'
          okText='Yes'
          onConfirm={handleUpdateFromTemplate}
          placement='rightTop'
          title={popoverMessage.confirmUnsavedChangesWillBeLost}
        >
          <SnippetsOutlined />
          <Badge dot count={Number(!!handleUpdateFromTemplate)} data-testid='plan-update-from-template-badge' title=''>
            Update from template
          </Badge>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item disabled={!!isTemplate} onClick={modal.goalSeek.showModal} data-testid='open-goal-seek-modal'>
        <RiseOutlined />
        Goal seek
      </Menu.Item>
      <Menu.Item disabled={!!isTemplate} onClick={modal.sort.showModal} data-testid='open-flight-group-sort-modal'>
        <SortAscendingOutlined />
        Sort
      </Menu.Item>
      {!isTemplate && (
        <Menu.Item disabled={redistributeModalVisible} onClick={modal.redistribute.showModal} data-testid='open-redistribution-modal'>
          <OrderedListOutlined />
        Redistribute
        </Menu.Item>
      )}
      <Menu.Item disabled={!!isTemplate} onClick={modal.subtotals.showModal} data-testid='open-subtotals-modal'>
        <GroupOutlined />
        Subtotals
      </Menu.Item>
    </Menu>
  )

  const settingsMenu = () => {
    return (
      <Menu className="toolbar__context-menu">
        <Menu.Item disabled={disabledCalendar} onClick={modal.planView.showModal} data-testid='plan-view-button'>
          <SnippetsOutlined />
        Plan view
        </Menu.Item>
        <Menu.Item onClick={modal.settings.showModal} data-testid='settings-button'>
          <SettingOutlined />
        Settings
        </Menu.Item>
      </Menu>
    )
  }

  const setUserSettings = useCallback(
    (values) => {
      updateUserPreferences(settings, values, mediaPlanTemplateId, 'userSettings', updateSetting)
    },
    [mediaPlanTemplateId, updateSetting, settings]
  )

  return (
    <Card className='toolbar-container'>
      <Dropdown overlay={addFieldMenu()} trigger={['click']}>
        <Button disabled={calculationMode} type='link' tabIndex={-1} icon={<PlusCircleOutlined />}>
        Add field
          <DownOutlined />
        </Button>
      </Dropdown>

      <Dropdown overlay={expandCollapseMenu()} trigger={['click']}>
        <Button disabled={calculationMode} type='link' tabIndex={-1} icon={<ShrinkOutlined />}>
          Expand / collapse
          <DownOutlined />
        </Button>
      </Dropdown>

      <Dropdown overlay={actionsMenu()} trigger={['click']} disabled={!!isTemplate}>
        <Button disabled={calculationMode} type='link' tabIndex={-1} icon={<MergeCellsOutlined />}>
          Actions
          <Badge dot count={Number(!!handleUpdateFromTemplate)} data-testid='update-from-template-badge' title=''>
            <DownOutlined />
          </Badge>
        </Button>
      </Dropdown>

      <Dropdown overlay={settingsMenu()} trigger={['click']} disabled={!!isTemplate}>
        <Button disabled={calculationMode} type='link' tabIndex={-1} icon={<SettingOutlined />}>
          Settings
          <DownOutlined />
        </Button>
      </Dropdown>

      <ModalComponent
        footer={planViewModalFooter}
        onCancel={modal.planView.hideModal}
        modalBody={planViewModalBody}
        title='Plan View'
        visible={planViewModalVisible}
      />
      {!isTemplate && <MediaPlanVersionFooter
        updatePlan={updatePlan}
        planningStageId={planningStageId}
        calculationsRunning={calculationsRunning}
        {...footerProps}
      />}
      <FlightGroupSortModalContainer
        fields={fields}
        hideModal={modal.sort.hideModal}
        visible={sortModalVisible}
        hierarchies={hierarchies}
        masteredListsData={masteredListsData}
        financeList={financeList}
        subtotalConfigurationFields={subtotalConfigurationFields}
      />
      {redistributeModalVisible &&
      <RedistributionModalContainer
        visible={redistributeModalVisible}
        hideModal={modal.redistribute.hideModal}
        hierarchies={hierarchies}
        masteredListsData={masteredListsData}
        financeList={financeList}
      />
      }
      {subtotalsModalVisible &&
      <SubtotalsModalContainer
        fields={fields}
        hideModal={modal.subtotals.hideModal}
        visible={subtotalsModalVisible}
      />
      }
      {settingsModalVisible &&
      <SettingsModalContainer
        hideModal={modal.settings.hideModal}
        visible={settingsModalVisible}
        calendarView={calendarView}
        mediaPlanTemplateFields={fields}
        isDisplayedFlightDates={displayFlightDates}
        isExpandedMode={expandHierarchies}
        isCompressedCalendarView={compressedCalendarView}
        isCollapsedCalculated={collapseCalculated}
        isCollapsedNonMandatory={collapseNonMandatory}
        isCollapsedRows={collapseRows}
        collapsedViewFieldLabel={collapsedViewField}
        setUserSettings={setUserSettings}
      />
      }
    </Card>
  )
}

export default MediaPlanVersionToolbarComponent
