import { useCallback, useEffect, useMemo, useState } from 'react'
import { IUpdateCompositeFilters, useCompositeFilters } from 'mindshare.layout'
import { IFlightGroup } from '../constants/entities/IFlightGroup'
import uniq from 'lodash/uniq'

export type IFilterFlightGroupDataWithExpandedHierarchies = (
  filterId: string,
  filterConfig: Parameters<IUpdateCompositeFilters<IFlightGroup>>[1],
  isExpandedHierarchyColumn: boolean
) => void

export const useFilterDataWithExpandedHierarchies = (
  flightGroups: IFlightGroup[],
  isExpandedHierarchiesMode: boolean
) => {
  const [expandedHierarchyColumnFilters, setExpandedHierarchyColumnFilters] = useState<string[]>([])
  const [filteredFlightGroups, filterFlightGroupData, activeFilters] = useCompositeFilters(flightGroups)

  const clearExpandedHierarchyFilter = useCallback(() => {
    const filterIdToClear = expandedHierarchyColumnFilters[0]
    filterFlightGroupData(
      filterIdToClear,
      {
        ...activeFilters[filterIdToClear],
        selectedValues: []
      }
    )
    setExpandedHierarchyColumnFilters(prevExpandedHierarchyColumnFilters => prevExpandedHierarchyColumnFilters.filter(id => (
      id !== filterIdToClear
    )))
  }, [activeFilters, expandedHierarchyColumnFilters, filterFlightGroupData])

  const filterFlightGroupDataWithExpandedHierarchies = useCallback<IFilterFlightGroupDataWithExpandedHierarchies>(
    (
      filterId,
      filterConfig,
      isExpandedHierarchyColumn
    ) => {
      if (isExpandedHierarchyColumn) {
        setExpandedHierarchyColumnFilters(prevExpandedHierarchyColumnFilters => uniq([
          ...prevExpandedHierarchyColumnFilters,
          filterId
        ]))
      }
      filterFlightGroupData(filterId, filterConfig)
    }, [filterFlightGroupData])

  const filteredFlightGroupsWithSubtotals = useMemo(() => {
    if (flightGroups.every(fg => !fg.subtotal)) {
      return filteredFlightGroups
    }

    const newFlightGroups = flightGroups.filter((fg, fgIndex) => {
      const isOneOfFilteredFlightGroups = filteredFlightGroups.some(ffg => ffg.mediaPlanFlightGroupId === fg.mediaPlanFlightGroupId)
      if (isOneOfFilteredFlightGroups) {
        return true
      }

      if (!fg.subtotal) {
        return false
      }

      const flightGroupsAbove = flightGroups.slice(0, fgIndex)
      const indexOfLastSubtotalAbove = flightGroupsAbove.map(f => f.subtotal).lastIndexOf(true)

      const flightGroupsOfCurrentSubtotal = flightGroups.slice(indexOfLastSubtotalAbove + 1, fgIndex)
      const shouldShowSubtotal = flightGroupsOfCurrentSubtotal.some(subtotalFgs => {
        return filteredFlightGroups.some(ffg => ffg.mediaPlanFlightGroupId === subtotalFgs.mediaPlanFlightGroupId)
      })

      return shouldShowSubtotal
    })

    return newFlightGroups
  }, [flightGroups, filteredFlightGroups])

  useEffect(() => {
    if (expandedHierarchyColumnFilters.length && !isExpandedHierarchiesMode) {
      clearExpandedHierarchyFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedHierarchyColumnFilters.length, isExpandedHierarchiesMode])

  return useMemo(
    () => ({
      filteredFlightGroups: filteredFlightGroupsWithSubtotals,
      filterFlightGroupData: filterFlightGroupDataWithExpandedHierarchies
    }),
    [
      filteredFlightGroupsWithSubtotals,
      filterFlightGroupDataWithExpandedHierarchies
    ]
  )
}
