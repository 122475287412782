export const getOptionsList = ({ columnName, linkedLookupFields, fields }) => {
  const selectedFields = fields?.reduce((acc, item) => {
    return item[columnName] ? { ...acc, [item[columnName]]: item[columnName] } : acc
  }, {})

  return linkedLookupFields.reduce(({ options, selectedOptions }, field) => {
    const fieldId = field.mediaPlanFieldId

    const option = {
      value: fieldId,
      label: field.fieldLabel
    }

    return selectedFields?.[fieldId]
      ? {
        options,
        selectedOptions: {
          ...selectedOptions,
          [fieldId]: option
        }
      }
      : {
        selectedOptions,
        options: [...options, field]
      }
  }, { options: [], selectedOptions: {} })
}
