import React, { FunctionComponent } from 'react'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import MediaPlanInputComponent from 'Components/MediaPlanInputComponent'
import EmptyCellComponent from 'Components/MediaPlanVersion/EmptyCellComponent'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { numberFormatter } from 'Helpers/numberHelper'

interface IProps {
  flightField: IMediaPlanTemplateFields
  value: string
  fieldId: number
}

export const SubtotalsFlightFieldContainer: FunctionComponent<IProps> = ({ flightField, value, fieldId }) => (
  <>
    {value ? (
      <div className='paste-container'>
        <Tooltip title={numberFormatter(value, flightField.clientMediaPlanField.mediaPlanField.fieldDataTypeId)}>
          <div className={classNames('fieldInput ', { '-internal': !!value })}>
            <MediaPlanInputComponent
              id={fieldId}
              mediaPlanField={flightField}
              value={value}
              newValue={value}
              readOnly
              disabled
            />
          </div>
        </Tooltip>
      </div>
    ) : (
      <EmptyCellComponent fieldLabel={flightField.fieldLabel} />
    )}
  </>
)

export default SubtotalsFlightFieldContainer
