import React from 'react'
import { IMSHierarchies } from 'mindshare.layout'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { CalculationFieldResult } from 'Apis/generated/mediaPlanVersionsApi'
import DayInput from './DayInput'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  id: number
  errors: CalculationFieldResult[]
  isTemplate: boolean
  flightGroupIndex: number
  flightValues: any[]
  valid: boolean
  calculationMode: boolean
  fieldTypeId: number
  mediaPlanField: IMediaPlanTemplateFields
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  mediaPlanFields: IMediaPlanTemplateFields[]
  selectedFlights: string[]
  hierarchies: Partial<IMSHierarchies>
  masteredListsData: IMasteredListsData
  planStartDate: string
  planEndDate: string
  mergeFlightSelection: (startDate: Date, endDate: Date, mergeByWeek: boolean, flightGroupIndex?: number, flightIndex?: number, clientMediaPlanFieldId?: number, isSubFlight?: boolean) => void
  updateDataValues: (selectedValue: string | number) => void
  onAvailableFieldSelected: (c: string, mediaPlanFields: IMediaPlanTemplateFields[],
    setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void,
    availableFields: IMediaPlanTemplateFields[],
    flightGroupIndex?: number) => void
  tokenHandler: (token: string) => void
  setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void
  deleteFlight: (flightGroupIndex: number, flightIndex: number, subFlightIndex: number, isSubFlight: boolean) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields, flightGroupIndex?: number) => void
}

export const FlightLevelItemComponent: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    id,
    mediaPlanField,
    valid,
    tokenHandler,
    flightValues,
    selectedFlights,
    hierarchies,
    calculationMode,
    updateDataValues,
    flightGroupIndex,
    mergeFlightSelection,
    planStartDate,
    planEndDate,
    deleteFlight,
    masteredListsData,
    errors
  } = props

  const itemSelected = (index) => selectedFlights && selectedFlights.some( x => x === `${id}-${index}-${flightGroupIndex}`)
  const isSubFlight = mediaPlanField.clientMediaPlanField && mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.SUB_FLIGHT

  const inputDays = () => {
    const inputFlight = flightValues && flightValues.map((c, index) => {
      if (c) {
        const flightValue = flightValues[0]
        const startDate = isSubFlight ? c.subFlightStartDate : c.flightStartDate
        const endDate = isSubFlight ? c.subFlightEndDate : c.flightEndDate
        const selected = itemSelected(index)
        const ix = isSubFlight ? c.subFlightIndex : index
        const error = errors.find( e => e.fieldId === mediaPlanField.clientMediaPlanFieldId && e.instanceId === (isSubFlight ? c.mediaPlanSubFlightId : c.mediaPlanFlightId))
        return (
          <div
            data-key={`${id}-${ix}-${flightGroupIndex}-${c.flightIndex}`}
            key={index}
          >
            <DayInput
              mergeFlightSelection={mergeFlightSelection}
              flightIndex={isSubFlight ? c.flightIndex : index}
              subFlightIndex={c.subFlightIndex}
              planStartDate={planStartDate}
              planEndDate={planEndDate}
              startDate={startDate}
              endDate={endDate}
              value={flightValue}
              merge={c.merge}
              calculationMode={calculationMode}
              hierarchies={hierarchies}
              masteredListsData={masteredListsData}
              valid={valid}
              tokenHandler={tokenHandler}
              updateDataValues={updateDataValues}
              mediaPlanField={mediaPlanField}
              flightGroupIndex={flightGroupIndex}
              daySelected={selected}
              deleteFlight={deleteFlight}
              error={error !== undefined && error.error}
              isTemplate={true}
            />
          </div>
        )
      }
    })

    return (<div className='flight__gridItem'>{inputFlight}</div>)
  }

  return (
    <>
      {
        id !== 0 && (
          <div className="flight__grid -readonly">
            {inputDays()}
          </div>
        )
      }
    </>
  )
}

export default FlightLevelItemComponent
