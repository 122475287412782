import React from 'react'
import { Alert } from 'antd'

interface IProps {
  description: string
  showWarning: boolean
}

export const AlertConfirmationComponent: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    showWarning,
    description
  } = props

  return showWarning ? (
    <Alert
      message='Warning'
      description={description}
      type='warning'
    />
  ) : null
}

export default AlertConfirmationComponent
