import React, { useCallback, useState } from 'react'
import { Button, Collapse, Space } from 'antd'
import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons'

const { Panel } = Collapse

interface ITemplateSyncAccordionProps {
  items: Array<{
    key: string
    headerText: string
    content: React.JSX.Element
  }>
}

const TemplateSyncAccordion: React.FC<ITemplateSyncAccordionProps> = ({ items }) => {
  const [activeItems, setActiveItems] = useState([items[0].key])

  const getShowHide = useCallback((key: string) => activeItems.includes(key)
    ? <Button type='link' style={{ border: 'none' }}>Hide<ShrinkOutlined /></Button>
    : <Button type='link' style={{ border: 'none' }}>Show<ArrowsAltOutlined /></Button>,
  [activeItems])

  const onAccordionChange = useCallback((keys: string[], currentKey: string) => {
    if (keys.length) {
      setActiveItems(state => [...state, ...keys])
    } else {
      setActiveItems(state => state.filter(k => k !== currentKey))
    }
  }, [])

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      {items.map((item, i) => (
        <Collapse key={`Collapse-${item.key}`} defaultActiveKey={!i ? [item.key] : undefined} onChange={(keys: string[]) => onAccordionChange(keys, item.key)}>
          <Panel key={item.key} header={item.headerText} showArrow={false} extra={getShowHide(item.key)}>
            {item.content}
          </Panel>
        </Collapse>
      ))}
    </Space>
  )
}

export default TemplateSyncAccordion
