import { useMemo } from 'react'
import { GetLinkedLookupsByIdApiResponse, GetLinkedLookupsByIdApiArg } from 'Apis/generated/linkedLookupsApi'
import { useGetLinkedLookupsByIdQuery } from 'Apis/enhancedLinkedLookupsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'

export const useCurrentLinkedLookup = ({ id }: Omit<GetLinkedLookupsByIdApiArg, 'clientId'>): UseQueryCommand<GetLinkedLookupsByIdApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetLinkedLookupsByIdQuery(
    { clientId: currentClient.id, id },
    { skip: !currentClient.id || !id }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
