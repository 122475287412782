import {
  PostCustomExportsApiResponse,
  PostCustomExportsUploadTemplateApiArg,
  PostCustomExportsApiArg
} from 'Apis/generated/customExportsApi'
import { usePostCustomExportsMutation, usePostCustomExportsUploadTemplateMutation } from 'Apis/enhancedCustomExportsApi'

import { useCurrentClient } from 'Hooks/useCurrentClient'
import { oneOf } from 'Helpers/conditionsHelper'
import { message } from 'antd'
import { UseMutationCommand } from '../../../redux/types'

export const useCreateCustomExport = (): UseMutationCommand<
Omit<PostCustomExportsApiArg, 'clientId'> & Omit<PostCustomExportsUploadTemplateApiArg, 'clientId'>,
PostCustomExportsApiResponse
> => {
  const { data: currentClient } = useCurrentClient()
  const [
    uploadTemplate,
    { isLoading: uploadingTemplate }
  ] = usePostCustomExportsUploadTemplateMutation()
  const [
    createCustomExport,
    { isLoading: creatingCustomExport }
  ] = usePostCustomExportsMutation()

  return {
    isLoading: oneOf(creatingCustomExport, uploadingTemplate),
    execute: async ({ body, customExport }) => {
      try {
        let customExportTemplateLocationUrl: string = null

        if (body.file) {
          const { url } = await uploadTemplate({
            body: { file: body.file },
            clientId: currentClient.id
          }).unwrap()
          customExportTemplateLocationUrl = url
        }

        const response = await createCustomExport({
          customExport: {
            ...customExport,
            ...(customExportTemplateLocationUrl && {
              customExportTemplateLocationUrl
            })
          },
          clientId: currentClient.id
        }).unwrap()

        message.success('Custom export has been created')

        return response
      } catch (error) {
        message.error(error.data.errors
          ? Object.entries(error.data.errors).map( ([key, value]) => `${key}: ${value} '\n'` )
          : error.data[0] ? error.data[0] : 'Something went wrong')

        throw error
      }
    }
  }
}
