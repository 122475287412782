import * as React from 'react'
import { ViewDisplayColumn, ClientMediaPlanField } from 'Apis/generated/frontSheetsApi'
import { LockOutlined } from '@ant-design/icons'
import { CSSProperties } from 'react'
import { getDisplayLabel } from 'Components/Client/constants/entities/IClientMediaPlanField'

interface IViewDisplayColumn extends ViewDisplayColumn {
  clientMediaPlanField?: ClientMediaPlanField & { key?: number }
}
interface IHeaderGlightGroups {
  columns: IViewDisplayColumn[]
  headersRef: React.MutableRefObject<any[]>
  getStickyStyle: (index: number) => CSSProperties
  toggleColumnSticky: (index: number) => void
}

const FrontSheetCalendarFlightGroupHeader = ({ columns, headersRef, getStickyStyle, toggleColumnSticky }: IHeaderGlightGroups) => {
  return (
    <>
      {columns.map((headerFlightGroup, headerFlightGroupIdx) => {
        const style = getStickyStyle(headerFlightGroupIdx)

        return (
          <th
            key={`headerFlightGroup_${headerFlightGroup.clientMediaPlanField.key}_${headerFlightGroupIdx}`}
            className='flightGroup__header'
            data-testid='flightGroup__header'
            rowSpan={6}
            style={style}
            onClick={() => toggleColumnSticky(headerFlightGroupIdx)}
            // eslint-disable-next-line functional/immutable-data
            ref={el => headersRef.current[headerFlightGroupIdx] = el}
          >
            {Object.keys(style).length !== 0 && (
              <span data-testid='flightGroup__icon' className='flightGroup__icon'><LockOutlined /></span>
            )}
            {getDisplayLabel(headerFlightGroup.clientMediaPlanField)}
          </th>
        )
      })}
    </>
  )
}

export default FrontSheetCalendarFlightGroupHeader
