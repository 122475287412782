import { Nullable } from 'Helpers/INullable'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { IMediaPlanFieldExtended } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'
import { ClientMediaPlanFieldRead } from 'Apis/generated/mediaPlanVersionsApi'
import { IMediaPlanFieldLevelReadExtended } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { getFieldLevel } from 'Components/LinkedLookup/helpers/fieldHelper'

interface IClientMediaPlanFieldReadExtended extends Omit<ClientMediaPlanFieldRead, 'mediaPlanField'> {
  mediaPlanField?: IMediaPlanFieldLevelReadExtended
}
export interface IClientMediaPlanField extends IClientMediaPlanFieldReadExtended {
  savedFieldLabel?: Nullable<string>
  isVisible?: boolean
  columnName?: string
  key?: number
}

export const getDisplayLabel = (clientMediaPlanField: IClientMediaPlanField) => {
  return !clientMediaPlanField ? undefined
    : clientMediaPlanField.approvedOn != null
      ? (clientMediaPlanField.fieldLabel ?? clientMediaPlanField.mediaPlanField?.fieldLabel)
      : clientMediaPlanField.mediaPlanField?.fieldLabel
}

export const filterMediaPlanFieldsById = (mediaPlanFields: IMediaPlanFieldExtended[], id: number) =>
  mediaPlanFields.filter((field) => field.mediaPlanFieldId !== id)

export const getLookupMediaPlanFields = (mediaPlanFields: IMediaPlanFieldExtended[], clientMediaPlanFields: IClientMediaPlanField[]) => {
  const filteredMediaPlanFields = mediaPlanFields.filter(
    field => {
      const typeIsMediaPartner = field.fieldDataTypeId === FieldDataType.MEDIA_PARTNERS
      const typeIsClientCampaign = field.fieldDataTypeId === FieldDataType.CLIENT_CAMPAIGNS
      const typeIsClientAgency = field.fieldDataTypeId === FieldDataType.CLIENT_AGENCY_LIST
      const typeIsClientDefinedList = field.fieldDataTypeId === FieldDataType.CLIENT_DEFINED_LIST
      const typeIsHierarchy = field.fieldDataType.isHierarchy

      return typeIsMediaPartner || typeIsClientCampaign || typeIsClientAgency || typeIsClientDefinedList || typeIsHierarchy
    }
  )

  return filteredMediaPlanFields.map((f) => getMediaPlanFieldWithCorrectDisplayLabel(clientMediaPlanFields, f))
}

export const getMediaPlanFieldsWithHierarchies = (mediaPlanFields: IMediaPlanFieldExtended[], clientMediaPlanFields: IClientMediaPlanField[]) => {
  const filteredMediaPlanFields = mediaPlanFields.filter(
    field => {
      const typeIsMediaPartner = field.fieldDataTypeId === FieldDataType.MEDIA_PARTNERS
      const typeIsClientCampaign = field.fieldDataTypeId === FieldDataType.CLIENT_CAMPAIGNS
      const typeIsClientAgency = field.fieldDataTypeId === FieldDataType.CLIENT_AGENCY_LIST
      const typeIsClientDefinedList = field.fieldDataTypeId === FieldDataType.CLIENT_DEFINED_LIST
      const typeIsHierarchy = field.fieldDataType.isHierarchy

      return typeIsMediaPartner || typeIsClientCampaign || typeIsClientAgency || typeIsClientDefinedList || typeIsHierarchy
    }
  )

  return filteredMediaPlanFields.map((f) => getMediaPlanFieldWithCorrectDisplayLabel(clientMediaPlanFields, f))
}

const getMediaPlanFieldWithCorrectDisplayLabel = (clientMediaPlanFields: IClientMediaPlanField[], field: IMediaPlanFieldExtended) => {
  const clientMediaPlanField = [...clientMediaPlanFields].sort((f1, f2) => getFieldLevel(f1) - getFieldLevel(f2)).find((c) => c.mediaPlanFieldId === field.mediaPlanFieldId && c.fieldLabel)

  if (clientMediaPlanField) {
    return {
      ...field,
      fieldLabel: getDisplayLabel(clientMediaPlanField)
    }
  }
  return field
}
