/**
 * Sort compare function
 * @param aVal sort compare value a
 * @param bVal sort compare value b
 * @param asc default: true - if the array is sorted ascendingly
 * @returns return sort compare number 0,1,-1 for use in .sort function
*/
export const compare = <T>(aVal: T, bVal: T, asc: boolean = true) => {
  let aCompare: T = aVal
  let bCompare: T = bVal

  // if type of string then lowercase before sorting
  if (typeof aVal === 'string' && typeof bVal === 'string') {
    aCompare = aVal.toLowerCase() as typeof aVal
    bCompare = bVal.toLowerCase() as typeof bVal
  }

  const sortVal = asc ? 1 : -1
  return aCompare > bCompare ? sortVal : aCompare < bCompare ? -sortVal : 0
}
