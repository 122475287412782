const text = `# Create/Edit Front Sheet - Plan selection
Information on the different sections, functionality and rules present in the Create/Edit Front Sheet page.

## Front Sheet Name
Here you must enter the name for your Front Sheet.

## Plan Selection
Here you can select the plans you wish to be included in your Front Sheet.

To create a Front Sheet, you must have at least one plan selected. On this page you will see a full list of all plans you have access to. It can be filtered by any combination of the 4 hierarchies (or the number of hierarchies present for the chosen client).

To add a plan to your Front Sheet simply tick the check box aligned with the plan you wish to be included. This will place the plans selected at the top of the list of plans.

## Save
To save a Front Sheet you must have:
- Front Sheet name
- At least one Plan Selected

Saving will take you to the edit view page where you are able to create and edit views in your Front Sheet.
`

export default text
