import { baseApi as api } from '../apis/generated/mediaPlanTemplateFieldsApi'

const enhancedRtkApi = api.enhanceEndpoints({
  endpoints: {
    getMediaPlanTemplateFields: { providesTags: ['templateAliasFields'] },
    putMediaPlanTemplateFieldByMediaPlanTemplateFieldIdApproveAlias: { invalidatesTags: ['templateAliasFields'] }
  }
})
export { enhancedRtkApi as baseApi }

export const {
  useGetMediaPlanTemplateFieldsQuery,
  usePutMediaPlanTemplateFieldByMediaPlanTemplateFieldIdApproveAliasMutation
} = enhancedRtkApi
