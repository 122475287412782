import { createSelector } from 'reselect'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { getFieldColumnName } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import {
  selectFlightGroups,
  selectMediaPlanVersionId,
  selectParseData,
  selectMediaPlanErrors,
  selectRedistributeByFields,
  selectCurrentRedistribution, selectCurrentMediaPlanVersionFields, selectRedistributeMode
} from './mediaPlansSelectors'
import { getAvailableFlightFields, formApiRedistributionFlightsData } from 'Helpers/redistributionHelpers'

interface ISelectFieldValueParams {
  fieldLevelId: number
  columnName: string
  flightGroupIndex?: number
  flightIndex?: number
  subFlightIndex?: number
}

export const createFieldValueSelector = () => createSelector(
  selectParseData,
  (state, params: ISelectFieldValueParams) => params.fieldLevelId,
  (state, params: ISelectFieldValueParams) => params.columnName,
  (state, params: ISelectFieldValueParams) => params.flightGroupIndex,
  (state, params: ISelectFieldValueParams) => params.flightIndex,
  (state, params: ISelectFieldValueParams) => params.subFlightIndex,
  (parseData, fieldLevelId: number, columnName: string, flightGroupIndex: number, flightIndex: number, subFlightIndex: number) => {
    switch (fieldLevelId) {
      case FieldLevelType.PLAN: {
        return parseData[columnName]
      }

      case FieldLevelType.FLIGHT_GROUP: {
        return parseData.flightGroups[flightGroupIndex]?.[columnName]
      }

      case FieldLevelType.FLIGHT: {
        return parseData.flightGroups[flightGroupIndex]?.flights[flightIndex]?.[columnName] ?? ''
      }

      case FieldLevelType.SUB_FLIGHT: {
        return parseData.flightGroups[flightGroupIndex]?.flights[flightIndex]?.subFlights[subFlightIndex][columnName] ?? ''
      }

      default: {
        return null
      }
    }
  }
)

interface ISelectFieldIdParams {
  fieldLevelId: number
  flightGroupIndex: number
  flightIndex: number
  subFlightIndex: number
}

export const createFieldIdSelector = () => createSelector(
  selectFlightGroups,
  selectMediaPlanVersionId,
  (state, params: ISelectFieldIdParams) => params.fieldLevelId,
  (state, params: ISelectFieldIdParams) => params.flightGroupIndex,
  (state, params: ISelectFieldIdParams) => params.flightIndex,
  (state, params: ISelectFieldIdParams) => params.subFlightIndex,
  (
    flightGroups,
    mediaPlanVersionId: number,
    fieldLevelId: number,
    flightGroupIndex: number,
    flightIndex,
    subFlightIndex: number
  ) => {
    switch (fieldLevelId) {
      case FieldLevelType.FLIGHT_GROUP: {
        return flightGroups[flightGroupIndex]?.mediaPlanFlightGroupId
      }

      case FieldLevelType.FLIGHT: {
        return flightGroups[flightGroupIndex]?.flights[flightIndex]?.mediaPlanFlightId
      }

      case FieldLevelType.SUB_FLIGHT: {
        return flightGroups[flightGroupIndex]?.flights[flightIndex]?.subFlights[subFlightIndex]?.mediaPlanSubFlightId
      }

      default: {
        return mediaPlanVersionId
      }
    }
  }
)

export const createFieldErrorSelector = () => createSelector(
  selectMediaPlanErrors,
  (state, params) => params.fieldLevelId,
  (state, params) => params.instanceId,
  (state, params) => params.clientMediaPlanFieldId,
  (errors, fieldLevelId, instanceId, clientMediaPlanFieldId) => {
    const currentError = errors.find(e => {
      if (fieldLevelId === FieldLevelType.PLAN) {
        return e.fieldId === clientMediaPlanFieldId && e.fieldLevel === fieldLevelId
      }

      return e.instanceId === instanceId && e.fieldId === clientMediaPlanFieldId && e.fieldLevel === fieldLevelId
    })

    return currentError?.error ?? ''
  }
)

export const createIsFieldSelectedForRedistributionSelector = () =>
  createSelector(
    selectRedistributeMode,
    selectRedistributeByFields,
    (state, params: any) => params.instanceId,
    (state, params: any) => params.columnName,
    (redistributeMode, selectedRedistributeByFields, instanceId, columnName) =>
      redistributeMode && selectedRedistributeByFields.some(
        (field) => {
          const selectedFieldColumnName = getFieldColumnName(field)

          return field.instanceId === instanceId && columnName === selectedFieldColumnName
        }
      )
  )

export const createIsFieldValidForRedistributionSelector = () => createSelector(
  selectCurrentRedistribution,
  selectCurrentMediaPlanVersionFields,
  selectFlightGroups,
  (state, params: any) => params.masteredListsData,
  (state, params: any) => params.columnName,
  (state, params: any) => params.flightGroupIndex,
  (state, params: any) => params.flightIndex,
  (state, params: any) => params.fieldLevelId,
  (state, params: any) => params.financeList,
  (state, params: any) => params.hierarchies,
  (
    currentRedistribution,
    mediaPlanVersionFields,
    flightGroups,
    masteredListsData,
    columnName,
    flightGroupIndex,
    flightIndex,
    fieldLevelId,
    financeList,
    hierarchies
  ) => {
    if (!currentRedistribution) {
      return false
    }

    const flightGroup = flightGroups[flightGroupIndex]
    const flight = flightGroup?.flights?.[flightIndex]

    return getAvailableFlightFields(
      currentRedistribution,
      mediaPlanVersionFields,
      flightGroup,
      flight,
      columnName,
      hierarchies,
      masteredListsData,
      fieldLevelId,
      financeList
    )
  }
)

export const createRedistributionFlightsDataSelector = () => createSelector(
  selectRedistributeByFields,
  (state, params: any) => params.columnName,
  (selectedRedistributeByFields, columnName) =>
    formApiRedistributionFlightsData(selectedRedistributeByFields, columnName)
)
