import { MutableRefObject, useRef } from 'react'
import { useEventListener } from 'Hooks/useEventListener'

export const useOutsideClick = <T extends HTMLElement>(callback: (event: MouseEvent) => void): MutableRefObject<T> => {
  const ref = useRef<T>()

  const handleClick = (event: MouseEvent) => {
    if ((event.target instanceof HTMLElement || event.target instanceof SVGElement) && !ref.current.contains(event.target)) {
      callback(event)
    }
  }

  useEventListener('click', handleClick, document)

  return ref
}
