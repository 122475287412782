import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFinanceDataAudiencesAll: build.query<
      GetFinanceDataAudiencesAllApiResponse,
      GetFinanceDataAudiencesAllApiArg
    >({
      query: (queryArg) => ({
        url: `/financeData/audiences/all`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    getFinanceDataAudiences: build.query<
      GetFinanceDataAudiencesApiResponse,
      GetFinanceDataAudiencesApiArg
    >({
      query: (queryArg) => ({
        url: `/financeData/audiences`,
        params: {
          clientId: queryArg.clientId,
          clientGeographyHierarchyId: queryArg.clientGeographyHierarchyId,
          clientMediaHierarchyId: queryArg.clientMediaHierarchyId,
          buyingAudienceOnly: queryArg.buyingAudienceOnly,
        },
      }),
    }),
    getFinanceDataBookingCategoriesAll: build.query<
      GetFinanceDataBookingCategoriesAllApiResponse,
      GetFinanceDataBookingCategoriesAllApiArg
    >({
      query: (queryArg) => ({
        url: `/financeData/bookingCategories/all`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    getFinanceDataBookingCategories: build.query<
      GetFinanceDataBookingCategoriesApiResponse,
      GetFinanceDataBookingCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/financeData/bookingCategories`,
        params: {
          clientId: queryArg.clientId,
          clientGeographyHierarchyId: queryArg.clientGeographyHierarchyId,
          clientMediaHierarchyId: queryArg.clientMediaHierarchyId,
        },
      }),
    }),
    getFinanceDataProductsAll: build.query<
      GetFinanceDataProductsAllApiResponse,
      GetFinanceDataProductsAllApiArg
    >({
      query: (queryArg) => ({
        url: `/financeData/products/all`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    getFinanceDataProducts: build.query<
      GetFinanceDataProductsApiResponse,
      GetFinanceDataProductsApiArg
    >({
      query: (queryArg) => ({
        url: `/financeData/products`,
        params: {
          clientId: queryArg.clientId,
          clientGeographyHierarchyId: queryArg.clientGeographyHierarchyId,
          clientMediaHierarchyId: queryArg.clientMediaHierarchyId,
        },
      }),
    }),
    getFinanceDataStationsAll: build.query<
      GetFinanceDataStationsAllApiResponse,
      GetFinanceDataStationsAllApiArg
    >({
      query: (queryArg) => ({
        url: `/financeData/stations/all`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    getFinanceDataStations: build.query<
      GetFinanceDataStationsApiResponse,
      GetFinanceDataStationsApiArg
    >({
      query: (queryArg) => ({
        url: `/financeData/stations`,
        params: {
          clientId: queryArg.clientId,
          clientGeographyHierarchyId: queryArg.clientGeographyHierarchyId,
          clientMediaHierarchyId: queryArg.clientMediaHierarchyId,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetFinanceDataAudiencesAllApiResponse =
  /** status 200 OK */ FinanceAudience[];
export type GetFinanceDataAudiencesAllApiArg = {
  clientId: number;
};
export type GetFinanceDataAudiencesApiResponse =
  /** status 200 OK */ FinanceAudience[];
export type GetFinanceDataAudiencesApiArg = {
  clientId: number;
  clientGeographyHierarchyId?: number;
  clientMediaHierarchyId?: number;
  buyingAudienceOnly?: boolean;
};
export type GetFinanceDataBookingCategoriesAllApiResponse =
  /** status 200 OK */ FinanceBookingCategory[];
export type GetFinanceDataBookingCategoriesAllApiArg = {
  clientId: number;
};
export type GetFinanceDataBookingCategoriesApiResponse =
  /** status 200 OK */ FinanceBookingCategory[];
export type GetFinanceDataBookingCategoriesApiArg = {
  clientId: number;
  clientGeographyHierarchyId?: number;
  clientMediaHierarchyId?: number;
};
export type GetFinanceDataProductsAllApiResponse =
  /** status 200 OK */ HierarchiesWithLevels;
export type GetFinanceDataProductsAllApiArg = {
  clientId: number;
};
export type GetFinanceDataProductsApiResponse =
  /** status 200 OK */ HierarchiesWithLevels;
export type GetFinanceDataProductsApiArg = {
  clientId: number;
  clientGeographyHierarchyId?: number;
  clientMediaHierarchyId?: number;
};
export type GetFinanceDataStationsAllApiResponse =
  /** status 200 OK */ HierarchiesWithLevels;
export type GetFinanceDataStationsAllApiArg = {
  clientId: number;
};
export type GetFinanceDataStationsApiResponse =
  /** status 200 OK */ HierarchiesWithLevels;
export type GetFinanceDataStationsApiArg = {
  clientId: number;
  clientGeographyHierarchyId?: number;
  clientMediaHierarchyId?: number;
};
export type FinanceAudience = {
  financeAudienceId?: number;
  audienceName?: string | null;
  audienceCategory?: number;
  audienceCategoryName?: string | null;
  isBuyingAudience?: boolean;
};
export type FinanceBookingCategory = {
  financeBookingCategoryId?: number;
  bookingCategoryName?: string | null;
  bookingCategoryShortName?: string | null;
  bookingCategoryMedia?: string | null;
};
export type Hierarchy = {
  id?: number;
  name?: string | null;
  parentId?: number | null;
  levelNumber?: number;
  children?: Hierarchy[] | null;
};
export type HierarchyLevel = {
  hierarchyLevelId?: number;
  hierarchyLevelNumber?: number;
  hierarchyLevelName?: string | null;
};
export type HierarchiesWithLevels = {
  hierarchies?: Hierarchy[] | null;
  hierarchyLevels?: HierarchyLevel[] | null;
};
export const {
  useGetFinanceDataAudiencesAllQuery,
  useLazyGetFinanceDataAudiencesAllQuery,
  useGetFinanceDataAudiencesQuery,
  useLazyGetFinanceDataAudiencesQuery,
  useGetFinanceDataBookingCategoriesAllQuery,
  useLazyGetFinanceDataBookingCategoriesAllQuery,
  useGetFinanceDataBookingCategoriesQuery,
  useLazyGetFinanceDataBookingCategoriesQuery,
  useGetFinanceDataProductsAllQuery,
  useLazyGetFinanceDataProductsAllQuery,
  useGetFinanceDataProductsQuery,
  useLazyGetFinanceDataProductsQuery,
  useGetFinanceDataStationsAllQuery,
  useLazyGetFinanceDataStationsAllQuery,
  useGetFinanceDataStationsQuery,
  useLazyGetFinanceDataStationsQuery,
} = injectedRtkApi;
