import * as actionTypes from 'Constants/actionTypes'
import * as mediaPlanService from '../../services/mediaPlanService'
import { Client } from 'Apis/generated/clientsApi'
import { getTemplateFieldTypes, getTemplateFieldAvailabilities } from './templatesActions'

interface IShowErrorPageAction {
  type: actionTypes.SHOW_ERROR_PAGE
}

interface ISetUserForbiddenAction {
  type: actionTypes.USER_FORBIDDEN
}

interface IGetClientsAction {
  type: actionTypes.GET_ALL_CLIENTS
  payload: Client[]
}

interface ISetCurrentClientAction {
  type: actionTypes.SET_CURRENT_CLIENT
  payload: Client
}

interface IGetClientsLoaded {
  type: actionTypes.CLIENTS_LOADED
  payload: boolean
}

interface IAddToCollapsedFlightGroups {
  type: actionTypes.ADD_TO_COLLAPSED_FLIGHT_GROUPS
  payload: number
}

interface IRemoveFromCollapsedFlightGroups {
  type: actionTypes.REMOVE_FROM_COLLAPSED_FLIGHT_GROUPS
  payload: number
}

interface IExpandAllFlightGroups {
  type: actionTypes.EXPAND_ALL_FLIGHT_GROUPS
  payload: number
}

interface ICollapseAllFlightGroups {
  type: actionTypes.COLLAPSE_ALL_FLIGHT_GROUPS
  payload: number[]
}

export type AppAction =
  | IShowErrorPageAction
  | ISetUserForbiddenAction
  | IGetClientsAction
  | ISetCurrentClientAction
  | IGetClientsLoaded
  | IAddToCollapsedFlightGroups
  | IRemoveFromCollapsedFlightGroups
  | IExpandAllFlightGroups
  | ICollapseAllFlightGroups

export const showErrorPage = () => dispatch => {
  dispatch({
    type: actionTypes.SHOW_ERROR_PAGE
  })
}

export const getClients = () => async dispatch => {
  const payload = await mediaPlanService.get('clients')
  dispatch({ type: actionTypes.GET_ALL_CLIENTS, payload })
  dispatch({
    type: actionTypes.CLIENTS_LOADED,
    payload: !!payload
  })
}

export const setCurrentClient = client => async dispatch => {
  dispatch({
    type: actionTypes.SET_CURRENT_CLIENT,
    payload: client
  })
  await dispatch(initialiseClientData(client.id))
}

const resetMediaPlans = () => async dispatch => {
  dispatch({
    type: actionTypes.GET_MEDIA_PLANS,
    payload: []
  })
}

export const initialiseClientData = (clientId) => async dispatch => {
  await Promise.all([
    dispatch(getTemplateFieldTypes(clientId)),
    dispatch(getTemplateFieldAvailabilities(clientId)),
    dispatch(resetMediaPlans())
  ])
}

export const addToCollapsedFlightGroups = (id: number) => dispatch => {
  dispatch({
    type: actionTypes.ADD_TO_COLLAPSED_FLIGHT_GROUPS,
    payload: id
  })
}

export const removeFromCollapsedFlightGroups = (id: number) => dispatch => {
  dispatch({
    type: actionTypes.REMOVE_FROM_COLLAPSED_FLIGHT_GROUPS,
    payload: id
  })
}

export const expandAllFlightGroups = () => dispatch => {
  dispatch({
    type: actionTypes.EXPAND_ALL_FLIGHT_GROUPS
  })
}

export const collapseAllFlightGroups = (ids: number[]) => dispatch => {
  dispatch({
    type: actionTypes.COLLAPSE_ALL_FLIGHT_GROUPS,
    payload: ids
  })
}
