import { msNumberFormatter, msCurrencyFormatter, msPercentFormatter } from 'mindshare.layout'
import { FieldDataType } from 'Constants/enums/FieldDataType'


export const numberFormatter = (value: string, type: FieldDataType) => {
  switch (type) {
    case FieldDataType.INTEGER:
      return msNumberFormatter(value)
    case FieldDataType.CURRENCY:
      return msCurrencyFormatter(value)
    case FieldDataType.DECIMAL:
      return msNumberFormatter(value)
    case FieldDataType.PERCENTAGE:
      return msPercentFormatter(value)
  }

  return value
}

export const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
