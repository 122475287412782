import { useCallback, useState, useEffect } from 'react'
import * as arrayHelpers from 'Helpers/arrayHelper'

export const useRedistributionFormValues = (initialValues, isEditMode = false) => {
  const [formValues, setFormValues] = useState(initialValues)

  useEffect(() => {
    if (isEditMode) {
      setFormValues(initialValues)
    }
  }, [isEditMode, initialValues])

  const setFieldValue = useCallback((value, name) => {
    setFormValues(currentFormValues => ({ ...currentFormValues, [name]: value }))
  }, [])

  const resetForm = useCallback(() => {
    setFormValues(initialValues)
  }, [initialValues])

  const updateFieldValue = useCallback((item, name, idx) => {
    setFormValues(currentFormValues => ({
      ...currentFormValues,
      [name]: arrayHelpers.update(currentFormValues[name], idx, item)
    })
    )
  }, [])

  const addFieldValue = useCallback((item, name) => {
    setFormValues((currentFormValues) => ({
      ...currentFormValues,
      [name]: arrayHelpers.push(currentFormValues[name], item)
    }))
  }, [])

  const removeFieldValue = useCallback((name, value) => {
    setFormValues((currentFormValues) =>
      ({ ...currentFormValues, [name]: currentFormValues[name].filter((item) => item.value !== value) })
    )
  }, [])

  return {
    formValues,
    setFieldValue,
    resetForm,
    updateFieldValue,
    removeFieldValue,
    addFieldValue
  }
}
