import React, { CSSProperties, FunctionComponent, memo, useMemo } from 'react'
import { useMindshareSelector } from 'mindshare.customhooks'
import { IMSHierarchies } from 'mindshare.layout'
import { RootState } from '../../redux/reducers'
import { IMediaPlanTemplateFields, availableFieldsByLevel, findByFieldLevelId } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import FlightGroupContainer from './FlightGroupContainer'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { IExpandedHierarchiesFlightGroupsValues } from 'Components/Hierarchies/helpers/getExpandedHierarchiesValues'
import { IMediaPlanVersionMasteredFieldsHelperValues, IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IClickableFieldProps } from 'Components/MediaPlanVersion/hooks'
import { getSubtotalFlightFields } from 'Components/Subtotals/helpers/getSubtotalFlightFields'
import { SubtotalCalculationResult } from 'Apis/generated/mediaPlanVersionsApi'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  calculationMode: boolean
  filteredFlightGroups: IFlightGroup[]
  sortedFlightGroupFields: IFlightGroup[]
  hierarchies: IMSHierarchies
  planHierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  tokenHandler: (token: string) => void
  onAvailableFieldSelected: (c: string, flightGroupIndex?: number) => void
  handleAddField: (fieldType: FieldLevelType, flightGroupIndex?: number) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  getStickyStyle: (type: StickyColumnType, id?: number) => CSSProperties
  unfilteredHierarchies: IMSHierarchies
  displayFlightDates: boolean
  expandedHierarchiesFlightGroupsValues: IExpandedHierarchiesFlightGroupsValues
  collapsedViewField: string
  compressedCalendarView: boolean
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  clickableFieldProps: IClickableFieldProps
  originalFlightGroups: IFlightGroup[]
  subtotals: SubtotalCalculationResult
  isLoadingHierarchies: boolean
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
}

export const FlightGroupsContainer: FunctionComponent<IProps> = ({
  filteredFlightGroups,
  sortedFlightGroupFields,
  hierarchies,
  planHierarchies,
  masteredListsData,
  calculationMode,
  tokenHandler,
  onAvailableFieldSelected,
  handleAddField,
  removePlanField,
  getStickyStyle,
  unfilteredHierarchies,
  displayFlightDates,
  expandedHierarchiesFlightGroupsValues,
  collapsedViewField,
  compressedCalendarView,
  masteredDataHelperValues,
  clickableFieldProps,
  originalFlightGroups,
  subtotals,
  isLoadingHierarchies,
  financeListFieldsData
}: IProps) => {
  const availableFlightGroupFieldsSelector = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.mediaPlanVersionFields) as IMediaPlanTemplateFields[]

  const availableFlightGroupFields = useMemo(() => {
    return availableFieldsByLevel(availableFlightGroupFieldsSelector, FieldLevelType.FLIGHT_GROUP)
  }, [availableFlightGroupFieldsSelector])

  const flightFields = useMemo(() => {
    return findByFieldLevelId(availableFlightGroupFieldsSelector, FieldLevelType.FLIGHT)
  }, [availableFlightGroupFieldsSelector])

  const availableFlightFields = useMemo(() => getSubtotalFlightFields(flightFields, subtotals), [flightFields, subtotals])

  return (
    <FlightGroupContainer
      filteredFlightGroups={filteredFlightGroups}
      planHierarchies={planHierarchies}
      hierarchies={hierarchies}
      masteredListsData={masteredListsData}
      mediaPlanFields={sortedFlightGroupFields}
      availableMediaPlanFields={availableFlightGroupFields}
      calculationMode={calculationMode}
      tokenHandler={tokenHandler}
      onAvailableFieldSelected={onAvailableFieldSelected}
      handleAddField={handleAddField}
      removePlanField={removePlanField}
      getStickyStyle={getStickyStyle}
      unfilteredHierarchies={unfilteredHierarchies}
      displayFlightDates={displayFlightDates}
      expandedHierarchiesFlightGroupsValues={expandedHierarchiesFlightGroupsValues}
      collapsedViewField={collapsedViewField}
      compressedCalendarView={compressedCalendarView}
      masteredDataHelperValues={masteredDataHelperValues}
      clickableFieldProps={clickableFieldProps}
      availableFlightFields={availableFlightFields}
      originalFlightGroups={originalFlightGroups}
      isLoadingHierarchies={isLoadingHierarchies}
      financeListFieldsData={financeListFieldsData}
    />
  )
}

export default memo(FlightGroupsContainer)
