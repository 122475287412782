import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { Button, Select, Tooltip } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Control, Controller, ControllerRenderProps } from 'react-hook-form'
import { MSTable } from 'mindshare.layout'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { ITemplateSyncFormData, ITemplateSyncOptionPerField } from './TemplateSyncModalComponent'
import { emptyItem } from './helpers/common'

const { Option } = Select

const FieldLevelLabels = {
  [FieldLevelType.PLAN]: 'Plan level',
  [FieldLevelType.FLIGHT_GROUP]: 'Flight group level',
  [FieldLevelType.FLIGHT]: 'Flight level',
  [FieldLevelType.SUB_FLIGHT]: 'Sub flight level'
}

const columns = [{
  dataIndex: 'fieldLevel',
  key: 'fieldLevel',
  width: '40%'
}, {
  dataIndex: 'fieldName',
  key: 'fieldName',
  width: '40%'
}, {
  dataIndex: 'buttons',
  key: 'buttons',
  width: '15%'
}]

interface ITemplateSyncPerFieldOptionsTableProps {
  control: Control<ITemplateSyncFormData>
  selectedTemplateOptionsPerField: ITemplateSyncOptionPerField[]
  templateFieldsList: IMediaPlanTemplateFields[]
  templateSyncOptionId: number
}

const TemplateSyncPerFieldOptionsTable: React.FC<ITemplateSyncPerFieldOptionsTableProps> = ({
  control,
  selectedTemplateOptionsPerField,
  templateFieldsList,
  templateSyncOptionId
}) => {
  const templateFieldsByLevel = useMemo(
    () => templateFieldsList.reduce((acc, field) => ({
      ...acc,
      [field.clientMediaPlanField.mediaPlanField.fieldLevelId]: [...(acc[field.clientMediaPlanField.mediaPlanField.fieldLevelId] || []), field]
    }), {}),
    [templateFieldsList]
  )

  const handleSelectChange = useCallback((field: ControllerRenderProps<ITemplateSyncFormData, 'selectedTemplateOptionsPerFields'>, index: number, valueOverrides) => {
    const listCopy = [...field.value[templateSyncOptionId]]
    // eslint-disable-next-line functional/immutable-data
    listCopy[index] = { ...listCopy[index], ...valueOverrides }
    field.onChange({
      ...field.value,
      [templateSyncOptionId]: listCopy
    })
  }, [templateSyncOptionId])

  const handleAddButtonClick = useCallback((field: ControllerRenderProps<ITemplateSyncFormData, 'selectedTemplateOptionsPerFields'>) => {
    field.onChange({
      ...field.value,
      [templateSyncOptionId]: [...field.value[templateSyncOptionId], { ...emptyItem }]
    })
  }, [templateSyncOptionId])

  const handleRemoveButtonClick = useCallback((field: ControllerRenderProps<ITemplateSyncFormData, 'selectedTemplateOptionsPerFields'>, index: number) => {
    field.onChange({
      ...field.value,
      [templateSyncOptionId]: [...field.value[templateSyncOptionId].filter((_, i) => index !== i)]
    })
  }, [templateSyncOptionId])

  const dataSource = useMemo(
    () => selectedTemplateOptionsPerField.map((row, index) => ({
      key: index,
      fieldLevel: (
        <Controller name='selectedTemplateOptionsPerFields' control={control} render={({ field }) => (
          <Select
            className={classNames('ms-select', { '--error': !row.fieldLevelId })}
            onChange={(newValue) => handleSelectChange(field, index, { fieldLevelId: newValue, mediaPlanFieldLevelId: null, fieldLabel: '' })}
            placeholder='Select field level'
            value={row.fieldLevelId}
          >
            {Object.keys(templateFieldsByLevel).map(level => (
              <Option key={level} value={Number(level)}>{FieldLevelLabels[level]}</Option>
            ))}
          </Select>
        )} />
      ),
      fieldName: (
        <Controller name='selectedTemplateOptionsPerFields' control={control} render={({ field }) => (
          <Select
            className={classNames('ms-select', { '--error': row.fieldLevelId && !row.mediaPlanFieldLevelId })}
            disabled={!row.fieldLevelId}
            onChange={(newValue) => handleSelectChange(field, index, {
              mediaPlanFieldLevelId: newValue,
              fieldLabel: templateFieldsByLevel[row.fieldLevelId].find(i => i.clientMediaPlanField.mediaPlanFieldLevelId === newValue).clientMediaPlanField.mediaPlanField.fieldLabel
            })}
            placeholder='Select field'
            value={row.mediaPlanFieldLevelId}
          >
            {templateFieldsByLevel[row.fieldLevelId]
              ?.filter(item => !field.value[templateSyncOptionId].some((selectedItem, i) => i !== index && selectedItem.mediaPlanFieldLevelId === item.clientMediaPlanField.mediaPlanFieldLevelId))
              .map(item => (
                <Option
                  key={item.clientMediaPlanField.mediaPlanFieldLevelId}
                  value={item.clientMediaPlanField.mediaPlanFieldLevelId}
                >
                  {item.clientMediaPlanField.mediaPlanField.fieldLabel}
                </Option>
              ))
            }
          </Select>
        )} />
      ),
      buttons: (
        <Controller name='selectedTemplateOptionsPerFields' control={control} render={({ field }) => (
          <div className='front-sheet-item-icons'>
            <Tooltip title='Add field'>
              <Button
                className='plus-icon'
                icon={<PlusCircleOutlined />}
                onClick={() => handleAddButtonClick(field)}
                size='large'
                shape='circle'
              />
            </Tooltip>
            {field.value[templateSyncOptionId]?.length > 1 && (
              <Tooltip title='Remove field'>
                <Button
                  className='minus-icon'
                  icon={<MinusCircleOutlined />}
                  onClick={() => handleRemoveButtonClick(field, index)}
                  size='large'
                  shape='circle'
                />
              </Tooltip>
            )}
          </div>
        )} />
      )
    })),
    [
      control,
      handleAddButtonClick,
      handleRemoveButtonClick,
      handleSelectChange,
      selectedTemplateOptionsPerField,
      templateSyncOptionId,
      templateFieldsByLevel
    ]
  )

  return (
    <MSTable
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowKey='key'
      showHeader={false}
    />
  )
}

export default TemplateSyncPerFieldOptionsTable
