import { message } from 'antd'
import { PutLinkedLookupsByIdApiResponse, PutLinkedLookupsByIdApiArg } from 'Apis/generated/linkedLookupsApi'
import { usePutLinkedLookupsByIdMutation } from 'Apis/enhancedLinkedLookupsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseMutationCommand } from '../../../redux/types'

export const useUpdateLinkedLookup = (): UseMutationCommand<Omit<PutLinkedLookupsByIdApiArg, 'clientId'>, PutLinkedLookupsByIdApiResponse> => {
  const { data: currentClient } = useCurrentClient()
  const [updateLinkedLookup, { isLoading }] = usePutLinkedLookupsByIdMutation()

  return {
    isLoading,
    execute: async ({ id, linkedLookup }) => {
      try {
        const response = await updateLinkedLookup({
          clientId: currentClient.id,
          id,
          linkedLookup
        }).unwrap()

        message.success('Linked lookup has been updated')

        return response
      } catch (error) {
        const errorMsg = error?.data?.[0] || 'Something went wrong'
        message.error(errorMsg)

        throw error
      }
    }
  }
}
