import React from 'react'
import { Button } from 'antd'
import { navigate, MSLayoutContentRow, MSTabs, MSTabPane } from 'mindshare.layout'
import { MediaPlanNamesAndIds } from 'Containers/views/FrontSheet/FrontSheetViewContainer'
import { MsMarkdownEditorComponent } from 'Components/MarkdownEditor/MsMarkdownEditorComponent'
import FrontSheetIncludedPlansComponent from './FrontSheetIncludedPlansComponent'
import FrontSheetViewTableComponent from './FrontSheetViewTableComponent'
import frontSheetViewText from 'Components/tooltipContent/FrontSheets/Front Sheet View'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import { FrontSheet, FrontSheetViewDisplay } from 'Apis/generated/frontSheetsApi'

interface IProps {
  currentClientId: number
  currentFrontSheet: FrontSheet
  currentFrontSheetView: FrontSheetViewDisplay
  getFrontSheetView: (frontSheetViewId: number) => void
  mediaPlanNamesAndIds: MediaPlanNamesAndIds[]
  onNotesUpdate: (Notes: string) => void
  saveFrontSheet: () => void
  savingFrontSheet: boolean
}

const buttonFunctions = {
  viewAllFrontSheets: (currentClientId: number) => navigate('/front-sheets', false, { clientId: currentClientId }),
  editFrontSheet: (frontSheetId: number, currentClientId: number) => navigate(`/edit-front-sheet/${frontSheetId}?clientId=${currentClientId}`, false)
}

export const FrontSheetViewComponent: React.FC<IProps> = ({
  currentClientId,
  currentFrontSheet,
  currentFrontSheetView,
  getFrontSheetView,
  mediaPlanNamesAndIds,
  onNotesUpdate,
  saveFrontSheet,
  savingFrontSheet
}): React.ReactElement => {
  return (
    <>
      <TitleAndButtonsComponent
        buttons={[{
          buttonClick: () => buttonFunctions.editFrontSheet(currentFrontSheet.frontSheetId, currentClientId),
          buttonText: 'Edit Front Sheet'
        }]}
        title={currentFrontSheet.frontSheetName}
        tooltipText={frontSheetViewText}
      />

      <MSLayoutContentRow extraClass='front-sheet-view-plan-list-row'>
        <FrontSheetIncludedPlansComponent
          currentClientId={currentClientId}
          mediaPlanNamesAndIds={mediaPlanNamesAndIds}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow>
        <MSTabs
          className='frontSheets__tabs'
          onChange={(key) => {
            getFrontSheetView(Number(key))
          }}
        >
          {currentFrontSheet.frontSheetViews.map(view => (
            <MSTabPane tab={view.frontSheetViewName} key={String(view.frontSheetViewId)}>
              <FrontSheetViewTableComponent
                currentClientId={currentClientId}
                currentFrontSheetView={currentFrontSheetView}
                frontSheetViewId={view.frontSheetViewId}
                frontSheetId={view.frontSheetId}
                frontSheetName={currentFrontSheet.frontSheetName}
              />
            </MSTabPane>
          ))}
        </MSTabs>
      </MSLayoutContentRow>

      <MSLayoutContentRow extraClass='front-sheet-view-plan-notes-row'>
        <h3>Notes</h3>
        <MsMarkdownEditorComponent
          value={currentFrontSheet.notes ?? '' }
          onChange={onNotesUpdate}
          data-testid='front-sheet-view-plan-notes'
        />
        <Button
          onClick={saveFrontSheet}
          disabled={savingFrontSheet}
          type='primary'
          data-testid='front-sheet-view-save-btn'
        >Save Notes</Button>
      </MSLayoutContentRow>

      <MSLayoutContentRow>
        <Button
          onClick={() => buttonFunctions.viewAllFrontSheets(currentClientId)}
          disabled={savingFrontSheet}
        >Back to Front Sheets</Button>
      </MSLayoutContentRow>
    </>
  )
}

export default FrontSheetViewComponent
