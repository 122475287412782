import { useMemo, useRef } from 'react'
import isEqual from 'lodash/isEqual'

export const useDeepEqualMemoized = <T>(value: T) => {
  const memoizedValueRef = useRef(value)
  const hasValueChanged = useMemo(() => {
    return !isEqual(value, memoizedValueRef.current)
  }, [value])

  if (hasValueChanged) {
  // eslint-disable-next-line functional/immutable-data
    memoizedValueRef.current = value
  }

  return memoizedValueRef.current
}
