import React, { useState } from 'react'
import Moment from 'react-moment'
import { Button, Popconfirm, Spin, Tooltip } from 'antd'
import { DeleteOutlined, DownloadOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { MSVirtualTable, getColumnSearchProps, formatEmailToName } from 'mindshare.layout'
import { FrontSheet } from 'Apis/generated/frontSheetsApi'
import { compare } from 'Helpers/sortHelper'
import { popoverMessage } from 'Constants/enums/PopoverMessage'

interface IProps {
  buttonFunctions: {
    createNewFrontSheet: () => void
    downloadFrontSheet: (frontSheetName: string, frontSheetId: number, clientId: number, setLoading: (frontSheetLoading: number) => void) => void
    deleteFrontSheet: (frontSheetId: number) => void
    editFrontSheet: (frontSheetId: number) => void
    viewFrontSheet: (frontSheetId: number) => void
  }
  clientId: number
  currentClientDisplayName: string
  frontSheets: FrontSheet[]
}

export const FrontSheetListComponent: React.FC<IProps> = ({
  buttonFunctions,
  clientId,
  currentClientDisplayName,
  frontSheets
}): React.ReactElement => {
  const [downloading, setDownloading] = useState<number>(null)

  if (frontSheets && frontSheets.length > 0) {
    const columns = [
      {
        title: 'Front Sheet Id',
        dataIndex: 'frontSheetId',
        key: 'frontSheetId',
        ...getColumnSearchProps('frontSheetId', 'Front Sheet ID')
      },
      {
        title: 'Front Sheet Name',
        dataIndex: 'frontSheetName',
        key: 'frontSheetName',
        ...getColumnSearchProps('frontSheetName', 'Front Sheet Name')
      },
      {
        title: 'Modified By',
        dataIndex: 'modifiedBy',
        key: 'modifiedBy',
        ...getColumnSearchProps('modifiedBy', 'Modified By')
      },
      {
        title: 'Modified On',
        dataIndex: 'modifiedOn',
        key: 'modifiedOn'
      },
      {
        title: '',
        dataIndex: 'buttons',
        className: 'button-cell',
        key: 'buttons'
      }
    ]

    const data = [...frontSheets]
      .sort((a, b) => compare(a.modifiedOn, b.modifiedOn, false))
      .map((item, i) => {
        return {
          key: i,
          frontSheetId: item.frontSheetId,
          frontSheetName: item.frontSheetName,
          modifiedBy: formatEmailToName(item.modifiedBy),
          modifiedOn: <Moment format='DD MMM YY'>{item.modifiedOn}</Moment>,
          buttons: (
            <>
              <Tooltip title='View Front Sheet'>
                <Button
                  disabled={item.frontSheetViews.length === 0 || item.mediaPlanIds.length === 0}
                  data-testid='view-front-sheet'
                  icon={<EyeOutlined />}
                  onClick={ () => buttonFunctions.viewFrontSheet(item.frontSheetId)}
                  type='link'
                />
              </Tooltip>
              <Tooltip title='Edit Front Sheet'>
                <Button
                  data-testid='edit-front-sheet'
                  icon={<EditOutlined />}
                  onClick={() => buttonFunctions.editFrontSheet(item.frontSheetId)}
                  type='link'
                />
              </Tooltip>
              <Popconfirm
                placement='rightTop'
                title={popoverMessage.confirmDeleteFrontSheet}
                onConfirm={() => buttonFunctions.deleteFrontSheet(item.frontSheetId)}
                okText='Yes'
                cancelText='No'
              >
                <Tooltip title='Delete Front Sheet'>
                  <Button
                    data-testid='delete-front-sheet'
                    icon={<DeleteOutlined />}
                    onClick={() => {}}
                    type='link'
                  />
                </Tooltip>
              </Popconfirm>
              <Tooltip title='Download Front Sheet'>
                <Button
                  disabled={item.frontSheetViews.length === 0 || item.mediaPlanIds.length === 0}
                  data-testid='download-front-sheet'
                  icon={downloading === item.frontSheetId ? <Spin data-testid='download-spin-icon' /> : <DownloadOutlined />}
                  type='link'
                  onClick={async () => {
                    setDownloading(item.frontSheetId)
                    try {
                      await buttonFunctions.downloadFrontSheet(item.frontSheetName, item.frontSheetId, clientId, setDownloading)
                    } finally {
                      setDownloading(null)
                    }
                  }}
                />
              </Tooltip>
            </>
          )
        }
      })

    return (
      <div className='list-table front-sheets-list-table'>
        <MSVirtualTable
          columns={columns}
          dataSource={data}
        />
      </div>
    )
  } else {
    return (
      <div className='list-table front-sheets-list-table' data-testid='no-front-sheets'>
        <p>
          No Front Sheets for {currentClientDisplayName}. <a onClick={buttonFunctions.createNewFrontSheet}>Create a Front Sheet</a>
        </p>
      </div>
    )
  }
}

export default FrontSheetListComponent
