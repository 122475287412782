import { message } from 'antd'
import { PostLinkedLookupsBatchUpdateApiResponse, PostLinkedLookupsBatchUpdateApiArg } from 'Apis/generated/linkedLookupsApi'
import { usePostLinkedLookupsBatchUpdateMutation } from 'Apis/enhancedLinkedLookupsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseMutationCommand } from '../../../redux/types'

export const useBatchUpdateLinkedLookup = (): UseMutationCommand<Omit<PostLinkedLookupsBatchUpdateApiArg, 'clientId'>, PostLinkedLookupsBatchUpdateApiResponse> => {
  const { data: currentClient } = useCurrentClient()
  const [batchUpdateLinkedLookup, { isLoading }] = usePostLinkedLookupsBatchUpdateMutation()

  return {
    isLoading,
    execute: async ({ body }) => {
      try {
        const response = await batchUpdateLinkedLookup({
          clientId: currentClient.id,
          body
        }).unwrap()

        message.success('Successfully updated linked lookups')

        return response
      } catch (error) {
        const errorMsg = error?.data?.[0] || 'Something went wrong'
        message.error(errorMsg)

        throw error
      }
    }
  }
}
