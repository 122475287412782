import { createSelector } from 'reselect'
import { selectMediaPlans } from './mediaPlansSelectors'

export const selectGoalSeek = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.goalSeek
)

export const selectGoalSeekModalData = createSelector(
  selectGoalSeek,
  goalSeek => goalSeek.goalSeekModalData
)

export const selectGoalSeekModalVisible = createSelector(
  selectGoalSeekModalData,
  goalSeekModalData => goalSeekModalData.modalVisible
)

export const selectGoalSeekWizardProgress = createSelector(
  selectGoalSeekModalData,
  goalSeekModalData => goalSeekModalData.wizardProgress
)
