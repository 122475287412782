import React from 'react'
import Moment from 'react-moment'
import moment from 'moment'
import { MSVirtualTable } from 'mindshare.layout'
import { Tooltip } from 'antd'
import { IPlanListSettings } from 'Constants/entities/IMapSettings'
import { ILookupPlan } from 'Components/MediaPlans/constants/entities/IMediaPlans'

export interface IProps {
  createNewPlan: () => void
  currentClientDisplayName: string
  currentClientId: number
  filterFunction: (dataIndex: keyof IPlanListSettings, placeholderString: string) => object
  lastColumn: any
  mediaPlans: ILookupPlan[]
}

const PlansListTable: React.FC<IProps> = (props) => {
  if (props.mediaPlans.length > 0) {
    const columns = [
      {
        title: 'Plan ID',
        dataIndex: 'planId',
        key: 'planId',
        ...props.filterFunction('planId', 'Plan ID')
      },
      {
        title: 'Geography',
        dataIndex: 'geography',
        key: 'geography',
        renderCellContent: (content: string) => <Tooltip title={content}><div className="cell-content">{content}</div></Tooltip>
      },
      {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
        ...props.filterFunction('year', 'year')
      },
      {
        title: 'Plan Name',
        dataIndex: 'planName',
        key: 'planName',
        renderCellContent: (content: string) => <Tooltip title={content}><div className="cell-content">{content}</div></Tooltip>,
        ...props.filterFunction('planName', 'Plan Name')
      },
      {
        title: 'Planning Stage',
        dataIndex: 'planningStage',
        key: 'planningStage',
        ...props.filterFunction('planningStage', 'Planning Stage')
      },
      {
        title: 'Media',
        dataIndex: 'media',
        key: 'media',
        renderCellContent: (content: string) => <Tooltip title={content}><div className="cell-content">{content}</div></Tooltip>
      },
      {
        title: 'Version',
        dataIndex: 'version',
        key: 'version'
        // align: 'center' as 'center'
      },
      {
        className: 'modified-by',
        title: 'Modified By',
        dataIndex: 'modifiedBy',
        key: 'modifiedBy',
        renderCellContent: (content: string) => <Tooltip title={content}><div className="cell-content">{content}</div></Tooltip>,
        ...props.filterFunction('modifiedBy', 'Modified By')
      },
      {
        title: 'Modified On',
        dataIndex: 'modifiedOn',
        key: 'modifiedOn'
      },
      {
        title: 'Planning Stage Modified On',
        dataIndex: 'planningStageModifiedOn',
        key: 'planningStageModifiedOn'
      },
      {
        title: '',
        dataIndex: 'buttons',
        className: 'button-cell',
        key: 'buttons'
      }
    ]

    const data = props.mediaPlans.map((item: ILookupPlan, i) => {
      return {
        key: i,
        planId: item.mediaPlanId,
        geography: item.planClientGeographyHierarchyValue || 'N/A',
        year: item.planYear,
        planName: item.planName,
        planningStage: item.planningStageName,
        media: item.planClientMediaHierarchyValue || 'N/A',
        version: item.versionNumber,
        modifiedBy: item.modifiedByFormatted,
        modifiedOn: (
          <Moment format='DD MMM YY'>{item.modifiedOn}</Moment>
        ),
        planningStageModifiedOn: (
          item.planningStageModifiedOn == null ? ''
            : <Tooltip title={`Planning Stage modified on ${moment(item.planningStageModifiedOn).format('DD MMM YY h:mmA')} by ${item.planningStageModifiedByFormatted}`}>
              <Moment format='DD MMM YY'>{item.planningStageModifiedOn}</Moment>
            </Tooltip>
        ),
        buttons: props.lastColumn(item)
      }
    })

    return (
      <div className='list-table plans-list-table'>
        <MSVirtualTable
          columns={columns}
          dataSource={data}
        />
      </div>
    )
  }
  return (
    <div className='list-table'>
      <p>
        No Plans for {props.currentClientDisplayName}. <a onClick={props.createNewPlan}>Create a Plan</a>
      </p>
    </div>
  )
}

export default PlansListTable
