import React from 'react'
import {
  differenceInCalendarDays,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  endOfMonth,
  endOfQuarter,
  format,
  isAfter,
  isBefore
} from 'date-fns'

interface IQuartersProps {
  startDate: Date
  endDate: Date
  pixelsPerDay: number
  weekStateOn: any
  compressedCalendarView: boolean
}

interface IQuarterProps {
  label: string
  daysInQuarter: number
}

interface IMonthsByQuarterProps {
  monthQuarterStartDate: Date
  monthQuarterEndDate: Date
}

const Quarters = ({ startDate, endDate, pixelsPerDay, weekStateOn, compressedCalendarView }: IQuartersProps) => {
  const Quarter = ({ label, daysInQuarter }: IQuarterProps) => {
    const width = compressedCalendarView ? (daysInQuarter * pixelsPerDay) / 3 : (daysInQuarter * pixelsPerDay)
    const widthStyle = { width: `${width}px` }
    return (
      <div className='flightHeader__title' style={widthStyle}>
        {label}
      </div>
    )
  }

  const MonthsByQuarter = ({ monthQuarterStartDate, monthQuarterEndDate }: IMonthsByQuarterProps) => {
    const monthsInQuarter = eachMonthOfInterval({ start: monthQuarterStartDate, end: monthQuarterEndDate })
    const monthElements = monthsInQuarter.map((month) => {
      let currentEndMonth = endOfMonth(month)
      currentEndMonth = isAfter(currentEndMonth, endDate) ? endDate : currentEndMonth
      const daysInMonth = differenceInCalendarDays(currentEndMonth, isBefore(month, startDate) ? startDate : month) + 1
      const width = compressedCalendarView ? (daysInMonth * pixelsPerDay) / 3 : (daysInMonth * pixelsPerDay)
      const widthStyle = { width: `${width}px` }
      return (
        <div className='flightHeader__month' key={`MonthContainer-${format(month, 'MMMM-yyyy')}`} style={widthStyle}>
          {format(month, 'MMMM')}
        </div>
      )
    })
    return (<div className='flightHeader__months'>{monthElements}</div>)
  }

  const quarters = eachQuarterOfInterval({ start: startDate, end: endDate })

  const quartersElements = quarters.map((quarter, index) => {
    const quarterName = format(quarter, 'QQQ', weekStateOn) + ' ' + quarter.getFullYear()
    let endOfQuar = endOfQuarter(quarter)
    endOfQuar = isAfter(endOfQuar, endDate) ? endDate : endOfQuar
    const daysInQuarter = differenceInCalendarDays(endOfQuar, (index === 0 ? startDate : quarter)) + 1
    return (
      <div key={`container-${quarterName}`} className='flightHeader__quarters'>
        <Quarter
          label={quarterName}
          daysInQuarter={daysInQuarter}
          key={quarterName}
        />
        <MonthsByQuarter
          key={`quarter-${quarterName}`}
          monthQuarterStartDate={(index === 0 ? startDate : quarter)}
          monthQuarterEndDate={endOfQuar}
        />
      </div>
    )
  })
  return (
    <div className='flightHeader__container'>
      {quartersElements}
    </div>
  )
}

export default Quarters
