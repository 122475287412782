import {
  findByFieldLevelId,
  IMediaPlanMetaField,
  isFieldAvailable
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { formatLowerCaseFirstLetter } from 'mindshare.layout'
import { IMediaPlanVersion } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { defineDefaultFlight, getValueOfCorrectType } from 'Helpers/flightHelper'
import { pick } from 'Helpers/objectHelper'
import { generateTemporaryId } from 'Helpers/commonUtils'
import { MediaPlanSubFlightMediaPlanFlightMediaPlanSubFlightMediaPlanFlightGroup } from 'Apis/generated/mediaPlanVersionsApi'
import { cloneFlight, IFlight } from './IFlight'

export interface IFlightGroup extends MediaPlanSubFlightMediaPlanFlightMediaPlanSubFlightMediaPlanFlightGroup {
  flights?: IFlight[] | null
  [key: string]: any
}

const cloneChosenFlightFields = (mediaPlanFlightGroupId: number, preserveTargetIds: boolean) => (chosenFlightField) => ({
  ...chosenFlightField,
  mediaPlanFlightGroupId,
  ...(!preserveTargetIds ? {
    chosenFlightFieldId: 0
  } : {})
})

export const generateFlightGroupId = (flightGroups: IFlightGroup[], step = 1) => {
  const flightGroupsNotSaved = flightGroups
    .filter(flightGroup => flightGroup.mediaPlanFlightGroupId <= 0)
    .map(f => f.mediaPlanFlightGroupId)

  return flightGroupsNotSaved.length > 0 ? Math.min(...flightGroupsNotSaved) - step : step * -1
}


/**
* Creates a deep copy of FlightGroup,
* assigning a temporary Id to the resulting FlightGroup and its every nested entity
**/
export const cloneFlightGroup = (flightGroup: IFlightGroup, preserveTargetIds: boolean, overrides?: Partial<IFlightGroup>) => {
  const { mediaPlanFlightGroupId = generateTemporaryId() } = overrides

  return ({
    ...flightGroup,
    sortOrder: 0,
    chosenFlightFields: flightGroup.chosenFlightFields?.map(cloneChosenFlightFields(mediaPlanFlightGroupId, preserveTargetIds)),
    flights: flightGroup.flights?.map(cloneFlight),
    ...overrides,
    mediaPlanFlightGroupId
  })
}

export const createDefaultFlightGroup = (flightGroupFields: IMediaPlanMetaField[], mediaPlanVersion: IMediaPlanVersion, mediaPlanFlightGroupId?: number) => {
  let currentFlightGroup = { ...mediaPlanVersion.parseData.flightGroups[0] }
  const flights = findByFieldLevelId(mediaPlanVersion.mediaPlanVersionFields.filter(c => !isFieldAvailable(c) || c.isInPlan), FieldLevelType.FLIGHT)
  const subFlights = findByFieldLevelId(mediaPlanVersion.mediaPlanVersionFields.filter(c => !isFieldAvailable(c) || c.isInPlan), FieldLevelType.SUB_FLIGHT)
  const sortOrder = Math.max(...mediaPlanVersion.parseData.flightGroups.map(fg => fg.sortOrder)) + 10

  Object.keys(currentFlightGroup).forEach(c => {
    if (c === 'mediaPlanFlightGroupId') {
      const flightGroupsNotSaved = mediaPlanVersion.parseData.flightGroups.filter(flightGroup => flightGroup.mediaPlanFlightGroupId <= 0).map(f => f.mediaPlanFlightGroupId)

      // eslint-disable-next-line functional/immutable-data
      currentFlightGroup[c] = mediaPlanFlightGroupId ?? (flightGroupsNotSaved.length > 0 ? Math.min(...flightGroupsNotSaved) - 1 : -1)
    } else if (c === 'mediaPlanVersionId') {
      // eslint-disable-next-line functional/immutable-data
      currentFlightGroup[c] = 0
    } else if (c !== 'chosenFlightFields' && c !== 'flights') {
      const field = flightGroupFields.find(m => formatLowerCaseFirstLetter(m.clientMediaPlanField?.mediaPlanField?.columnName) === c)
      if (field) {
        const value = field?.defaultValue
        const fieldTypeId = field?.clientMediaPlanField?.mediaPlanField?.fieldDataTypeId
        // eslint-disable-next-line functional/immutable-data
        currentFlightGroup[c] = getValueOfCorrectType(value, fieldTypeId)
      }
    } else if (c === 'chosenFlightFields') {
      // eslint-disable-next-line functional/immutable-data
      currentFlightGroup[c] = currentFlightGroup[c].map(cff => ({ ...cff, chosenFlightFieldId: 0, mediaPlanFlightGroupId: 0 }))
    }
  })
  if (mediaPlanVersion.parseData.calendarView && mediaPlanVersion.parseData !== '' && mediaPlanVersion.parseData.calendarView !== 'table') {
    currentFlightGroup = {
      ...currentFlightGroup,
      sortOrder,
      flights: defineDefaultFlight(
        mediaPlanVersion.parseData.planStartDate,
        mediaPlanVersion.parseData.planEndDate,
        [...flights, ...subFlights],
        mediaPlanVersion
      )
    }
  } else {
    currentFlightGroup = {
      ...currentFlightGroup,
      sortOrder,
      flights: [{
        flightStartDate: null,
        flightEndDate: null,
        merge: true,
        width: '',
        mediaPlanFlightId: -1 * Math.floor(Math.random() * 10000)
      }]
    }
  }

  return currentFlightGroup
}

export const mergeFlightGroups = (target: IFlightGroup, source: Partial<IFlightGroup>, preserveTargetIds: boolean ) =>
  cloneFlightGroup(
    { ...target, ...source },
    preserveTargetIds,
    { mediaPlanFlightGroupId: target.mediaPlanFlightGroupId }
  )

export const resetFlightGroup = (
  flightGroupFields: IMediaPlanMetaField[],
  mediaPlanVersion: IMediaPlanVersion,
  flightGroup: IFlightGroup,
  flightGroupSelection: Partial<IFlightGroup>
) => {
  const defaultFlightGroup = createDefaultFlightGroup(flightGroupFields, mediaPlanVersion, flightGroup.mediaPlanFlightGroupId)
  const keys = Object.keys(flightGroupSelection)

  return {
    ...flightGroup,
    ...pick<IFlightGroup, keyof IFlightGroup>(defaultFlightGroup, ...keys)
  }
}
