import React, { useCallback, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Button, Dropdown, Menu, Spin, Tooltip } from 'antd'
import { QuestionOutlined, QuestionCircleOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons'
import ModalComponent from '../ModalComponent'
import { IMediaPlanVersionField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { PlanInfoTableComponent } from 'Components/MediaPlanVersion/PlanInfoTableComponent'
import { IMediaPlans } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import { downloadExcelPlan } from './helpers/downloadExcelPlan'

interface IPlanInfoModalComponentProps {
  clientId: number
  displayFlightDates: boolean
  expandHierarchies: boolean
  hasUnsavedChanges: boolean
  mediaPlan: IMediaPlans
  mediaPlanVersionFields?: IMediaPlanVersionField[]
  text: string
}

const PlanInfoDownloadActionsComponent: React.FC<IPlanInfoModalComponentProps> = ({ clientId, displayFlightDates, expandHierarchies, hasUnsavedChanges, mediaPlan, mediaPlanVersionFields, text }) => {
  const [helpModalVisible, setHelpModalVisible] = useState(false)
  const [fieldDefinitionModalVisible, setFieldDefinitionModalVisible] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const showHelpModal = () => setHelpModalVisible(true)
  const showFieldDefinitionModal = () => setFieldDefinitionModalVisible(true)

  const hideHelpModal = () => setHelpModalVisible(false)
  const hideFieldDefinitionModal = () => setFieldDefinitionModalVisible(false)

  const onPlanDownloadClick = useCallback(async () => {
    const { mediaPlanId, planName, mediaPlanVersionId } = mediaPlan
    setDownloading(true)
    try {
      await downloadExcelPlan(planName, mediaPlanId, mediaPlanVersionId, clientId, !!expandHierarchies, !!displayFlightDates)
    } finally {
      setDownloading(false)
    }
  }, [clientId, displayFlightDates, expandHierarchies, mediaPlan])

  const infoMenu = () => (
    <Menu className="toolbar__context-menu">
      <Menu.Item onClick={showHelpModal} data-testid='media-plan-input-help'>
        <QuestionCircleOutlined />
        Media plan input help
      </Menu.Item>
      <Menu.Item onClick={showFieldDefinitionModal} data-testid='field-definitions'>
        <InfoCircleOutlined />
        Field definitions
      </Menu.Item>
    </Menu>
  )

  return (
    <div className='tooltip'>
      <Tooltip title={hasUnsavedChanges ? 'Media Plan must be saved before downloading' : 'Download Media Plan'}>
        <Button
          className='ms-button'
          data-testid='download-plan-button'
          disabled={hasUnsavedChanges}
          onClick={onPlanDownloadClick}
          tabIndex={-1}
          type='primary'
        >
          {downloading ? <Spin data-testid='download-spin-icon' /> : <DownloadOutlined />}
        </Button>
      </Tooltip>
      <Dropdown overlay={infoMenu()} trigger={['click']}>
        <Button
          className='ms-button'
          data-testid='tooltip'
          type='primary'
          tabIndex={-1}
        >
          <QuestionOutlined />
        </Button>
      </Dropdown>

      <ModalComponent
        onCancel={hideHelpModal}
        visible={helpModalVisible}
        width={900}
        footer={null}
        modalBody={<ReactMarkdown linkTarget="_blank" source={text} />}
      />

      <ModalComponent
        onCancel={hideFieldDefinitionModal}
        visible={fieldDefinitionModalVisible}
        width={900}
        footer={null}
        className='field-definitions-modal'
        modalBody={<PlanInfoTableComponent mediaPlanVersionFields={mediaPlanVersionFields} />}
      />
    </div>
  )
}

export default PlanInfoDownloadActionsComponent
