import { SubtotalCalculationResult } from 'Apis/generated/mediaPlanVersionsApi'
import { IMediaPlanMetaField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

export const getSubtotalFlightFields = (
  flightFields: IMediaPlanMetaField[], subtotals: SubtotalCalculationResult
): IMediaPlanMetaField[] => {
  if (!subtotals?.subtotalGroups) {
    return flightFields
  }

  let flightIds = []
  subtotals.subtotalGroups.forEach((item) => {
    const flightSubtotalIds = item.flightSubtotals.map((f) => f.clientMediaPlanFieldId)
    flightIds = flightIds.concat(flightSubtotalIds)
  })
  const availableFlightFields = flightFields.filter((f) => flightIds.includes(f.clientMediaPlanFieldId))

  return availableFlightFields
}
