import { IMediaPlanTemplateFieldsExtended } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

export const sortOrderFlightGroup: IMediaPlanTemplateFieldsExtended = {
  mediaPlanVersionFieldId: Number.MAX_SAFE_INTEGER,
  mediaPlanTemplateId: 0,
  isInPlan: true,
  clientMediaPlanFieldId: Number.MAX_SAFE_INTEGER,
  fieldLabel: 'Sort Order',
  templateFieldAvailabilityId: 10,
  templateFieldTypeId: 10,
  functionId: null,
  defaultValue: '2020-04-24',
  calculation: null,
  isComplexCalculation: false,
  requiredHierarchyLevelId: 0,
  includeLevelsBelowHierarchy: false,
  isEditable: true,
  isFooter: false,
  sortOrder: -10000000,
  approvedBy: null,
  approvedOn: null,
  token: '{FlightGroups.SortOrder}',
  clientMediaPlanField: {
    clientMediaPlanFieldId: Number.MAX_SAFE_INTEGER,
    mediaPlanFieldLevelId: 521,
    mediaPlanFieldId: 0,
    fieldLabel: 'S',
    isMandatory: false,
    isDisabled: false,
    sortOrder: 1340,
    isApportionmentField: false,
    isApportioned: false,
    approvedOn: null,
    columnName: '',
    isVisible: true,
    key: 0,
    mediaPlanField: {
      mediaPlanFieldLevelId: Number.MAX_SAFE_INTEGER,
      mediaPlanFieldId: Number.MAX_SAFE_INTEGER,
      fieldLabel: 'SortOrder',
      fieldDataTypeId: 10,
      columnName: 'SortOrder',
      fieldLevelId: 2,
      fieldDescription: 'SortOrder',
      isMandatory: false,
      sortOrder: 1420,
      defaultValue: '',
      isApportionable: false,
      isDefaultApportioned: false,
      isDefaultApportionmentField: false,
      isSelected: false,
      isVisible: false,
      key: 0,
      fieldDataType: {
        fieldDataTypeId: 10,
        dataTypeName: 'Integer',
        dataType: 'BIGINT',
        label: 'An integer',
        isHierarchy: false
      },
      fieldLevel: {
        fieldLevelId: 2,
        fieldLevelName: 'FlightGroup'
      },
      fieldTags: []
    },
    clientFieldValues: []
  }
}
