import { useMemo } from 'react'
import { GetCustomExportsDisplayTypesApiResponse } from 'Apis/generated/customExportsApi'
import { useGetCustomExportsDisplayTypesQuery } from 'Apis/enhancedCustomExportsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'

export const useDisplayTypes = (): UseQueryCommand<GetCustomExportsDisplayTypesApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetCustomExportsDisplayTypesQuery(
    { clientId: currentClient.id },
    { skip: !currentClient.id }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
