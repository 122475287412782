import React, { useState, useMemo } from 'react'
import { Button, Popconfirm, Spin, Tooltip, Dropdown, Menu } from 'antd'
import { BranchesOutlined, CopyOutlined, DeleteOutlined, FileExcelOutlined, DownloadOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons'
import { navigate } from 'mindshare.layout'
import { popoverMessage } from 'Constants/enums/PopoverMessage'

interface IButtonProps {
  buttonActions: {
    clonePlan: (mediaPlanId: number, copyRedistribution?: boolean) => void
    deletePlan: (mediaPlanId: number, clientId: number) => void
    downloadExcel: (planName: string, mediaPlanId: number, mediaPlanVersionId: number, clientId: number, expandHierarchies: boolean, displayFlightDates: boolean) => void
    getPlanVersionHistory: (mediaPlanId: number, clientId: number, mediaPlan) => void
    removePlanFromReporting: (mediaPlanId: number, clientId: number) => void
    removePlanFromCoreM: (mediaPlanId: number, clientId: number) => void
  }
  clientId: number
  item: any
  showRemoveFromReportIcon: boolean
  expandHierarchies: boolean
  displayFlightDates: boolean
}

const PlanListTableButtonColumn: React.FC<IButtonProps> = ({ buttonActions, clientId, item, showRemoveFromReportIcon, expandHierarchies, displayFlightDates }) => {
  const [downloading, setDownloading] = useState(false)

  const contextMenu = useMemo(() => (
    <Menu className="plan-list__context-menu">
      {showRemoveFromReportIcon && (
        <Menu.Item disabled={!item.planningStageModifiedOn} onClick={() => undefined} data-testid='remove-plan-from-report-button'>
          <Tooltip title='Remove plan from reporting'>
            <Popconfirm
              placement='rightTop'
              title={popoverMessage.confirmRemovePlanFromReport}
              onConfirm={() => buttonActions.removePlanFromReporting(item.mediaPlanId, clientId)}
              okText='Yes'
              cancelText='No'
              disabled={!item.planningStageModifiedOn}
            >
              <FileExcelOutlined />
                Remove plan from reporting
            </Popconfirm>
          </Tooltip>
        </Menu.Item>
      )}
      <Menu.Item disabled={!item.canRemoveFromCoreM} onClick={() => buttonActions.removePlanFromCoreM(item.mediaPlanId, clientId)} data-testid='remove-plan-from-corem-button'>
        <Tooltip title='Remove plan from deal book'>
          <FileExcelOutlined />
            Remove plan from deal book
        </Tooltip>
      </Menu.Item>
    </Menu>
  ), [buttonActions, clientId, item.canRemoveFromCoreM, item.mediaPlanId, item.planningStageModifiedOn, showRemoveFromReportIcon])

  return (
    <>
      <Tooltip title='Edit Media Plan'>
        <Button
          icon={<EditOutlined />}
          onClick={ () => navigate(`/mediaplanversion/${item.mediaPlanVersionId}`, false, { clientId })}
          type='link'
        />
      </Tooltip>
      <Popconfirm
        placement='rightTop'
        title={popoverMessage.confirmCopyRedistribution}
        onConfirm={() => buttonActions.clonePlan(item.mediaPlanId)}
        onCancel={() => buttonActions.clonePlan(item.mediaPlanId, false)}
        okText='Yes'
        cancelText='No'
      >
        <Tooltip title='Clone Media Plan'>
          <Button
            data-testid='clone-plan-button'
            icon={<CopyOutlined />}
            type='link'
          />
        </Tooltip>
      </Popconfirm>
      <Tooltip title='Version History'>
        <Button
          data-testid='history-plan-button'
          icon={<BranchesOutlined />}
          onClick={() => buttonActions.getPlanVersionHistory(item.mediaPlanId, clientId, item)}
          type='link'
        />
      </Tooltip>
      <Popconfirm
        placement='rightTop'
        title={popoverMessage.confirmDeletePlan}
        onConfirm={() => buttonActions.deletePlan(item.mediaPlanId, clientId)}
        okText='Yes'
        cancelText='No'
      >
        <Tooltip title='Delete Media Plan'>
          <Button
            data-testid='delete-plan-button'
            type='link'
            icon={<DeleteOutlined />}
          />
        </Tooltip>
      </Popconfirm>
      <Tooltip title='Download Media Plan'>
        <Button
          data-testid='download-plan-button'
          icon={downloading ? <Spin data-testid='download-spin-icon' /> : <DownloadOutlined />}
          type='link'
          onClick={async () => {
            setDownloading(true)
            try {
              await buttonActions.downloadExcel(item.planName, item.mediaPlanId, item.mediaPlanVersionId, clientId, !!expandHierarchies, !!displayFlightDates)
              setDownloading(false)
            } catch (e) {
              setDownloading(false)
            }
          }}
        />
      </Tooltip>
      <Dropdown overlay={contextMenu} trigger={['click']}>
        <Button type='link' tabIndex={-1} icon={<EllipsisOutlined />} data-testid='plan-list-context-menu-button' />
      </Dropdown>
    </>
  )
}

export default PlanListTableButtonColumn
