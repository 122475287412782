import React, { CSSProperties, FunctionComponent, memo } from 'react'
import EditListFlightContainer from './EditListFlightContainer'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { IMediaPlanTemplateFields, IMediaPlanMetaField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import FlightLabels from 'Components/MediaPlanVersion/FlightLabels'
import { useChosenFields, IClickableFieldProps, useSubtotalFlightFields } from 'Components/MediaPlanVersion/hooks'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import classNames from 'classnames'
import FlightLabel from 'Components/MediaPlanVersion/FlightLabel'
import { useFlightGroupFlights } from 'Components/MediaPlanVersion/hooks/useFlightGroupFlights'

interface IProps {
  flightGroup: IFlightGroup
  flightGroupIndex: number
  calculationMode: boolean
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  handleAddField: (fieldType: FieldLevelType, flightGroupIndex?: number) => void
  onAvailableFieldSelected: (mediaPlanFieldSelected: string, flightGroupIndex?: number) => void
  getStickyStyle: (type: StickyColumnType, id?: number) => CSSProperties
  getFieldError: (clientMediaPlanFieldId: number, flightGroup: IFlightGroup) => string
  isCollapsed: boolean
  collapsedViewField: string
  clickableFieldProps: IClickableFieldProps
  isSubtotalGroup?: boolean
  availableFlightFields?: IMediaPlanMetaField[]
  displayFlightDates: boolean
}

const emptyFlightDatesMediaPlanField = { fieldLabel: 'Flight Dates' }

export const FlightsGridLabelsContainer: FunctionComponent<IProps> = ({
  flightGroup,
  flightGroupIndex,
  calculationMode,
  handleAddField,
  removePlanField,
  onAvailableFieldSelected,
  getStickyStyle,
  getFieldError,
  isCollapsed,
  collapsedViewField,
  clickableFieldProps,
  isSubtotalGroup,
  availableFlightFields,
  displayFlightDates
}) => {
  const availableFlights = useFlightGroupFlights(flightGroupIndex)
  const { chosenFlightFields, chosenSubFlightFields } = useChosenFields({ flightGroupIndex, isCollapsed, collapsedViewField })
  const subtotalFlightFields = useSubtotalFlightFields({ flightFields: availableFlightFields, isCollapsed, collapsedViewField })
  const flightFields = isSubtotalGroup ? subtotalFlightFields : chosenFlightFields

  return (
    <td className={classNames('flight__itemLabels', { '-subtotal': isSubtotalGroup })} style={getStickyStyle(StickyColumnType.flight)}>
      {displayFlightDates && !isCollapsed && availableFlights?.some(f => f.merge) && (
        <FlightLabel
          flightGroupIndex={flightGroupIndex}
          isSubtotalGroup={isSubtotalGroup}
          mediaPlanField={emptyFlightDatesMediaPlanField}
          removePlanField={removePlanField}
          valid={false}
        />
      )}
      <FlightLabels
        flightGroupIndex={flightGroupIndex}
        removePlanField={removePlanField}
        flightFields={flightFields}
        getFieldError={getFieldError}
        flightGroup={flightGroup}
        clickableFieldProps={clickableFieldProps}
        isSubtotalGroup={isSubtotalGroup}
      />
      {!isSubtotalGroup && (
        <>
          <FlightLabels
            flightGroupIndex={flightGroupIndex}
            removePlanField={removePlanField}
            flightFields={chosenSubFlightFields}
            getFieldError={getFieldError}
            flightGroup={flightGroup}
            clickableFieldProps={clickableFieldProps}
          />
          <EditListFlightContainer
            flightGroupIndex={flightGroupIndex}
            handleAddField={handleAddField}
            onAvailableFieldSelected={onAvailableFieldSelected}
            calculationMode={calculationMode}
          />
        </>
      )
      }
    </td>
  )
}

export default memo(FlightsGridLabelsContainer)
