import { message } from 'antd'
import { PostLinkedLookupsApiResponse, PostLinkedLookupsApiArg } from 'Apis/generated/linkedLookupsApi'
import { usePostLinkedLookupsMutation } from 'Apis/enhancedLinkedLookupsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseMutationCommand } from '../../../redux/types'

export const useCreateLinkedLookup = (): UseMutationCommand<Omit<PostLinkedLookupsApiArg, 'clientId'>, PostLinkedLookupsApiResponse> => {
  const { data: currentClient } = useCurrentClient()
  const [createLinkedLookup, { isLoading }] = usePostLinkedLookupsMutation()

  return {
    isLoading,
    execute: async ({ linkedLookup }) => {
      try {
        const response = await createLinkedLookup({
          clientId: currentClient.id,
          linkedLookup
        }).unwrap()

        message.success('Successfully created linked lookups')

        return response
      } catch (error) {
        const errorMsg = error?.data && (Array.isArray(error.data) ? error.data?.[0] : error.data) || 'Something went wrong'
        message.error(errorMsg)

        throw error
      }
    }
  }
}
