import React, { ReactNode } from 'react'
import { Modal, ModalProps } from 'antd'

interface IModalProps extends ModalProps {
  modalBody: ReactNode
}

export const ModalComponent = ({ modalBody, ...props }: IModalProps) => (
  <Modal {...props}>
    {modalBody}
  </Modal>
)

export default ModalComponent
