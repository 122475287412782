import React, { CSSProperties, FC, memo, useMemo } from 'react'
import { Dropdown, Menu } from 'antd'
import classNames from 'classnames'
import { IMSHierarchies } from 'mindshare.layout'
import FieldAvailableComponent from 'Components/FieldAvailableComponent'
import { getFieldId, IMediaPlanTemplateFields, getFieldColumnName } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { KeyCode } from 'Constants/enums/KeyCode'
import { IPoint } from 'Helpers/selectionHelper'
import FieldContainer from './FieldTypeSwitchContainer'
import { useFieldValue, useClickableField, IClickableFieldProps } from 'Components/MediaPlanVersion/hooks'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import { IExpandedHierarchiesFlightGroupsValues } from 'Components/Hierarchies/helpers/getExpandedHierarchiesValues'
import { IMediaPlanVersionMasteredFieldsHelperValues, IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { useFlightGroupSelection } from 'Components/MediaPlanVersion/hooks/useFlightGroupSelection'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { useDeepEqualMemoized } from 'Hooks/useDeepEqualMemoized'

interface IFlightGroupFieldActions {
  canReset: boolean
  canCopy: boolean
  canUpsert: boolean
  onReset: () => void
  onCopy: () => void
  onUpsert: () => void
}

const FlightGroupFieldActions: FC<IFlightGroupFieldActions> = ({
  children,
  canReset,
  canCopy,
  canUpsert,
  onReset,
  onCopy,
  onUpsert
}) => {
  const menu = (
    <Menu className="flightGroup__context-menu">
      <Menu.Item disabled={!canCopy} onClick={onCopy}>
        Copy
      </Menu.Item>
      <Menu.Item disabled={!canUpsert} onClick={onUpsert}>
        Paste
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item disabled={!canReset} onClick={onReset}>
        Clear Data
      </Menu.Item>
    </Menu>)

  return (
    <Dropdown
      overlay={menu}
      trigger={['contextMenu']}
    >
      {children}
    </Dropdown>
  )
}

interface IProps {
  collapse: boolean
  calculationMode: boolean
  mediaPlanField: IMediaPlanTemplateFields
  collapsedFlightGroups: number[]
  masteredListsData: IMasteredListsData
  flightGroupIndex: number
  flightGroupFieldIndex: number
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  tokenHandler: (token: string) => void
  getStickyStyle: (type: StickyColumnType, id: number) => CSSProperties
  onAvailableFieldSelected: (c: string, flightGroupIndex?: number) => void
  onSelectFlightGroups: (event: React.MouseEvent<HTMLElement, MouseEvent>, point: IPoint, flightGroupSelection: Partial<IFlightGroup>, isSelected: boolean) => void
  onResetFlightGroups: (flightGroupSelection: Partial<IFlightGroup>) => void
  onCopyFlightGroups: (flightGroupSelection: Partial<IFlightGroup>) => void
  onUpsertFlightGroups: (value: string | number, flightGroupSelection: Partial<IFlightGroup>) => void
  isCopied: boolean
  isNewlyAdded: boolean
  canUpsert: boolean
  canCopy: (flightGroupSelection: Partial<IFlightGroup>) => boolean
  onClearSelection: () => void
  onClearClipboard: () => void
  linkedLookupFieldsHelperValues: IMediaPlanVersionLinkedLookupHelperValues
  unfilteredHierarchies: IMSHierarchies
  expandedHierarchiesFlightGroupsValues: IExpandedHierarchiesFlightGroupsValues
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  flightGroupId: number
  clickableFieldProps: IClickableFieldProps
  originalIndex: number
  isLoadingHierarchies: boolean
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
}

export const FlightGroupFieldContainer = (props: IProps) => {
  const {
    flightGroupIndex,
    flightGroupFieldIndex,
    calculationMode,
    mediaPlanField,
    collapsedFlightGroups,
    masteredListsData,
    availableMediaPlanFields,
    isCopied,
    isNewlyAdded,
    canUpsert,
    canCopy,
    tokenHandler,
    onAvailableFieldSelected,
    onSelectFlightGroups,
    onResetFlightGroups,
    onCopyFlightGroups,
    onUpsertFlightGroups,
    getStickyStyle,
    onClearSelection,
    onClearClipboard,
    linkedLookupFieldsHelperValues,
    unfilteredHierarchies,
    expandedHierarchiesFlightGroupsValues,
    masteredDataHelperValues,
    flightGroupId,
    clickableFieldProps,
    originalIndex,
    isLoadingHierarchies,
    financeListFieldsData
  } = props
  const { isFieldValidForClick } = useClickableField(clickableFieldProps)
  const id = getFieldId(mediaPlanField)
  const columnName = getFieldColumnName(mediaPlanField)
  const valid = isFieldValidForClick(mediaPlanField)

  const fieldValue = useFieldValue({
    fieldLevelId: mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId,
    columnName,
    flightGroupIndex
  })

  const { isSelected, flightGroupSelection } = useFlightGroupSelection({ mediaPlanFlightGroupId: flightGroupId, mediaPlanFieldColumnName: columnName }) ?? {
    isSelected: false,
    flightGroupSelection: null
  }

  const expandedHierarchiesValues = useMemo(() => {
    return expandedHierarchiesFlightGroupsValues[originalIndex]
  }, [expandedHierarchiesFlightGroupsValues, originalIndex])

  const memoizedExpandedHierarchiesValues = useDeepEqualMemoized(expandedHierarchiesValues)

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTableCellElement>) => {
    const handlers = {
      [`ctrl+${KeyCode.C}`]: canCopy(flightGroupSelection) ? () => onCopyFlightGroups(flightGroupSelection) : () => { },
      [`ctrl+${KeyCode.V}`]: () => canUpsert && onUpsertFlightGroups(fieldValue, flightGroupSelection),
      [KeyCode.ESCAPE]: onClearSelection
    }
    const handlerKey = [(event.ctrlKey || event.metaKey) && 'ctrl', event.keyCode].filter(Boolean).join('+')
    const handler = handlers[handlerKey]

    if (handler) {
      handler()
    }
  }

  return (
    <FlightGroupFieldActions
      canReset={isSelected}
      canCopy={isSelected && canCopy(flightGroupSelection)}
      canUpsert={canUpsert}
      onReset={() => onResetFlightGroups(flightGroupSelection)}
      onCopy={() => onCopyFlightGroups(flightGroupSelection)}
      onUpsert={() => onUpsertFlightGroups(fieldValue, flightGroupSelection)}
    >
      {id !== 0 ? (
        <td
          className={classNames({
            'flightGroup__field': true,
            '-valid': valid && mediaPlanField.fieldLabel !== 'Sort Order',
            '-collapsed': collapsedFlightGroups.includes(id),
            '-calculated': mediaPlanField.templateFieldTypeId === TemplateFieldTypes.CALCULATED,
            '-aggregated': mediaPlanField.templateFieldTypeId === TemplateFieldTypes.AGGREGATED,
            '-internal': mediaPlanField.templateFieldTypeId === TemplateFieldTypes.INTERNAL,
            '-shown-empty': mediaPlanField.templateFieldTypeId === TemplateFieldTypes.INTERNAL && !mediaPlanField.isReadOnly && isNewlyAdded,
            '-selected': isSelected,
            '-copied': isCopied
          })}
          onClick={(event) => onSelectFlightGroups(event, { flightGroupIndex, flightGroupFieldIndex }, flightGroupSelection, isSelected)}
          onContextMenu={(event) => onSelectFlightGroups(event, { flightGroupIndex, flightGroupFieldIndex }, flightGroupSelection, isSelected)}
          data-testid='flight-group-field-container'
          style={getStickyStyle(StickyColumnType.flightGroup, id)}
          key={mediaPlanField.mediaPlanVersionFieldId}
          onKeyDown={handleKeyDown}
          tabIndex={-1}
        >
          <FieldContainer
            calculationMode={calculationMode}
            field={mediaPlanField}
            masteredListsData={masteredListsData}
            tokenHandler={tokenHandler}
            valid={valid}
            flightGroupIndex={flightGroupIndex}
            defaultClass='input-flight-group-value'
            collapsedFlightGroup={collapsedFlightGroups.includes(id)}
            onClearClipboard={onClearClipboard}
            linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
            unfilteredHierarchies={unfilteredHierarchies}
            expandedHierarchiesValues={memoizedExpandedHierarchiesValues}
            masteredDataHelperValues={masteredDataHelperValues}
            isLoadingHierarchies={isLoadingHierarchies}
            financeListFieldsData={financeListFieldsData}
          />
        </td>
      ) : (
        <td className='flightGroup__field'>
          <FieldAvailableComponent
            onAvailableFieldSelected={(selectedValue: string) => onAvailableFieldSelected(selectedValue)}
            availableMediaPlanFields={availableMediaPlanFields}
          />
        </td>
      )}
    </FlightGroupFieldActions>
  )
}

export default memo(FlightGroupFieldContainer)

