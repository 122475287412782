import React from 'react'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { IMSHierarchies } from 'mindshare.layout'
import CalendarComponent from '../Calendar/CalendarComponent'
import FlightLevelLabelsComponent from './FlightLevelLabelsComponent'
import FlightLevelItemComponent from './FlightLevelItemComponent'
import FlightGroupLevelHeaderComponent from '../FlightGroupLevelHeaderComponent'
import FlightGroupLevelItemComponent from './FlightGroupLevelItemComponent'
import HeaderFlightLevel from 'Components/MediaPlanVersion/HeaderFlightLevel'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from '../../constants/enums/FieldLevel'
import { CalculationFieldResult } from 'Apis/generated/mediaPlanVersionsApi'
import { CalendarView } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IExampleFlightGroupLevel {
  isTemplate: boolean
  flightErrors: CalculationFieldResult[]
  flightGroupErrors: CalculationFieldResult[]
  calculationMode: boolean
  data?: any[]
  hierarchies?: Partial<IMSHierarchies>
  calendarView: CalendarView
  startDayOfWeek: string
  planStartDate: string
  planEndDate: string
  mediaPlanFieldsFlights: IMediaPlanTemplateFields[]
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  mediaPlanFields: IMediaPlanTemplateFields[]
  availableFlightGroupFields: IMediaPlanTemplateFields[]
  availableFlightFields: IMediaPlanTemplateFields[]
  collapsedFlightGroups: number[]
  masteredListsData: IMasteredListsData
  mergeFlightSelection?: (startDate: Date, endDate: Date, mergeByWeek: boolean, flightGroupIndex: number, flightIndex: number, clientMediaPlanFieldId?: number, isSubFlight?: boolean) => void
  tokenHandler: (token: string) => void
  setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void
  setFunctionForFlights: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void
  onAvailableFieldSelected: (c: string,
    mediaPlanFields: IMediaPlanTemplateFields[],
    setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void,
    availableFields: IMediaPlanTemplateFields[]) => void
  isFieldValidForClick: (mediaPlanTemplateFields: IMediaPlanTemplateFields) => boolean
  updateDataValues?: (selectedValue: string | number) => void
  addFlightGroup?: (id: number) => void
  deleteFlightGroup?: (id: number) => void
  toggleHandler?: (data: any, index: number) => void
  setCollapsedFlightGroups?: (add: boolean, id: number) => void
  handleAddField: (fieldType: FieldLevelType) => void
  updateFlightValue?: (newValue: string, id: number, flightGroupIndex?: number, flightIndex?: number, subFlightIndex?: number) => void
  deleteFlight?: (flightGroupIndex: number, flightIndex: number, subFlightIndex: number, isSubFlight: boolean) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields, flightGroupIndex?: number) => void
}

export const TemplateExampleFlightGroupLevelComponent: React.FunctionComponent<IExampleFlightGroupLevel> = (exampleFlightGroupLevel) => {
  const {
    isTemplate,
    planStartDate,
    planEndDate,
    calendarView,
    startDayOfWeek,
    mediaPlanFields,
    tokenHandler,
    isFieldValidForClick,
    availableFlightGroupFields,
    onAvailableFieldSelected,
    setFunction,
    calculationMode,
    updateDataValues,
    hierarchies,
    collapsedFlightGroups,
    setCollapsedFlightGroups,
    mergeFlightSelection,
    availableFlightFields,
    mediaPlanFieldsFlights,
    handleAddField,
    deleteFlight,
    masteredListsData,
    setFunctionForFlights,
    flightGroupErrors,
    flightErrors,
    removePlanField
  } = exampleFlightGroupLevel

  // eslint-disable-next-line functional/immutable-data
  mediaPlanFieldsFlights.sort(mpf => mpf.clientMediaPlanField &&
    mpf.clientMediaPlanField.mediaPlanField &&
    mpf.clientMediaPlanField.mediaPlanField.fieldLevelId)

  // eslint-disable-next-line functional/immutable-data
  mediaPlanFields.sort(mpf => mpf.clientMediaPlanField &&
    mpf.clientMediaPlanField.mediaPlanField &&
    mpf.clientMediaPlanField.mediaPlanField.fieldLevelId)

  const buildFlightsHeaders = () => {
    sortFlightGroups()
    return mediaPlanFields && mediaPlanFields.map((item, i) => (
      <FlightGroupLevelHeaderComponent
        key={i}
        mediaPlanField={item}
        setCollapsedFlightGroups={setCollapsedFlightGroups}
        removePlanField={removePlanField}
      />
    ))
  }

  const sortFlightGroups = () => {
    // eslint-disable-next-line functional/immutable-data
    mediaPlanFields.sort((current, next) => current.sortOrder < next.sortOrder ? -1 : 1)
  }

  const buildFlightsMetaData = (key: number, collapse: boolean, dataFlightGroup?) => {
    sortFlightGroups()
    return mediaPlanFields.map(item => {
      const valid = isFieldValidForClick(item)
      const defaultValue = item.mediaPlanTemplateId > 0 ? item.clientMediaPlanField.mediaPlanField.defaultValue : ''
      const error = flightGroupErrors.find( e => e.fieldId === item.clientMediaPlanFieldId && e.instanceId === dataFlightGroup.mediaPlanFlightGroupId)
      return (
        <FlightGroupLevelItemComponent
          key={item.mediaPlanTemplateFieldId || item.mediaPlanVersionFieldId}
          value={defaultValue}
          mediaPlanField={item}
          mediaPlanFields={mediaPlanFields}
          tokenHandler={tokenHandler}
          availableMediaPlanFields={availableFlightGroupFields}
          onAvailableFieldSelected={onAvailableFieldSelected}
          valid={valid}
          setFunction={setFunction}
          calculationMode={calculationMode}
          updateDataValues={updateDataValues}
          hierarchies={hierarchies}
          masteredListsData={masteredListsData}
          flightGroupIndex={key}
          collapse={collapse}
          collapsedFlightGroups={collapsedFlightGroups}
          removePlanField={removePlanField}
          error={error && error.error}
          isTemplate={isTemplate}
        />
      )
    })
  }

  const flightFieldLabelsByTemplate = () => {
    const flightFields = mediaPlanFieldsFlights.map((mf) => {
      const valid = isFieldValidForClick(mf)
      return (
        <FlightLevelLabelsComponent
          mediaPlanField={mf}
          valid={valid}
          id={mf.mediaPlanVersionFieldId || mf.mediaPlanTemplateFieldId}
          key={mf.mediaPlanVersionFieldId || mf.mediaPlanTemplateFieldId}
          availableMediaPlanFields={availableFlightFields}
          onAvailableFieldSelected={onAvailableFieldSelected}
          setFunction={setFunctionForFlights}
          mediaPlanFields={mediaPlanFieldsFlights}
          flightGroupIndex={0}
          removePlanField={removePlanField}
        />
      )
    })

    return (
      <td className='flight__itemLabels'>
        {flightFields}
        <BuildEmptyCells onHandleAddField={handleAddField} view={calendarView} />
      </td>
    )
  }


  const flightFieldsByTemplate = () => {
    const flightFields = mediaPlanFieldsFlights.map((mf) => {
      const valid = isFieldValidForClick(mf)
      const defaultValue = mf.mediaPlanTemplateId > 0 ? mf.clientMediaPlanField.mediaPlanField.defaultValue : ''
      return (
        <FlightLevelItemComponent
          mediaPlanField={mf}
          flightValues={[defaultValue]}
          tokenHandler={tokenHandler}
          valid={valid}
          id={mf.mediaPlanVersionFieldId || mf.mediaPlanTemplateFieldId}
          fieldTypeId={mf.templateFieldTypeId}
          key={mf.mediaPlanVersionFieldId || mf.mediaPlanTemplateFieldId}
          availableMediaPlanFields={availableFlightFields}
          onAvailableFieldSelected={onAvailableFieldSelected}
          setFunction={setFunctionForFlights}
          mediaPlanFields={mediaPlanFieldsFlights}
          flightGroupIndex={0}
          calculationMode={calculationMode}
          hierarchies={hierarchies}
          selectedFlights={[]}
          updateDataValues={updateDataValues}
          isTemplate={isTemplate}
          mergeFlightSelection={mergeFlightSelection}
          planStartDate={planStartDate}
          planEndDate={planEndDate}
          deleteFlight={deleteFlight}
          masteredListsData={masteredListsData}
          errors={flightErrors}
          removePlanField={removePlanField}
        />
      )
    })

    return (
      <td className='flight__container'>
        {flightFields}
      </td>
    )
  }

  const BuildEmptyCells = ({ onHandleAddField, view, flightGroupIndex }: { onHandleAddField: any; view: string; flightGroupIndex?: number }) => {
    return (
      <div className={`flight__grid -readonly ${view === 'day' ? 'contains-days' : ''}`}>
        <Button icon={<EditOutlined />} type='primary' className='btn-edit-list' onClick={() => onHandleAddField(FieldLevelType.FLIGHT, flightGroupIndex)} tabIndex={-1}>
          Edit List
        </Button>
      </div>
    )
  }

  return (
    <div className='msCalendar'>
      <table className="-borderLeft">
        {
          isTemplate && (
            <>
              <thead>
                <tr>
                  {buildFlightsHeaders()}
                </tr>
                <tr>
                  <th className='flightHeader__title'>
                    &nbsp;
                  </th>
                  <th rowSpan={4} className="flightHeader__view">
                    <CalendarComponent
                      startDate={planStartDate}
                      endDate={planEndDate}
                      dayStartOfWeek={startDayOfWeek}
                      calendarView={calendarView}
                      compressedCalendarView={false}
                    />
                  </th>
                </tr>
                <HeaderFlightLevel text='Months' />
                <HeaderFlightLevel text='Week#' />
                <HeaderFlightLevel text='Week Start' />
              </thead>
              <tbody>
                <tr className="flightGroup__container">
                  {buildFlightsMetaData(0, false)}
                  {flightFieldLabelsByTemplate()}
                  {flightFieldsByTemplate()}
                </tr>
              </tbody>
            </>
          )
        }
      </table>
    </div>
  )
}

export default TemplateExampleFlightGroupLevelComponent
