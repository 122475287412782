import React, { useMemo, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import { CannonComponent } from './CannonComponent'

const keyMap: any = {
  KONAMI: { name: 'Konami code', sequence: 'up up down down left right left right b a enter' }
}

export const WrapperAnimationComponent: React.FC = () => {
  const [done, setDone] = useState(false)

  const handlers = useMemo(() => ({
    KONAMI: function triggerAnimation () {
      setDone(true)
    }
  }), [])

  return (
    <GlobalHotKeys
      keyMap={keyMap}
      handlers={handlers}
    >
      {done && ( <CannonComponent dotCount={200} setDone={setDone} />)}
    </GlobalHotKeys>
  )
}

export default WrapperAnimationComponent
