import React, { FunctionComponent } from 'react'
import { Radio, Form, FormItemProps } from 'antd'
import { IMediaPlanMetaField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { redistributionTooltipMessage } from 'Constants/enums/TooltipMessages'
import { defaultContainerHeight } from 'Components/Redistribution/constants/redistribution'

interface IProps {
  name: string
  data: IMediaPlanMetaField[]
  onChange: (value: any, name: string) => void
  value: number
  containerHeight?: number
  disabled?: boolean
  formItemProps?: FormItemProps
}

export const FormItemRadioGroup: FunctionComponent<IProps> = ({
  name,
  data,
  disabled,
  onChange,
  value,
  formItemProps,
  containerHeight = defaultContainerHeight
}) => {
  return (
    <div className="radio-group" style={{ height: containerHeight }}>
      <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: redistributionTooltipMessage.missingFields
          }
        ]}
        {...formItemProps}
      >
        <Radio.Group
          onChange={e => onChange(e.target.value, name)}
          value={value}
          className="radio-group__list"
        >
          {data.map(item =>
            <Radio
              key={item.clientMediaPlanFieldId}
              value={item.clientMediaPlanFieldId}
              disabled={disabled}
              aria-label={name}
              className="radio-group__list-item"
            >
              {item.fieldLabel}
            </Radio>
          )}
        </Radio.Group>
      </Form.Item>
    </div>
  )
}
