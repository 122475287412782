import * as actionTypes from 'Constants/actionTypes'
import * as mediaPlanService from '../../services/mediaPlanService'
import { createMediaPlanField, IMediaPlanFieldExtended } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { createMediaPlanTemplate } from 'Components/MediaPlanTemplate/constants/entities/IMediaPlanTemplate'
import { IMediaPlans } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import { ISelectedHierarchies } from 'Components/TemplateFieldSelection/constants/entities/ISelectedHierarchies'
import { MediaPlanTemplate } from 'Apis/generated/templatesApi'
import { IValidationResult } from 'Components/TemplateFieldSelection/constants/entities/IValidationResult'

interface ITemplatesDataAction {
  type: actionTypes.SET_TEMPLATE_DATA_FIELDS
  payload: IMediaPlanFieldExtended[]
}

interface ITemplateMetaData {
  type: actionTypes.TEMPLATE_META_DATA
  payload: any
}
interface ITemplateFieldType {
  type: actionTypes.TEMPLATE_FIELD_TYPE
  payload: any
}

interface ITemplateAvailabilityType {
  type: actionTypes.TEMPLATE_AVAILABILITY_TYPE
  payload: any
}

interface ISetTemplateErrors {
  type: actionTypes.SET_TEMPLATE_ERRORS
  payload: IValidationResult[]
}

export type ITemplatesAction =
  | ITemplatesDataAction
  | ITemplateMetaData
  | ITemplateFieldType
  | ITemplateAvailabilityType
  | ISetTemplateErrors

export const getMediaPlanFields = (clientId: number) => async dispatch => {
  const payload = await mediaPlanService.get(`clientMediaPlanFields?clientId=${clientId}`)
  const asMediaPlanFields: IMediaPlanFieldExtended[] = payload.map((f) =>
    createMediaPlanField({
      mediaPlanFieldLevelId: f.mediaPlanFieldLevelId,
      fieldLabel: f.fieldLabel,
      fieldDataTypeId: f.fieldDataTypeId,
      columnName: f.columnName,
      fieldLevelId: f.fieldLevelId,
      fieldDescription: f.fieldDescription,
      isMandatory: f.isMandatory,
      sortOrder: f.sortOrder,
      fieldTags: f.fieldTags
    })
  )

  dispatch({ type: actionTypes.SET_TEMPLATE_DATA_FIELDS, payload: asMediaPlanFields })
}

export const getTemplateMetaData = clientId => async dispatch => {
  const payload = await mediaPlanService.get(`templateMetaData?clientId=${clientId}`)
  dispatch({ type: actionTypes.TEMPLATE_META_DATA, payload })
}

export const getTemplateFieldTypes = clientId => async dispatch => {
  const payload = await mediaPlanService.get(`templateFieldTypes?clientId=${clientId}`)
  dispatch({ type: actionTypes.TEMPLATE_FIELD_TYPE, payload })
}

export const getTemplateFieldAvailabilities = clientId => async dispatch => {
  const payload = await mediaPlanService.get(`templateFieldAvailabilities?clientId=${clientId}`)
  dispatch({ type: actionTypes.TEMPLATE_AVAILABILITY_TYPE, payload })
}

export const saveMediaPlanTemplates = async (
  data: IMediaPlanFieldRow[],
  templateName: string,
  clientId: number,
  leftAlignNumbersInExcelExport: boolean,
  selectedHierarchies: ISelectedHierarchies,
  hideSortOrderInExcelExport: boolean
): Promise<number> => {
  const mediaPlanTemplate = createMediaPlanTemplate({
    templateName,
    mediaPlanTemplateFields: data,
    brandHierarchyId: selectedHierarchies.brand,
    businessHierarchyId: selectedHierarchies.business,
    geographyHierarchyId: selectedHierarchies.geography,
    mediaHierarchyId: selectedHierarchies.media,
    leftAlignNumbersInExcelExport,
    hideSortOrderInExcelExport
  })
  const mediaPlanTemplateId = await mediaPlanService.postJson(`mediaPlanTemplates?clientId=${clientId}`, mediaPlanTemplate)
  return mediaPlanTemplateId
}

export const updateMediaPlanTemplatesByItemRow = async (
  data: IMediaPlanFieldRow[],
  templateName: string,
  mediaPlanTemplateId: number,
  clientId: number,
  leftAlignNumbersInExcelExport: boolean,
  selectedHierarchies: ISelectedHierarchies,
  hideSortOrderInExcelExport: boolean
): Promise<void> => {
  const mediaPlanTemplate = createMediaPlanTemplate({
    templateName,
    mediaPlanTemplateId,
    mediaPlanTemplateFields: data,
    brandHierarchyId: selectedHierarchies.brand,
    businessHierarchyId: selectedHierarchies.business,
    geographyHierarchyId: selectedHierarchies.geography,
    mediaHierarchyId: selectedHierarchies.media,
    leftAlignNumbersInExcelExport,
    hideSortOrderInExcelExport
  })
  await mediaPlanService.putJson(`mediaPlanTemplates/${mediaPlanTemplateId}?clientId=${clientId}`, mediaPlanTemplate)
}

export const updateMediaPlanTemplates = async (mediaPlanTemplate: MediaPlanTemplate, clientId: number): Promise<void> => {
  await mediaPlanService.putJson(`mediaPlanTemplates/${mediaPlanTemplate.mediaPlanTemplateId}?clientId=${clientId}`, mediaPlanTemplate)
}

export const createMediaPlan = async (mediaPlan: IMediaPlans, clientId: number): Promise<number> => {
  const postMediaPlanService = await mediaPlanService.postJson(`mediaplans?clientId=${clientId}`, mediaPlan)
  return postMediaPlanService
}
