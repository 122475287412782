import React, { FunctionComponent, memo, useMemo } from 'react'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import FlightLabel from './FlightLabel'
import { IClickableFieldProps, useClickableField } from './hooks'

interface IProps {
  flightGroup: IFlightGroup
  flightGroupIndex: number
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  flightFields: IMediaPlanTemplateFields[]
  getFieldError: (clientMediaPlanFieldId: number, flightGroup: IFlightGroup) => string
  clickableFieldProps: IClickableFieldProps
  isSubtotalGroup?: boolean
}

export const FlightLabels: FunctionComponent<IProps> = (props: IProps) => {
  const {
    flightGroupIndex,
    removePlanField,
    flightFields,
    getFieldError,
    flightGroup,
    clickableFieldProps,
    isSubtotalGroup
  } = props

  const { isFieldValidForClick } = useClickableField(clickableFieldProps)

  const sortedFlightFields = useMemo(
    () => flightFields && [...flightFields]
      .filter(c => c.mediaPlanVersionFieldId !== 0)
      .sort((a, b) => a.sortOrder - b.sortOrder),
    [flightFields]
  )

  return (
    <>
      {
        sortedFlightFields.map((fieldFlight) => {
          const valid = isFieldValidForClick(fieldFlight)
          const error = getFieldError(fieldFlight.clientMediaPlanFieldId, flightGroup)

          return (
            <FlightLabel
              key={`${fieldFlight.mediaPlanVersionFieldId}`}
              valid={valid}
              flightGroupIndex={flightGroupIndex}
              mediaPlanField={fieldFlight}
              removePlanField={removePlanField}
              error={error}
              isSubtotalGroup={isSubtotalGroup}
            />
          )
        })
      }
    </>
  )
}

export default memo(FlightLabels)
