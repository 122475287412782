
import { useMemo } from 'react'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useGetClientSettingsQuery } from 'Apis/enhancedClientSettingsApi'
import { UseQueryCommand } from '../../../redux/types'
import { IClientSettingExtended } from 'Components/Client/constants/entities/IClientSetting'

const initialSettings: IClientSettingExtended = {
  isTemplateAliasingEnabled: false,
  isBrandRequired: false,
  isBusinessRequired: false,
  isGeographyRequired: false,
  isMediaRequired: false,
  planningYear: null,
  importPlanningStageId: null,
  availablePlanYears: null,
  planningStages: [],
  preserveApprovedPlans: false
}

export const useClientSettings = (): UseQueryCommand<IClientSettingExtended> => {
  const { data: currentClient } = useCurrentClient()

  const { data = initialSettings, isLoading } = useGetClientSettingsQuery(
    { clientId: currentClient.id },
    { skip: !currentClient.id }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
