import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    hierarchies: build.query<HierarchiesApiResponse, HierarchiesApiArg>({
      query: (queryArg) => ({
        url: `/hierarchies`,
        params: {
          clientId: queryArg.clientId,
          clientBrandHierarchyId: queryArg.clientBrandHierarchyId,
          clientBusinessHierarchyId: queryArg.clientBusinessHierarchyId,
          clientGeographyHierarchyId: queryArg.clientGeographyHierarchyId,
          clientMediaHierarchyId: queryArg.clientMediaHierarchyId,
          includeDeleted: queryArg.includeDeleted,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type HierarchiesApiResponse =
  /** status 200 Returns hierarchies for client */ Hierarchies;
export type HierarchiesApiArg = {
  clientId: number;
  clientBrandHierarchyId?: number;
  clientBusinessHierarchyId?: number;
  clientGeographyHierarchyId?: number;
  clientMediaHierarchyId?: number;
  includeDeleted?: boolean;
};
export type ClientBrandHierarchy = {
  clientBrandHierarchyId?: number;
  clientId?: number;
  clientStatusId?: number;
  clientBrandUniqueIdentifier?: string | null;
  brandHierarchyParentId?: number | null;
  brandLevelNumber?: number;
  brandHierarchyValue?: string | null;
  brandAbbreviation?: string | null;
  deletedDate?: string | null;
  rootId?: number;
  branchLeft?: number;
  branchRight?: number;
  children?: ClientBrandHierarchy[] | null;
  name?: string | null;
  id?: number;
  parentId?: number | null;
  levelNumber?: number;
  abbreviation?: string | null;
  uniqueIdentifier?: string | null;
};
export type ClientBusinessHierarchy = {
  clientBusinessHierarchyId?: number;
  clientId?: number;
  clientStatusId?: number;
  clientBusinessUniqueIdentifier?: string | null;
  businessHierarchyParentId?: number | null;
  businessLevelNumber?: number;
  businessHierarchyValue?: string | null;
  businessAbbreviation?: string | null;
  deletedDate?: string | null;
  rootId?: number;
  branchLeft?: number;
  branchRight?: number;
  children?: ClientBusinessHierarchy[] | null;
  name?: string | null;
  id?: number;
  parentId?: number | null;
  levelNumber?: number;
  abbreviation?: string | null;
  uniqueIdentifier?: string | null;
};
export type ClientGeographyHierarchy = {
  clientGeographyHierarchyId?: number;
  clientId?: number;
  clientStatusId?: number;
  clientGeographyUniqueIdentifier?: string | null;
  geographyHierarchyParentId?: number | null;
  mindshareGeographyHierarchyId?: number | null;
  geographyLevelNumber?: number;
  geographyHierarchyValue?: string | null;
  geographyAbbreviation?: string | null;
  rootId?: number;
  branchLeft?: number;
  branchRight?: number;
  deletedDate?: string | null;
  children?: ClientGeographyHierarchy[] | null;
  name?: string | null;
  id?: number;
  parentId?: number | null;
  levelNumber?: number;
  abbreviation?: string | null;
  uniqueIdentifier?: string | null;
};
export type ClientMediaHierarchy = {
  clientMediaHierarchyId?: number;
  clientId?: number;
  clientStatusId?: number;
  clientMediaUniqueIdentifier?: string | null;
  mediaHierarchyParentId?: number | null;
  mindshareMediaHierarchyId?: number | null;
  mediaLevelNumber?: number;
  mediaHierarchyValue?: string | null;
  mediaAbbreviation?: string | null;
  rootId?: number;
  branchLeft?: number;
  branchRight?: number;
  deletedDate?: string | null;
  children?: ClientMediaHierarchy[] | null;
  name?: string | null;
  id?: number;
  parentId?: number | null;
  levelNumber?: number;
  abbreviation?: string | null;
  uniqueIdentifier?: string | null;
};
export type HierarchyLevel = {
  hierarchyLevelId?: number;
  hierarchyLevelNumber?: number;
  hierarchyLevelName?: string | null;
};
export type Hierarchies = {
  clientBrandHierarchies?: ClientBrandHierarchy[] | null;
  clientBusinessHierarchies?: ClientBusinessHierarchy[] | null;
  clientGeographyHierarchies?: ClientGeographyHierarchy[] | null;
  clientMediaHierarchies?: ClientMediaHierarchy[] | null;
  clientBrandHierarchyLevels?: HierarchyLevel[] | null;
  clientBusinessHierarchyLevels?: HierarchyLevel[] | null;
  clientGeographyHierarchyLevels?: HierarchyLevel[] | null;
  clientMediaHierarchyLevels?: HierarchyLevel[] | null;
};
export const { useHierarchiesQuery, useLazyHierarchiesQuery } = injectedRtkApi;
