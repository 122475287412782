import React, { ChangeEvent, useMemo } from 'react'
import { Button, Col, Row, Select, Space, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { MSLayoutContentRow, LoadingComponent, MSTabPane, MSTabs, navigate, MSHierarchyDropdown } from 'mindshare.layout'

import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import CheckboxComponent from 'Components/CheckboxComponent'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useHierarchies } from 'Hooks/useHierarchies'
import { oneOf } from 'Helpers/conditionsHelper'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { initialFilterField } from 'Components/CustomExport/constants/entities/IFilterField'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { CustomExportFilter } from 'Apis/generated/customExportsApi'

import { appPaths } from '../../../providers/AppRoutes'

import {
  CustomExportFilterFields,
  CustomExportDisplayFields,
  CustomExportSortByFields,
  CustomExportNameInput
} from '../components'
import {
  useCustomExportModel,
  useLatestFlags,
  useFieldOperators,
  useCurrentCustomExport,
  useCustomExportFields,
  useDisplayTypes,
  useCurrencyDisplays,
  useCreateCustomExport,
  useUpdateCustomExport
} from '../hooks'
import {
  createCustomExportDto,
  createCustomExportFromResponse
} from '../dtos'
import { useMasteredListFieldsData } from 'Hooks/useMasteredListFieldsData'
import { UploadFile } from 'antd/lib/upload/interface'

interface IProps {
  customExportId?: number
}

const CustomExportFieldContainer: React.FC<IProps> = ({ customExportId }): React.ReactElement => {
  const { data: currentClient } = useCurrentClient()
  const { data: hierarchies } = useHierarchies(currentClient?.id)
  const masteredListsData = useMasteredListFieldsData(currentClient?.id)
  const { isMasteredListsDataLoading } = masteredListsData

  const { data: customExportFields, isLoading: areCustomExportFieldsLoading } = useCustomExportFields()
  const { data: operators = [], isLoading: areOperatorsLoading } = useFieldOperators()
  const { data: latestFlags = [], isLoading: areLatestFlagsLoading } = useLatestFlags()
  const { data: displayTypes = [] } = useDisplayTypes()
  const { data: currency = [] } = useCurrencyDisplays()

  const { data: currentCustomExport } = useCurrentCustomExport({ id: customExportId })

  const { execute: createCustomExport } = useCreateCustomExport()
  const { execute: updateCustomExport } = useUpdateCustomExport()

  const isEditMode = Boolean(customExportId)
  const customExportModel = useCustomExportModel({
    getInitialValues: () => createCustomExportFromResponse(currentCustomExport),
    skip: !currentCustomExport
  })

  const handleSaveCustomExport = async () => {
    const customExportDto = createCustomExportDto(customExportModel.values)

    try {
      if (isEditMode) {
        await updateCustomExport({
          id: customExportModel.values.customExportId,
          customExport: customExportDto,
          body: {
            file: customExportModel.template
          }
        })
      } else {
        await createCustomExport({
          customExport: customExportDto,
          body: {
            file: customExportModel.template
          }
        })
      }
      navigate(appPaths.customExports)
    } catch (error) {
      /* noop */
    }
  }

  const handleShouldDistinctChange = (e: ChangeEvent<HTMLInputElement>) => {
    customExportModel.changeShouldDistinct(e.target.checked)
  }

  const handleFullHierarchiesChange = (e: ChangeEvent<HTMLInputElement>) => {
    customExportModel.changeFullHierarchies(e.target.checked)
  }

  const handleFileUpload = (file: File) => {
    customExportModel.selectTemplate(file)

    // this prevents instant uploading
    return false
  }

  const handleFileRemove = () => {
    customExportModel.selectTemplate(null)
  }

  const fileList = useMemo(() => {
    if (customExportModel.template) {
      return [customExportModel.template as unknown as UploadFile]
    }
    return undefined
  }, [customExportModel.template])

  return oneOf(
    !hierarchies,
    areOperatorsLoading,
    areCustomExportFieldsLoading,
    areLatestFlagsLoading,
    isMasteredListsDataLoading,
    (isEditMode && !currentCustomExport)
  ) ? (
      <LoadingComponent
        appDataSuccess={!!currentClient.id}
        agencyLoadingGif={ currentClient.agency?.agencyLoadingGifLocation}
      />
    ) : (
      <div>
        <TitleAndButtonsComponent
          title={!isEditMode ? 'Create New Export' : `Update ${currentCustomExport.customExportName}`}
          buttons={[{
            buttonClick: handleSaveCustomExport,
            buttonText: 'Save'
          }]}
        />

        <MSLayoutContentRow extraClass='custom-export-create-title'>
          <Row gutter={8}>
            <Col span={6}>
              <CustomExportNameInput
                value={customExportModel.values.customExportName}
                onChange={customExportModel.changeName}
              />
            </Col>
            <Col span={6}>
              <MSHierarchyDropdown
                label='Geography'
                id='clientGeographyHierarchyId'
                className='custom-export-geography-search-filter'
                treeData={hierarchies.clientGeographyHierarchies}
                idColumn='clientGeographyHierarchyId'
                valueColumn='geographyHierarchyValue'
                setFieldValue={(field, value) => {
                  const selectedFilterField = customExportFields.find((f: IClientMediaPlanField) =>
                    f.mediaPlanField?.fieldDataTypeId === FieldDataType.GEOGRAPHY_HIERARCHY
                  ) as IClientMediaPlanField
                  const idx = customExportModel.values.customExportFilters.findIndex(
                    (item: CustomExportFilter) =>
                      selectedFilterField
                        .clientMediaPlanFieldId ===
                          item.clientMediaPlanFieldId
                  )

                  customExportModel.changeGeographyHierarchy(value)

                  if (idx !== -1) {
                    customExportModel.updateField('customExportFilters', idx, {
                      customExportFilterDefaultValue: initialFilterField.customExportFilterDefaultValue
                    })
                  }
                }}
                selectedValue={customExportModel.values.geographyHierarchyId}
                placeholder='Select Geography'
                data-testid='custom-export-geography-search-filter'
                hierarchyName='geography'
              />
            </Col>
            <Col span={12} />
          </Row>
        </MSLayoutContentRow>

        <MSLayoutContentRow>
          <MSTabs destroyInactiveTabPane>
            <MSTabPane tab="Filters" key={1}>
              <CustomExportFilterFields
                customExport={customExportModel}
                customExportFields={customExportFields}
                latestFlags={latestFlags}
                operators={operators}
                hierarchies={hierarchies}
                masteredListsData={masteredListsData}
              />
            </MSTabPane>
            <MSTabPane tab="Display" key={2}>
              <Space direction="vertical" size="middle" className="display-tab__content">
                <div className="display-tab__content-row">
                  <CheckboxComponent
                    label="Distinct"
                    checked={customExportModel.values.customExportDistinct}
                    Toggle={handleShouldDistinctChange}
                  />
                  <Space className="display-tab__content--currency">
                    <label htmlFor="select-currency">Currency</label>
                    <Select
                      id="select-currency"
                      onChange={customExportModel.changeDisplayCurrency}
                      placeholder='Select Currency'
                      value={customExportModel.values.customExportCurrencyDisplayId}
                      data-testid="select-currency"
                      style={{ width: 150 }}
                    >
                      {currency.map((el) => (
                        <Select.Option
                          key={el.customExportCurrencyDisplayId}
                          value={el.customExportCurrencyDisplayId}
                        >
                          {el.customExportCurrencyDisplayName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Space>
                  <CheckboxComponent
                    label="Full Hierarchies"
                    checked={customExportModel.values.customExportFullHierarchies}
                    Toggle={handleFullHierarchiesChange}
                  />
                </div>
                <Row gutter={8}>
                  <Col span={24}>
                    <Space>
                      <label htmlFor="display-type" className="label">
                      Display Type:
                      </label>
                      <Select
                        id="select-display-type"
                        style={{ width: 200 }}
                        onChange={customExportModel.changeDisplayType}
                        placeholder='Select Display Type'
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        value={customExportModel.values.customExportDisplayTypeId}
                        data-testid='display-type'
                      >
                        {displayTypes.map((el) =>
                          <Select.Option
                            key={el.customExportDisplayTypeId}
                            value={el.customExportDisplayTypeId}>
                            {el.customExportDisplayTypeName}
                          </Select.Option>
                        )}
                      </Select>
                    </Space>
                  </Col>
                </Row>
                {
                  customExportModel.withTemplate && (
                    <Row gutter={8}>
                      <Col span={24}>
                        <label>
                          <Space>
                            <span>
                            Template upload:
                            </span>
                            <Upload
                              accept=".xls,.xlsx"
                              name="file"
                              beforeUpload={handleFileUpload}
                              onRemove={handleFileRemove}
                              data-testid="file-upload"
                              fileList={fileList}
                            >
                              <Button icon={<UploadOutlined/>}>Upload</Button>
                            </Upload>
                          </Space>
                        </label>
                        {customExportModel.values.customExportTemplateLocationUrl && (
                          <span className="indication-label">
                            (Already uploaded)
                          </span>
                        )}
                      </Col>
                    </Row>
                  )
                }
                <Row gutter={8}>
                  <Col span={8}>
                    <CustomExportDisplayFields
                      customExport={customExportModel}
                      customExportFields={customExportFields}
                    />
                  </Col>
                  <Col span={16}>
                    <CustomExportSortByFields
                      customExport={customExportModel}
                      customExportFields={customExportFields}
                    />
                  </Col>
                </Row>
              </Space>
            </MSTabPane>
          </MSTabs>
        </MSLayoutContentRow>
      </div>
    )
}

export default CustomExportFieldContainer
