import React, { useState, FunctionComponent } from 'react'
import { Input, Select, Button } from 'antd'
import { useFieldTags } from 'Hooks/useFieldTags'
import { useFieldLevels } from 'Hooks/useFieldLevels'
import { useFieldDataTypes } from 'Hooks/useFieldDataTypes'

const { Search } = Input
const { Option } = Select

export interface IClientDataFilterProps {
  handleFilter: (textFilter: string, dataType: number[], level: number[], tags: number[]) => void
}

export const ClientDataFilter: FunctionComponent<IClientDataFilterProps> = ({ handleFilter }) => {
  const [textFilter, setTextFilter] = useState<string>('')
  const [dataTypeId, setDataTypeId] = useState<number[]>([])
  const [level, setLevel] = useState<number[]>([])
  const [tags, setTags] = useState<number[]>([])
  const { data: fieldTags } = useFieldTags()
  const { data: fieldLevels } = useFieldLevels()
  const { data: dataTypes } = useFieldDataTypes()

  return (
    <div className='ms-heading-filter client-data-filter'>
      <div className='ms-heading-filter__inner'>
        <div className='ms-filter'>
          <div className='ms-filter-column ms-filter-column__quick-filter'>
            <Search
              className='ms-search'
              placeholder='Quick Filter'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTextFilter(e.currentTarget.value.toLocaleLowerCase())
              }}
            />
          </div>
          <div className='ms-filter-column ms-filter-column__select'>
            <Select
              className='ms-select'
              onChange={(value: number[]) => setDataTypeId(value)}
              mode='multiple'
              placeholder='Data Type'
              allowClear={true}
              showSearch={true}
              optionFilterProp='children'
            >
              {dataTypes.map((el) => (
                <Option key={el.fieldDataTypeId} value={el.fieldDataTypeId}>
                  {el.dataTypeName}
                </Option>
              ))}
            </Select>
          </div>
          <div className='ms-filter-column ms-filter-column__select'>
            <Select
              className='ms-select'
              onChange={(value: number[]) => setLevel(value)}
              mode='multiple'
              placeholder='Level'
              allowClear={true}
              showSearch={true}
              optionFilterProp='children'
            >
              {fieldLevels.map((el) =>
                <Option key={el.fieldLevelId} value={el.fieldLevelId}>{el.fieldLevelName} </Option>
              )}
            </Select>
          </div>
          <div className='ms-filter-column ms-filter-column__select'>
            <Select
              className='ms-select'
              onChange={(value: number[]) => setTags(value)}
              mode='multiple'
              placeholder='Tags'
              allowClear={true}
              showSearch={true}
              optionFilterProp='children'
            >
              {fieldTags.map((el) =>
                <Option key={el.fieldTagId} value={el.fieldTagId}>{el.fieldTagName}</Option>
              )}
            </Select>
          </div>
          <div className='ms-filter-column ms-filter-column__search-button'>
            <Button
              type='primary'
              onClick={() => handleFilter(textFilter, dataTypeId, level, tags)}
            >
              Search
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientDataFilter
