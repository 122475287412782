import { useMemo } from 'react'
import { IRedistributionFormValues } from 'Components/Redistribution/constants/entities/IRedistribution'
import { Redistribution } from 'Apis/generated/redistributionsApi'

const redistributionInitialValues: IRedistributionFormValues = {
  redistributionName: '',
  fieldToRedistributeId: null,
  fieldToRedistributeById: null,
  redistributeByValues: []
}

const getInitialValuesFromCurrentRedistribution = values => ({
  redistributionName: values.redistributionName,
  fieldToRedistributeId: values.redistributeClientMediaPlanFieldId,
  fieldToRedistributeById: values.redistributeByClientMediaPlanFieldId,
  redistributeByValues: values.redistributeByValues
})

export const useRedistributionInitialValues = (redistribution: Redistribution | undefined) =>
  useMemo(
    () =>
      redistribution
        ? getInitialValuesFromCurrentRedistribution(redistribution)
        : redistributionInitialValues,
    [redistribution]
  )
