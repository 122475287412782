import React, { FC, memo, useMemo } from 'react'
import classNames from 'classnames'
import { Select, Tooltip } from 'antd'
import { IMediaPlanFieldContainerProps } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { Agency } from 'Apis/generated/buyingAgenciesApi'
import { ClientMediaPartner } from 'Apis/generated/clientMediaPartnersApi'
import { ClientCampaign } from 'Apis/generated/clientCampaignsApi'
import { LinkedLookup, LinkedLookupValue } from 'Apis/generated/linkedLookupsApi'
import { IMasteredListsData, MasteredListConfig } from 'Hooks/useMasteredListFieldsData'
import { renderMasteredListOptionsByAvailability } from 'Components/TemplateFieldSelection/TemplatePlanFieldValueMasteredListSelectComponent'

type TMasteredListDataSourceItem = ClientMediaPartner | ClientCampaign | Agency

interface IProps extends IMediaPlanFieldContainerProps {
  masteredListsData: IMasteredListsData
  mediaPlanFieldDataType: number
  onChange: (fieldValue: string | number) => void
  value: ClientMediaPartner['clientMediaPartnerId'] | ClientCampaign['clientCampaignId'] | Agency['agencyId']
  collapsedFlightGroup?: boolean
  availableLinkedLookupOptions?: LinkedLookupValue[]
  isFilteredLookupValuesVisible?: boolean
  errorMessage?: string
  isEnforcedLink?: boolean
  isLinkedFieldDisabled?: boolean
  linkedLookupConnection?: LinkedLookup
}

export const MasteredListSelectorContainer: FC<IProps> = ({
  masteredListsData,
  mediaPlanFieldDataType,
  value,
  onChange,
  onClick,
  className,
  error,
  disabled,
  mediaPlanField,
  collapsedFlightGroup,
  isFilteredLookupValuesVisible,
  errorMessage,
  isEnforcedLink,
  isLinkedFieldDisabled,
  linkedLookupConnection,
  availableLinkedLookupOptions = []
}) => {
  const { dataSourceKey, idKey, displayKey } = MasteredListConfig[mediaPlanFieldDataType] || {}
  const dataSource = masteredListsData[dataSourceKey] as TMasteredListDataSourceItem[]

  const selectedEntity = useMemo(
    () => dataSource?.find(c => c[idKey] === value),
    [dataSource, idKey, value]
  )

  const notSelectedValues = dataSource?.filter((item) => !linkedLookupConnection?.linkedLookupValues?.some((v) => item[idKey] === v.dependentValueId))
  const filteredValues = dataSource
    ?.filter(item =>
      availableLinkedLookupOptions?.some(option => item[idKey] === option.dependentValueId)
    )
  const allValues = [...filteredValues, ...notSelectedValues]

  const validOptions = allValues.map(option => ({ value: option[idKey], label: option[displayKey] }))
  const invalidOptions = dataSource
    ?.filter(
      item =>
        !availableLinkedLookupOptions?.some(
          option => item[idKey] === option.dependentValueId
        ) && !notSelectedValues.some((v) => item[idKey] === v[idKey])
    )
    .map(option => ({
      value: option[idKey],
      label: option[displayKey],
      optionProps: {
        disabled: isEnforcedLink,
        className: 'not-valid'
      }
    }))

  const options = [
    {
      label: isEnforcedLink ? 'Valid' : 'Recommended',
      options: validOptions
    },
    {
      label: isEnforcedLink ? 'Not valid' : 'Not recommended',
      options: invalidOptions
    }
  ]
  const isDisplayingLookupOptions =
    isFilteredLookupValuesVisible &&
    (
      !!validOptions.length &&
      !!invalidOptions.length ||
      !!invalidOptions.length
    )

  const tooltipTitle = useMemo(() => {
    if (error) {
      return error
    }
    if (isLinkedFieldDisabled) {
      return errorMessage
    }
    if (isDisplayingLookupOptions) {
      return ''
    }
    return selectedEntity?.[displayKey]
  }, [error, errorMessage, isDisplayingLookupOptions, isLinkedFieldDisabled, selectedEntity, displayKey])

  return (
    <Tooltip
      key={value}
      title={tooltipTitle}
    >
      {!collapsedFlightGroup ? (
        <Select
          key={value}
          className={classNames('input-item-select', className)}
          placeholder={error || 'Select Value'}
          onChange={onChange}
          defaultValue={value}
          onClick={onClick}
          optionFilterProp='children'
          allowClear
          disabled={disabled || isLinkedFieldDisabled}
          showSearch
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ textAlign: 'left' }}
          data-testid={mediaPlanField.fieldLabel}
        >
          {isDisplayingLookupOptions
            ? options.map((f, index) => (
              <Select.OptGroup key={index} label={f.label}>
                {renderMasteredListOptionsByAvailability(f)}
              </Select.OptGroup>
            ))
            : dataSource?.map(item => (
              <Select.Option
                key={item[idKey]}
                value={item[idKey]}
              >
                {item[displayKey]}
              </Select.Option>
            ))
          }
        </Select>
      ) : (
        <div className='-value' data-testid='collapse-value'>{selectedEntity?.[displayKey]}</div>
      )}
    </Tooltip>
  )
}

export default memo(MasteredListSelectorContainer)
