import React, { FunctionComponent, memo, useCallback, useMemo } from 'react'
import { useMindshareSelector } from 'mindshare.customhooks'
import { IMediaPlanTemplateFields, isFieldAllowForCalculation, isAggregatedOrCalculated } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { message } from 'antd'
import { setSelectedCalculatedField, updateMediaPlanFieldsRevised } from 'Actions/mediaPlansActions'
import CalculationHeaderComponent from 'Components/MediaPlanVersion/CalculationHeaderComponent'
import { IMediaPlanVersion } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { RootState } from '../../redux/reducers'
import { MediaPlanTemplate } from 'Apis/generated/templatesApi'
import { useAppDispatch } from '../../store'
import { useHierarchies } from 'Hooks/useHierarchies'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useFieldLevels } from 'Hooks/useFieldLevels'
import { useFunctions } from 'Hooks/useFunctions'
import { useClientSettings } from 'Components/Client/hooks/useClientSettings'

const parseErrors = (errors: string[]) => `${errors && errors.map(e => e)}`

interface ICalculationHeader {
  planName?: string
  planVersion?: number
  calculationMode: boolean
  clientId: number
  displayFlightDates: boolean
  enableToggle: boolean
  expandHierarchies: boolean
  hasUnsavedChanges: boolean
  updatingFromTemplate: boolean
  setUpdatePlanModalVisible: (arg: boolean) => void
  setCalculationMode: React.Dispatch<React.SetStateAction<boolean>>
}

export const CalculationHeaderRevisedContainer: FunctionComponent<ICalculationHeader> = ({
  planName,
  planVersion,
  calculationMode,
  clientId,
  displayFlightDates,
  enableToggle,
  expandHierarchies,
  hasUnsavedChanges,
  updatingFromTemplate,
  setUpdatePlanModalVisible,
  setCalculationMode
}) => {
  const dispatch = useAppDispatch()
  const currentMediaPlanVersion = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion) as IMediaPlanVersion
  const selectedCalculatedField = useMindshareSelector((state: RootState) => state.mediaPlans.selectedCalculatedField) as IMediaPlanTemplateFields
  const calculatedAndAggregatedFields = currentMediaPlanVersion.mediaPlanVersionFields.filter(c => isFieldAllowForCalculation(c)).filter(c => isAggregatedOrCalculated(c))
  const currentMediaPlan = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlan)
  const planningStageId = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.parseData?.planningStageId || '')
  const mediaPlanTemplate = useMindshareSelector((state: RootState) => state.mediaPlanTemplates.currentMediaPlanTemplate) as MediaPlanTemplate
  const { data: currentClient } = useCurrentClient()
  const { data: hierarchies } = useHierarchies(currentClient?.id)
  const { data: fieldLevels } = useFieldLevels()
  const { data: clientSettings } = useClientSettings()
  const { data: calculationFunctions } = useFunctions()

  const headerTitle = (planName || 'Unnamed Plan') + (planVersion ? ` Version ${planVersion}` : '')

  const fieldCalculatedHandler = useCallback((c: string) =>
    dispatch(setSelectedCalculatedField(JSON.parse(c) as IMediaPlanTemplateFields))
  , [dispatch])

  const expressionHandler = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) =>
    dispatch(setSelectedCalculatedField({ ...selectedCalculatedField, calculation: e.target.value }))
  , [dispatch, selectedCalculatedField])

  const onFunctionSelected = useCallback((functionId: string) =>
    dispatch(setSelectedCalculatedField({ ...selectedCalculatedField, functionId: Number(functionId) }))
  , [dispatch, selectedCalculatedField])

  const runCalculation = useCallback(async (currentClientId: number) => {
    const result = await dispatch(updateMediaPlanFieldsRevised(currentMediaPlanVersion.parseData.mediaPlanVersionId, currentClientId, selectedCalculatedField))
    return result
  }
  , [dispatch, currentMediaPlanVersion.parseData.mediaPlanVersionId, selectedCalculatedField])

  const handleCalculation = useCallback(async () => {
    message.loading('Running calculation')
    const result = await runCalculation(clientId)
    message.destroy()
    if (result.topLevelErrors.length > 0) {
      const errors = result.topLevelErrors.length > 0 ? result.topLevelErrors : result.fieldResults.map(c => c.error)
      message.error({ content: parseErrors(errors) })
    } else {
      message.success('Calculation set successfully')
    }
  }, [clientId, runCalculation])

  const mediaPlanTemplateLink = useMemo(() => ({
    name: mediaPlanTemplate?.templateName,
    path: mediaPlanTemplate && `/template-edit/${mediaPlanTemplate.mediaPlanTemplateId}/?clientId=${clientId}`
  }), [clientId, mediaPlanTemplate])

  return (
    <CalculationHeaderComponent
      calculationFunctions={calculationFunctions}
      calculationMode={calculationMode}
      clientId={clientId}
      displayFlightDates={displayFlightDates}
      enableToggle={enableToggle}
      expandHierarchies={expandHierarchies}
      expressionHandler={expressionHandler}
      fieldCalculatedHandler={fieldCalculatedHandler}
      fieldLevels={fieldLevels}
      handleCalculation={handleCalculation}
      hasUnsavedChanges={hasUnsavedChanges}
      headerTitle={headerTitle}
      mediaPlanTemplateFields={calculatedAndAggregatedFields}
      onFunctionSelected={onFunctionSelected}
      setUpdatePlanModalVisible={setUpdatePlanModalVisible}
      selectedCalculatedMediaField={selectedCalculatedField}
      setCalculationMode={setCalculationMode}
      updatingFromTemplate={updatingFromTemplate}
      hierarchies={hierarchies}
      mediaPlan={currentMediaPlan}
      mediaPlanTemplateLink={mediaPlanTemplateLink}
      clientPlanningStages={clientSettings.planningStages}
      planningStageId={planningStageId}
      preserveApprovedPlans={clientSettings.preserveApprovedPlans}
      mediaPlanVersion={currentMediaPlanVersion}
    />
  )
}

export default memo(CalculationHeaderRevisedContainer)
