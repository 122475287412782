import React, { useEffect, useState } from 'react'
import FrontSheetCalendarComponent from '../FrontSheetCalendar/FrontSheetCalendarComponent'
import { message } from 'antd'
import { downloadExcelFrontSheet } from 'Helpers/frontSheetHelper'
import { FrontSheetViewDisplay } from 'Apis/generated/frontSheetsApi'

interface IProps {
  currentClientId: number
  currentFrontSheetView: FrontSheetViewDisplay
  frontSheetViewId: number
  frontSheetId: number
  frontSheetName: string
}

export const FrontSheetViewTableComponent: React.FC<IProps> = ({
  currentClientId, currentFrontSheetView, frontSheetId, frontSheetName
}): React.ReactElement => {
  const [loading, setLoading] = useState<number>(null)

  useEffect(() => {
    if (loading !== null) {
      message.destroy()
      message.loading({ content: 'Downloading...', key: 'downloadingExcel' })
    } else {
      message.destroy()
    }
  }, [loading])

  return (
    <>
      <button className="ant-btn ant-btn-primary ms-button" disabled={loading !== null} onClick={() => downloadExcelFrontSheet(frontSheetName, frontSheetId, currentClientId, setLoading)}>Download</button>
      <FrontSheetCalendarComponent currentFrontSheetView={currentFrontSheetView} />
    </>
  )
}

export default FrontSheetViewTableComponent
