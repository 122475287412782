import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFunctions: build.query<GetFunctionsApiResponse, GetFunctionsApiArg>({
      query: (queryArg) => ({
        url: `/functions`,
        params: { clientId: queryArg.clientId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetFunctionsApiResponse =
  /** status 200 Returns list of functions */ Function[];
export type GetFunctionsApiArg = {
  clientId: number;
};
export type FunctionType = {
  functionTypeId?: number;
  functionTypeName?: string | null;
  functionTypeDescription?: string | null;
};
export type Function = {
  functionId?: number;
  functionName?: string | null;
  functionDescription?: string | null;
  functionType?: FunctionType;
};
export const { useGetFunctionsQuery, useLazyGetFunctionsQuery } =
  injectedRtkApi;
