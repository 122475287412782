import React, { FunctionComponent, memo, useCallback, useMemo } from 'react'
import { RootState } from '../../redux/reducers'
import { useMindshareSelector } from 'mindshare.customhooks'
import { IMSHierarchies } from 'mindshare.layout'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import FlightFieldContainer from './FlightFieldContainer'
import { clearFlightData, copyFlight, deleteFlight, mergeFlight, setSelectedDates, setSelectedFlights } from 'Actions/mediaPlansActions'
import { useAppDispatch } from '../../store'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import { IClickableFieldProps, useClickableField } from 'Components/MediaPlanVersion/hooks'
import { IMediaPlanVersionMasteredFieldsHelperValues, IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IFlight } from 'Components/MediaPlanVersion/constants/entities/IFlight'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { useFlightGroupFlights } from 'Components/MediaPlanVersion/hooks/useFlightGroupFlights'

interface IProps {
  calculationMode: boolean
  flightGroupId: number
  flightGroupIndex: number
  unfilteredHierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  tokenHandler: (token: string) => void
  handleAddField: (fieldType: FieldLevelType, flightGroupIndex?: number) => void
  onAvailableFieldSelected: (mediaPlanFieldSelected: string, flightGroupIndex?: number) => void
  flightFields: IMediaPlanTemplateFields[]
  linkedLookupFieldsHelperValues: IMediaPlanVersionLinkedLookupHelperValues
  isCollapsed?: boolean
  compressedCalendarView: boolean
  clickableFieldProps: IClickableFieldProps
  isSelectedFlightCellEmpty: boolean
  selectedFlightsWithinFlightGroup: string[]
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  isSubtotalGroup?: boolean
  flights?: IFlight[]
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
}

export const FlightsContainer: FunctionComponent<IProps> = (props: IProps) => {
  const {
    calculationMode,
    flightGroupId,
    flightGroupIndex,
    unfilteredHierarchies,
    masteredListsData,
    tokenHandler,
    flightFields,
    linkedLookupFieldsHelperValues,
    isCollapsed,
    compressedCalendarView,
    clickableFieldProps,
    isSelectedFlightCellEmpty,
    selectedFlightsWithinFlightGroup,
    masteredDataHelperValues,
    isSubtotalGroup,
    flights,
    financeListFieldsData
  } = props

  const dispatch = useAppDispatch()
  const planStartDate = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.parseData?.planStartDate || '')
  const planEndDate = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.parseData?.planEndDate || '')
  const startDayOfWeek = useMindshareSelector((state: RootState) => state.mediaPlans.currentMediaPlanVersion.parseData?.startDayOfWeek || 'Monday') as string
  const { isFieldValidForClick } = useClickableField(clickableFieldProps)

  const handDeleteFlight = useCallback(
    (currentFlightGroupIndex: number, flightIndex: number) =>
      dispatch(deleteFlight(currentFlightGroupIndex, flightIndex)),
    [dispatch]
  )

  const handleClearFlightData = useCallback(
    (currentFlightGroupIndex: number, flightIndex: number) =>
      dispatch(clearFlightData(currentFlightGroupIndex, flightIndex)),
    [dispatch]
  )

  const handleCopyFlight = useCallback(
    () =>
      dispatch(copyFlight()),
    [dispatch]
  )

  const handleMergeFlight = useCallback(
    (startDate: Date, enDate: Date, mergeByWeek: boolean, currentFlightGroupIndex: number, flightIndex: number, mergeByMonth: boolean) => {
      dispatch(mergeFlight(startDate, enDate, mergeByWeek, startDayOfWeek, currentFlightGroupIndex, flightIndex, mergeByMonth))
      dispatch(setSelectedFlights([]))
      dispatch(setSelectedDates([]))
      dispatch(copyFlight(null))
    }, [dispatch, startDayOfWeek]
  )

  const sortedFlightFields = useMemo(() => {
    return flightFields && [...flightFields]
      .filter(c => c.mediaPlanVersionFieldId !== 0)
      .sort((a, b) => a.sortOrder - b.sortOrder)
  }, [flightFields])

  const flightsDates = useFlightGroupFlights(flightGroupIndex)

  if (!sortedFlightFields.length) {
    return null
  }

  return (
    <div className='flight__chosenItem'>
      {
        sortedFlightFields.map((fieldFlight, fieldFlightIdx) => {
          const valid = isFieldValidForClick(fieldFlight)
          return (
            <FlightFieldContainer
              key={`FlightFieldContainer_${flightGroupIndex}_${fieldFlight.mediaPlanVersionFieldId}_${fieldFlightIdx}`}
              valid={valid}
              flightGroupId={flightGroupId}
              flightGroupIndex={flightGroupIndex}
              mediaPlanField={fieldFlight}
              calculationMode={calculationMode}
              unfilteredHierarchies={unfilteredHierarchies}
              masteredListsData={masteredListsData}
              planEndDate={planEndDate}
              planStartDate={planStartDate}
              tokenHandler={tokenHandler}
              deleteFlight={handDeleteFlight}
              clearFlightData={handleClearFlightData}
              copySelectedFlight={handleCopyFlight}
              mergeFlightSelection={handleMergeFlight}
              isSelectedFlightCellEmpty={isSelectedFlightCellEmpty}
              linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
              isCollapsed={isCollapsed}
              compressedCalendarView={compressedCalendarView}
              selectedFlights={selectedFlightsWithinFlightGroup}
              masteredDataHelperValues={masteredDataHelperValues}
              isSubtotalGroup={isSubtotalGroup}
              flights={flights}
              flightsDates={flightsDates}
              financeListFieldsData={financeListFieldsData}
            />
          )
        })
      }
    </div>
  )
}

export default memo(FlightsContainer)
