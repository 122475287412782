import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    clientMediaPartners: build.query<
      ClientMediaPartnersApiResponse,
      ClientMediaPartnersApiArg
    >({
      query: (queryArg) => ({
        url: `/clientMediaPartners`,
        params: { clientId: queryArg.clientId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type ClientMediaPartnersApiResponse =
  /** status 200 Returns Client Media Partners for client */ ClientMediaPartner[];
export type ClientMediaPartnersApiArg = {
  clientId: number;
};
export type ClientMediaPartner = {
  clientMediaPartnerId?: number;
  clientId?: number;
  mediaPartnerValue?: string | null;
  deletedDate?: string | null;
};
export const { useClientMediaPartnersQuery, useLazyClientMediaPartnersQuery } =
  injectedRtkApi;
