import { RootState } from 'Reducers/index'
import { IMediaPlansState } from 'Reducers/mediaPlansReducer'
import { createSelector } from 'reselect'

export const selectMediaPlans = (state: RootState) => state.mediaPlans

export const selectMediaPlanErrors = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.errors
)

export const selectNewUniqueStringObject = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.newUniqueStringObject
)

export const selectSelectedFlights = createSelector(
  selectMediaPlans,
  (mediaPlans) => mediaPlans.selectedFlights
)

export const selectCopiedFlight = createSelector(
  selectMediaPlans,
  (mediaPlans): IMediaPlansState['copiedFlight'] => mediaPlans.copiedFlight
)

export const selectCopiedFlightData = createSelector(
  selectMediaPlans,
  (mediaPlans): IMediaPlansState['copiedFlightData'] => mediaPlans.copiedFlightData
)

export const selectFlightGroupSelection = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.selectedFlightGroups
)

const selectCurrentMediaPlanVersionUnmodified = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.currentMediaPlanVersionUnmodified
)

export const selectUnmodifiedStringifiedParseData = createSelector(
  selectCurrentMediaPlanVersionUnmodified,
  currentMediaPlanVersion => currentMediaPlanVersion && currentMediaPlanVersion?.parseDataStringified
)
