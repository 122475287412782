import { useMemo } from 'react'
import { getFilteredChosenFlightFields, getChosenSubFlightFields, getChosenFlightFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { useAppSelector } from '../../../store'
import { selectCurrentMediaPlanVersionFields, selectParseData } from '../../../selectors'

export const useChosenFields = ({
  flightGroupIndex,
  isCollapsed,
  collapsedViewField
}:
{
  flightGroupIndex: number
  isCollapsed: boolean
  collapsedViewField: string
}) => {
  const mediaPlanVersionFields = useAppSelector(selectCurrentMediaPlanVersionFields)
  const parseData = useAppSelector(selectParseData)
  const mediaPlanVersionChosenFlightFields = useMemo(() => parseData?.flightGroups[flightGroupIndex]?.chosenFlightFields, [parseData?.flightGroups, flightGroupIndex])

  const chosenFlightFields = useMemo(() => getChosenFlightFields(mediaPlanVersionChosenFlightFields, mediaPlanVersionFields), [mediaPlanVersionChosenFlightFields, mediaPlanVersionFields])
  const chosenSubFlightFields = useMemo(() => getChosenSubFlightFields(mediaPlanVersionChosenFlightFields, mediaPlanVersionFields), [mediaPlanVersionChosenFlightFields, mediaPlanVersionFields])
  const filteredChosenFlightFields = useMemo(() => getFilteredChosenFlightFields(chosenFlightFields, isCollapsed, collapsedViewField), [chosenFlightFields, isCollapsed, collapsedViewField])
  const filteredChosenSubFlightFields = useMemo(() => getFilteredChosenFlightFields(chosenSubFlightFields, isCollapsed, collapsedViewField), [chosenSubFlightFields, isCollapsed, collapsedViewField])

  return useMemo(() => ({
    chosenFlightFields: filteredChosenFlightFields,
    chosenSubFlightFields: filteredChosenSubFlightFields
  }), [filteredChosenFlightFields, filteredChosenSubFlightFields])
}
