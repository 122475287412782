import React, { ClipboardEvent, FunctionComponent, memo } from 'react'
import { IMSHierarchies } from 'mindshare.layout'
import { isWeeklyView } from '../../helpers/calendarHelper'

import {
  IMediaPlanTemplateFields,
  isFlightOrSubFlight
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import {
  pasteData
} from '../../redux/actions/mediaPlansActions'

import PasteComponent from 'Components/PasteComponent'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import {
  selectCalendarView
} from '../../selectors'
import { useAppDispatch, useAppSelector } from '../../store'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import { IMediaPlanVersionMasteredFieldsHelperValues, IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'

import { IExpandedHierarchiesValues } from 'Components/Hierarchies/helpers/getExpandedHierarchiesValues'
import { MASTERED_FIELD_DATA_TYPES } from 'Constants/enums/FieldDataType'
import MasteredHierarchyFieldContainer from './MasteredHierarchyFieldContainer'
import FieldContainer from './FieldContainer'
import EmptyCellComponent from 'Components/MediaPlanVersion/EmptyCellComponent'
import { useHierarchies } from 'Hooks/useHierarchies'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

export interface IFieldContainerProps {
  calculationMode: boolean
  defaultClass?: string
  endDate?: string
  field: IMediaPlanTemplateFields
  flightGroupIndex?: number
  flightIndex?: number
  masteredListsData: IMasteredListsData
  merge?: boolean
  startDate?: string
  subFlightIndex?: number
  tokenHandler: (token: string) => void
  valid: boolean
  collapsedFlightGroup?: boolean
  onClearClipboard?: () => void
  linkedLookupFieldsHelperValues?: IMediaPlanVersionLinkedLookupHelperValues
  unfilteredHierarchies?: IMSHierarchies
  expandedHierarchiesValues?: IExpandedHierarchiesValues
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
  masteredDataHelperValues?: IMediaPlanVersionMasteredFieldsHelperValues
  isCollapsed?: boolean
  isLoadingHierarchies?: boolean
}

export const FieldTypeSwitchContainer: FunctionComponent<IFieldContainerProps> = (props: IFieldContainerProps) => {
  const isEmptyFlightOrSubflightCell = isFlightOrSubFlight(props.field) && !props.merge
  const typeIsMasteredField = MASTERED_FIELD_DATA_TYPES.includes(props.field.clientMediaPlanField.mediaPlanField.fieldDataTypeId) ||
    MASTERED_FIELD_DATA_TYPES.includes(props.field.expandedHierarchyTypeId)
  const dispatch = useAppDispatch()
  const { data: currentClient } = useCurrentClient()
  const { data: hierarchies } = useHierarchies(currentClient?.id)
  const calendarView = useAppSelector(selectCalendarView)

  const handlePasteEvent = (event: ClipboardEvent<HTMLDivElement>) => {
    const unsupported = [FieldLevelType.PLAN, FieldLevelType.FLIGHT_GROUP].includes(props.field.clientMediaPlanField.mediaPlanField.fieldLevelId)

    if (!unsupported && !(isWeeklyView(calendarView) && isFlightOrSubFlight(props.field))) {
      event.preventDefault()
      const data = event.clipboardData.getData('text')
      dispatch(pasteData(currentClient.id, data, props.field, props.flightGroupIndex, hierarchies, props.masteredListsData, props.financeListFieldsData, props.flightIndex))
    }
  }

  if (
    isEmptyFlightOrSubflightCell) {
    return (
      <EmptyCellComponent fieldLabel={props.field.fieldLabel} />
    )
  }

  return (
    <PasteComponent handlePasteEvent={handlePasteEvent}>
      {typeIsMasteredField ? <MasteredHierarchyFieldContainer {...props}/> : <FieldContainer {...props} />}
    </PasteComponent>
  )
}

export default memo(FieldTypeSwitchContainer)
