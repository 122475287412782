import * as React from 'react'
import { CSSProperties } from 'react'

interface HeaderFlightLevelProps {
  text: string
  style?: CSSProperties
}

export default function HeaderFlightLevel ({ text, style }: HeaderFlightLevelProps) {
  return (
    <tr>
      <th className='flightGroup__header -textRight' style={style}>
        {text}
      </th>
    </tr>
  )
}
