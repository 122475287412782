import React, { FC, useMemo } from 'react'
import { Card, Input } from 'antd'
import { DndContext } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { IMSHierarchies } from 'mindshare.layout'
import {
  DragIcon,
  SortableListBody,
  SortableListItem,
  SortableListCell,
  SortableListHeader,
  SortableListRow
} from 'Components/shared/SortableList'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { fakeDateDataType } from 'Components/shared/constants/entities/IFieldMetaData'
import { useCustomExportOptions, UseCustomExport } from '../hooks'
import {
  findOperatorByOperatorId,
  getOperatorsByFieldDataTypeId,
  createOptionValue,
  updateOrder,
  IFilterField,
  initialFilterField,
  LATEST_FLAG_OPERATOR_ID
} from '../constants/entities'
import { isOperatorIdOfMultipleValueOperator } from 'Components/CustomExport/constants/entities/IOperator'
import { FilterOperatorSelector } from './FilterOperatorSelector'
import { CustomExportFieldActions } from './CustomExportFieldActions'
import { LatestFlagsSelector } from './LatestFlagSelector'
import { CustomExportDefaultValue } from './CustomExportDefaultValue'
import { CustomExportControlField, CustomExportFilterDefaultValue, CustomExportLatestFlag, CustomExportFieldDataTypeOperator } from 'Apis/generated/customExportsApi'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { CustomExportFilterFieldItemComponent } from 'Components/CustomExport/components/CustomExportFilterFieldItemComponent'

interface IProps {
  customExport: UseCustomExport
  latestFlags: CustomExportLatestFlag[]
  operators: CustomExportFieldDataTypeOperator[]
  customExportFields: Array<IClientMediaPlanField | CustomExportControlField>
  hierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  isRunCustomExport?: boolean
}

export const CustomExportFilterFields: FC<IProps> = ({
  customExport,
  latestFlags,
  operators,
  customExportFields,
  hierarchies,
  masteredListsData,
  isRunCustomExport
}) => {
  const { options, selectedOptions } = useCustomExportOptions({
    fields: customExport.values.customExportFilters,
    customExportFields
  })

  const handleRemove = (fieldIndex: number) => () => customExport.removeField(
    'customExportFilters',
    fieldIndex,
    updateOrder<IFilterField>('sortOrder')
  )

  const handlePush = () => customExport.pushField('customExportFilters', {
    ...initialFilterField,
    customExportId: customExport.values.customExportId,
    sortOrder: customExport.values.customExportFilters.length
  })

  const handleMove = ({ active, over }: DragEndEvent) => {
    const oldIndex = customExport.values.customExportFilters.findIndex((i) => i.itemRowId === active.id)
    const newIndex = customExport.values.customExportFilters.findIndex((i) => i.itemRowId === over?.id)

    customExport.moveField(
      'customExportFilters',
      oldIndex,
      newIndex,
      updateOrder('sortOrder')
    )
  }

  const handlePlaningDateChange = ([startDate, endDate]: CustomExportFilterDefaultValue[]) => customExport.changePlaningDate({
    startDate: startDate.defaultValue,
    endDate: endDate.defaultValue
  })

  const planningDateValue = useMemo(() => [
    {
      customExportFilterId: 0,
      customExportFilterDefaultValueId: 0,
      defaultValue: customExport.values.customExportPlanningDateStartDate
    },
    {
      customExportFilterId: 0,
      customExportFilterDefaultValueId: 0,
      defaultValue: customExport.values.customExportPlanningDateEndDate
    }
  ], [
    customExport.values.customExportPlanningDateStartDate,
    customExport.values.customExportPlanningDateEndDate
  ])

  return (
    <Card className='sortable-list custom-export-filters' data-testid='sortable-list'>
      <SortableListHeader>
        <SortableListRow>
          <SortableListCell>
            Filter
          </SortableListCell>
          <SortableListCell>
            Operation
          </SortableListCell>
          <SortableListCell>
            Default
          </SortableListCell>
          <SortableListCell className='actions-cell'>
            {/* <CustomActions /> */}
          </SortableListCell>
          <SortableListCell className='sortable-list__drag-icon'>
            {/* <DragIcon /> */}
          </SortableListCell>
        </SortableListRow>
      </SortableListHeader>

      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleMove}>
        <SortableContext
          items={customExport.values.customExportFilters.map((item) => item.itemRowId)}
          strategy={verticalListSortingStrategy}
        >
          <SortableListBody>
            {/* props of custom export */}
            <SortableListItem>
              <SortableListCell>
                <Input value='Planing date' disabled />
              </SortableListCell>
              <SortableListCell>
                <FilterOperatorSelector
                  onChange={customExport.changeFieldDataTypeOperator}
                  value={customExport.values.fieldDataTypeOperatorId}
                  options={getOperatorsByFieldDataTypeId(operators, fakeDateDataType.fieldDataTypeId)}
                />
              </SortableListCell>
              <SortableListCell>
                <CustomExportDefaultValue
                  value={planningDateValue}
                  onChange={handlePlaningDateChange}
                  fieldDataType={fakeDateDataType}
                  hierarchies={hierarchies}
                  operator={findOperatorByOperatorId(operators, customExport.values.fieldDataTypeOperatorId)}
                  masteredListsData={masteredListsData}
                />
              </SortableListCell>
              {!isRunCustomExport && (
                <>
                  <SortableListCell className='actions-cell'>
                    <CustomExportFieldActions
                      onPush={handlePush}
                      canRemove={false}
                    />
                  </SortableListCell>
                  <SortableListCell className='sortable-list__drag-icon'>
                    <DragIcon disabled />
                  </SortableListCell>
                </>
              )}
            </SortableListItem>
            <SortableListItem>
              <SortableListCell>
                <LatestFlagsSelector
                  onChange={customExport.changeExportLatestFlag}
                  options={latestFlags}
                  value={customExport.values.customExportLatestFlagId}
                />
              </SortableListCell>
              <SortableListCell>
                <FilterOperatorSelector
                  disabled
                  value={LATEST_FLAG_OPERATOR_ID}
                  options={operators}
                />
              </SortableListCell>
              <SortableListCell>
                <Input value="true" disabled />
              </SortableListCell>
              {!isRunCustomExport && (
                <>
                  <SortableListCell className='actions-cell'>
                    <CustomExportFieldActions
                      onPush={handlePush}
                      canRemove={false}
                    />
                  </SortableListCell>
                  <SortableListCell className='sortable-list__drag-icon'>
                    <DragIcon disabled />
                  </SortableListCell>
                </>
              )}
            </SortableListItem>

            {/* filters */}
            {customExport.values.customExportFilters.map((item, fieldIndex) => {
              const optionId = createOptionValue(item)
              const { field = {}, value = null, label = null } = selectedOptions[optionId] || {}
              const fieldDataType = field?.mediaPlanField?.fieldDataType ||
            field.control?.fieldDataType || field.fieldDataType
              const clientFieldValues = field?.clientFieldValues

              const handleFieldChange = (customExportFieldId: Partial<IFilterField>) => {
                customExport.updateField('customExportFilters', fieldIndex, {
                  ...customExportFieldId,
                  fieldDataTypeOperatorId: initialFilterField.fieldDataTypeOperatorId,
                  customExportFilterDefaultValue: initialFilterField.customExportFilterDefaultValue
                })
              }

              const handleOperatorChange = (fieldDataTypeOperatorId: number) => {
                const currentDefaultValue = customExport.values.customExportFilters[fieldIndex]?.customExportFilterDefaultValue
                const hasFromOperatorMultipleValues = isOperatorIdOfMultipleValueOperator(
                  operators,
                  customExport.values.customExportFilters[fieldIndex].fieldDataTypeOperatorId
                )
                const hasToOperatorMultipleValues = isOperatorIdOfMultipleValueOperator(
                  operators,
                  fieldDataTypeOperatorId
                )

                if (
                  hasFromOperatorMultipleValues ||
              hasToOperatorMultipleValues ||
              currentDefaultValue.length !== 1
                ) {
                  customExport.updateField('customExportFilters', fieldIndex, {
                    fieldDataTypeOperatorId,
                    customExportFilterDefaultValue: initialFilterField.customExportFilterDefaultValue
                  })
                  return
                }

                const newDefaultValues = [
                  {
                    ...currentDefaultValue[0],
                    defaultValue: ''
                  }
                ]

                customExport.updateField('customExportFilters', fieldIndex, {
                  fieldDataTypeOperatorId,
                  customExportFilterDefaultValue: newDefaultValues
                })
              }

              const handleDefaultValueChange = (defaultValues: Array<Pick<CustomExportFilterDefaultValue, 'defaultValue' | 'customExportFilterDefaultValueId'>>) => {
                const customExportFilterDefaultValue = defaultValues.map((defaultValue) => ({
                  ...defaultValue,
                  customExportFilterId: item.customExportFilterId
                }))

                customExport.updateField('customExportFilters', fieldIndex, {
                  customExportFilterDefaultValue
                })
              }

              const canRemove = !!(field.mediaPlanField?.fieldLabel === 'Plan Name') || !field?.isMandatory

              return (
                <CustomExportFilterFieldItemComponent
                  key={fieldIndex}
                  fieldIndex={fieldIndex}
                  item={item}
                  canRemove={canRemove}
                  handleRemove={handleRemove}
                  handlePush={handlePush}
                  value={value}
                  label={label}
                  options={options}
                  handleFieldChange={handleFieldChange}
                  isRunCustomExport={isRunCustomExport}
                  fieldDataType={fieldDataType}
                  handleOperatorChange={handleOperatorChange}
                  operators={operators}
                  handleDefaultValueChange={handleDefaultValueChange}
                  hierarchies={hierarchies}
                  masteredListsData={masteredListsData}
                  clientFieldValues={clientFieldValues}
                  customExport={customExport}
                />
              )
            })}
          </SortableListBody>
        </SortableContext>
      </DndContext>
    </Card>
  )
}
