import React, { FunctionComponent, ReactElement, useCallback, useEffect } from 'react'
import { useMindshareSelector } from 'mindshare.customhooks'
import {
  MSLayoutContentRow, useQueryParams, useCurrentPath, useRouteNavigation
} from 'mindshare.layout'
import { RootState } from 'Reducers/index'
import { clientSelectionHelper } from 'Helpers/clientSelectionHelper'
import ClientSelectionComponent from 'Components/ClientSelectionComponent'
import { Client } from 'Apis/generated/clientsApi'
import { setCurrentClient } from 'Actions/appActions'
import { appPaths } from '../providers/AppRoutes'
import { useAppDispatch } from '../store'

const invalidPaths = [appPaths.templateEdit, appPaths.templateExample]

export const setDefaultClient = (clients, clientIdParam, dispatch) => {
  const selectedClientId = clientIdParam || Number(localStorage.getItem('clientSelected'))
  let selectedClient = clients.find(c => c.id === selectedClientId)
  selectedClient = selectedClient && selectedClient.id > 0 ? selectedClient : clients[0]
  dispatch(setCurrentClient(selectedClient))
}

export const useDefaultClient = () => {
  const dispatch = useAppDispatch()
  const clients = useMindshareSelector((state: RootState) => state.app.clients) as Client[]
  const clientsLoaded = useMindshareSelector((state: RootState) => state.app.clientsLoaded) as boolean
  const clientIdParam = useQueryParams('clientId', Number)
  const navigate = useRouteNavigation()

  useEffect(() => {
    if (clientsLoaded) {
      if (clients.length === 0) {
        navigate('/notauthorised')
      } else {
        setDefaultClient(clients, clientIdParam, dispatch)
      }
    }
  }, [clientsLoaded, clients, clientIdParam, dispatch, navigate])
  return null
}

export const DefaultClientContainer: FunctionComponent = () => useDefaultClient()

export const ClientSelectionContainer: FunctionComponent = (): ReactElement => {
  const dispatch = useAppDispatch()
  const clientSelectionHelperResult = clientSelectionHelper(useCurrentPath())
  const clients = useMindshareSelector((state: RootState) => state.app.clients) as Client[]
  const currentClient = useMindshareSelector((state: RootState) => state.app.currentClient) as Client
  const navigate = useRouteNavigation()

  const selectClient = useCallback(async (clientID: number) => {
    const client = clients.find((c) => c.id === clientID)
    const currentlySelected = localStorage.getItem('clientSelected')

    if (!currentlySelected || parseInt(currentlySelected, 10) !== clientID) {
      if (clientSelectionHelperResult.parsePath !== '' && invalidPaths.some(c => c.includes(clientSelectionHelperResult.parsePath))) {
        navigate(appPaths.templateList)
      }

      localStorage.setItem('clientSelected', String(clientID))
      dispatch(setCurrentClient(client))

      const mediaPlanningToolName = client.agency?.mediaPlanningToolName

      if (mediaPlanningToolName) {
        // eslint-disable-next-line functional/immutable-data
        document.title = mediaPlanningToolName
      } else {
        // eslint-disable-next-line functional/immutable-data
        document.title = 'Media Activity Plan'
      }
    }
  }, [dispatch, clients, clientSelectionHelperResult.parsePath, navigate])

  return !clientSelectionHelperResult.isClientSelectionHidden ? (
    <div className='client-selection'>
      <MSLayoutContentRow extraClass='client-selection-content-row'>
        <ClientSelectionComponent
          clients={clients}
          currentClient={currentClient}
          clientSelect={selectClient}
        />
      </MSLayoutContentRow>
    </div>
  ) : null
}

export default ClientSelectionContainer
