import React, { useEffect, useState, useCallback } from 'react'
import { message } from 'antd'
import {
  navigate,
  MSLayoutContentRow,
  LoadingContainer,
  LoadingComponent,
  getColumnSearchWithSettingsFunction,
  MSClientHierarchySelection,
  IMSHierarchies
} from 'mindshare.layout'
import { useMapSettings } from '@mindshare/services'
import {
  cloneMediaPlan,
  deleteMediaPlan,
  getMediaPlans,
  getPlanVersionHistory,
  initialisePlanListContainer,
  removeMediaPlanFromReporting,
  removeMediaPlanFromCoreM
} from 'Actions/mediaPlansActions'
import {
  clearCurrentMediaPlanTemplate,
  getMediaPlanTemplates,
  getMediaPlanTemplateById
} from 'Actions/mediaPlanTemplatesActions'
import { createMediaPlan } from 'Actions/templatesActions'
import PlansListTable from 'Components/PlansListTable'
import PlanListTableButtonColumn from 'Components/PlanList/PlanListTableButtonColumn'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import { IMediaPlans, ILookupPlan } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import { IMAPSettings } from 'Constants/entities/IMapSettings'
import mediaPlanListText from 'Components/tooltipContent/Media Plan List'
import { MediaPlanCreateModalContainer } from 'Containers/MediaPlanVersion/MediaPlanCreateModalContainer'
import { VersionHistoryModal } from 'Components/MediaPlanVersion/VersionHistoryModal'
import { useAgencyLoadingGif } from 'Hooks/useAgencyLoadingGif'
import { useAppDispatch, useAppSelector } from '../../store'
import { getUserPreferences } from 'Helpers/userPreferenceHelper'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { selectCurrentMediaPlanTemplate } from '../../selectors'
import { useHierarchies } from 'Hooks/useHierarchies'
import { initialPermissions } from 'Hooks/useUserPermissions'
import { useGetUserPermissionsQuery } from 'Apis/enhancedUserPermissionsApi'
import { downloadExcelPlan } from 'Components/MediaPlanVersion/helpers/downloadExcelPlan'

const initialMediaPlanTemplate = {
  brandHierarchyId: null,
  geographyHierarchyId: null,
  mediaHierarchyId: null,
  businessHierarchyId: null,
  mediaPlanTemplateId: 0,
  versionNumber: 0,
  mediaPlanId: 0,
  mediaPlanVersionId: 0,
  planningStageName: null,
  planYear: 0
}

interface IProps {
  hierarchies: IMSHierarchies
}

export const PlanListContainer: React.FunctionComponent<IProps> = ({ hierarchies }): React.ReactElement => {
  const [hierarchiesLoading, setHierarchiesLoading] = useState(false)
  const [versionHistoryModalVisible, setVersionHistoryModalVisible] = useState(false)
  const [mediaPlan, setMediaPlan] = useState<IMediaPlans>(initialMediaPlanTemplate)

  const [createPlanModalVisible, setCreatePlanModalVisible] = useState(false)
  const [selectedMediaPlan, setSelectedMediaPlan] = useState<IMediaPlans>(initialMediaPlanTemplate)
  const dispatch = useAppDispatch()
  const { data: currentClient } = useCurrentClient()
  const currentMediaPlanTemplate = useAppSelector(selectCurrentMediaPlanTemplate)
  const mediaPlans = useAppSelector(state => state.mediaPlans.mediaPlans) as ILookupPlan[]
  const mediaPlanVersionHistory = useAppSelector(state => state.mediaPlans.planListHistory)
  const { data: userPermissions = initialPermissions } = useGetUserPermissionsQuery({ clientName: currentClient.name })

  const { resetToDefault, settings, settingsLoading, updateSetting } = useMapSettings<IMAPSettings, 'planList'>('planList', currentClient.id, {})
  const { geography, media, business, brand, userSettings } = settings
  const { adminAuthorised, clientAdmin } = userPermissions

  const getMediaPlansFromStore = useCallback(async (
    clientId: string | number,
    geographyHierarchyId: number,
    mediaHierarchyId: number,
    businessHierarchyId: number,
    brandHierarchyId: number
  ) => {
    await dispatch(getMediaPlans(clientId, hierarchies, geographyHierarchyId, mediaHierarchyId, businessHierarchyId, brandHierarchyId))
  }
  , [dispatch, hierarchies])

  const getTemplatesByCurrentSelection = useCallback(async () => {
    await dispatch(getMediaPlanTemplates(currentClient.id))
  }
  , [currentClient.id, dispatch])

  useEffect(() => {
    if (createPlanModalVisible) {
      getTemplatesByCurrentSelection()
    }
  }, [getTemplatesByCurrentSelection, createPlanModalVisible])

  useEffect(() => {
    if (currentMediaPlanTemplate.mediaPlanTemplateId > 0) {
      setMediaPlan({
        ...mediaPlan,
        brandHierarchyId: currentMediaPlanTemplate.brandHierarchyId !== mediaPlan.brandHierarchyId
          ? currentMediaPlanTemplate.brandHierarchyId
          : mediaPlan.brandHierarchyId,
        businessHierarchyId: currentMediaPlanTemplate.businessHierarchyId !== mediaPlan.businessHierarchyId
          ? currentMediaPlanTemplate.businessHierarchyId
          : mediaPlan.businessHierarchyId,
        geographyHierarchyId: currentMediaPlanTemplate.geographyHierarchyId !== mediaPlan.geographyHierarchyId
          ? currentMediaPlanTemplate.geographyHierarchyId
          : mediaPlan.geographyHierarchyId,
        mediaHierarchyId: currentMediaPlanTemplate.mediaHierarchyId !== mediaPlan.mediaHierarchyId
          ? currentMediaPlanTemplate.mediaHierarchyId
          : mediaPlan.mediaHierarchyId
      })
      setHierarchiesLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMediaPlanTemplate.mediaPlanTemplateId])

  useEffect(() => {
    getMediaPlansFromStore(
      currentClient.id,
      geography,
      media,
      business,
      brand
    )
  }, [
    getMediaPlansFromStore,
    currentClient.id,
    geography,
    media,
    business,
    brand
  ])

  const selectTemplate = useCallback((plan: IMediaPlans, mediaPlanTemplateId: number) => {
    setMediaPlan({ ...plan, mediaPlanTemplateId })
    if (mediaPlanTemplateId) {
      setHierarchiesLoading(true)
      dispatch(getMediaPlanTemplateById(mediaPlanTemplateId, currentClient.id))
    } else {
      dispatch(clearCurrentMediaPlanTemplate())
    }
  }, [currentClient.id, dispatch])

  const showModal = useCallback(() => {
    dispatch(clearCurrentMediaPlanTemplate())
    setMediaPlan(initialMediaPlanTemplate)
    setCreatePlanModalVisible(true)
  }, [dispatch])

  const createNewPlan = useCallback(() => showModal(), [showModal])

  const saveMediaPlan = useCallback(async () => {
    try {
      const mediaPlanVersionId = await createMediaPlan(mediaPlan, currentClient.id)
      setCreatePlanModalVisible(false)
      // fetchRequest handler from @mindshare/services package returns integer for responses with status 403
      if (mediaPlanVersionId === 403) {
        message.error('You don\'t have access to perform this action. Please contact the administrators.')
      } else {
        navigate(`/mediaplanversion/${mediaPlanVersionId}`, false, { clientId: currentClient.id })
      }
    } catch (err) {
      message.error(err.httpResponseBody.split('\r\n').shift())
    }
  }, [currentClient.id, mediaPlan])

  const deletePlan = useCallback(async (mediaPlanId: number, clientId: number) => {
    await dispatch(deleteMediaPlan(mediaPlanId, clientId))
    getMediaPlansFromStore(
      currentClient.id,
      geography,
      media,
      business,
      brand
    )
  }, [dispatch, currentClient.id, brand, business, geography, media, getMediaPlansFromStore])

  const handleCloneMediaPlan = useCallback(async (mediaPlanId: number, copyRedistribution?: boolean) => {
    await dispatch(cloneMediaPlan(mediaPlanId, currentClient.id, copyRedistribution))
    getMediaPlansFromStore(
      currentClient.id,
      geography,
      media,
      business,
      brand
    )
  }, [dispatch, currentClient.id, brand, business, geography, media, getMediaPlansFromStore])

  const handleGetMediaPlanHistory = useCallback(async (mediaPlanId: number, clientId: number, currentMediaPlan: ILookupPlan) => {
    await dispatch(getPlanVersionHistory(mediaPlanId, clientId))
    setVersionHistoryModalVisible(true)
    setSelectedMediaPlan(currentMediaPlan)
  }, [dispatch])

  const removePlanFromReporting = useCallback(async (mediaPlanId: number, clientId: number) => {
    const result = await dispatch(removeMediaPlanFromReporting(mediaPlanId, clientId))
    if (!result) {
      message.success({ content: 'Media Plan successfully removed from reporting' })
    } else {
      message.error({ content: result, duration: 10 })
    }
    getMediaPlansFromStore(
      currentClient.id,
      geography,
      media,
      business,
      brand
    )
  }, [brand, business, currentClient.id, dispatch, geography, getMediaPlansFromStore, media])

  const removePlanFromCoreM = useCallback(async (mediaPlanId: number, clientId: number) => {
    const result = await dispatch(removeMediaPlanFromCoreM(mediaPlanId, clientId))

    if (!result) {
      message.success({ content: 'The plan remove message has been sent' })
    } else {
      message.error({ content: result, duration: 10 })
    }
    getMediaPlansFromStore(
      currentClient.id,
      geography,
      media,
      business,
      brand
    )
  }, [brand, business, currentClient.id, dispatch, geography, getMediaPlansFromStore, media])

  const planListTableButtonColumn = useCallback((item) => {
    const { expandHierarchies, displayFlightDates } = getUserPreferences(userSettings, item.mediaPlanTemplateId)

    return (
      <PlanListTableButtonColumn
        buttonActions={{
          clonePlan: handleCloneMediaPlan,
          deletePlan,
          downloadExcel: downloadExcelPlan,
          getPlanVersionHistory: handleGetMediaPlanHistory,
          removePlanFromReporting,
          removePlanFromCoreM
        }}
        clientId={currentClient.id}
        item={item}
        showRemoveFromReportIcon={adminAuthorised || clientAdmin}
        expandHierarchies={expandHierarchies}
        displayFlightDates={displayFlightDates}
      />
    )
  }, [
    adminAuthorised,
    clientAdmin,
    currentClient.id,
    deletePlan,
    handleCloneMediaPlan,
    handleGetMediaPlanHistory,
    removePlanFromReporting,
    userSettings,
    removePlanFromCoreM
  ])

  return settingsLoading ? (
    <LoadingComponent
      appDataSuccess={!!currentClient.id}
      agencyLoadingGif={currentClient.agency?.agencyLoadingGifLocation}
    />
  ) : (
    <>
      <TitleAndButtonsComponent
        title='Plans'
        subtitle='Your latest plans'
        buttons={[{
          buttonClick: resetToDefault,
          buttonText: 'Clear Filters'
        }, {
          buttonClick: createNewPlan,
          buttonText: 'Create New Plan'
        }]}
        tooltipText={mediaPlanListText}
      />

      <MSLayoutContentRow>
        <MSClientHierarchySelection
          hierarchies={hierarchies}
          selectedGeography={geography}
          selectedMedia={media}
          selectedBusiness={business}
          selectedBrand={brand}
          onChange={updateSetting}
          showSelectedIcons={true}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow fillAvailableSpace={true}>
        <PlansListTable
          createNewPlan={createNewPlan}
          currentClientDisplayName={currentClient.displayName}
          currentClientId={currentClient.id}
          // @ts-expect-error
          filterFunction={getColumnSearchWithSettingsFunction(settings, updateSetting)}
          lastColumn={planListTableButtonColumn}
          mediaPlans={mediaPlans}
        />
      </MSLayoutContentRow>

      <MediaPlanCreateModalContainer
        visible={createPlanModalVisible}
        hideModal={ () => setCreatePlanModalVisible(false)}
        mediaPlan={mediaPlan}
        mediaPlanTemplate={currentMediaPlanTemplate}
        hierarchiesLoading={hierarchiesLoading}
        selectMediaPlan={setMediaPlan}
        saveMediaPlan={saveMediaPlan}
        selectTemplate={selectTemplate}
      />

      <VersionHistoryModal
        visible={versionHistoryModalVisible}
        hideModal={ () => {
          setVersionHistoryModalVisible(false)
          setSelectedMediaPlan(initialMediaPlanTemplate)
        }}
        clientId={currentClient.id}
        mediaPlanVersionHistory={mediaPlanVersionHistory}
        downloadExcelPlan={downloadExcelPlan}
        userSettings={userSettings}
        mediaPlan={selectedMediaPlan}
      />
    </>
  )
}

export const PlanListLoadingContainer: React.FC = (props) => {
  const agencyLoadingGifLocation = useAgencyLoadingGif()
  const clientId = useAppSelector((state) => state.app.currentClient.id)
  const { data: hierarchies } = useHierarchies(clientId)
  const dispatch = useAppDispatch()
  return (
    <LoadingContainer
      appDataSuccess={!!clientId}
      agencyLoadingGif={agencyLoadingGifLocation}
      initialiseContainer={(loadingComplete) => initialisePlanListContainer(loadingComplete, dispatch, clientId, hierarchies, props)}
    >
      <PlanListContainer hierarchies={hierarchies} />
    </LoadingContainer>
  )
}

export default PlanListLoadingContainer
