import React, { FunctionComponent, memo } from 'react'
import { usePlanFinanceListFields, usePlanMasteredHierarchiesFields } from 'Components/MediaPlanVersion/hooks/usePlanMasteredData'
import { IFieldContainerProps } from './FieldTypeSwitchContainer'
import FieldContainer from './FieldContainer'

export const MasteredHierarchyFieldContainer: FunctionComponent<IFieldContainerProps> = (props) => {
  const {
    field,
    flightGroupIndex,
    flightIndex,
    subFlightIndex,
    masteredDataHelperValues
  } = props

  const {
    getIsMasteredHierarchyFieldPickable,
    getGeographyHierarchyFieldValue,
    getMediaHierarchyFieldValue
  } = masteredDataHelperValues?.[field.expandedHierarchyOriginalClientMediaPlanFieldId || field.clientMediaPlanField.clientMediaPlanFieldId] ?? {
    getIsMasteredHierarchyFieldPickable: () => false,
    getGeographyHierarchyFieldValue: () => undefined,
    getMediaHierarchyFieldValue: () => undefined
  }
  const isMasteredHierarchyFieldPickable = getIsMasteredHierarchyFieldPickable(flightGroupIndex, flightIndex, subFlightIndex)

  const geographyHierarchyId = getGeographyHierarchyFieldValue(flightGroupIndex, flightIndex, subFlightIndex)
  const mediaHierarchyId = getMediaHierarchyFieldValue(flightGroupIndex, flightIndex, subFlightIndex)
  const financeListFieldsData = usePlanFinanceListFields({ geographyHierarchyId, mediaHierarchyId, currentField: field })
  const {
    currentMasteredHierarchies,
    cachedMasteredHierarchies
  } = usePlanMasteredHierarchiesFields({ geographyHierarchyId, mediaHierarchyId })

  return (
    <FieldContainer
      {...props}
      financeListFieldsData={financeListFieldsData}
      isMasteredHierarchyFieldPickable={isMasteredHierarchyFieldPickable}
      currentMasteredHierarchies={currentMasteredHierarchies}
      cachedMasteredHierarchies={cachedMasteredHierarchies}
    />
  )
}

export default memo(MasteredHierarchyFieldContainer)
