
import { useMemo } from 'react'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useGetFieldTagsQuery } from 'Apis/enhancedFieldTagsApi'
import { GetFieldTagsApiResponse } from 'Apis/generated/fieldTagsApi'
import { UseQueryCommand } from '../redux/types'

export const useFieldTags = (): UseQueryCommand<GetFieldTagsApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data = [], isLoading } = useGetFieldTagsQuery(
    { clientId: currentClient.id },
    { skip: !currentClient.id }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
