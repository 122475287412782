import React, { FunctionComponent, ReactNode, useRef, useEffect } from 'react'
import { Tooltip } from 'antd'

interface ISelectWrapperProps {
  [x: string]: any
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const SelectWrapper: FunctionComponent<ISelectWrapperProps> = ({ onMouseEnter, onMouseLeave, ...restProps }: ISelectWrapperProps) => {
  const ref = useRef(null)

  useEffect(() => {
    const container = ref.current
    container?.addEventListener('mouseenter', onMouseEnter)
    container?.addEventListener('mouseleave', onMouseLeave)

    return () => {
      container?.removeEventListener('mouseenter', onMouseEnter)
      container?.removeEventListener('mouseleave', onMouseLeave)
    }
  }, [onMouseEnter, onMouseLeave])

  return (
    <div
      ref={ref}
      {...restProps}
    />
  )
}

interface ISelectTooltipProps {
  title: string | ReactNode
}

export const SelectTooltip: FunctionComponent<ISelectTooltipProps> = ({ children, title }) => (
  <Tooltip title={title}>
    <SelectWrapper>{children}</SelectWrapper>
  </Tooltip>
)

export default SelectTooltip
