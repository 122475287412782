import React, { useCallback } from 'react'
import { Button, List } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { UseFormSetValue } from 'react-hook-form'
import { ITemplateSyncChildTemplate, ITemplateSyncFormData } from './TemplateSyncModalComponent'

interface ITemplateSyncSelectedTemplatesComponentProps {
  setValue: UseFormSetValue<ITemplateSyncFormData>
  selectedClientTemplatesList: ITemplateSyncChildTemplate[]
  selectedLinkedTemplatesList: ITemplateSyncChildTemplate[]
}

const TemplateSyncSelectedTemplatesComponent: React.FC<ITemplateSyncSelectedTemplatesComponentProps> = ({ setValue, selectedClientTemplatesList, selectedLinkedTemplatesList }) => {
  const handleRemoveClick = useCallback((item: ITemplateSyncChildTemplate) => {
    const index = selectedClientTemplatesList.findIndex(i => i.mediaPlanTemplateId === item.mediaPlanTemplateId)
    if (index !== -1) {
      // When delete is clicked for new template, make sure to remove Use Existing template radio selection
      if (selectedClientTemplatesList[index].isNewTemplate) {
        setValue('useExistingTemplate', undefined)
      }
      setValue('selectedClientTemplates', selectedClientTemplatesList.filter(i => i.mediaPlanTemplateId !== item.mediaPlanTemplateId))
    } else {
      setValue('selectedLinkedTemplates', selectedLinkedTemplatesList.filter(i => i.mediaPlanTemplateId !== item.mediaPlanTemplateId))
    }
  }, [setValue, selectedClientTemplatesList, selectedLinkedTemplatesList])

  return (
    <div className='template-sync__list-wrapper'>
      <List
        dataSource={[...selectedLinkedTemplatesList, ...selectedClientTemplatesList]}
        renderItem={(item) => (
          <List.Item key={`${item.clientId}-${item.mediaPlanTemplateId}`}>
            <div className='template-sync__list-item'>{item.clientDisplayName}</div>
            <div className='template-sync__list-item -align-right'>{item.mediaPlanTemplateDisplayName}</div>
            <Button
              className='ms-button remove-template-button'
              data-testid='template-sync-remove-template-button'
              onClick={() => handleRemoveClick(item)}
              type='link'
            >
              <DeleteOutlined />
            </Button>
          </List.Item>
        )}
      />
    </div>
  )
}

export default TemplateSyncSelectedTemplatesComponent
