import React, { CSSProperties, FunctionComponent, memo, useCallback, useEffect } from 'react'
import { DeleteFilled, StarFilled, LockOutlined, ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import moment from 'moment'
import {
  CompositeFilter,
  IUpdateCompositeFilters,
  isClientHierarchyType,
  getClientHierarchyTypeDetails,
  getClientHierarchyValue,
  formatLowerCaseFirstLetter,
  IMSHierarchies,
  getHierarchyTypeDetails,
  getHierarchyValue,
  getHierarchyValueProp,
  formatUpperCaseFirstLetter
} from 'mindshare.layout'
import { IMediaPlanTemplateFields, getCorrectDisplayField, isFieldAvailableOrDefault, isFieldReadOnly } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { useResizeObserver } from 'Hooks/CustomHooks'
import {
  isDateType,
  isUniqueStringType,
  isMediaPartnersType,
  isClientDefinedListType,
  isFinanceProductHierarchyType,
  isFinanceStationHierarchyType,
  isFinanceTargetAudienceListType,
  isFinanceBuyingAudienceListType,
  isFinanceBookingCategoryList,
  isClientCampaignsType,
  isCostBuyingRouteHierarchyType,
  isClientAgenciesType
} from 'Components/shared/constants/entities/IFieldMetaData'
import { numberFormatter } from 'Helpers/numberHelper'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { getExpandedHierarchyValue, isExpandedHierarchyColumn } from './Hierarchies/helpers/expandedHierarchies'
import { IExpandedHierarchiesFlightGroupsValues } from './Hierarchies/helpers/getExpandedHierarchiesValues'
import { IFilterFlightGroupDataWithExpandedHierarchies } from './MediaPlanVersion/hooks/useFilterDataWithExpandedHierarchies'
import { getExpandedHierarchyValueFieldName } from './MediaPlans/constants/entities/ExpandedHierarchyGroupField'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { FinanceAudience, FinanceBookingCategory } from 'Apis/generated/financeDataApi'
import { useCollapsedFlightGroups } from 'Hooks/useCollapsedFlightGroups'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  mediaPlanField: IMediaPlanTemplateFields
  setCollapsedFlightGroups: (add: boolean, id: number) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  setHeaderWidths?: (id: number, width: number) => void
  toggleColumnSticky?: (id: number) => void
  getStickyStyle?: (type: StickyColumnType, id: number) => CSSProperties
  isSticky?: boolean
  expandedHierarchiesFlightGroupsValues?: IExpandedHierarchiesFlightGroupsValues
  financeList?: IMediaPlanVersionFinanceListFields
}
interface IPropsWithFilter extends IProps {
  flightGroups: IFlightGroup[]
  hierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  filterFlightGroupData: IFilterFlightGroupDataWithExpandedHierarchies
}
interface IPropsWithoutFilter extends IProps {
  flightGroups?: never
  hierarchies?: never
  masteredListsData?: never
  filterFlightGroupData?: never
}
export const FlightGroupLevelHeaderComponent: FunctionComponent<IPropsWithFilter | IPropsWithoutFilter> = ({
  mediaPlanField,
  hierarchies,
  masteredListsData,
  flightGroups,
  filterFlightGroupData,
  setCollapsedFlightGroups,
  removePlanField,
  setHeaderWidths,
  toggleColumnSticky,
  getStickyStyle,
  isSticky,
  expandedHierarchiesFlightGroupsValues,
  financeList
}) => {
  const [ref, sizes] = useResizeObserver()
  const id = mediaPlanField.mediaPlanTemplateFieldId || mediaPlanField.mediaPlanVersionFieldId
  const isCollapsed = useCollapsedFlightGroups(id)
  const fieldLabel = getCorrectDisplayField(mediaPlanField)
  const defaultOrAvailable = isFieldAvailableOrDefault(mediaPlanField)
  const readOnly = isFieldReadOnly(mediaPlanField)
  const dataColumnName = formatLowerCaseFirstLetter(
    isExpandedHierarchyColumn(mediaPlanField)
      ? getExpandedHierarchyValueFieldName(mediaPlanField)
      : mediaPlanField.clientMediaPlanField?.mediaPlanField?.columnName || ''
  )
  const dataType = mediaPlanField.clientMediaPlanField?.mediaPlanField?.fieldDataType
  const dataTypeName = dataType && dataType.dataTypeName
  const dataIsNumeric = dataType && dataType.isNumeric

  useEffect(() => {
    if (setHeaderWidths) {
      // will get the borderBox size, -1 for the border width
      const width = sizes?.borderBoxSize ? sizes?.borderBoxSize[0]?.inlineSize - 1 : 0
      setHeaderWidths(id, isCollapsed ? 19 : width || 100)
    }
  }, [id, sizes, isCollapsed, setHeaderWidths])

  const getTitleProp = useCallback((dataItem) => {
    if (isMediaPartnersType(dataTypeName)) {
      const mediaPartner = masteredListsData?.mediaPartners.find((mp) => mp.clientMediaPartnerId === dataItem[dataColumnName])
      return mediaPartner && mediaPartner.mediaPartnerValue
    } else if (isClientCampaignsType(dataTypeName)) {
      const clientCampaign = masteredListsData?.clientCampaigns.find((cc) => cc.clientCampaignId === dataItem[dataColumnName])
      return clientCampaign && clientCampaign.clientCampaignName
    } else if (isClientAgenciesType(dataTypeName)) {
      const clientAgency = masteredListsData?.clientAgencies.find((ca) => ca.agencyId === dataItem[dataColumnName])
      return clientAgency && clientAgency.agencyDisplayName
    } else if (isClientHierarchyType(dataTypeName)) {
      const isMasteredHierarchyType = isFinanceProductHierarchyType(dataTypeName) || isFinanceStationHierarchyType(dataTypeName) || isCostBuyingRouteHierarchyType(dataTypeName)
      if (isMasteredHierarchyType) {
        const { hierarchyType, hierarchyList } = getHierarchyTypeDetails(dataTypeName, hierarchies)
        const valueProp = getHierarchyValueProp(hierarchyType)
        return getHierarchyValue(hierarchyList, hierarchyType, Number(dataItem[valueProp]))
      } else {
        const { valueProp, hierarchyType, hierarchyList } = getClientHierarchyTypeDetails(dataTypeName, hierarchies)
        return getClientHierarchyValue(hierarchyList, hierarchyType, Number(dataItem[valueProp]))
      }
    } else if (isExpandedHierarchyColumn(mediaPlanField)) {
      return getExpandedHierarchyValue(
        mediaPlanField,
        flightGroups.findIndex(flightGroup => flightGroup.mediaPlanFlightGroupId === dataItem.mediaPlanFlightGroupId),
        expandedHierarchiesFlightGroupsValues
      )
    } else if (isUniqueStringType(dataTypeName) || isClientDefinedListType(dataTypeName)) {
      const option = mediaPlanField.clientMediaPlanField.clientFieldValues.find((h) => h.clientFieldValueId === dataItem[dataColumnName])
      return option && option.valueDisplayName
    } else if (isDateType(dataTypeName)) {
      const dateVal = dataItem[dataColumnName]
      return dateVal && moment(dateVal).format('DD-MMM-YYYY')
    } else if (isFinanceTargetAudienceListType(dataTypeName) || isFinanceBuyingAudienceListType(dataTypeName)) {
      const columnName = formatUpperCaseFirstLetter(dataColumnName)
      const financeValues = financeList?.[columnName]?.data as FinanceAudience[]
      const option = financeValues?.find(item => item.financeAudienceId === dataItem[dataColumnName])
      return option && option.audienceName
    } else if (isFinanceBookingCategoryList(dataTypeName)) {
      const columnName = formatUpperCaseFirstLetter(dataColumnName)
      const financeValues = financeList?.[columnName]?.data as FinanceBookingCategory[]
      const option = financeValues?.find(item => item.financeBookingCategoryId === dataItem[dataColumnName])
      return option && option.bookingCategoryName
    } else {
      return numberFormatter(dataItem[dataColumnName], mediaPlanField.clientMediaPlanField.mediaPlanField.fieldDataTypeId)
    }
  }, [hierarchies, masteredListsData, mediaPlanField, dataColumnName, dataTypeName, expandedHierarchiesFlightGroupsValues, flightGroups, financeList])

  const onConfirmFilter = useCallback<IUpdateCompositeFilters<IFlightGroup>>((...args) => {
    filterFlightGroupData(...args, isExpandedHierarchyColumn(mediaPlanField))
  }, [mediaPlanField, filterFlightGroupData])

  const onClickHeaderButton = useCallback(() => {
    setCollapsedFlightGroups(!isCollapsed, id)
  }, [setCollapsedFlightGroups, isCollapsed, id])

  const onClickHeaderOuterLabel = useCallback(() => {
    if (toggleColumnSticky) {
      toggleColumnSticky(id)
    }
  }, [toggleColumnSticky, id])

  const onClickFlightGroupIcon = useCallback((e) => {
    e.stopPropagation()
    removePlanField(mediaPlanField)
    if (isSticky) {
      toggleColumnSticky(id)
    }
  }, [removePlanField, isSticky, toggleColumnSticky, id, mediaPlanField])

  return (
    <th
      data-testid={'flightGroup__header'}
      className={`flightGroup__header ${isCollapsed ? '-collapsed' : ''}`}
      rowSpan={5}
      style={getStickyStyle ? getStickyStyle(StickyColumnType.flightGroup, id) : {}}
      ref={ref}
    >
      <div className='flightGroup__headerButton' data-testid='flightGroup__headerButton' onClick={onClickHeaderButton}>
        <Tooltip placement='top' title={`${isCollapsed ? 'Expand' : 'Collapse'} flight group field`}>
          {isCollapsed ? <ArrowsAltOutlined /> : <ShrinkOutlined />}
        </Tooltip>
      </div>

      <div className='flightGroup__headerOuterLabel' data-testid='flight-group-label' onClick={onClickHeaderOuterLabel}>
        {defaultOrAvailable && !readOnly && (
          <Tooltip title='Delete flight group field'>
            <i
              className='flightGroup__icon -right'
              data-testid='btn-delete-field'
              onClick={onClickFlightGroupIcon}
            >
              <DeleteFilled />
            </i>
          </Tooltip>
        )}
        {!defaultOrAvailable && !readOnly && (
          <Tooltip title='Mandatory flight group field' >
            <i className='flightGroup__icon -right'>
              <StarFilled />
            </i>
          </Tooltip>
        )}
        {isSticky && (
          <i className='flightGroup__icon -left' data-testid='flightGroup__icon'>
            <LockOutlined />
          </i>
        )}

        {flightGroups && dataColumnName && !['sortOrder', 'mediaPlanFlightGroupId'].includes(dataColumnName) && (
          <i className='flightGroup__icon -bottom -left'>
            <CompositeFilter
              keyProp={dataColumnName}
              titleProp={getTitleProp}
              dataList={flightGroups}
              onConfirmFilter={onConfirmFilter}
              filterType={dataIsNumeric ? 'number' : 'text'}
            />
          </i>
        )}

        <Tooltip placement='bottom' title={isCollapsed && fieldLabel}>
          <div className='flightGroup__headerLabel'>{fieldLabel}</div>
        </Tooltip>
      </div>
    </th>
  )
}

export default memo(FlightGroupLevelHeaderComponent)
