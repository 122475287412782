import moment from 'moment'
import { formatLowerCaseFirstLetter } from 'mindshare.layout'
import { findByFieldLevelId, IMediaPlanVersionField } from '../../../MediaPlans/constants/entities/IMediaPlanMetaFields'
import {
  flightWidthCalculation,
  subFlightWidthCalculation,
  isDateGap,
  getWeeksBetweenByWeekDay,
  dateFormat,
  findSubFlightBetweenDates,
  isSame
} from '../../../../helpers/calendarHelper'
import { FieldLevelType } from '../../../../constants/enums/FieldLevel'
import { getDefaultValue } from '../../../../helpers/flightHelper'
import { IMediaPlans } from '../../../MediaPlans/constants/entities/IMediaPlans'
import { cloneObject } from 'Helpers/objectHelper'

export interface IMediaPlanVersion {
  data: string
  mediaPlan?: IMediaPlans
  mediaPlanVersionFields: IMediaPlanVersionField[]
  parseData?: any
}

export interface INewUniqueString {
  clientId: number
  clientMediaPlanFieldId: number
  uniqueStringValue: string
  updateDataValuesFn: (
    selectedValue: string,
    id: number,
    flightGroupIndex?: number,
    flightIndex?: number,
    subFlightIndex?: number,
    startDate?: Date,
    endDate?: Date
  ) => void
  updateDataValuesFnParams: IUpdateDataValuesFnParams
  mediaPlanFieldId: number
}

export interface IUpdateDataValuesFnParams {
  id: number
  flightGroupIndex?: number
  flightIndex?: number
  subFlightIndex?: number
  startDate?: Date
  endDate?: Date
}

export interface INewUniqueStringObject {
  id: number
  value: number
  label: string
}

export type CalendarView = 'week' | 'table' | 'week-iso' | 'week-broadcast'

const cachedSubFlightsData: any[] = []

/**
 * @param {IMediaPlanVersion} mediaPlanVersion A media plan version with or without placeholder flights
 * Reverses the initialisation process which adds placeholder flights to fill calendar
 * @returns {IMediaPlanVersion} with all the extra placeholder flights removed
 */
export const sanitiseMediaPlanVersionFlightsRevised = (mediaPlanVersion: IMediaPlanVersion) => {
  const { parseData, ...version } = mediaPlanVersion
  const sanitizedData = {
    ...parseData,
    flightGroups: removePlaceholderFlights(parseData.flightGroups)
  }

  return {
    ...version,
    data: JSON.stringify(sanitizedData)
  }
}

export const getFlightsWithData = (flights: any[]) =>
  flights
    .filter((flight) => flight.merge)
    .map(({ subFlights = [], ...flight }) => {
      const subFlightsWithData = subFlights.filter((subFlight) => subFlight.merge)
      return { ...flight, subFlights: subFlightsWithData }
    })

const removePlaceholderFlights = (flightGroups) => flightGroups
  .map(({ flights = [], ...flightGroup }) => {
    const flightsWithData = getFlightsWithData(flights)

    return { ...flightGroup, flights: flightsWithData }
  })

/**
 * @param {IMediaPlanVersion} mediaPlanVersion Assumes an media plan version returned from the backend,
 * this method will fill the weeks from the beginning of the plan version to the end of the plan version.
 * It will do it base of calendar View type selected for the plan version
 * @returns {IMediaPlanVersion} with all the flights needed from the beginning of the plan version to the end
 * of plan version
 */
export const initialiseMediaPlanVersionFlights = (mediaPlanVersion: IMediaPlanVersion): IMediaPlanVersion => {
  const planData = JSON.parse(mediaPlanVersion.data)
  const { startDayOfWeek } = planData

  // This is needed to synch moment globally
  if (startDayOfWeek) {
    setMomentLocale(startDayOfWeek)
  }

  const parseData = addPlaceholderFlights(mediaPlanVersion.mediaPlanVersionFields, planData)
  const mediaPlanVersionWithPlaceholderFlights = {
    ...mediaPlanVersion,
    parseData
  }
  return mediaPlanVersionWithPlaceholderFlights
}

const addPlaceholderFlights = (mediaPlanVersionFields, mediaPlanVersionData) => {
  const planData = cloneObject(mediaPlanVersionData)

  // For each flight group we need to fill the flight gaps
  planData.flightGroups.forEach((flightGroup) => {
    // if flight exists we need to fill gaps from the beginning of the plan to the end
    if (flightGroup.flights && flightGroup.flights.length > 0) {
      let emptyCells = []
      // make sure that flights are in order
      // eslint-disable-next-line functional/immutable-data
      flightGroup.flights.sort((a, b) => a.flightStartDate.localeCompare(b.flightStartDate))
      /*
        Each flight has a width base in pixels so we apply to all, also flights coming from the back
        All the flights coming from the backend data are not being set as merge we need to this as well
      */
      // eslint-disable-next-line functional/immutable-data
      flightGroup.flights = flightWidthCalculation(flightGroup.flights.map(flight => ({ ...flight, merge: true })))
      flightGroup.flights.forEach((f, flightIndex) => {
        // check for week gaps between each flight
        if (flightIndex + 1 <= flightGroup.flights.length - 1) {
          const startDate = moment(f.flightEndDate).add(1, 'day').toDate()
          const endDate = moment(flightGroup.flights[flightIndex + 1].flightStartDate).subtract(1, 'day').toDate()

          if (isDateGap(startDate, endDate) || isSame(startDate, endDate)) {
            // we attach empty flights not merge
            emptyCells = emptyCells.concat(buildEmptyFlightGrid(startDate, endDate, mediaPlanVersionFields, flightGroup))
          }
        }
        // if the current flight has subflights we need to fill gaps as well
        if (f.subFlights) {
          /*
            Each flight has a width base in pixels so we apply to all, also flights coming from the back
            All the flights coming from the backend data are not being set as merge we need to this as well
          */
          // eslint-disable-next-line functional/immutable-data
          f.subFlights = subFlightWidthCalculation(f.subFlights.map(subFlight => ({ ...subFlight, merge: true })))
          if (f.subFlights.length > 0) {
            let emptySubFlights = []
            // find the subflights within the chosen flight fields
            const subFlights = findByFieldLevelId(mediaPlanVersionFields, FieldLevelType.SUB_FLIGHT)
            const chosenSubFlights = flightGroup.chosenFlightFields.map(c => subFlights.find(m => m.clientMediaPlanFieldId === c.clientMediaPlanFieldId)).filter(c => c)
            const result = chosenSubFlights.map(sf => sf.clientMediaPlanFieldId)
            result.forEach(clientMediaPlanFieldId => {
              const subFlightsByClientId = f.subFlights.filter(sf => sf.clientMediaPlanFieldId === clientMediaPlanFieldId)
              if (subFlightsByClientId.length > 0) {
                subFlightsByClientId.forEach((sf, subFlightIndex) => {
                  // fill the gap between subflights
                  if (subFlightIndex + 1 <= subFlightsByClientId.length - 1) {
                    const startDate = moment(sf.subFlightEndDate).add(1, 'day').toDate()
                    const endDate = moment(subFlightsByClientId[subFlightIndex + 1].subFlightStartDate).subtract(1, 'day').toDate()
                    if (isDateGap(startDate, endDate) || isSame(startDate, endDate)) {
                      const missingFlights = buildEmptySubFlights(startDate, endDate, mediaPlanVersionFields, flightGroup)
                      emptySubFlights = emptySubFlights.concat(missingFlights.filter(ef => ef.clientMediaPlanFieldId === clientMediaPlanFieldId))
                    }
                  }
                })

                // fill the gap between the beginning of the flight and the first subflight
                if (isDateGap(f.flightStartDate, subFlightsByClientId[0].subFlightStartDate)) {
                  const subFlightStartDate = moment(f.flightStartDate).toDate()
                  const subFlightEndDate = moment(subFlightsByClientId[0].subFlightStartDate).subtract(1, 'day').toDate()
                  const missingFlights = buildEmptySubFlights(subFlightStartDate, subFlightEndDate, mediaPlanVersionFields, flightGroup)
                  emptySubFlights = emptySubFlights.concat(missingFlights.filter(ef => ef.clientMediaPlanFieldId === clientMediaPlanFieldId))
                }

                // fill the gap between the last subflight and the end of the flight
                if (isDateGap(subFlightsByClientId[subFlightsByClientId.length - 1].subFlightEndDate, f.flightEndDate)) {
                  const subFlightStartDate = moment(subFlightsByClientId[subFlightsByClientId.length - 1].subFlightEndDate).add(1, 'day').toDate()
                  const subFlightEndDate = moment(f.flightEndDate).toDate()
                  const missingFlights = buildEmptySubFlights(
                    subFlightStartDate,
                    subFlightEndDate,
                    mediaPlanVersionFields,
                    flightGroup
                  )
                  emptySubFlights = emptySubFlights.concat(missingFlights.filter(ef => ef.clientMediaPlanFieldId === clientMediaPlanFieldId))
                }
              } else {
                // if no subflights we add an empty subflights grid to the current flight
                const startDate = moment(f.flightStartDate)
                const endDate = moment(f.flightEndDate)
                const missingFlights = buildEmptySubFlights(startDate, endDate, mediaPlanVersionFields, flightGroup)
                emptySubFlights = emptySubFlights.concat(missingFlights.filter(ef => ef.clientMediaPlanFieldId === clientMediaPlanFieldId))
              }
            })
            // add all the subflights found in gaps
            const totalSubFlights = f.subFlights.concat(emptySubFlights)
            // order
            totalSubFlights.sort((current, next) => moment(next.subFlightStartDate).isBefore(moment(current.subFlightStartDate)) ? 1 : -1)
            // Add those flights to the current flight
            // eslint-disable-next-line functional/immutable-data
            f.subFlights = totalSubFlights
          } else {
            const startDate = moment(f.flightStartDate)
            const endDate = moment(f.flightEndDate)
            // eslint-disable-next-line functional/immutable-data
            f.subFlights = buildEmptySubFlights(startDate, endDate, mediaPlanVersionFields, flightGroup)
          }
        } else {
          // no subflight we add an empty subflights from the begging to the end of the flight
          const startDate = moment(f.flightStartDate)
          const endDate = moment(f.flightEndDate)
          // eslint-disable-next-line functional/immutable-data
          f.subFlights = buildEmptySubFlights(startDate, endDate, mediaPlanVersionFields, flightGroup)
        }
      })

      // Check gaps from the beginning to the plan version to the first merged flight start date

      if (isDateGap(planData.planStartDate, flightGroup.flights[0].flightStartDate)) {
        const startDate = moment(planData.planStartDate)
        const endDate = moment(flightGroup.flights[0].flightStartDate).subtract(1, 'day')
        emptyCells = emptyCells.concat(buildEmptyFlightGrid(startDate, endDate, mediaPlanVersionFields, flightGroup))
      }

      // Check gaps from the last merge flight to the end of the plan version

      if (isDateGap(flightGroup.flights[flightGroup.flights.length - 1].flightEndDate, planData.planEndDate)) {
        const startDate = moment(flightGroup.flights[flightGroup.flights.length - 1].flightEndDate).add(1, 'day')
        const endDate = moment(planData.planEndDate)
        emptyCells = emptyCells.concat(buildEmptyFlightGrid(startDate, endDate, mediaPlanVersionFields, flightGroup))
      }
      const totalCells = flightGroup.flights.concat(emptyCells)
      totalCells.sort((current, next) => moment(next.flightStartDate).isBefore(moment(current.flightStartDate)) ? 1 : -1)
      // eslint-disable-next-line functional/immutable-data
      flightGroup.flights = totalCells
    }
  })

  return planData
}

const getSubFlightsData = (mediaPlanVersionFields, flightGroup, subFlightsCreated) => {
  let subFlightsData = []
  const subFlights = findByFieldLevelId(mediaPlanVersionFields, FieldLevelType.SUB_FLIGHT)
  const chosenSubFlights = flightGroup.chosenFlightFields.map(c => subFlights.find(m => m.clientMediaPlanFieldId === c.clientMediaPlanFieldId)).filter(c => c)
  chosenSubFlights.forEach((csf) => {
    const subFlightsWithDefaultValue = subFlightsCreated.map((subFlight, index) => index === 0
      ? { ...subFlight, [formatLowerCaseFirstLetter(csf.clientMediaPlanField.mediaPlanField.columnName)]: getDefaultValue(csf), clientMediaPlanFieldId: csf.clientMediaPlanFieldId }
      : { ...subFlight, [formatLowerCaseFirstLetter(csf.clientMediaPlanField.mediaPlanField.columnName)]: '', clientMediaPlanFieldId: csf.clientMediaPlanFieldId })

    subFlightsData = subFlightsData.concat(subFlightsWithDefaultValue)
  })

  return subFlightsData
}

const getCachedSubFlightsData = (startDate, endDate, mediaPlanVersionFields, flightGroup) => {
  let cachedValue = cachedSubFlightsData.find((value) => value.startDate === startDate && value.endDate === endDate)


  if (!cachedValue) {
    const result = getWeeksBetweenByWeekDay(startDate, endDate)
    const subFlightsCreated = subFlightWidthCalculation(result.map((date) => ({
      subFlightStartDate: date.flightStartDate.format(dateFormat),
      subFlightEndDate: date.flightEndDate.format(dateFormat)
    })))

    const subFlightsData = getSubFlightsData(mediaPlanVersionFields, flightGroup, subFlightsCreated)
    cachedValue = { startDate, endDate, result, subFlightsCreated, subFlightsData }
    // eslint-disable-next-line functional/immutable-data
    cachedSubFlightsData.push(cachedValue)
  }

  return cachedValue
}

export const buildEmptySubFlights = (startDate, endDate, mediaPlanVersionFields, flightGroup) => {
  const { subFlightsData } = getCachedSubFlightsData(startDate, endDate, mediaPlanVersionFields, flightGroup)

  return subFlightsData
}

export const buildEmptyFlightGrid = (startDate, endDate, mediaPlanVersionFields, flightGroup) => {
  const { result, subFlightsData } = getCachedSubFlightsData(startDate, endDate, mediaPlanVersionFields, flightGroup)
  const formatResult = result.map((r) => ({
    flightStartDate: r.flightStartDate.format(dateFormat),
    flightEndDate: r.flightEndDate.format(dateFormat),
    subFlights: subFlightsData.filter(sf => findSubFlightBetweenDates(sf, r.flightStartDate.format(dateFormat), r.flightEndDate.format(dateFormat)))
  }))

  return flightWidthCalculation(formatResult)
}

export const setMomentLocale = (dayStartOfWeek: string) => {
  const weekDays = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  }

  moment.updateLocale('en', {
    week: {
      dow: weekDays[dayStartOfWeek],
      doy: 6
    }
  })
}
