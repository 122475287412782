export enum CalculationMessage {
  UpdateMediaPlanRevised = 'Updating Media Plan',
  SaveMediaPlanVersionRevised = 'Saving Media Plan Version',
  AddFlightGroup = 'Adding Flight Group',
  DuplicateFlightGroup = 'Duplicating Flight Group',
  DeleteFlightGroup = 'Deleting Flight Group',
  DeleteFlight = 'Deleting Flight',
  DeleteSubFlight = 'Deleting SubFlight',
  ClearFlightData = 'Clearing Flight Data',
  RemoveFlightTableView = 'Removing Flight Table View',
  DuplicateFlightTableView = 'Duplicating Flight Table View',
}
