import { useCallback, useMemo } from 'react'
import { IMediaPlanMetaField, isFieldValidForCalculation, isValidForGoalSeek, isFieldValidForClick, IMediaPlanVersionField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { WizardProgress } from 'Components/GoalSeek/constants/entities/IGoalSeekData'

export interface IClickableFieldProps {
  goalSeekWizardProgress: WizardProgress
  goalSeekModalVisible: boolean
  selectedCalculatedField: IMediaPlanMetaField
  mediaPlanVersionFields: IMediaPlanVersionField[]
}

export const useClickableField = (props: IClickableFieldProps) => {
  const {
    goalSeekWizardProgress,
    goalSeekModalVisible,
    selectedCalculatedField,
    mediaPlanVersionFields
  } = props
  const isCurrentFieldValidForClick = useCallback((item: IMediaPlanMetaField) => {
    if (goalSeekModalVisible) {
      return isValidForGoalSeek(item, mediaPlanVersionFields, goalSeekWizardProgress)
    } else {
      return isFieldValidForClick(item, selectedCalculatedField) && isFieldValidForCalculation(item, selectedCalculatedField)
    }
  }, [mediaPlanVersionFields, goalSeekModalVisible, goalSeekWizardProgress, selectedCalculatedField])

  return useMemo(() => ({
    isFieldValidForClick: isCurrentFieldValidForClick
  }), [isCurrentFieldValidForClick])
}
