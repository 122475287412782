import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { TemplateFieldAvailability } from 'Constants/enums/TemplateFieldAvailability'
import { IMediaPlanField, createMediaPlanField } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'
import { ClientFieldValue } from 'Apis/generated/clientFieldAliasApi'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { Nullable } from 'Helpers/INullable'
import { v4 as uuid } from 'uuid'

export interface IMediaPlanFieldRow extends Omit<IMediaPlanTemplateFields, 'defaultValue'> {
  clientFieldValues?: Nullable<ClientFieldValue[]>
  columnName?: Nullable<string>
  fieldName?: Nullable<string>
  savedFieldLabel?: string
  key?: number
  isDisabled?: boolean
  isMandatory?: boolean
  isVisible?: boolean
  mediaPlanField?: IMediaPlanField
  mediaPlanFieldId?: number
  itemRowId?: string
  isUsed?: boolean
  defaultValue?: string | number
}

export const createMediaPlanFieldRow = (
  mediaPlanFieldProps: Partial<IMediaPlanFieldRow> = {}
): IMediaPlanFieldRow => ({
  templateFieldTypeId: TemplateFieldTypes.MANUAL,
  templateFieldAvailabilityId: TemplateFieldAvailability.DEFAULT,
  itemRowId: uuid(),
  isMandatory: false,
  isEditable: true,
  isFooter: false,
  mediaPlanField: createMediaPlanField(),
  ...mediaPlanFieldProps
})
