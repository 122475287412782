import React, { useEffect, useState, useMemo } from 'react'
import { Button, message } from 'antd'
import { navigate, MSLayoutContentRow, LoadingComponent, MSClientHierarchySelection, MSNavigationBlocker } from 'mindshare.layout'

import { getClientMediaPlanFields } from 'Actions/clientMediaPlanFieldActions'
import { getTemplateMetaData, saveMediaPlanTemplates, updateMediaPlanTemplatesByItemRow } from 'Actions/templatesActions'
import { cleanUpTemplateView, getMediaPlanTemplateById } from 'Actions/mediaPlanTemplatesActions'
import { appPaths } from '../../../providers/AppRoutes'

import { TemplateFieldSelectionTab, TemplateFieldSelectionTabs } from 'Components/TemplateFieldSelection/TemplateFieldSelectionTabs'
import TemplateTitleComponent from 'Components/TemplateTitleComponent'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { MediaPlanTemplateWithMediaTemplateFields } from 'Apis/generated/templatesApi'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { useFilteredHierarchies } from 'Hooks/CustomHooks'
import { useTemplateFieldRows } from 'Components/TemplateFieldSelection/hooks/useTemplateFieldRows'
import { useMediaPlanHierarchyValidation } from 'Hooks/useMediaPlanHierarchyValidation'
import { useTemplateLinkedLookups } from 'Components/TemplateFieldSelection/hooks/useTemplateLinkedLookups'
import { useTemplateMasteredFields } from 'Components/TemplateFieldSelection/hooks/useTemplateMasteredFields'
import { baseApi } from 'Apis/generated/baseApi'
import { useAppDispatch, useAppSelector } from '../../../store'
import { validateMasteredFields } from 'Helpers/validateMasteredFields'
import { selectCurrentMediaPlanTemplate } from '../../../selectors'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useHierarchies } from 'Hooks/useHierarchies'
import { useMasteredListFieldsData } from 'Hooks/useMasteredListFieldsData'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { getModifiedMediaPlanTemplate, getBaseMediaPlanTemplate } from 'Containers/views/Template/helpers/mediaPlanTemplateHelper'
import { DEFAULT_TEMPLATE_FIELD_ORDER } from 'Containers/views/Template/constants/template'
import { useFieldTags } from 'Hooks/useFieldTags'
import { useClientSettings } from 'Components/Client/hooks/useClientSettings'
import { useTemplateHierarchiesFields } from 'Components/TemplateFieldSelection/hooks/useTemplateHierarchiesFields'
import * as actionTypes from 'Constants/actionTypes'

interface ITemplateEditTabContainerProps {
  templateId?: number
  templateName: string
  setTemplateName: React.Dispatch<React.SetStateAction<string>>
  hasUnsavedChanges: boolean
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>
}

const TemplateEditTabContainer: React.FunctionComponent<ITemplateEditTabContainerProps> = ({ templateId, templateName, setTemplateName, hasUnsavedChanges, setHasUnsavedChanges }): React.ReactElement => {
  const dispatch = useAppDispatch()
  const templateFieldType = useAppSelector((state) => state.templates.templateFieldType)
  const templateAvailabilityType = useAppSelector((state) => state.templates.templateAvailabilityType)
  const clientMediaPlanFields = useAppSelector((state) => state.mediaPlanDataTerms.clientMediaPlanFields)
  const mediaPlanTemplate = useAppSelector(selectCurrentMediaPlanTemplate) as MediaPlanTemplateWithMediaTemplateFields
  const errors = useAppSelector((state) => state.templates.errors)
  const { data: currentClient } = useCurrentClient()
  const { data: hierarchies } = useHierarchies(currentClient?.id)
  const masteredListsData = useMasteredListFieldsData(currentClient?.id)
  const { data: tags } = useFieldTags()
  const { data: clientSettings } = useClientSettings()

  const [leftAlignNumbersInExcelExport, setLeftAlignNumbersInExcelExport] = useState(false)
  const [availableMetaData, setAvailableMetaData] = useState<IClientMediaPlanField[]>([])
  const [loading, setLoading] = useState(true)
  const [selectedTab, setSelectedTab] = useState<number>()
  const [hierarchiesLoading, setHierarchiesLoading] = useState(true)
  const [templateSaving, setTemplateSaving] = useState(false)
  const [selectedHierarchies, setSelectedHierarchies] = useState({
    geography: null, media: null, business: null, brand: null
  })
  const [hideSortOrderInExcelExport, setHideSortOrderInExcelExport] = useState(false)
  const { filteredHierarchies } = useFilteredHierarchies(
    selectedHierarchies.brand, selectedHierarchies.business, selectedHierarchies.geography, selectedHierarchies.media
  )
  const requiredHierarchies = {
    isBrandRequired: clientSettings.isBrandRequired,
    isBusinessRequired: clientSettings.isBusinessRequired,
    isGeographyRequired: clientSettings.isGeographyRequired,
    isMediaRequired: clientSettings.isMediaRequired
  }

  const {
    templateFieldRows, templateFieldRowsForTab, setTemplateFieldRows,
    sortCount, setSortCount,
    rowFunctions
  } = useTemplateFieldRows({
    clientMediaPlanFields,
    mediaPlanTemplateFields: mediaPlanTemplate.mediaPlanTemplateFields,
    selectedTab,
    availableMetaData,
    hierarchies,
    setAvailableMetaData,
    setSelectedHierarchies
  })
  const linkedLookupFieldsHelperValues = useTemplateLinkedLookups({ templateFieldRows, templateFieldRowsForTab })
  const masteredDataHelperValues = useTemplateMasteredFields({ templateFieldRows })
  const selectedParentHierarchiesHelperValues = useTemplateHierarchiesFields({ templateFieldRows, selectedTab })

  const isEditMode = templateId > 0

  const modifiedMediaPlanTemplate = useMemo(
    () =>
      getModifiedMediaPlanTemplate(
        mediaPlanTemplate,
        templateFieldRows,
        templateName,
        templateId,
        leftAlignNumbersInExcelExport,
        selectedHierarchies,
        hideSortOrderInExcelExport
      ),
    [
      mediaPlanTemplate,
      templateFieldRows,
      templateName,
      templateId,
      leftAlignNumbersInExcelExport,
      selectedHierarchies,
      hideSortOrderInExcelExport
    ]
  )

  const unmodifiedMediaPlanTemplate = useMemo(() => {
    const mediaPlanTemplateFields = mediaPlanTemplate.mediaPlanTemplateFields?.map(m => rowFunctions.createFromMediaPlanTemplateFields(m))
    const baseMediaPlanTemplate = getBaseMediaPlanTemplate(mediaPlanTemplate, mediaPlanTemplateFields)

    return baseMediaPlanTemplate
  }, [mediaPlanTemplate, rowFunctions])

  useEffect(() => {
    setHasUnsavedChanges(isEditMode && !templateSaving && JSON.stringify(unmodifiedMediaPlanTemplate) !== JSON.stringify(modifiedMediaPlanTemplate))
  }, [modifiedMediaPlanTemplate, unmodifiedMediaPlanTemplate, isEditMode, templateSaving, setHasUnsavedChanges])

  const initialise = {
    editMode: () => {
      setTemplateName(mediaPlanTemplate.templateName)
      setLeftAlignNumbersInExcelExport(mediaPlanTemplate.leftAlignNumbersInExcelExport)
      setHideSortOrderInExcelExport(mediaPlanTemplate.hideSortOrderInExcelExport)
      const myItems = mediaPlanTemplate.mediaPlanTemplateFields && mediaPlanTemplate.mediaPlanTemplateFields.length > 0
        ? mediaPlanTemplate.mediaPlanTemplateFields.map(m => rowFunctions.createFromMediaPlanTemplateFields(m)) : []
      setSortCount(myItems.length - 1)
      setTemplateFieldRows([...myItems])
    },
    screen: async () => {
      let actions = [
        dispatch(getClientMediaPlanFields(currentClient.id)),
        dispatch(getTemplateMetaData(currentClient.id))
      ]
      if (isEditMode) {
        actions = [...actions, dispatch(getMediaPlanTemplateById(templateId, currentClient.id))]
      }
      await Promise.all(actions)
      setSelectedTab(TemplateFieldSelectionTab.PLAN)
    },
    tab: () => {
      if (selectedTab > 0 && templateFieldRows.length === 0) {
        let mandatoryTemplates = rowFunctions.createFromClientMediaPlanFields(clientMediaPlanFields.filter(el => el.isMandatory && !el.isDisabled && !el.mediaPlanField.fieldDataType.isClientHierarchy))

        if (!mandatoryTemplates.some(c => c.mediaPlanField.fieldLevelId === selectedTab)) {
          const itemRow = rowFunctions.createRow(selectedTab, sortCount)
          mandatoryTemplates = [...mandatoryTemplates, itemRow]
        }

        if (selectedTab === FieldLevelType.PLAN) {
          const clientHierarchyTemplates = rowFunctions.createFromClientMediaPlanFields(clientMediaPlanFields.filter(c => c.mediaPlanField.fieldDataType.isClientHierarchy && c.mediaPlanField.fieldLevelId === selectedTab && !c.isDisabled))

          // apply default sorting for plan tab fields for new template
          mandatoryTemplates = isEditMode
            ? [...mandatoryTemplates, ...clientHierarchyTemplates]
            : [...mandatoryTemplates, ...clientHierarchyTemplates].sort(
              (a, b) =>
                DEFAULT_TEMPLATE_FIELD_ORDER.indexOf(
                  a.mediaPlanField.columnName
                ) -
                  DEFAULT_TEMPLATE_FIELD_ORDER.indexOf(
                    b.mediaPlanField.columnName
                  )
            ).map((item, index) => ({ ...item, sortOrder: index }))
        }

        setTemplateFieldRows(mandatoryTemplates)
      } else if (selectedTab > 0 && !templateFieldRows.some(c => c.mediaPlanField.fieldLevelId === selectedTab)) {
        const itemRow = rowFunctions.createRow(selectedTab)
        setTemplateFieldRows([...templateFieldRows, itemRow])
      }

      setAvailableMetaData(
        clientMediaPlanFields
          .filter(f => f.mediaPlanField.fieldLevelId === selectedTab && !f.isDisabled)
          .filter(f => !f.isMandatory && !templateFieldRows.some(r => r.clientMediaPlanFieldId === f.clientMediaPlanFieldId))
      )
      setLoading(false)
    }
  }

  const isMasteredFieldsValid = useMemo(() => validateMasteredFields({ templateFieldRows }), [templateFieldRows])
  const isValid = templateName !== '' &&
    templateFieldRows.filter(c => c.fieldName && c.fieldName !== '')
      .every(c => c.templateFieldAvailabilityId > 0 && c.templateFieldTypeId > 0) &&
    templateFieldRows.filter(c => !c.isEditable && c.templateFieldTypeId === TemplateFieldTypes.MANUAL && c.fieldName !== '')
      .every(c => c.defaultValue) && isMasteredFieldsValid

  useEffect(() => () => dispatch(cleanUpTemplateView()), [dispatch])

  useEffect(() => {
    if (currentClient && currentClient.id > 0) {
      initialise.screen()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id])

  useEffect(() => {
    initialise.tab()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  useEffect(() => {
    if (isEditMode && mediaPlanTemplate && mediaPlanTemplate.mediaPlanTemplateId) {
      initialise.editMode()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, mediaPlanTemplate.mediaPlanTemplateId, mediaPlanTemplate.modifiedOn])

  const saveFunctions = useMemo(() => ({
    itemNotSelected: (itemRow: IMediaPlanFieldRow) => itemRow.clientMediaPlanFieldId > 0,
    saveMediaPlanTemplate: async () => {
      const mediaPlanTemplateId = await saveMediaPlanTemplates(
        templateFieldRows.filter(i => saveFunctions.itemNotSelected(i)).map(saveFunctions.convertDefaultValueToString),
        templateName, currentClient.id, leftAlignNumbersInExcelExport, selectedHierarchies, hideSortOrderInExcelExport
      )
      // TODO: permanent fix MDSGLOMPT-3759 is to move all template actions to rtkQuery hook -> MDSGLOMPT-3881
      dispatch(baseApi.util.invalidateTags(['templateList']))
      navigate(`${appPaths.templateExample}/${mediaPlanTemplateId}`, false, { clientId: currentClient.id })
    },
    updateMediaPlanTemplate: async () => {
      try {
        const filteredTemplateFieldRows = templateFieldRows.filter(i => saveFunctions.itemNotSelected(i)).map(saveFunctions.convertDefaultValueToString)

        await updateMediaPlanTemplatesByItemRow(
          filteredTemplateFieldRows,
          templateName, templateId, currentClient.id, leftAlignNumbersInExcelExport, selectedHierarchies, hideSortOrderInExcelExport
        )
        // TODO: permanent fix MDSGLOMPT-3759 is to move all template actions to rtkQuery hook -> MDSGLOMPT-3881
        dispatch(baseApi.util.invalidateTags(['templateList']))
        dispatch({ type: actionTypes.SET_TEMPLATE_ERRORS, payload: [] })
        navigate(`${appPaths.templateExample}/${templateId}`, false, { clientId: currentClient.id })
      } catch (err) {
        if (err.httpResponseBody) {
          dispatch({ type: actionTypes.SET_TEMPLATE_ERRORS, payload: err.httpResponseBody })

          const errMessage = Array.isArray(err.httpResponseBody) &&
            err.httpResponseBody.map((e) => e.error).join('\r\n')

          message.error(errMessage)
        } else {
          message.error('There was an error saving this template')
        }
      }
    },
    submitTemplates: async () => {
      try {
        setHierarchiesLoading(true)
        if (isEditMode) {
          setTemplateSaving(true)
          await saveFunctions.updateMediaPlanTemplate()
          setTemplateSaving(false)
        } else {
          await saveFunctions.saveMediaPlanTemplate()
        }
      } catch (err) {
        setHierarchiesLoading(false)
        setTemplateSaving(false)
        const errMessage = err.httpResponseBody && Array.isArray(err.httpResponseBody)
          ? err.httpResponseBody.map((e) => e.error).join('\r\n')
          : 'There was an error saving this template'

        message.error(errMessage)
      }
    },
    convertDefaultValueToString: (itemRow: IMediaPlanFieldRow) => ({ ...itemRow, defaultValue: itemRow.defaultValue?.toString() || itemRow.defaultValue })
  }), [
    dispatch,
    templateId,
    currentClient.id,
    templateName,
    isEditMode,
    templateFieldRows,
    leftAlignNumbersInExcelExport,
    selectedHierarchies,
    hideSortOrderInExcelExport
  ])

  const { missingSelectedTemplateHierarchies } = useMediaPlanHierarchyValidation(
    mediaPlanTemplate, undefined, !(loading || hierarchiesLoading)
  )

  // Template hierarchies validation
  useEffect(() => {
    if (isEditMode && !hierarchiesLoading && clientMediaPlanFields.length) {
      Object.keys(selectedHierarchies).forEach((hierarchyname) => {
        if (mediaPlanTemplate[`${hierarchyname}HierarchyId`] && selectedHierarchies[hierarchyname] === null) {
          if (missingSelectedTemplateHierarchies.includes(hierarchyname as any)) {
            rowFunctions.onHierarchyFilterSelectionChange(hierarchyname, undefined)
          } else {
            rowFunctions.onHierarchyFilterSelectionChange(hierarchyname, mediaPlanTemplate[`${hierarchyname}HierarchyId`])
          }
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEditMode, hierarchies, mediaPlanTemplate, selectedHierarchies,
    missingSelectedTemplateHierarchies, clientMediaPlanFields, hierarchiesLoading
  ])

  // Load template and hierarchies TODO: Use loading container
  useEffect(() => {
    if (
      hierarchiesLoading &&
      hierarchies.clientGeographyHierarchies.length &&
      (!isEditMode || (isEditMode && mediaPlanTemplate.mediaPlanTemplateId))
    ) {
      setHierarchiesLoading(false)
    }
  }, [hierarchiesLoading, isEditMode, hierarchies, mediaPlanTemplate])

  return loading || hierarchiesLoading ? (
    <LoadingComponent
      appDataSuccess={!!currentClient.id}
      agencyLoadingGif={currentClient.agency?.agencyLoadingGifLocation}
    />
  ) : (
    <MSNavigationBlocker isActive={hasUnsavedChanges}>
      <>
        <MSLayoutContentRow>
          <TemplateTitleComponent
            inputLabel='Template Title'
            inputValue={templateName}
            onTemplateNameChange={setTemplateName}
            placeholder='Enter Template Title'
            tags={tags || []}
            title={isEditMode ? 'Edit Template' : 'Create Template'}
            tooltip='templateEdit'
            leftAlignNumbersInExcelExport={leftAlignNumbersInExcelExport}
            onLeftAlignExportChange={setLeftAlignNumbersInExcelExport}
            hideSortOrderInExcelExport={hideSortOrderInExcelExport}
            onHideSortOrderExportChange={setHideSortOrderInExcelExport}
          />
        </MSLayoutContentRow>

        <MSLayoutContentRow>
          <MSClientHierarchySelection
            hierarchies={hierarchies}
            selectedGeography={selectedHierarchies.geography}
            selectedMedia={selectedHierarchies.media}
            selectedBusiness={selectedHierarchies.business}
            selectedBrand={selectedHierarchies.brand}
            onChange={rowFunctions.onHierarchyFilterSelectionChange}
          />
        </MSLayoutContentRow>

        <MSLayoutContentRow>
          <TemplateFieldSelectionTabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            templateFieldRowsForTab={templateFieldRowsForTab}
            hierarchies={filteredHierarchies}
            selectedParentHierarchiesHelperValues={selectedParentHierarchiesHelperValues}
            selectedHierarchies={selectedHierarchies}
            masteredListsData={masteredListsData}
            availableMetaData={availableMetaData}
            rowFunctions={rowFunctions}
            templateAvailability={templateAvailabilityType}
            templateFieldType={templateFieldType}
            isTemplateAliasingEnabled={clientSettings.isTemplateAliasingEnabled}
            linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
            masteredDataHelperValues={masteredDataHelperValues}
            errors={errors}
            requiredHierarchies={requiredHierarchies}
          />
        </MSLayoutContentRow>

        <MSLayoutContentRow>
          <Button type='primary' className='ms-template-tabs-back-button' onClick={() => navigate(appPaths.templateList)}>
          Back to Template list
          </Button>
          <Button
            className='ms-template-tabs-save-button'
            disabled={!isValid}
            onClick={saveFunctions.submitTemplates}
            type='primary'
          >
          Save
          </Button>
        </MSLayoutContentRow>
      </>
    </MSNavigationBlocker>
  )
}

export default TemplateEditTabContainer
