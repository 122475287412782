import { useMemo } from 'react'
import { useFinanceDataProducts } from 'Components/FinanceData/hooks/useFinanceDataProducts'
import { useFinanceDataStations } from 'Components/FinanceData/hooks/useFinanceDataStations'
import { transformMasteredHierarchies } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { useGetCostMasterDataBuyingRouteHierarchy } from 'Components/CostMasterData/hooks/useGetCostMasterDataBuyingRouteHierarchy'

export const useFrontSheetMasteredHierarchiesData = () => {
  const {
    data: financeDataProducts
  } = useFinanceDataProducts({
    clientGeographyHierarchyId: undefined,
    clientMediaHierarchyId: undefined
  })
  const {
    data: financeDataStations
  } = useFinanceDataStations({
    clientGeographyHierarchyId: undefined,
    clientMediaHierarchyId: undefined
  })
  const {
    data: costBuyingRouteData
  } = useGetCostMasterDataBuyingRouteHierarchy({
    clientGeographyHierarchyId: undefined,
    clientMediaHierarchyId: undefined
  })

  const financeProductsHierarchies = useMemo(() => transformMasteredHierarchies('financeProduct', financeDataProducts), [financeDataProducts])
  const financeStationsHierarchies = useMemo(() => transformMasteredHierarchies('financeStation', financeDataStations), [financeDataStations])
  const costBuyingRouteHierarchies = useMemo(() => transformMasteredHierarchies('costBuyingRoute', costBuyingRouteData), [costBuyingRouteData])

  return useMemo(() => ({
    currentMasteredHierarchies: {
      ...financeProductsHierarchies,
      ...financeStationsHierarchies,
      ...costBuyingRouteHierarchies
    }
  }), [
    financeProductsHierarchies,
    financeStationsHierarchies,
    costBuyingRouteHierarchies
  ])
}
