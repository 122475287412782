import { Agency, useBuyingAgenciesQuery } from 'Apis/generated/buyingAgenciesApi'
import { ClientCampaign, useClientCampaignsQuery } from 'Apis/generated/clientCampaignsApi'
import { ClientMediaPartner, useClientMediaPartnersQuery } from 'Apis/generated/clientMediaPartnersApi'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { useMemo } from 'react'

export interface IMasteredListsData {
  clientCampaigns: ClientCampaign[]
  clientAgencies: Agency[]
  mediaPartners: ClientMediaPartner[]
  isMasteredListsDataLoading: boolean
}

export const MasteredListConfig = {
  [FieldDataType.CLIENT_CAMPAIGNS]: {
    dataSourceKey: 'clientCampaigns',
    idKey: 'clientCampaignId',
    displayKey: 'clientCampaignName'
  },
  [FieldDataType.CLIENT_AGENCY_LIST]: {
    dataSourceKey: 'clientAgencies',
    idKey: 'agencyId',
    displayKey: 'agencyDisplayName'
  },
  [FieldDataType.MEDIA_PARTNERS]: {
    dataSourceKey: 'mediaPartners',
    idKey: 'clientMediaPartnerId',
    displayKey: 'mediaPartnerValue'
  }
}

export const useMasteredListFieldsData = (clientId: number): IMasteredListsData => {
  const { data: clientCampaigns = [], isLoading: areClientCampaignsLoading } = useClientCampaignsQuery({ clientId })
  const { data: clientAgencies = [], isLoading: areClientAgenciesLoading } = useBuyingAgenciesQuery({ clientId })
  const { data: mediaPartners = [], isLoading: areMediaPartnersLoading } = useClientMediaPartnersQuery({ clientId })

  return useMemo(() => ({
    clientCampaigns,
    clientAgencies,
    mediaPartners,
    isMasteredListsDataLoading: areClientCampaignsLoading || areClientAgenciesLoading || areMediaPartnersLoading
  }), [clientCampaigns, clientAgencies, mediaPartners, areClientCampaignsLoading, areClientAgenciesLoading, areMediaPartnersLoading])
}
