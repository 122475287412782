import { IClientHierarchy, IMSHierarchies, getClientHierarchyNode, getClientHierarchyTypeDetails, IClientHierarchyName, IHierarchyName, IGenericHierarchies, getHierarchyLevelProp, getHierarchyValueProp } from 'mindshare.layout'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { HierarchyLevel } from 'Apis/generated/hierarchiesApi'
import { HierarchiesWithLevels } from 'Apis/generated/costMasterDataApi'

export const filterClientHierarchies = (hierarchies: Partial<IMSHierarchies>, dataType: FieldDataType, hierarchyId: number): IClientHierarchy[] => {
  let clientHierarchies: IClientHierarchy[] = []
  let hierarchyNode: IClientHierarchy
  switch (dataType) {
    case FieldDataType.BRAND_HIERARCHY:
      clientHierarchies = hierarchies.clientBrandHierarchies
      hierarchyNode = getClientHierarchyNode(clientHierarchies, 'brand', hierarchyId)
      break
    case FieldDataType.BUSINESS_HIERARCHY:
      clientHierarchies = hierarchies.clientBusinessHierarchies
      hierarchyNode = getClientHierarchyNode(clientHierarchies, 'business', hierarchyId)
      break
    case FieldDataType.GEOGRAPHY_HIERARCHY:
      clientHierarchies = hierarchies.clientGeographyHierarchies
      hierarchyNode = getClientHierarchyNode(clientHierarchies, 'geography', hierarchyId)
      break
    case FieldDataType.MEDIA_HIERARCHY:
      clientHierarchies = hierarchies.clientMediaHierarchies
      hierarchyNode = getClientHierarchyNode(clientHierarchies, 'media', hierarchyId)
      break
  }
  return hierarchyNode ? [hierarchyNode] : clientHierarchies
}

export const expandGeographies = ({ idProp, valueProp, hierarchyList = [] }, revert = false) => {
  let format = {
    key: valueProp,
    value: idProp
  }
  if (!revert) {
    format = {
      key: idProp,
      value: valueProp
    }
  }
  return hierarchyList.reduce((result, item) => {
    if (item.children?.length > 0) {
      return { ...result, [item[format.key]]: item[format.value], ...expandGeographies(getClientHierarchyTypeDetails('geography', item.children)) }
    } else {
      return {
        ...result,
        [item[format.key]]: item[format.value]
      }
    }
  }, {})
}

export const getHierarchyListBySpecificLevel = (hierarchyList: IClientHierarchy[] | IGenericHierarchies[], hierarchyLevels: HierarchyLevel[], requiredHierarchyLevelId: number, includeLevelsBelowHierarchy: boolean) => {
  const hierarchyLevelNumber = hierarchyLevels.find(el => el.hierarchyLevelId === requiredHierarchyLevelId)?.hierarchyLevelNumber

  const processHierarchy = (data: IClientHierarchy[] | IGenericHierarchies[] = []) => data.map((el) => {
    const disabled = requiredHierarchyLevelId && !isNaN(el.levelNumber) ? el.levelNumber < hierarchyLevelNumber : false
    const children = !requiredHierarchyLevelId
      ? el.children
      : !includeLevelsBelowHierarchy && el.levelNumber >= hierarchyLevelNumber ? [] : processHierarchy(el.children)

    return { ...el, children, options: { disabled, 'data-testid': `levelNumber-${el.levelNumber}` } }
  })

  return processHierarchy(hierarchyList)
}

export const CLIENT_HIERARCHY_TYPES_MAP: Record<number, IClientHierarchyName> = {
  90: 'business',
  100: 'brand',
  110: 'media',
  120: 'geography'
}

export const FINANCE_HIERARCHY_TYPES_MAP: Record<number, IHierarchyName> = {
  160: 'financeProduct',
  170: 'financeStation',
  220: 'costBuyingRoute'
}

export const HIERARCHY_TYPES_MAP: Record<number, IClientHierarchyName | IHierarchyName> = {
  ...CLIENT_HIERARCHY_TYPES_MAP,
  ...FINANCE_HIERARCHY_TYPES_MAP
}

export const expandHierarchies = ({ hierarchyList = [] }, dataTypeName) =>
  hierarchyList?.reduce((result, item) => {
    if (item.children?.length > 0) {
      return [...result, { value: item.value, title: item.title }, ...expandHierarchies(getClientHierarchyTypeDetails(dataTypeName, item.children), dataTypeName)]
    } else {
      return [
        ...result,
        { value: item.value, title: item.title }
      ]
    }
  }, [])

export const transformMasteredHierarchies = (hierarchyType: IHierarchyName, hierarchyList: HierarchiesWithLevels) => {
  const keyProp = getHierarchyValueProp(hierarchyType)
  const levelProp = getHierarchyLevelProp(hierarchyType)

  return {
    [keyProp]: hierarchyList?.hierarchies || [],
    [levelProp]: hierarchyList?.hierarchyLevels || []
  }
}
