import { useMemo } from 'react'
import { GetCustomExportsApiArg, GetCustomExportsApiResponse } from 'Apis/generated/customExportsApi'
import { useGetCustomExportsQuery } from 'Apis/enhancedCustomExportsApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseQueryCommand } from '../../../redux/types'

export const useCustomExportList = ({ geographyHierarchyId }: Omit<GetCustomExportsApiArg, 'clientId'>): UseQueryCommand<GetCustomExportsApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetCustomExportsQuery(
    { clientId: currentClient.id, geographyHierarchyId },
    { skip: !currentClient.id }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
