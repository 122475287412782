const text = `# Create/Edit Front Sheet – View editor
Information on the different sections, functionality and rules present in the Create/Edit Front Sheet page.

## Views
In a single Front Sheet, you can include a number of different views. Each view is displayed with a separate tab.

### Add new view
To add a new view to the front sheet, simply select the plus tab next to the current tab(s).

### Edit View Name
Select the edit pencil on the tab, enter your desired name, followed by the tick icon.

### Delete View
In the top right you will see the button **Delete View**. This will delete the selected View. Once you select delete view, you must then confirm you actually want to delete the view. Once it is deleted you will not be able to find that view again.

## Field Selection

### Laydown Field
The Laydown field is the field represented in the calendar in the front sheet. This list will contain all fields present at flight level in ALL of the plans selected. Only one field can be selected per view.

### Group By
#### Field Name
The field selected will represent the fields which the aggregated fields are grouped by.  
Hierarchy Level – If the field selected is a Hierarchy field, you must specify which level of the hierarchy you wish to be used.

#### Subtotals
If you wish there to be subtotals present in your front sheet view, you will need to select subtotals for one of the fields. The field you select for the subtotal column will represent where the subtotals begin. If you do not wish for there to be any subtotals in your front sheet, select the bottom field in the list.

### Add new field
Add another field at the bottom of the list.

### Sort
Click the sort icon and drag the field to the position you want it to be in.

### Aggregated

#### Field Name
This list contains a list of the reporting metric fields. Fields selected here will be reflected in the subtotal columns. A field cannot be present in the list of selected fields for both group by and aggregated fields.

#### Add new field
Add another field at the bottom of the list.

#### Sort
Click the sort icon and drag the field to the position you want it to be in.

## Back to Plan Selection
This will take you back to the previous edit page where you are able to select the list of plans included in your front sheet, as well as the front sheet name.

## Save
To save a Front Sheet you must have per view:
- Group By – At least one field selected under field name. If this is a hierarchy field, the hierarchy level must also be selected. 
- Aggregated – At least one field selected under field name.

If the Front Sheet has been successfully saved, you will receive a green pop up confirming.

## View Front Sheet
This will take you to the Front Sheet view page, where you are able to see the Front Sheet that has been created. If you have made any changes to your Front Sheet, you will first have to Save your front sheet.
`

export default text
