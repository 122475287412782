/* eslint-disable no-fallthrough */
import { createSelector } from 'reselect'
import { TOP_LEVEL_HIERARCHIES } from 'mindshare.layout'

import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { ISelectedHierarchies } from 'Components/TemplateFieldSelection/constants/entities/ISelectedHierarchies'
import { MediaPlanTemplate } from 'Apis/generated/templatesApi'
import { selectParseData } from './mediaPlansSelectors'

interface ISelectedParentHierarchiesParams {
  fieldLevelId: number
  flightGroupIndex?: number
}

export const createSelectedParentHierarchiesSelector = () => createSelector(
  selectParseData,
  (state) => state.mediaPlanTemplates.currentMediaPlanTemplate,
  (state, params: ISelectedParentHierarchiesParams) => params.fieldLevelId,
  (state, params: ISelectedParentHierarchiesParams) => params.flightGroupIndex,
  (parseData, currentTemplate: MediaPlanTemplate, fieldLevelId: number, flightGroupIndex: number) => {
    return TOP_LEVEL_HIERARCHIES.reduce((acc: ISelectedHierarchies, hierarchyName) => {
      let hierarchyValue: number
      switch (fieldLevelId) {
        case FieldLevelType.FLIGHT: {
          hierarchyValue = parseData.flightGroups[flightGroupIndex]?.[`${hierarchyName}HierarchyValue`]
          if (hierarchyValue) break
        }
        case FieldLevelType.FLIGHT_GROUP: {
          hierarchyValue = parseData[`${hierarchyName}HierarchyValue`]
          if (hierarchyValue) break
        }
        case FieldLevelType.PLAN: {
          hierarchyValue = currentTemplate?.[`${hierarchyName}HierarchyId`]
        }
      }
      return {
        ...acc,
        [hierarchyName]: hierarchyValue
      }
    }, {
      brand: null,
      business: null,
      geography: null,
      media: null
    })
  }
)
