import { MediaPlanField, MediaPlanFieldLevel, MappingDestinationOptions, FieldDataTypeRead } from 'Apis/generated/mediaPlanFieldsApi'

export interface IFieldDataTypeExtended extends Omit<FieldDataTypeRead, 'mappingDestination'> {
  mappingDestination?: number | MappingDestinationOptions
}
export interface IMediaPlanFieldExtended extends Omit<MediaPlanField, 'defaultValue' | 'fieldDataType'> {
  key?: number
  isSelected?: boolean
  isVisible?: boolean
  defaultValue?: string | number
  fieldDataType?: IFieldDataTypeExtended
}

export interface IMediaPlanFieldLevelExtended extends Omit<MediaPlanFieldLevel, 'defaultValue' | 'fieldDataType'> {
  defaultValue?: string | number
  fieldDataType?: IFieldDataTypeExtended
}

export type IMediaPlanField = Partial<IMediaPlanFieldExtended> & Partial<IMediaPlanFieldLevelExtended>

export const createMediaPlanField = (
  mediaPlanFieldProps: IMediaPlanField = {}
): IMediaPlanFieldExtended => ({
  isVisible: true,
  ...mediaPlanFieldProps
})
