import React from 'react'
import { Button } from 'antd'
import { MSLayoutTitleRow } from 'mindshare.layout'
import InfoModalComponent from 'Components/InfoModalComponent'

export interface IButton {
  buttonText?: string
  buttonClick?: () => void
  disable?: boolean
}

export interface IProps {
  title: string
  subtitle?: string
  extraContent?: React.ElementType
  buttons?: IButton[]
  tooltipText?: string
}

export const TitleAndButtonsComponent: React.FunctionComponent<IProps> = ({
  title,
  subtitle,
  extraContent,
  buttons,
  tooltipText
}) => (
  <MSLayoutTitleRow
    subtitle={subtitle}
    extraContent={(
      <TitleButtons
        buttons={buttons}
        tooltipText={tooltipText}
        extraContent={extraContent}
      />
    )}
  >{title}</MSLayoutTitleRow>
)
export default TitleAndButtonsComponent


export interface ITitleButtonsProps {
  extraContent?: React.ElementType
  buttons?: IButton[]
  tooltipText?: string
}

export const TitleButtons: React.FunctionComponent<ITitleButtonsProps> = ({
  extraContent: ExtraContent,
  buttons,
  tooltipText
}) => (
  <div className='title-and-button'>
    <div className='title-and-button__buttons'>
      {ExtraContent && <ExtraContent />}
      {buttons?.map((button, btn_index) => (
        <Button
          key={btn_index}
          className='ms-button title-and-button__button'
          type='primary'
          onClick={button.buttonClick}
          disabled={button.disable}
        >
          {button.buttonText}
        </Button>
      ))}
      {tooltipText && <InfoModalComponent text={tooltipText} />}
    </div>
  </div>
)
