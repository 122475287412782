import React, { useEffect, useState } from 'react'
import { Switch } from 'antd'
import { MediaPlanTemplateFieldAlias, PutMediaPlanTemplateFieldByMediaPlanTemplateFieldIdApproveAliasApiArg } from 'Apis/generated/mediaPlanTemplateFieldsApi'
import TemplateFieldAliasTable from './TemplateFieldAliasTable'

interface TemplateFieldAliasSetupProps {
  clientId: number
  data: MediaPlanTemplateFieldAlias[]
  updateStatus: (data: PutMediaPlanTemplateFieldByMediaPlanTemplateFieldIdApproveAliasApiArg) => void
  updateCache: (endpoint: string, fieldName: string, fieldId: number, approved: boolean) => void
}

export default function TemplateFieldAliasSetup (props: TemplateFieldAliasSetupProps) {
  const { clientId, data, updateStatus, updateCache } = props
  const [editedItems, setEditedItems] = useState<number[]>([])
  const [showApproved, setShowApproved] = useState(false)
  const [templateFieldAliases, setTemplateFieldAliases] = useState(data.filter(x => !x.approvedBy))

  useEffect(() => {
    const aliases = showApproved ? data : data.filter(x => !x.approvedBy || editedItems.find(y => y === x.mediaPlanTemplateFieldId))
    setTemplateFieldAliases(aliases)
  }, [data, showApproved, editedItems])

  const updateShowApproved = (show: boolean) => {
    setEditedItems([])
    setShowApproved(show)
  }

  const doUpdateStatus = async (mediaPlanTemplateFieldId: number, approve: boolean) => {
    setEditedItems([...editedItems, mediaPlanTemplateFieldId])
    updateCache('getMediaPlanTemplateFields', 'mediaPlanTemplateFieldId', mediaPlanTemplateFieldId, approve)
    updateStatus({ clientId, mediaPlanTemplateFieldId, approveAliasRequest: { approve } })
  }

  return (
    <div className="aliasTab templateAliasTab">
      <div>
        <h2>Template Field Alias Approvals {showApproved ? '- All' : ' - Unapproved'}</h2>
        <div className='aliasTab__toggle'>
          <span>Show approved</span>
          <Switch
            size='small'
            onClick={() => updateShowApproved(!showApproved)}
            data-testid='btn-toggle-calculation'
            aria-label='show approved'
            checked={showApproved}
          />
        </div>
      </div>

      <TemplateFieldAliasTable
        data={templateFieldAliases}
        updateStatus={doUpdateStatus}
      />
    </div>
  )
}
