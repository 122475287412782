import { useMemo } from 'react'
import { Client } from 'Apis/generated/clientsApi'
import { UseQueryCommand } from '../redux/types'
import { selectCurrentClient } from '../selectors'
import { useAppSelector } from '../store'

export const useCurrentClient = (): UseQueryCommand<Client> => {
  const currentClient = useAppSelector(selectCurrentClient)

  return useMemo(() => ({
    isLoading: false,
    data: currentClient
  }), [currentClient])
}
