import React from 'react'
import { Button, Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { MSVirtualTable } from 'mindshare.layout'
import { DataImport } from 'Apis/generated/dataImportsApi'

export interface IProps {
  currentClientDisplayName: string
  createNewImport: () => void
  dataImports: DataImport[]
  editImport: (importId: number) => void
}

const ImportListComponent: React.FC<IProps> = (props) => {
  if (props.dataImports && props.dataImports.length > 0) {
    const columns = [
      {
        title: 'Import Name',
        dataIndex: 'importName',
        key: 'importName'
      },
      {
        title: 'Enabled',
        dataIndex: 'enabled',
        key: 'enabled'
      },
      {
        title: '',
        dataIndex: 'buttons',
        className: 'button-cell',
        key: 'buttons'
      }
    ]

    const data = props.dataImports.map((item, i) => {
      return {
        key: i,
        importName: item.dataImportName,
        enabled: item.isActive ? 'Enabled' : 'Disabled',
        buttons: (
          <Tooltip title='Edit Import'>
            <Button
              data-testid='edit-import'
              icon={<EditOutlined />}
              onClick={() => props.editImport(item.dataImportId)}
              type='link'
            />
          </Tooltip>
        )
      }
    })

    return (
      <div className='list-table imports-list-table'>
        <MSVirtualTable
          columns={columns}
          dataSource={data}
        />
      </div>
    )
  } else {
    return (
      <div className='list-table imports-list-table' data-testid='no-imports'>
        <p>
          No Imports for {props.currentClientDisplayName}. <a onClick={props.createNewImport}>Create an Import</a>
        </p>
      </div>
    )
  }
}

export default ImportListComponent
