import React from 'react'
import { DeleteFilled, StarFilled } from '@ant-design/icons'
import { Button } from 'antd'
import { IMSHierarchies } from 'mindshare.layout'
import { IMediaPlanTemplateFields, getCorrectDisplayField, getCssClassByInputType } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { TemplateFieldAvailability } from 'Constants/enums/TemplateFieldAvailability'
import FieldAvailableComponent from 'Components/FieldAvailableComponent'
import RenderByTypeComponent from 'Components/RenderByTypeComponent'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  error: string
  mediaPlanField: IMediaPlanTemplateFields
  value: string | number
  valid: boolean
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  mediaPlanFields: IMediaPlanTemplateFields[]
  calculationMode: boolean
  hierarchies?: IMSHierarchies
  masteredListsData: IMasteredListsData
  updateDataValues?: (selectedValue: string | number) => void
  setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void
  onAvailableFieldSelected: (c: string, mediaPlanFields: IMediaPlanTemplateFields[],
    setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void,
    availableFields: IMediaPlanTemplateFields[]) => void
  tokenHandler: (token: string) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  isTemplate: boolean
}

export const TemplateExamplePlanLevelItemComponent: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    valid,
    mediaPlanField,
    mediaPlanFields,
    availableMediaPlanFields,
    calculationMode,
    hierarchies,
    updateDataValues,
    setFunction,
    onAvailableFieldSelected,
    tokenHandler,
    value,
    masteredListsData,
    error,
    removePlanField,
    isTemplate
  } = props

  const id = mediaPlanField.mediaPlanTemplateFieldId || mediaPlanField.mediaPlanVersionFieldId
  const fieldLabel = getCorrectDisplayField(mediaPlanField)
  const defaultOrAvailable = mediaPlanField.templateFieldAvailabilityId === TemplateFieldAvailability.DEFAULT ||
    mediaPlanField.templateFieldAvailabilityId === TemplateFieldAvailability.AVAILABLE

  const fieldTypeClasses = getCssClassByInputType(mediaPlanField)

  if (id !== 0) {
    return (
      <div className='container-row-item'>
        {defaultOrAvailable ? (
          <Button
            className='ant-btn-icon-only'
            data-testid='btn-delete-field'
            icon={<DeleteFilled />}
            onClick={() => removePlanField(mediaPlanField)}
          />
        ) : (
          <Button
            className='ant-btn-icon-only ant-btn-icon-no-click'
            icon={<StarFilled />}
          />
        )}
        <label data-testid={mediaPlanField.mediaPlanVersionFieldId} className={`input-item-label ${fieldTypeClasses}`}>{fieldLabel}</label>
        <RenderByTypeComponent
          calculationMode={calculationMode}
          mediaPlanField={mediaPlanField}
          masteredListsData={masteredListsData}
          hierarchies={hierarchies}
          tokenHandler={tokenHandler}
          updateDataValues={updateDataValues}
          valid={valid}
          value={value}
          defaultClass={'input-item-value'}
          error={error}
          isTemplate={isTemplate}
          isMasteredHierarchyFieldPickable={true}
        />
      </div>
    )
  } else if (availableMediaPlanFields.length) {
    return (
      <div className='container-row-item'>
        <label className={`input-item-label ${fieldTypeClasses}`}>Available Fields</label>
        <FieldAvailableComponent
          onAvailableFieldSelected={(selectedValue: string) => onAvailableFieldSelected(selectedValue, mediaPlanFields, setFunction, availableMediaPlanFields)}
          availableMediaPlanFields={availableMediaPlanFields}
        />
      </div>
    )
  } else {
    return <></>
  }
}
export default TemplateExamplePlanLevelItemComponent
