import MultipleSortComponent from 'Components/MultipleSortComponent'
import { ISortColumn } from 'Components/MediaPlanVersion/constants/entities/ISortColumn'
import { ISortItem } from 'Components/MediaPlanVersion/constants/entities/ISortItem'
import React, { FunctionComponent, useState, useEffect } from 'react'

interface IProps {
  columns: ISortColumn[]
  sortItems: ISortItem[]
  setSortItems: (sorts: ISortItem[]) => void
}

export const MultipleSortContainer: FunctionComponent<IProps> = (props: IProps) => {
  const {
    columns,
    sortItems,
    setSortItems
  } = props

  const [showDeleteButton, setShowDeleteButton] = useState<boolean>()

  const handleChangeColumn = (index: number, value: string) => {
    setSortItems(sortItems && sortItems.map((sortItem, sortItemIndex) => {
      if (sortItemIndex === index) {
        return { ...sortItem, column: value }
      } else {
        return sortItem
      }
    }))
  }

  const handleChangeAscending = (index: number, value: boolean) => {
    setSortItems(sortItems && sortItems.map((sortItem, sortItemIndex) => {
      if (sortItemIndex === index) {
        return { ...sortItem, ascending: value }
      } else {
        return sortItem
      }
    }))
  }

  const handleAdd = (index: number) => {
    if (columns && columns.length) {
      const sortItemToAdd: ISortItem = sortItems && sortItems.length ? sortItems[index] : { column: columns[0].name, ascending: false }
      setSortItems([
        ...sortItems.slice(0, index + 1),
        sortItemToAdd,
        ...sortItems.slice(index + 1)
      ])
    }
  }

  const handleDelete = (index: number) => {
    if (sortItems && sortItems.length > 1) {
      setSortItems(sortItems.filter((sortItem, sortItemIndex) => sortItem && sortItemIndex !== index))
    }
  }

  useEffect(() => {
    setShowDeleteButton(sortItems && sortItems.length > 1)
  }, [sortItems])

  useEffect(() => {
    if (sortItems && sortItems.length > 0) {
      setSortItems(sortItems.filter((sortItem) => columns.some((column) => sortItem.column === column.name)))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (sortItems && !sortItems.length) {
      handleAdd(0)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortItems])

  return (
    <MultipleSortComponent
      columns={columns}
      sortItems={sortItems}
      showDeleteButton={showDeleteButton}
      handleChangeColumn={handleChangeColumn}
      handleChangeAscending={handleChangeAscending}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
    />
  )
}

export default MultipleSortContainer
