import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createFlightGroupFlightsSelector } from '../../../selectors'
import { useDeepEqualMemoized } from 'Hooks/useDeepEqualMemoized'

export const useFlightGroupFlights = (flightGroupIndex: number) => {
  const selectFlightGroupFlights = useMemo(createFlightGroupFlightsSelector, [])

  const flightGroupFlights = useSelector(state => selectFlightGroupFlights(state, flightGroupIndex))

  const memoizedFlightGroupFlights = useDeepEqualMemoized(flightGroupFlights)

  return memoizedFlightGroupFlights
}
