import { useMemo } from 'react'
import { IMediaPlanVersionLinkedLookupHelperValues } from '../entities/IMediaPlanVersionLinkedLookupHelperValues'
import { useLinkedLookupsList } from 'Components/LinkedLookup/hooks/useLinkedLookupsList'
import { getLinkedLookupVariables } from 'Components/MediaPlanVersion/hooks/linkedLookupHelper'
import { useAppSelector } from '../../../store'
import { selectCurrentMediaPlanVersionFields, selectParseData } from '../../../selectors'
import { useDeepEqualMemoized } from 'Hooks/useDeepEqualMemoized'

export const usePlanLinkedLookups = (): IMediaPlanVersionLinkedLookupHelperValues => {
  const { data: linkedLookups = [] } = useLinkedLookupsList()
  const mediaPlanVersionFields = useAppSelector(selectCurrentMediaPlanVersionFields)
  const parseData = useAppSelector(selectParseData)

  const mediaPlanFieldsWithLookups = useMemo(() => mediaPlanVersionFields.filter((item) =>
    linkedLookups.some((l) => item.clientMediaPlanField.mediaPlanFieldId === l.dependentMediaPlanFieldId)
  ), [mediaPlanVersionFields, linkedLookups])

  const linkedLookupValues = useMemo(
    () => {
      return mediaPlanFieldsWithLookups.reduce((lookupValues: IMediaPlanVersionLinkedLookupHelperValues, currentField) => ({
        ...lookupValues,
        [currentField.clientMediaPlanField.mediaPlanFieldId]:
            getLinkedLookupVariables(
              { currentField, fields: mediaPlanVersionFields, linkedLookups, parseData }
            )
      }), {})
    },
    [mediaPlanFieldsWithLookups, mediaPlanVersionFields, linkedLookups, parseData]
  )

  const memoizedLinkedLookupValues = useDeepEqualMemoized(linkedLookupValues)

  return memoizedLinkedLookupValues
}

