import React from 'react'
import { MSFeatureSwitch } from 'mindshare.layout'

import createEditFrontSheetPlanSelectionText from 'Components/tooltipContent/FrontSheets/Create Edit Front Sheet Plan selection'
import createEditFrontSheetViewEditorText from 'Components/tooltipContent/FrontSheets/Create Edit Front Sheet View editor'
import templateEditText from 'Components/tooltipContent/Edit Template'
import InfoModalComponent from './InfoModalComponent'
import TemplateSyncModalComponent from './TemplateSync/TemplateSyncModalComponent'

interface IProps {
  tooltip: string
  isPublishDisabled?: boolean
  isPublishVisible?: boolean
  onAfterPublish?: () => void
}

const TooltipButton: React.FC<IProps> = ({ tooltip, isPublishDisabled, isPublishVisible, onAfterPublish }) => {
  switch (tooltip) {
    case 'templateEdit': {
      return (
        <div className='tooltip'>
          {isPublishVisible && (
            <MSFeatureSwitch name='TEMPLATE_SYNC'>
              <TemplateSyncModalComponent isPublishDisabled={isPublishDisabled} onAfterPublish={onAfterPublish}/>
            </MSFeatureSwitch>
          )}
          <InfoModalComponent text={templateEditText} isUnwrapped />
        </div>
      )
    }
    case 'createEditFrontSheetPlanSelection': {
      return <InfoModalComponent text={createEditFrontSheetPlanSelectionText} />
    }
    case 'createEditFrontSheetViewEditor': {
      return <InfoModalComponent text={createEditFrontSheetViewEditorText} />
    }

    default:
      return null
  }
}

export default TooltipButton
