import { Select } from 'antd'
import React from 'react'
import { SortDirection } from '../enums'

export const SortDirectionSelector = ({ value, onChange }) => (
  <Select
    id='sort-order'
    className='select select--fluid'
    data-testid='sort-direction'
    onChange={(sortAscending) => onChange({ sortAscending: Boolean(sortAscending) })}
    value={Number(value)}
  >
    <Select.Option value={SortDirection.ASC}>
      ASC
    </Select.Option>
    <Select.Option value={SortDirection.DESC}>
      DESC
    </Select.Option>
  </Select>
)
