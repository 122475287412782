import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getClientSettings: build.query<
      GetClientSettingsApiResponse,
      GetClientSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/clientSettings`,
        params: { clientId: queryArg.clientId },
      }),
    }),
    postClientSettings: build.mutation<
      PostClientSettingsApiResponse,
      PostClientSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/clientSettings`,
        method: "POST",
        body: queryArg.clientSetting,
        params: { clientId: queryArg.clientId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetClientSettingsApiResponse =
  /** status 200 Returns Client Settings */ ClientSetting;
export type GetClientSettingsApiArg = {
  clientId: number;
};
export type PostClientSettingsApiResponse = unknown;
export type PostClientSettingsApiArg = {
  clientId: number;
  clientSetting: ClientSetting;
};
export type PlanningStage = {
  planningStageId?: number;
  planningStageName?: string | null;
  planningStageDescription?: string | null;
};
export type ClientSetting = {
  isTemplateAliasingEnabled?: boolean;
  isBrandRequired?: boolean;
  isBusinessRequired?: boolean;
  isGeographyRequired?: boolean;
  isMediaRequired?: boolean;
  isAzureDataLakeEnabled?: boolean;
  planningYear?: number | null;
  importPlanningStageId?: number | null;
  preserveApprovedPlans?: boolean;
  availablePlanYears?: number[] | null;
  planningStages?: PlanningStage[] | null;
};
export const {
  useGetClientSettingsQuery,
  useLazyGetClientSettingsQuery,
  usePostClientSettingsMutation,
} = injectedRtkApi;
