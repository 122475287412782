import React, { CSSProperties, FunctionComponent, useState, useCallback, useMemo } from 'react'
import { Button, Tooltip } from 'antd'
import { PlusCircleOutlined, ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons'
import { IMSHierarchies } from 'mindshare.layout'
import { IMediaPlanTemplateFields, getFieldId } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import FlightGroupLevelHeaderComponent from 'Components/FlightGroupLevelHeaderComponent'
import { addToCollapsedFlightGroups, removeFromCollapsedFlightGroups } from 'Actions/appActions'
import { addFlightGroup, collapseAllFlightGroupRows, expandAllFlightGroupRows } from 'Actions/mediaPlansActions'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { IStickyColumn } from 'Constants/entities/IStickyColumn'
import { useAppDispatch } from '../../store'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { IExpandedHierarchiesFlightGroupsValues } from 'Components/Hierarchies/helpers/getExpandedHierarchiesValues'
import { IFilterFlightGroupDataWithExpandedHierarchies } from 'Components/MediaPlanVersion/hooks/useFilterDataWithExpandedHierarchies'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  flightGroups: IFlightGroup[]
  filterFlightGroupData: IFilterFlightGroupDataWithExpandedHierarchies
  hierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  sortedFlightGroupFields: IMediaPlanTemplateFields[]
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  setHeaderWidths?: (id: number, width: number) => void
  toggleColumnSticky?: (id: number) => void
  getStickyStyle?: (type: StickyColumnType, id?: number) => CSSProperties
  stickyHeaders?: IStickyColumn[]
  expandedHierarchiesFlightGroupsValues: IExpandedHierarchiesFlightGroupsValues
  financeList: IMediaPlanVersionFinanceListFields
}

const FlightGroupHeaderContainer: FunctionComponent<IProps> = ({
  flightGroups,
  filterFlightGroupData,
  hierarchies,
  masteredListsData,
  sortedFlightGroupFields,
  setHeaderWidths,
  removePlanField,
  toggleColumnSticky,
  getStickyStyle,
  stickyHeaders,
  expandedHierarchiesFlightGroupsValues,
  financeList
}) => {
  const dispatch = useAppDispatch()
  const [flightGroupRowsCollapsed, setFlightGroupRowsCollapsed] = useState(false)
  const subtotalGroups = useMemo(() => flightGroups.filter((f) => f.subtotal), [flightGroups])

  const setCollapsedFlightGroups = useCallback((add: boolean, id: number) => {
    if (add) {
      dispatch(addToCollapsedFlightGroups(id))
    } else {
      dispatch(removeFromCollapsedFlightGroups(id))
    }
  }, [dispatch])

  const handleAddFlightGroup = useCallback(() => dispatch(addFlightGroup(sortedFlightGroupFields)), [dispatch, sortedFlightGroupFields])

  const handleFlightGroupRowsCollapseAll = useCallback(() => {
    if (flightGroupRowsCollapsed) {
      dispatch(expandAllFlightGroupRows())
    } else {
      dispatch(collapseAllFlightGroupRows(subtotalGroups))
    }
    setFlightGroupRowsCollapsed(!flightGroupRowsCollapsed)
  }, [dispatch, flightGroupRowsCollapsed, subtotalGroups])

  return (
    <>
      <th className='plus-button-container' rowSpan={5} style={getStickyStyle ? getStickyStyle(StickyColumnType.toolbar) : {}}>
        <div className='flightGroup__toolbar -header'>
          <Tooltip placement = 'right' title='Add flight group'>
            <Button
              icon={<PlusCircleOutlined />}
              size='large'
              type='link'
              tabIndex={-1}
              onClick={handleAddFlightGroup}
              data-testid='btn-add-group'
            />
          </Tooltip>
          <Tooltip placement='topRight' title={flightGroupRowsCollapsed ? 'Expand flight group' : 'Collapse flight group'}>
            <Button
              icon={flightGroupRowsCollapsed ? <ArrowsAltOutlined /> : <ShrinkOutlined />}
              size='large'
              type='link'
              tabIndex={-1}
              onClick={handleFlightGroupRowsCollapseAll}
              data-testid='btn-collapse-all-flight-group-rows'
            />
          </Tooltip>
        </div>
      </th>

      {sortedFlightGroupFields.map((item) => (
        <FlightGroupLevelHeaderComponent
          key={item.clientMediaPlanFieldId}
          mediaPlanField={item}
          hierarchies={hierarchies}
          masteredListsData={masteredListsData}
          flightGroups={flightGroups}
          filterFlightGroupData={filterFlightGroupData}
          setCollapsedFlightGroups={setCollapsedFlightGroups}
          removePlanField={removePlanField}
          setHeaderWidths={setHeaderWidths}
          toggleColumnSticky={toggleColumnSticky}
          getStickyStyle={getStickyStyle}
          isSticky={!!stickyHeaders.find(x => x.id === getFieldId(item) && x.sticky)}
          expandedHierarchiesFlightGroupsValues={expandedHierarchiesFlightGroupsValues}
          financeList={financeList}
        />
      ))}
    </>
  )
}

export default FlightGroupHeaderContainer
