import React from 'react'
import { Card } from 'antd'
import { DndContext } from '@dnd-kit/core'
import type { DragEndEvent } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  SortableListBody,
  SortableListCell,
  SortableListHeader,
  SortableListRow
} from 'Components/shared/SortableList'
import { useCustomExportOptions } from '../hooks'
import { createOptionValue, IDisplayField, initialDisplayField, updateOrder } from '../constants/entities'
import { CustomExportDisplayFieldItemComponent } from 'Components/CustomExport/components/CustomExportDisplayFieldItemComponent'

export const CustomExportDisplayFields = ({ customExport, customExportFields }) => {
  const { options, selectedOptions } = useCustomExportOptions({
    fields: customExport.values.customExportDisplayFields,
    customExportFields
  })

  const handleUpdate = (fieldIndex: number) => (field: Partial<IDisplayField>) => customExport.updateField(
    'customExportDisplayFields',
    fieldIndex,
    field
  )

  const handlePush = () => customExport.pushField('customExportDisplayFields', {
    ...initialDisplayField,
    customExportId: customExport.values.customExportId,
    displayOrder: customExport.values.customExportDisplayFields.length
  })

  const handleRemove = (fieldIndex: number) => () => customExport.removeField(
    'customExportDisplayFields',
    fieldIndex,
    updateOrder('displayOrder')
  )

  const handleMove = ({ active, over }: DragEndEvent) => {
    const oldIndex = customExport.values.customExportDisplayFields.findIndex((i) => i.itemRowId === active.id)
    const newIndex = customExport.values.customExportDisplayFields.findIndex((i) => i.itemRowId === over?.id)

    customExport.moveField(
      'customExportDisplayFields',
      oldIndex,
      newIndex,
      updateOrder('displayOrder')
    )
  }

  return (
    <Card className='sortable-list' data-testid='sortable-list'>
      <SortableListHeader>
        <SortableListRow>
          <SortableListCell>
            Field
          </SortableListCell>
          <SortableListCell className='actions-cell'>
            {/* <Actions /> */}
          </SortableListCell>
          <SortableListCell className='sortable-list__drag-icon'>
            {/* <DragIcon /> */}
          </SortableListCell>
        </SortableListRow>
      </SortableListHeader>

      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleMove}>
        <SortableContext
          items={customExport.values.customExportDisplayFields.map((item) => item.itemRowId)}
          strategy={verticalListSortingStrategy}
        >
          <SortableListBody>
            {customExport.values.customExportDisplayFields.map((item, fieldIndex) => {
              const optionId = createOptionValue(item)
              const { field = {}, value = null, label = null } = selectedOptions[optionId] || {}
              const canRemove = !!(field.mediaPlanField?.fieldLabel === 'Plan Name') || !field?.isMandatory

              return (
                <CustomExportDisplayFieldItemComponent
                  key={fieldIndex}
                  fieldIndex={fieldIndex}
                  handleUpdate={handleUpdate}
                  item={item}
                  canRemove={canRemove}
                  handleRemove={handleRemove}
                  handlePush={handlePush}
                  value={value}
                  label={label}
                  options={options}
                />
              )
            })}
          </SortableListBody>
        </SortableContext>
      </DndContext>
    </Card>
  )
}

export default CustomExportDisplayFields
