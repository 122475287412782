import React from 'react'
import ReactDOM from 'react-dom'
import ProviderWrapper from './ProviderWrapper'
import './scss/app.scss'

// UNCOMMENT TO RUN APP WITH MOCK DATA
// INITIALISE MSW BEFORE FIRST RUN - `npx msw init public/ --save`
// import allHandlers from './mocks/mswHandlers'
// import { MSWBrowser } from 'mindshare.services/lib/msw/MSWBrowser'
// if (process.env.NODE_ENV === 'development') {
//   MSWBrowser(allHandlers)
// }

declare const module: any

const renderReactApp = () => {
  ReactDOM.render(
    <ProviderWrapper />,
    document.getElementById('root')
  )
}

renderReactApp()

if (module.hot) {
  module.hot.accept('./ProviderWrapper', function () {
    renderReactApp()
  })
}
