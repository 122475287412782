import { IAuthClaims, msAuthHandler } from '@mindshare/services'
import { baseApi as api } from './generated/baseApi'

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserPermissions: build.query({
      queryFn: async ({ clientName }) => {
        const claimValues: IAuthClaims = {
          template: { value: 'template', matchFunction: (value: string, authValue: string) => authValue.startsWith(value) },
          submitSetUp: { value: `submitSetUp_${clientName.replace(/\W/g, '')}` },
          clientAdmin: { value: `clientAdmin_${clientName.replace(/\W/g, '')}` },
          exportSetup: { value: `exportSetup_${clientName.replace(/\W/g, '')}` },
          exportRun: { value: `exportRun_${clientName.replace(/\W/g, '')}` }
        }

        const userPermissions = await msAuthHandler.verifyAuthorisation(claimValues)

        return { data: userPermissions }
      }
    })
  })
})

export { injectedRtkApi as baseApi }

export const {
  useGetUserPermissionsQuery,
  useLazyGetUserPermissionsQuery
} = injectedRtkApi
