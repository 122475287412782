import React, { useState, useCallback } from 'react'
import Moment from 'react-moment'
import { Button, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { MSVirtualTable, MSLayoutTitleRow } from 'mindshare.layout'
import { compare } from 'Helpers/sortHelper'
import { ISyncTemplateSettings } from 'Constants/entities/IMapSettings'
import { TemplateSyncUsage } from 'Apis/generated/templateSyncApi'
import { Client } from 'Apis/generated/clientsApi'
import { TemplateSyncResultModalComponent } from 'Components/TemplateSync/TemplateSyncResultModalComponent'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'

interface ITemplateUsageTabProps {
  templates: TemplateSyncUsage[]
  clients: Client[]
  filterFunction: (dataIndex: keyof ISyncTemplateSettings, placeholderString: string) => object
  clientMediaPlanFields: IClientMediaPlanField[]
}

const TemplateUsageTabContainer: React.FC<ITemplateUsageTabProps> = ({ templates, clients, filterFunction, clientMediaPlanFields }) => {
  const [visible, setVisible] = useState(false)
  const [templateSyncUsageId, setTemplateSyncUsageId] = useState<number>()

  const showModal = useCallback(() => setVisible(true), [])
  const hideModal = useCallback(() => setVisible(false), [])

  const handleOpenModal = useCallback((id: number) => {
    showModal()
    setTemplateSyncUsageId(id)
  }, [showModal])

  if (templates && templates.length > 0) {
    const columns = [
      {
        className: 'client-name-column-sort',
        title: 'Client Name',
        dataIndex: 'clientName',
        key: 'clientName',
        sorter: (a, b) => compare(a.clientName, b.clientName),
        ...filterFunction('clientName', 'Client Name')
      },
      {
        className: 'client-template-name',
        title: 'Client Template Name',
        dataIndex: 'clientTemplateName',
        key: 'clientTemplateName',
        sorter: (a, b) => compare(a.clientTemplateName, b.clientTemplateName)
      },
      {
        className: 'sync-status',
        title: 'State of last sync with base template',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => compare(a.status, b.status)
      },
      {
        className: 'last-updated',
        title: 'Last updated',
        dataIndex: 'lastUpdated',
        key: 'lastUpdated',
        sorter: (a, b) => compare(new Date(a.lastUpdated), new Date(b.lastUpdated)),
        renderCellContent: (lastUpdated: string) => <Moment format='DD MMM YY h:mm:ss'>{lastUpdated}</Moment>
      },
      {
        title: '',
        dataIndex: 'buttons',
        className: 'button-cell',
        key: 'buttons'
      }
    ]

    const data = templates.map((item) => {
      const clientName = clients.find((c) => c.id === item.destinationClientId)?.displayName

      return {
        key: item.destinationTemplateId,
        clientName,
        clientTemplateName: item.destinationTemplateName,
        status: item.status,
        lastUpdated: item.lastUpdated,
        buttons: (
          <Tooltip title='Info'>
            <Button
              type='link'
              icon={<InfoCircleOutlined />}
              data-testid='info-icon'
              onClick={() => handleOpenModal(item.templateSyncUsageId)}
            />
          </Tooltip>
        )
      }
    })

    return (
      <div className='template-usage-container'>
        <MSLayoutTitleRow extraClass='template-usage-container__header'>Template usage</MSLayoutTitleRow>
        <div className='list-table template-usage-list-table'>
          <MSVirtualTable
            columns={columns}
            dataSource={data}
          />
        </div>
        <TemplateSyncResultModalComponent
          visible={visible}
          hideModal={hideModal}
          templateSyncUsageId={templateSyncUsageId}
          templates={templates}
          setTemplateSyncUsageId={setTemplateSyncUsageId}
          clientMediaPlanFields={clientMediaPlanFields}
        />
      </div>
    )
  }
  return (
    <div className='template-usage-container'>
      <MSLayoutTitleRow extraClass='template-usage-container__header'>Template usage</MSLayoutTitleRow>
      <div className='list-table'>
        <p>
        No linked templates.
        </p>
      </div>
    </div>
  )
}

export default TemplateUsageTabContainer
