import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import { IDisplayField } from './IDisplayField'
import { IFilterField } from './IFilterField'
import { ISortByField } from './ISortByField'

export const isEmptyField = (obj: IDisplayField | ISortByField | IFilterField) => {
  return Boolean('clientMediaPlanFieldId' in obj && obj.clientMediaPlanFieldId) ||
    Boolean('customExportControlFieldId' in obj && obj.customExportControlFieldId)
}

export const updateOrder = <T>(key: string) => (fields: T[], start: number): T[] => {
  const untouched = fields.slice(0, start)
  const updated = fields.slice(start).map((field, position) => ({
    ...field,
    [key]: start + position
  }))

  return [...untouched, ...updated]
}

export const createOptionsFromFields = ({ customExportFields, fields }) => {
  const fieldsMap = fields.reduce((hashTable, { clientMediaPlanFieldId, customExportControlFieldId }) => {
    const rawValue = pickBy({ clientMediaPlanFieldId, customExportControlFieldId }, identity)
    const [entry = []] = Object.entries(rawValue)
    const value = entry.join(':')

    return value
      ? { ...hashTable, [value]: value }
      : hashTable
  }, {})

  return customExportFields.reduce(({ options, selectedOptions }, field) => {
    const fieldId = createOptionValue(field)

    const option = {
      value: fieldId,
      label: createOptionLabel(field),
      field
    }

    return fieldsMap[fieldId]
      ? {
        options,
        selectedOptions: {
          ...selectedOptions,
          [fieldId]: option
        }
      }
      : {
        selectedOptions,
        options: [...options, option]
      }
  }, { options: [], selectedOptions: {} })
}

export const createOptionValue = (field): string => {
  const rawValue = pickBy({
    clientMediaPlanFieldId: field.clientMediaPlanFieldId,
    customExportControlFieldId: field.customExportControlFieldId
  }, identity)

  return (Object.entries(rawValue)[0] ?? []).join(':')
}

const createOptionLabel = (field): string => field.mediaPlanField?.fieldLabel || field.fieldLabel
