import { FieldDataType } from 'Constants/enums/FieldDataType'
import { formatDate } from 'Helpers/formatDate'
import { numberFormatter } from 'Helpers/numberHelper'
import { DATE_FORMAT } from 'Components/Redistribution/constants/redistribution'

export const formatFieldValue = (value: string | number, fieldDataTypeId: number) => {
  switch (fieldDataTypeId) {
    case FieldDataType.DATE:
      return formatDate(value, DATE_FORMAT)
    default:
      return numberFormatter(value?.toString(), fieldDataTypeId)
  }
}
