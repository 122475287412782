import { useCallback, useMemo, useState } from 'react'

export interface IWizardStep {
  content: React.JSX.Element
  nextBtnConfig: {
    disabled?: boolean
    label: string
    onBeforeNext?: () => Promise<boolean>
    tooltipText?: string
  }
  prevBtnConfig?: {
    label: string
    onBeforePrev?: () => Promise<boolean>
  }
}

export const useWizardComponent = (wizardSteps: IWizardStep[]) => {
  const [currentStep, setCurrentStep] = useState(0)

  const nextStep = useCallback(async () => {
    let result = true

    if (wizardSteps[currentStep].nextBtnConfig.onBeforeNext) {
      result = await wizardSteps[currentStep].nextBtnConfig.onBeforeNext()
    }

    if (result && currentStep < wizardSteps.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }, [currentStep, wizardSteps])

  const prevStep = useCallback(async () => {
    let result = true

    if (wizardSteps[currentStep].prevBtnConfig?.onBeforePrev) {
      result = await wizardSteps[currentStep].prevBtnConfig?.onBeforePrev()
    }

    if (result && currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }, [currentStep, wizardSteps])

  const resetSteps = useCallback(() => setCurrentStep(0), [])

  return useMemo(() => ({
    currentStep,
    nextStep,
    prevStep,
    resetSteps
  }), [currentStep, nextStep, prevStep, resetSteps])
}
