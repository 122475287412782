export const newFrontSheetViewAggregateRow = {
  frontSheetViewAggregateId: 0,
  frontSheetViewId: 0,
  functionId: 10,
  sortOrder: 0
}

export const newFrontSheetViewGroupByRow = {
  frontSheetViewGroupById: 0,
  frontSheetViewId: 0,
  subtotalCutOff: false,
  sortOrder: 0
}
