import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

interface DraggableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
  item: any
}

export const SortableItem = ({ children, item, ...props }: DraggableRowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: item?.itemRowId })

  const style: React.CSSProperties = {
    ...props.style,
    transition,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    ...(isDragging ? { position: 'relative' } : {})
  }

  return (
    <tr data-testid="draggable-row" {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'drag') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <div ref={setActivatorNodeRef} {...listeners}>
                {(child as React.ReactElement).props.render()}
              </div>
            )
          })
        }
        return child
      })}
    </tr>
  )
}

export default SortableItem
