import { baseApi as api } from './userPermissionsApi'
import { queryCacheValidTime } from './queryCacheValidTime'

const enhancedRtkApi = api.enhanceEndpoints({
  endpoints: {
    getUserPermissions: { keepUnusedDataFor: queryCacheValidTime.getUserPermissions }
  }
})
export { enhancedRtkApi as baseApi }

export const {
  useGetUserPermissionsQuery,
  useLazyGetUserPermissionsQuery
} = enhancedRtkApi
