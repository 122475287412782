import React, { FunctionComponent } from 'react'
import {
  BusinessHierarchies,
  GeographyHierarchies, MediaHierarchies, MediaPartners,
  ExchangeRates, CampaignNames, CampaignTypes, Genders,
  BrandHierarchies,
  Languages, Devices
} from 'mindshare.clienthierarchies'
import { MSTabs, MSTabPane } from 'mindshare.layout'

export type TabOptions = 'business' | 'geography' | 'brand' | 'media'

interface IProps {
  clientId: number
}


const HierarchySelectionTabComponent: FunctionComponent<IProps> = ({ clientId }) => {
  return (
    <MSTabs
      tabPosition={'left'}
      type='card'
      className='hierarchy-tabs'
      data-testid='client-admin-tabs'
    >
      <MSTabPane tab='Brand' key='hierarchyTabBrand'>
        <div className='hierarchy-tabs__tab-pane'>
          <BrandHierarchies clientId={clientId} />
        </div>
      </MSTabPane>
      <MSTabPane tab='Business' key='hierarchyTabBusiness'>
        <div className='hierarchy-tabs__tab-pane'>
          <BusinessHierarchies clientId={clientId} />
        </div>
      </MSTabPane>
      <MSTabPane tab='Campaign Names' key='campaignNames'>
        <CampaignNames clientId={clientId} />
      </MSTabPane>
      <MSTabPane tab='Campaign Types' key='campaignTypes'>
        <CampaignTypes clientId={clientId} />
      </MSTabPane>
      <MSTabPane tab='Devices' key='devices'>
        <Devices clientId={clientId} />
      </MSTabPane>
      <MSTabPane tab='Exchange Rates' key='exchangeRates'>
        <ExchangeRates clientId={clientId} />
      </MSTabPane>
      <MSTabPane tab='Genders' key='genders'>
        <Genders clientId={clientId} />
      </MSTabPane>
      <MSTabPane tab='Geography' key='hierarchyTabGeography'>
        <div className='hierarchy-tabs__tab-pane'>
          <GeographyHierarchies clientId={clientId} />
        </div>
      </MSTabPane>
      <MSTabPane tab='Languages' key='languages'>
        <Languages clientId={clientId} />
      </MSTabPane>
      <MSTabPane tab='Media' key='hierarchyTabMedia'>
        <div className='hierarchy-tabs__tab-pane'>
          <MediaHierarchies clientId={clientId} />
        </div>
      </MSTabPane>
      <MSTabPane tab='Media Partners' key='mediaPartners'>
        <MediaPartners clientId={clientId} />
      </MSTabPane>
    </MSTabs>
  )
}

export default HierarchySelectionTabComponent
