import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { IMSHierarchies } from 'mindshare.layout'
import FieldAvailableComponent from 'Components/FieldAvailableComponent'
import RenderByTypeComponent from 'Components/RenderByTypeComponent'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  error: string
  collapse: boolean
  flightGroupIndex: number
  hierarchies?: Partial<IMSHierarchies>
  masteredListsData: IMasteredListsData
  calculationMode: boolean
  mediaPlanField: IMediaPlanTemplateFields
  value: string | number
  valid: boolean
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  mediaPlanFields: IMediaPlanTemplateFields[]
  setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void
  onAvailableFieldSelected: (c: string, mediaPlanFields: IMediaPlanTemplateFields[],
    setFunction: (value: React.SetStateAction<IMediaPlanTemplateFields[]>) => void,
    availableFields: IMediaPlanTemplateFields[]) => void
  tokenHandler: (token: string) => void
  updateDataValues: (selectedValue: string | number) => void
  collapsedFlightGroups: number[]
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  isTemplate: boolean
}

export const FlightGroupLevelItemComponent: FunctionComponent<IProps> = ({
  collapse,
  flightGroupIndex,
  valid,
  mediaPlanField,
  value,
  mediaPlanFields,
  setFunction,
  tokenHandler,
  availableMediaPlanFields,
  onAvailableFieldSelected,
  calculationMode,
  hierarchies,
  updateDataValues,
  masteredListsData,
  collapsedFlightGroups,
  error,
  isTemplate
}) => {
  const id = mediaPlanField.mediaPlanTemplateFieldId || mediaPlanField.mediaPlanVersionFieldId
  const isCollapsed = collapsedFlightGroups.includes(id)
  const defaultClass = `flightGroup__field ${isCollapsed ? '-collapsed' : ''}`

  const gridItemClasses = classNames(
    defaultClass,
    { '-valid': valid },
    { '-aggregated': mediaPlanField.templateFieldTypeId === TemplateFieldTypes.AGGREGATED },
    { '-calculated': mediaPlanField.templateFieldTypeId === TemplateFieldTypes.CALCULATED }
  )

  const render = () => {
    if (id !== 0) {
      return (
        <td className={gridItemClasses}>
          <RenderByTypeComponent
            calculationMode={calculationMode}
            mediaPlanField={mediaPlanField}
            masteredListsData={masteredListsData}
            hierarchies={hierarchies}
            tokenHandler={tokenHandler}
            updateDataValues={updateDataValues}
            valid={valid}
            value={value}
            flightGroupIndex={flightGroupIndex}
            defaultClass={classNames('input-flight-group-value', { 'input-flight-group-value__collapsed': collapse })}
            error={error}
            isTemplate={isTemplate}
            isMasteredHierarchyFieldPickable={true}
          />
        </td>
      )
    } else {
      return (
        <td className={gridItemClasses}>
          <FieldAvailableComponent
            onAvailableFieldSelected={(selectedValue: string) => onAvailableFieldSelected(selectedValue, mediaPlanFields, setFunction, availableMediaPlanFields)}
            availableMediaPlanFields={availableMediaPlanFields}
          />
        </td>
      )
    }
  }
  return (
    <>
      {render()}
    </>
  )
}

export default FlightGroupLevelItemComponent
