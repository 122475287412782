import { batch } from 'react-redux'
import { initialState } from 'Reducers/importReducer'

import * as actionTypes from 'Constants/actionTypes'
import { DataImport } from 'Apis/generated/dataImportsApi'
import * as mediaPlanService from '../../services/mediaPlanService'

interface IGetDataImport {
  type: actionTypes.GET_DATA_IMPORT
  payload: DataImport
}

interface IGetDataImports {
  type: actionTypes.GET_DATA_IMPORTS
  payload: DataImport[]
}

interface ISaveNewImport {
  type: actionTypes.SAVE_NEW_IMPORT
}

interface IUpdateExistingImport {
  type: actionTypes.UPDATE_EXISTING_IMPORT
}

export type IImportActions =
  | IGetDataImport
  | IGetDataImports
  | ISaveNewImport
  | IUpdateExistingImport

export const initialiseImportListContainer = (done, dispatch, currentClientId) => {
  batch(async () => {
    try {
      await dispatch(getDataImports(currentClientId))
      done()
    } catch {
      done('The Import List failed to load. Please try refreshing. If that fails, contact administrator.')
    }
  })
}

export const initialiseCreateImportContainer = (done, dispatch, currentClientId, props) => {
  const { dataImportId } = props
  batch(async () => {
    if (dataImportId) {
      try {
        await dispatch(getDataImport(dataImportId, currentClientId))
      } catch {
        done('Create Import failed to load. Please try refreshing. If that fails, contact administrator.')
      }
    }
    done()
  })
}

export const getDataImports = (clientId: number) => async dispatch => {
  const payload = await mediaPlanService.get(`dataImports?clientId=${clientId}`)
  dispatch({
    type: actionTypes.GET_DATA_IMPORTS,
    payload
  })
  return true
}

export const getDataImport = (importId: number, clientId: number) => async dispatch => {
  const payload = await mediaPlanService.get(`dataImports/${importId}?clientId=${clientId}`)
  dispatch({
    type: actionTypes.GET_DATA_IMPORT,
    payload
  })
  return true
}

export const saveNewImport = async (importObject: DataImport, clientId: number) => {
  await mediaPlanService.postJson(
    `dataImports?clientId=${clientId}`,
    { ...importObject }
  )
}

export const updateExistingImport = async (importObject: DataImport, clientId: number) => {
  await mediaPlanService.putJson(
    `dataImports/${importObject.dataImportId}?clientId=${clientId}`,
    { ...importObject }
  )
}

export const cleanUpImportListContainer = () => dispatch => {
  batch(() => {
    dispatch({
      type: actionTypes.GET_DATA_IMPORTS,
      payload: initialState.dataImports
    })
  })
}


export const cleanUpCreateImportContainer = () => dispatch => {
  batch(() => {
    dispatch({
      type: actionTypes.GET_DATA_IMPORT,
      payload: initialState.currentDataImport
    })
  })
}
