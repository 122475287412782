/* eslint-disable functional/immutable-data */

export const copyArray = <T>(array: T[]): T[] => [...array]

export const push = <T>(array: T[], item: T): T[] => {
  const copy = copyArray<T>(array)

  copy.push(item)

  return copy
}

export const remove = <T>(array: T[], index: number, cb): T[] => {
  const copy = copyArray<T>(array)

  copy.splice(index, 1)

  return cb(copy, index)
}

export const move = <T>(array: T[], from: number, to: number, cb): T[] => {
  const copy = copyArray<T>(array)

  const field = copy[from]
  copy.splice(from, 1)
  copy.splice(to, 0, field)

  return cb(copy, Math.min(from, to))
}

export const insert = <T>(
  array: T[],
  index: number,
  item: T | T[]
) => {
  const copy = copyArray(array)

  copy.splice(index, 0, ...[].concat(item))

  return copy
}

export const update = <T>(array: T[], at: number, item: Partial<T>) => {
  const copy = copyArray<T>(array)

  copy[at] = { ...copy[at], ...item }

  return copy
}
