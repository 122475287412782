import { useMemo } from 'react'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { GetMediaPlanFieldsByLookUpTypeApiResponse } from 'Apis/generated/mediaPlanFieldsByLookUpTypeApi'
import { useGetMediaPlanFieldsByLookUpTypeQuery } from 'Apis/enhancedMediaPlanFieldsByLookUpTypeApi'
import { UseQueryCommand } from '../../../redux/types'

export const useMediaPlanFields = (): UseQueryCommand<GetMediaPlanFieldsByLookUpTypeApiResponse> => {
  const { data: currentClient } = useCurrentClient()

  const { data, isLoading } = useGetMediaPlanFieldsByLookUpTypeQuery(
    { clientId: currentClient.id },
    { skip: !currentClient.id, refetchOnMountOrArgChange: true }
  )

  return useMemo(
    () => ({ data, isLoading }),
    [data, isLoading]
  )
}
