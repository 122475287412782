import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { getFieldColumnName, IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

export interface IPoint {
  flightGroupIndex: number
  flightGroupFieldIndex: number
}

export const createRangeFromPoints = (a: IPoint, b: IPoint = a): [IPoint, IPoint] => {
  const inclusiveFrom: IPoint = {
    flightGroupIndex: Math.min(a.flightGroupIndex, b.flightGroupIndex),
    flightGroupFieldIndex: Math.min(a.flightGroupFieldIndex, b.flightGroupFieldIndex)
  }

  const inclusiveTo: IPoint = {
    flightGroupIndex: Math.max(a.flightGroupIndex, b.flightGroupIndex) + 1,
    flightGroupFieldIndex: Math.max(a.flightGroupFieldIndex, b.flightGroupFieldIndex) + 1
  }

  return [inclusiveFrom, inclusiveTo]
}

export const createFlightGroupSelection = (
  flightGroup: IFlightGroup,
  columnName?: string,
  selectedFlightGroup?: Partial<IFlightGroup>,
  shouldToggleSelection: boolean = false
) => {
  if (!columnName) {
    return shouldToggleSelection && selectedFlightGroup?.hasOwnProperty('mediaPlanFlightGroupId')
      ? undefined
      : flightGroup
  }

  if (!selectedFlightGroup || !shouldToggleSelection) {
    const { [columnName]: columnValue } = flightGroup

    return { [columnName]: columnValue }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [columnName]: value, ...shouldRemain } = selectedFlightGroup

  return {
    ...shouldRemain,
    // include missing and exclude if present
    ...(!selectedFlightGroup.hasOwnProperty(columnName) && { [columnName]: flightGroup[columnName] })
  }
}

export const createFlightGroupRangeSelection = (
  flightGroupsRange: IFlightGroup[],
  flightGroupFieldsRange: IMediaPlanTemplateFields[]
) => {
  return flightGroupsRange.reduce((flightGroupsSelection, currentFlightGroup) => {
    if (!flightGroupFieldsRange.length) {
      return {
        ...flightGroupsSelection,
        [currentFlightGroup.mediaPlanFlightGroupId]: createFlightGroupSelection(currentFlightGroup)
      }
    }

    const currentFlightGroupSelection = flightGroupFieldsRange.reduce((flightGroupSelection, currentFlightGroupField) => {
      return {
        ...flightGroupSelection,
        ...createFlightGroupSelection(
          currentFlightGroup,
          getFieldColumnName(currentFlightGroupField),
          flightGroupSelection
        )
      }
    }, {})

    return {
      ...flightGroupsSelection,
      [currentFlightGroup.mediaPlanFlightGroupId]: currentFlightGroupSelection
    }
  }, {})
}
