import classNames from 'classnames'
import {
  NUMBER_TYPES,
  getClientHierarchyTypeDetails,
  getClientHierarchyValue,
  formatLowerCaseFirstLetter,
  IMSHierarchies,
  getHierarchyTypeDetails,
  getHierarchyValue,
  IClientHierarchy,
  IHierarchyName,
  IClientHierarchyName
} from 'mindshare.layout'
import { sortOrderFlightGroup } from 'Components/MediaPlans/constants/entities/SortFlightGroupField'
import { flightEndDate, flightStartDate } from 'Components/MediaPlanVersion/constants/entities/FlightDates'
import { WizardProgress } from 'Components/GoalSeek/constants/entities/IGoalSeekData'
import { TemplateFieldAvailability } from 'Constants/enums/TemplateFieldAvailability'
import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { flightGroupId } from 'Constants/entities/FlightGroupIdField'
import { NUMBER_FIELD_DATA_TYPES, FieldDataType } from 'Constants/enums/FieldDataType'
import { FieldDataTypeRead } from 'Apis/generated/fieldDataTypesApi'
import { ChosenFlightField, MediaPlanVersionFieldRead, ClientMediaPlanFieldRead, MediaPlanFieldLevelRead, MappingDestinationOptions } from 'Apis/generated/mediaPlanVersionsApi'
import { MediaPlanTemplateField } from 'Apis/generated/templatesApi'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { isFinanceBookingCategoryList, isFinanceBuyingAudienceListType, isFinanceProductHierarchyType, isFinanceStationHierarchyType, isFinanceTargetAudienceListType, isCostBuyingRouteHierarchyType } from 'Components/shared/constants/entities/IFieldMetaData'
import { getFinanceDataForPlansList, getCostMasterDataForPlansList } from 'Services/mediaPlanService'
import { getMasteredFieldVariables } from 'Components/TemplateFieldSelection/hooks/templateMasteredFieldsHelper'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IFieldDataTypeExtended extends Omit<FieldDataTypeRead, 'mappingDestination' | 'isFinance'> {
  mappingDestination?: number | MappingDestinationOptions
  isFinance?: boolean
}
export interface IMediaPlanFieldLevelReadExtended extends Omit<MediaPlanFieldLevelRead, 'defaultValue' | 'fieldDataType'> {
  defaultValue?: string | number
  fieldDataType?: IFieldDataTypeExtended
  isSelected?: boolean
  isVisible?: boolean
  key?: number
}

interface IMediaPlanVersionFieldReadExtended extends Omit<MediaPlanVersionFieldRead, 'defaultValue' | 'clientMediaPlanField'> {
  defaultValue?: string | number
  clientMediaPlanField?: IClientMediaPlanField
  isReadOnly?: boolean
  isExpandedHierarchyField?: boolean
  expandedHierarchyTypeId?: number
  expandedHierarchyLevel?: number
  expandedHierarchyOriginalColumnName?: string
  expandedHierarchyOriginalClientMediaPlanFieldId?: number
}

interface IMediaPlanTemplateFieldExtended extends Omit<MediaPlanTemplateField, 'defaultValue'> {
  defaultValue?: string | number
}

export type IMediaPlanMetaField = IMediaPlanVersionFieldReadExtended &
Partial<IMediaPlanTemplateFieldExtended>

export type IMediaPlanTemplateFields = Omit<IMediaPlanMetaField, 'mediaPlanVersionId'>

export type IMediaPlanVersionField = Omit<IMediaPlanMetaField, 'mediaPlanTemplateId'>

interface IMediaPlanFieldLevelExtended extends MediaPlanFieldLevelRead {
  isSelected?: boolean
  isVisible?: boolean
  key?: number
}
interface IClientMediaPlanFieldExtended extends ClientMediaPlanFieldRead{
  mediaPlanField: IMediaPlanFieldLevelExtended
  columnName?: string
  isVisible?: boolean
  key?: number
}
export interface IMediaPlanTemplateFieldsExtended extends IMediaPlanTemplateFields {
  clientMediaPlanField: IClientMediaPlanFieldExtended
}

export interface IMediaPlanFieldContainerProps {
  error: string
  disabled: boolean
  value: string | number
  onClick: () => void
  mediaPlanField: IMediaPlanTemplateFields
  className?: string
}

// TODO: Why does this build template fields with mediaPlanVersionFieldId?
export const buildMediaPlanTemplateFields = (): IMediaPlanTemplateFields => ({
  mediaPlanTemplateFieldId: 0,
  mediaPlanTemplateId: 0,
  mediaPlanVersionFieldId: 0,
  clientMediaPlanFieldId: 0,
  fieldLabel: '',
  templateFieldAvailabilityId: 0,
  templateFieldTypeId: 0,
  defaultValue: '',
  calculation: '',
  isEditable: false,
  isComplexCalculation: false,
  requiredHierarchyLevelId: 0,
  includeLevelsBelowHierarchy: false,
  isFooter: false,
  sortOrder: -1,
  approvedBy: '',
  approvedOn: '',
  functionId: 0,
  token: ''
})

export const getFieldColumnName = (mediaPlanField: IMediaPlanTemplateFields | undefined) => formatLowerCaseFirstLetter(
  mediaPlanField?.clientMediaPlanField.mediaPlanField.columnName
)

export const getFieldDefaultValue = (mediaPlanField: IMediaPlanTemplateFields) => {
  return mediaPlanField.defaultValue ?? mediaPlanField.clientMediaPlanField.mediaPlanField.defaultValue
}

export const getFieldId = (field: IMediaPlanMetaField) => field.mediaPlanTemplateFieldId || field.mediaPlanVersionFieldId

export const getFieldDataTypeId = (field: IMediaPlanTemplateFields) => field.clientMediaPlanField.mediaPlanField.fieldDataTypeId

export const getFieldDataType = (field: IMediaPlanTemplateFields) => field?.clientMediaPlanField.mediaPlanField.fieldDataType as FieldDataTypeRead

export const getDisplayValue = (mediaPlanTemplateField: IMediaPlanMetaField): string => {
  const clientMediaPlanFieldLabel = mediaPlanTemplateField.clientMediaPlanField?.approvedOn != null
    ? (mediaPlanTemplateField.clientMediaPlanField.fieldLabel ?? mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.fieldLabel)
    : mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.fieldLabel
  return mediaPlanTemplateField.approvedOn != null
    ? (mediaPlanTemplateField.fieldLabel ?? clientMediaPlanFieldLabel)
    : clientMediaPlanFieldLabel
}

const getDefaultValueForFinanceField = async (mediaPlanTemplateField: IMediaPlanMetaField, allMediaPlanTemplateFields: IMediaPlanMetaField[], clientId: number, hierarchies: Partial<IMSHierarchies>) => {
  const { geographyHierarchyId, mediaHierarchyId } = getMasteredFieldVariables({ currentField: mediaPlanTemplateField, fields: allMediaPlanTemplateFields })
  const fieldDataType = mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.fieldDataType as FieldDataTypeRead

  let value: string
  if (geographyHierarchyId && mediaHierarchyId && !mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.defaultValue) {
    const data = await getFinanceDataForPlansList(fieldDataType.fieldDataTypeId, clientId, geographyHierarchyId, mediaHierarchyId)
    if (fieldDataType.dataTypeName.toLowerCase().includes('hierarchy')) {
      const { hierarchyType } = getHierarchyTypeDetails(fieldDataType.dataTypeName, hierarchies)
      value = getHierarchyValue(
        data.financeHierarchies || [],
        hierarchyType as IHierarchyName,
        Number(mediaPlanTemplateField.defaultValue)
      )
    } else if (isFinanceBookingCategoryList(fieldDataType.dataTypeName)) {
      value = data.find((f) => f.financeBookingCategoryId === Number(mediaPlanTemplateField.defaultValue))?.bookingCategoryName
    } else {
      value = data.find((f) => f.financeAudienceId === Number(mediaPlanTemplateField.defaultValue))?.audienceName
    }
  } else {
    value = mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.defaultValue.toString()
  }

  return value
}

const getDefaultValueForCostMasterField = async (mediaPlanTemplateField: IMediaPlanMetaField, allMediaPlanTemplateFields: IMediaPlanMetaField[], clientId: number, hierarchies: Partial<IMSHierarchies>) => {
  const { geographyHierarchyId, mediaHierarchyId } = getMasteredFieldVariables({ currentField: mediaPlanTemplateField, fields: allMediaPlanTemplateFields })
  const fieldDataType = mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.fieldDataType as FieldDataTypeRead

  let value: string
  if (geographyHierarchyId && mediaHierarchyId && !mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.defaultValue) {
    const data = await getCostMasterDataForPlansList(clientId, geographyHierarchyId, mediaHierarchyId)
    const { hierarchyType } = getHierarchyTypeDetails(fieldDataType.dataTypeName, hierarchies)

    value = getHierarchyValue(
      data.hierarchies || [],
      hierarchyType as IHierarchyName,
      Number(mediaPlanTemplateField.defaultValue)
    )
  }

  return value
}

export const changeDefaultValue = async (
  mediaPlanTemplateField: IMediaPlanMetaField,
  hierarchies: Partial<IMSHierarchies>,
  masteredListsData: IMasteredListsData,
  allMediaPlanTemplateFields: IMediaPlanMetaField[],
  clientId: number
): Promise<void> => {
  const { clientCampaigns, clientAgencies, mediaPartners } = masteredListsData
  const fieldDataType = mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.fieldDataType as FieldDataTypeRead

  if (fieldDataType) {
    if (fieldDataType.dataTypeName.toLowerCase().includes('hierarchy')) {
      let hierarchyValue: string
      const isFinanceHierarchyType = isFinanceProductHierarchyType(fieldDataType.dataTypeName) || isFinanceStationHierarchyType(fieldDataType.dataTypeName)
      const isCostMasterHierarchyType = isCostBuyingRouteHierarchyType(fieldDataType.dataTypeName)
      if (isFinanceHierarchyType) {
        hierarchyValue = await getDefaultValueForFinanceField(mediaPlanTemplateField, allMediaPlanTemplateFields, clientId, hierarchies)
      } else if (isCostMasterHierarchyType) {
        hierarchyValue = await getDefaultValueForCostMasterField(mediaPlanTemplateField, allMediaPlanTemplateFields, clientId, hierarchies)
      } else {
        const { hierarchyType, hierarchyList } = getClientHierarchyTypeDetails(fieldDataType.dataTypeName, hierarchies)
        hierarchyValue = getClientHierarchyValue(
          hierarchyList as IClientHierarchy[],
          hierarchyType as IClientHierarchyName,
          Number(mediaPlanTemplateField.defaultValue)
        )
      }
      // eslint-disable-next-line functional/immutable-data
      mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.defaultValue = hierarchyValue
    } else if (fieldDataType.dataTypeName.toLowerCase().includes('client defined list') || fieldDataType.dataTypeName.toLowerCase().includes('unique string')) {
      if (mediaPlanTemplateField.clientMediaPlanField?.clientFieldValues && mediaPlanTemplateField.clientMediaPlanField.clientFieldValues.length > 0) {
        const clientFieldValue = mediaPlanTemplateField.clientMediaPlanField.clientFieldValues
          .find(c => c.clientFieldValueId === Number(mediaPlanTemplateField.defaultValue))
        // eslint-disable-next-line functional/immutable-data
        mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.defaultValue = clientFieldValue ? clientFieldValue.valueDisplayName : mediaPlanTemplateField.defaultValue
      }
    } else if (fieldDataType.dataTypeName?.toLowerCase().includes('media partners')) {
      const value = mediaPartners.find(c => c.clientMediaPartnerId === Number(mediaPlanTemplateField.defaultValue))
      // eslint-disable-next-line functional/immutable-data
      mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.defaultValue = value ? value.mediaPartnerValue : mediaPlanTemplateField.defaultValue
    } else if (fieldDataType.dataTypeName?.toLowerCase().includes('campaign list')) {
      const value = clientCampaigns.find(c => c.clientCampaignId === Number(mediaPlanTemplateField.defaultValue))
      // eslint-disable-next-line functional/immutable-data
      mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.defaultValue = value ? value.clientCampaignName : mediaPlanTemplateField.defaultValue
    } else if (fieldDataType.dataTypeName?.toLowerCase().includes('agency list')) {
      const value = clientAgencies.find(c => c.agencyId === Number(mediaPlanTemplateField.defaultValue))
      // eslint-disable-next-line functional/immutable-data
      mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.defaultValue = value ? value.agencyDisplayName : mediaPlanTemplateField.defaultValue
    } else if (isFinanceTargetAudienceListType(fieldDataType.dataTypeName) || isFinanceBuyingAudienceListType(fieldDataType.dataTypeName) || isFinanceBookingCategoryList(fieldDataType.dataTypeName)) {
      const value = await getDefaultValueForFinanceField(mediaPlanTemplateField, allMediaPlanTemplateFields, clientId, hierarchies)
      // eslint-disable-next-line functional/immutable-data
      mediaPlanTemplateField.clientMediaPlanField.mediaPlanField.defaultValue = value
    }
  }
}

export const isFieldAllowForCalculation = (c: IMediaPlanMetaField) => c.templateFieldAvailabilityId === TemplateFieldAvailability.MANDATORY ||
  c.templateFieldAvailabilityId === TemplateFieldAvailability.DEFAULT ||
  (c.templateFieldAvailabilityId === TemplateFieldAvailability.AVAILABLE &&
    (c.calculation || c.isInPlan))

export const isAggregatedOrCalculated = (c: IMediaPlanMetaField) => c.templateFieldTypeId === TemplateFieldTypes.CALCULATED ||
  c.templateFieldTypeId === TemplateFieldTypes.AGGREGATED

export const findByFieldLevelId = (clientMediaPlanField: IMediaPlanMetaField[], fieldLevelId: number) =>
  clientMediaPlanField.filter(c => c.clientMediaPlanField.mediaPlanField.fieldLevelId === fieldLevelId)

export const isFieldAvailable = (c: IMediaPlanMetaField) => c.templateFieldAvailabilityId === TemplateFieldAvailability.AVAILABLE && !c.isInPlan

export const isFieldDefaultInPlan = (c: IMediaPlanMetaField) => c.templateFieldAvailabilityId === TemplateFieldAvailability.DEFAULT && !c.isInPlan

export const isValidForGoalSeek = (item: IMediaPlanMetaField, mediaPlanVersionFields: any, goalSeekWizardProgress: WizardProgress) => {
  const fieldDataTypeId = item.clientMediaPlanField.mediaPlanField.fieldDataType.fieldDataTypeId
  const itemHasNumberDataType = NUMBER_FIELD_DATA_TYPES.includes(fieldDataTypeId)
  const calculatedOrAggregatedFields = mediaPlanVersionFields.filter(c => isFieldAllowForCalculation(c)).filter(c => isAggregatedOrCalculated(c))
  const itemIsCalculatedOrAggregated = calculatedOrAggregatedFields.find(x => x.clientMediaPlanFieldId === item.clientMediaPlanFieldId)
  if (goalSeekWizardProgress === WizardProgress.SetGoalCell) {
    if (itemHasNumberDataType && itemIsCalculatedOrAggregated) {
      return true
    }
  } else if (goalSeekWizardProgress === WizardProgress.SetTargetCell) {
    if (itemHasNumberDataType && (item.templateFieldTypeId === TemplateFieldTypes.MANUAL)) {
      return true
    }
  }
  return false
}

export const isFieldValidForClick = (item: IMediaPlanMetaField, selectedCalculatedField: IMediaPlanMetaField) => {
  if (item.mediaPlanTemplateFieldId === 0 || item.mediaPlanVersionFieldId === 0) {
    return false
  }

  // same rules apply for complex and simple calculations, other than complex can use any field type covered in isFieldValidForCalculation
  return selectedCalculatedField &&
    ((selectedCalculatedField.templateFieldAvailabilityId === TemplateFieldAvailability.MANDATORY &&
      item.templateFieldAvailabilityId === TemplateFieldAvailability.MANDATORY) ||
      selectedCalculatedField.templateFieldAvailabilityId === TemplateFieldAvailability.AVAILABLE ||
      (selectedCalculatedField.templateFieldAvailabilityId === TemplateFieldAvailability.DEFAULT && item.templateFieldAvailabilityId !== TemplateFieldAvailability.AVAILABLE) ||
      (selectedCalculatedField.templateFieldAvailabilityId === TemplateFieldAvailability.MANDATORY && item.templateFieldAvailabilityId === TemplateFieldAvailability.AVAILABLE)) &&
    (selectedCalculatedField.mediaPlanVersionFieldId !== item.mediaPlanVersionFieldId ||
      selectedCalculatedField.mediaPlanTemplateFieldId !== item.mediaPlanTemplateFieldId) &&
    (isCalculatedAndSameLevelOrParent(selectedCalculatedField, item) || isAggregatedAndBelow(selectedCalculatedField, item))
}

export const isFieldValidForCalculation = (item: IMediaPlanMetaField, selectedCalculatedField: IMediaPlanMetaField) => {
  if (item.mediaPlanTemplateFieldId === 0 || item.mediaPlanVersionFieldId === 0) {
    return false
  }

  if (selectedCalculatedField && Object.keys(selectedCalculatedField).length) {
    if (
      selectedCalculatedField.isComplexCalculation ||
      isIIFCalculation(selectedCalculatedField.calculation)
    ) {
      return true
    }

    const selectedDataTypeName = selectedCalculatedField.clientMediaPlanField.mediaPlanField.fieldDataType.dataTypeName

    if (selectedDataTypeName === 'String') {
      return true
    } else {
      const itemDataTypeName = item.clientMediaPlanField.mediaPlanField.fieldDataType.dataTypeName
      const numberDataTypeNames = NUMBER_TYPES as string[]
      const selectedHasNumberDataType = numberDataTypeNames.includes(selectedDataTypeName)
      const itemHasNumberDataType = numberDataTypeNames.includes(itemDataTypeName)
      return selectedHasNumberDataType === itemHasNumberDataType
    }
  }

  return false
}

const isCalculatedAndSameLevelOrParent = (current: IMediaPlanMetaField, item: IMediaPlanMetaField) => current.templateFieldTypeId === TemplateFieldTypes.CALCULATED &&
  current.clientMediaPlanField.mediaPlanField.fieldLevelId >= item.clientMediaPlanField.mediaPlanField.fieldLevelId &&
  ((item.templateFieldTypeId === TemplateFieldTypes.MANUAL) ||
    (item.templateFieldTypeId === TemplateFieldTypes.CALCULATED && item.calculation !== '' && item.calculation !== null) ||
    (item.templateFieldTypeId === TemplateFieldTypes.AGGREGATED && item.calculation !== '' && item.calculation !== null))

const isAggregatedAndBelow = (current: IMediaPlanMetaField, item: IMediaPlanMetaField) => current.templateFieldTypeId === TemplateFieldTypes.AGGREGATED &&
  current.clientMediaPlanField.mediaPlanField.fieldLevelId + 1 >= item.clientMediaPlanField.mediaPlanField.fieldLevelId &&
  ((item.templateFieldTypeId === TemplateFieldTypes.MANUAL) ||
    (item.templateFieldTypeId === TemplateFieldTypes.CALCULATED && item.calculation !== '' && item.calculation !== null) ||
    (item.templateFieldTypeId === TemplateFieldTypes.AGGREGATED && item.calculation !== '' && item.calculation !== null))

export const getCorrectDisplayField = (mediaPlanField: IMediaPlanMetaField) => mediaPlanField.mediaPlanTemplateFieldId > 0 ? mediaPlanField.displayLabel : mediaPlanField.fieldLabel

export const fieldsInPlanByLevel = (mediaPlanVersionFields: IMediaPlanMetaField[], levelType: FieldLevelType) =>
  findByFieldLevelId(mediaPlanVersionFields.filter(c => (!isFieldAvailable(c) && !isFieldDefaultInPlan(c)) || c.isInPlan), levelType)

export const availableFieldsByLevel = (mediaPlanVersionFields: IMediaPlanMetaField[], levelType: FieldLevelType) =>
  findByFieldLevelId(mediaPlanVersionFields.filter(c => (isFieldAvailable(c)) || isFieldDefaultInPlan(c)), levelType)

export const availableFlightAndSubFlights = (mediaPlanVersionFields: IMediaPlanMetaField[], chosenFlightFields: ChosenFlightField[]) => mediaPlanVersionFields.filter(m => !chosenFlightFields.some(c =>
  m.clientMediaPlanFieldId === c.clientMediaPlanFieldId) &&
  isFieldAvailableOrDefault(m) &&
  isFlightOrSubFlight(m)
)

export const hasAvailableFields = (mediaPlanVersionFields: IMediaPlanMetaField[], levelType: FieldLevelType, chosenFlightFields?: ChosenFlightField[]) => {
  if (levelType === FieldLevelType.FLIGHT) {
    return availableFlightAndSubFlights(mediaPlanVersionFields, chosenFlightFields).length > 0
  } else {
    return availableFieldsByLevel(mediaPlanVersionFields, levelType).length > 0
  }
}

export const isFieldAvailableOrDefault = (mediaPlanField: IMediaPlanMetaField) =>
  mediaPlanField.templateFieldAvailabilityId === TemplateFieldAvailability.DEFAULT ||
  mediaPlanField.templateFieldAvailabilityId === TemplateFieldAvailability.AVAILABLE

export const isFieldReadOnly = (mediaPlanField: IMediaPlanMetaField) => !!mediaPlanField.isReadOnly

export const isFlightOrSubFlight = (m: IMediaPlanMetaField) => (
  m.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT ||
  m.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.SUB_FLIGHT
)

export const getCssClassByInputType = (mediaPlanField: IMediaPlanMetaField) => classNames(
  { 'fieldtype-mandatory': mediaPlanField.templateFieldAvailabilityId === TemplateFieldAvailability.MANDATORY },
  { 'fieldtype-available': mediaPlanField.templateFieldAvailabilityId === TemplateFieldAvailability.AVAILABLE }
)

export const getSortedFlightGroupFields = (mediaPlanVersionFields: IMediaPlanMetaField[]) => {
  const flightGroupFields = fieldsInPlanByLevel(mediaPlanVersionFields, FieldLevelType.FLIGHT_GROUP)
  return flightGroupFields && [...flightGroupFields, sortOrderFlightGroup, flightGroupId].sort((a, b) => a.sortOrder - b.sortOrder)
}

export const getSortedFlightFields = (mediaPlanVersionFields: IMediaPlanMetaField[]) => {
  const flightFields = fieldsInPlanByLevel(mediaPlanVersionFields, FieldLevelType.FLIGHT)
  return flightFields && [...flightFields, flightStartDate, flightEndDate].sort((a, b) => a.sortOrder - b.sortOrder)
}

export const getApportionedFlightFields = (mediaPlanVersionFields: IMediaPlanMetaField[]) => {
  const flightFields = fieldsInPlanByLevel(mediaPlanVersionFields, FieldLevelType.FLIGHT)
  return flightFields.filter(flight => flight.clientMediaPlanField.isApportioned)
}

export const getLookupFlightGroupFields = (mediaPlanVersionFields: IMediaPlanMetaField[]) => {
  const flightGroupFields = fieldsInPlanByLevel(mediaPlanVersionFields, FieldLevelType.FLIGHT_GROUP)

  return flightGroupFields.filter(({ clientMediaPlanField }) => {
    const typeIsMediaPartner = clientMediaPlanField.mediaPlanField.fieldDataTypeId === FieldDataType.MEDIA_PARTNERS
    const typeIsHierarchy = clientMediaPlanField.mediaPlanField.fieldDataType.isHierarchy

    return clientMediaPlanField.isApportioned || typeIsMediaPartner || typeIsHierarchy
  })
}

export const filterFlightFieldsById = (flightFields: IMediaPlanMetaField[], id: number) =>
  flightFields.filter((flight) => flight.clientMediaPlanFieldId !== id)

const isIIFCalculation = (fieldCalculation: string) => fieldCalculation?.toLowerCase().includes('iif')

export const getChosenFields = (flightFields: ChosenFlightField[], mediaPlanVersionFields: IMediaPlanMetaField[]) => {
  return mediaPlanVersionFields.filter((x) => {
    return flightFields?.some(y => y.clientMediaPlanFieldId === x.clientMediaPlanFieldId)
  })
}

export const getChosenFlightFields = (fields: ChosenFlightField[], mediaPlanVersionFields: IMediaPlanMetaField[]) => {
  const flightFields = findByFieldLevelId(mediaPlanVersionFields, FieldLevelType.FLIGHT)
  const chosenFlightFields = getChosenFields(fields, flightFields)

  return chosenFlightFields
}

export const getChosenSubFlightFields = (fields: ChosenFlightField[], mediaPlanVersionFields: IMediaPlanMetaField[]) => {
  const subFlightFields = findByFieldLevelId(mediaPlanVersionFields, FieldLevelType.SUB_FLIGHT)
  const chosenSubFlightFields = getChosenFields(fields, subFlightFields)

  return chosenSubFlightFields
}

export const getFilteredChosenFlightFields = (chosenFields: IMediaPlanMetaField[], isCollapsed: boolean, collapsedViewField: string): IMediaPlanMetaField[] =>
  chosenFields
    .filter(c =>
      (isCollapsed && collapsedViewField)
        ? c.clientMediaPlanField.mediaPlanField.columnName ===
      collapsedViewField
        : c
    )

export const isAggregated = (c: IMediaPlanMetaField) => c.templateFieldTypeId === TemplateFieldTypes.AGGREGATED
