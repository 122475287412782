const text = `# Create/Edit Template

A template is required to start a new media plan. The tool will copy down all data input for the template and create the plan from this. This means that once a plan has been created, any changes to the template will have no effect on it. It will only have an impact on plans created from the updated template.

------



## Configuring fields

Template creation separates out the fields you want in the template into a tab per field level.

**Field name** - drop down list of all client fields selected in the client setup (filtered by field level within each tab). Field names will appear with their client alias value if it has been approved. If it has not been approved, it will appear with the generic field name. If this field is then selected the field name will remain as the generic name in al plans using this template.

**Alias** - if template aliasing is enabled in the client setup then fields can be given an alias here. This alias will only appear in plans once it has approved. Any plan that is created before the alias has been approved will not display the alias name. An approval status icon is displayed next an alias to denote whether it is approved or not.

**Field type** - there are 3 options for field type and each come with rules. Rules to consider for field type:

- *Manual*
  - User manually types in a value. Only restriction is the value must be the correct data type e.g. a number

- *Calculated*
  - Field is calculated from other fields.
    - The other fields must be at the same or a higher level than the field being calculated
    - The other fields must be of the same or a stricter availability than the field being calculated

- *Aggregated*
  - Field is a sum of another field
    - Field being summed must be one level below the aggregated field
    - The other field must be of the same or a stricter availability than the field being calculated

**Set value** - you can set a default value for any chosen field. This means any plan created using this template will automatically populate this field with this value.

**Availability** - when you first open the create template page any fields that are mandatory at the client or system level (e.g. plan name) will appear and cannot be removed from the template.

There are 3 options for availability:

- *Mandatory*
  - Field will appear in a plan when it is created from this template
  - Field must be filled in to be saved as the latest version

- *Default*
  - Field will appear in a plan when it is created from this template
  - Field can be removed from plan
  - Plan can be saved as latest version without values filled in for this field

- *Available*
  - Field will not appear in a plan when it is created from this template but can be added by a user
  - Once added, the field can be removed
  - Plan can be saved as latest version without values filled in for this field

**Editable** - you can choose whether a field is editable by the user or not. If a field is not editable then it must either have a calculation/aggregation input or a default value.

**Add new field** - add a new field to the current level at the bottom of the list

**Remove field** - remove the chosen field from the template. You cannot remove a field that is set as mandatory at the client level.

**Sort** - click the sort icon and drag the field to the position you want it to be in.

------



## Back to Template list

You will lose any work not saved and be taken back to the list of templates for the current client.

------



## Save

To save a template you must have:

- Template name
- At least 1 flight group field
- At least 1 flight field

Saving the template will take you to the template example page where you can input calculations and aggregations.`

export default text
