import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useMindshareSelector } from 'mindshare.customhooks'
import { MSLayout, appHistory, MSRoutesComponent, navigate } from 'mindshare.layout'
import { authenticateApp, msAuthHandler } from '@mindshare/services'

import menuItems from 'Constants/menuItems'
import { Client } from 'Apis/generated/clientsApi'
import { agencyStyleCfg } from 'Constants/agencyStyleCfg'
import { getClients } from 'Actions/appActions'
import { RootState } from 'Reducers/index'
import { useDefaultClient, ClientSelectionContainer } from 'Containers/ClientSelectionContainer'
import { authoriseMediaPlanningTool, renderSideNavMenuItemsBasedOnClaims, setColours } from 'Helpers/initialisation'
import { appRoutes } from './providers/AppRoutes'
import { useAppDispatch } from './store'
import { useUserPermissions, initialPermissions } from 'Hooks/useUserPermissions'

Spin.setDefaultIndicator(<LoadingOutlined />)

const App = () => {
  const dispatch = useAppDispatch()
  const { getUserPermissions, data: userPermissions = initialPermissions } = useUserPermissions()
  const currentClient = useMindshareSelector((state: RootState) => state.app.currentClient) as Client
  const [agencyColours, setAgencyColours] = useState(setColours(currentClient))
  const [sideNav, setSideNav] = useState(menuItems)
  const [appReady, setAppReady] = useState(false)
  const [appInitialised, setAppInitialised] = useState(false)

  useEffect(() => {
    (async function prepareApp () {
      try {
        if (await authenticateApp()) {
          const { userAuthorised } = await authoriseMediaPlanningTool()
          if (userAuthorised) {
            getUserPermissions({ clientName: currentClient.name })

            const user = await msAuthHandler.getUser()
            if (user) {
              await dispatch(getClients())
            }
            setAppReady(true)
          }
        }
      } catch (err) {
        throw new Error(`Error initialising app: ${err}`)
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient.name, dispatch])

  useDefaultClient()

  useEffect(() => {
    if (currentClient.id > 0) {
      const color = setColours(currentClient)
      setAgencyColours(color)
      renderSideNavMenuItemsBasedOnClaims(setSideNav, appHistory.location.pathname, userPermissions, navigate, currentClient.mptReportUrl, currentClient.name)
      setAppInitialised(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient.id, userPermissions])

  return (
    <MSLayout
      appReady={appReady}
      appName={currentClient.agency?.mediaPlanningToolName || 'Media Activity Plan'}
      appDataSuccess={appInitialised}
      wavemakerOsEnabled={currentClient.isWavemakerOsEnabled}
      menuItems={sideNav}
      clientName={currentClient.displayName}
      clientLogoUrl={currentClient.logo}
      agencyLogoUrl={currentClient.agency?.agencyLogoLocation}
      agencyFavIconUrl={currentClient.agency?.agencyFavIconLocation}
      agencyStyleCfg={agencyStyleCfg}
      agencyColours={agencyColours}
      fullWidth={true}
    >
      <ClientSelectionContainer />
      <MSRoutesComponent routes={appRoutes} />
    </MSLayout>
  )
}

export default App
