import React, { useState, useRef, memo, useCallback, useEffect } from 'react'
import { Dropdown } from 'antd'
import { IMSHierarchies } from 'mindshare.layout'
import FieldContainer from 'Containers/MediaPlanVersion/FieldTypeSwitchContainer'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import FlightCellContextMenuComponent from '../FlightCellContextMenuComponent'
import { IMediaPlanVersionLinkedLookupHelperValues } from './entities/IMediaPlanVersionLinkedLookupHelperValues'
import { useAppSelector } from '../../store'
import { IFlight } from './constants/entities/IFlight'
import { MediaPlanSubFlight } from 'Apis/generated/mediaPlanVersionsApi'
import { IMediaPlanVersionMasteredFieldsHelperValues, IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { SubtotalsFlightFieldContainer } from 'Containers/MediaPlanVersion/SubtotalsFlightFieldContainer'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  valid: boolean
  merge: boolean
  flightGroupIndex: number
  subFlightIndex?: number
  flightIndex: number
  planStartDate: string
  planEndDate: string
  calculationMode: boolean
  masteredListsData: IMasteredListsData
  mediaPlanField: IMediaPlanTemplateFields
  unfilteredHierarchies: IMSHierarchies
  startDate: string
  endDate: string
  tokenHandler: (token: string) => void
  deleteFlight: (flightGroupIndex: number, flightIndex: number, subFlightIndex: number) => void
  clearFlightData?: (flightGroupIndex: number, flightIndex: number) => void
  copySelectedFlight: () => void
  openFlightPasteModalWithStartDate: (date: string) => void
  mergeFlightSelection: (startDate: Date, enDate: Date, mergeByWeek: boolean, flightGroupIndex: number, flightIndex: number, mergeByMonth: boolean, clientMediaPlanFieldId?: number) => void
  canCopy: boolean
  canPaste: boolean
  firstSelectedFlight?: IFlight | MediaPlanSubFlight
  lastSelectedFlight?: IFlight | MediaPlanSubFlight
  isCellWithinSelectedRange: boolean
  linkedLookupFieldsHelperValues?: IMediaPlanVersionLinkedLookupHelperValues
  isCollapsed?: boolean
  masteredDataHelperValues?: IMediaPlanVersionMasteredFieldsHelperValues
  isSubtotalGroup?: boolean
  subtotalFieldValue?: string
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
}

export const FlightCellComponent = (props: IProps) => {
  const flightPasteModalStartDate = useAppSelector(state => state.mediaPlans.flightPasteModalStartDate)
  const {
    valid,
    merge,
    masteredListsData,
    mediaPlanField,
    calculationMode,
    unfilteredHierarchies,
    tokenHandler,
    planStartDate,
    planEndDate,
    deleteFlight,
    clearFlightData,
    copySelectedFlight,
    openFlightPasteModalWithStartDate,
    startDate,
    endDate,
    mergeFlightSelection,
    flightGroupIndex,
    flightIndex,
    subFlightIndex,
    canCopy,
    canPaste,
    firstSelectedFlight,
    lastSelectedFlight,
    isCellWithinSelectedRange,
    linkedLookupFieldsHelperValues,
    isCollapsed,
    masteredDataHelperValues,
    isSubtotalGroup,
    subtotalFieldValue,
    financeListFieldsData
  } = props

  const [visible, setVisible] = useState(false)
  const flightCellComponent = useRef<HTMLDivElement>(null)
  const handleVisibleChange = (flag: boolean) => setVisible(flag)
  const fieldId = mediaPlanField.mediaPlanTemplateFieldId || mediaPlanField.mediaPlanVersionFieldId

  const handlePasteClick = useCallback(() => {
    openFlightPasteModalWithStartDate(startDate)
  }, [openFlightPasteModalWithStartDate, startDate])

  useEffect(() => {
    if (typeof flightPasteModalStartDate === 'string') {
      setVisible(false)
    }
  }, [flightPasteModalStartDate])

  const contextMenuComponent = (
    <FlightCellContextMenuComponent
      merge={merge}
      clientMediaPlanFieldId={mediaPlanField.clientMediaPlanFieldId}
      deleteFlight={deleteFlight}
      clearFlightData={clearFlightData}
      copySelectedFlight={copySelectedFlight}
      handlePasteClick={handlePasteClick}
      planEndDate={planEndDate}
      startDate={
        isCellWithinSelectedRange && firstSelectedFlight
          ? (
            ('flightStartDate' in firstSelectedFlight && firstSelectedFlight?.flightStartDate) ||
            ('subFlightStartDate' in firstSelectedFlight && firstSelectedFlight?.subFlightStartDate) ||
            startDate
          )
          : startDate
      }
      endDate={
        isCellWithinSelectedRange && lastSelectedFlight
          ? (
            ('flightEndDate' in lastSelectedFlight && lastSelectedFlight?.flightEndDate) ||
            ('subFlightEndDate' in lastSelectedFlight && lastSelectedFlight?.subFlightEndDate) ||
            endDate
          )
          : endDate
      }
      planStartDate={planStartDate}
      setVisible={setVisible}
      flightGroupIndex={flightGroupIndex}
      flightIndex={flightIndex}
      subFlightIndex={subFlightIndex}
      mergeFlightSelection={mergeFlightSelection}
      canCopy={canCopy}
      canPaste={canPaste}
    />
  )

  return (
    <Dropdown
      overlay={contextMenuComponent}
      trigger={['contextMenu']}
      onVisibleChange={handleVisibleChange}
      visible={visible}
    >
      <div
        className={`${merge ? 'merge' : ''}`}
        data-testid='flight-values'
        ref={flightCellComponent}
      >
        {isSubtotalGroup ? (
          <SubtotalsFlightFieldContainer
            flightField={mediaPlanField}
            value={subtotalFieldValue}
            fieldId={fieldId}
            key={`${flightGroupIndex} - ${flightIndex}`}
          />
        ) : (
          <FieldContainer
            valid={valid}
            masteredListsData={masteredListsData}
            calculationMode={calculationMode}
            unfilteredHierarchies={unfilteredHierarchies}
            field={mediaPlanField}
            tokenHandler={tokenHandler}
            key={`${flightGroupIndex} - ${flightIndex}`}
            flightIndex={flightIndex}
            startDate={startDate}
            endDate={endDate}
            flightGroupIndex={flightGroupIndex}
            subFlightIndex={subFlightIndex}
            merge={merge}
            linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
            isCollapsed={isCollapsed}
            masteredDataHelperValues={masteredDataHelperValues}
            financeListFieldsData={financeListFieldsData}
          />
        )}
      </div>
    </Dropdown>
  )
}

export default memo(FlightCellComponent)
