import React, { FC } from 'react'
import { Col, Row } from 'antd'
import {
  LoadingComponent,
  MSHierarchyDropdown,
  MSLayoutContentRow,
  MSTabPane,
  MSTabs
} from 'mindshare.layout'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import {
  createCustomExportFromResponse,
  createRunCustomExportDto
} from 'Components/CustomExport/dtos'
import { oneOf } from 'Helpers/conditionsHelper'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useHierarchies } from 'Hooks/useHierarchies'
import { useMasteredListFieldsData } from 'Hooks/useMasteredListFieldsData'
import { CustomExportFilterFields, CustomExportNameInput } from '../components'
import {
  useCurrentCustomExport,
  useCustomExportFields,
  useCustomExportModel,
  useFieldOperators,
  useLatestFlags,
  useRunCustomExport
} from '../hooks'

interface IProps {
  customExportId: number
}

const RunCustomExportContainer: FC<IProps> = ({ customExportId }) => {
  const { data: currentClient } = useCurrentClient()
  const { data: hierarchies } = useHierarchies(currentClient?.id)
  const masteredListsData = useMasteredListFieldsData(currentClient?.id)
  const { isMasteredListsDataLoading } = masteredListsData

  const { data: currentCustomExport, isLoading: isCustomExportLoading } = useCurrentCustomExport({ id: customExportId })
  const { data: customExportFields, isLoading: areCustomExportFieldsLoading } = useCustomExportFields()
  const { data: latestFlags = [], isLoading: areLatestFlagsLoading } = useLatestFlags()
  const { data: operators = [], isLoading: areOperatorsLoading } = useFieldOperators()

  const { execute: runCustomExport, isLoading: isRunningCustomExport } = useRunCustomExport()

  const customExportModel = useCustomExportModel({
    getInitialValues: () => createCustomExportFromResponse(currentCustomExport),
    skip: !currentCustomExport
  })

  const handleRunCustomExports = async () => {
    try {
      await runCustomExport({
        customExportId: customExportModel.values.customExportId,
        customExportRun: createRunCustomExportDto(customExportModel.values),
        exportName: customExportModel.values.customExportName
      })
    } catch {
      /* noop */
    }
  }

  return oneOf(
    isCustomExportLoading,
    isMasteredListsDataLoading,
    areCustomExportFieldsLoading,
    areLatestFlagsLoading,
    areOperatorsLoading
  ) ? (
      <LoadingComponent
        appDataSuccess={!!currentClient.id}
        agencyLoadingGif={currentClient.agency?.agencyLoadingGifLocation}
      />
    ) : (
      <div>
        <TitleAndButtonsComponent
          title="Run Custom Export"
          buttons={[{
            disable: isRunningCustomExport,
            buttonClick: handleRunCustomExports,
            buttonText: 'Run'
          }]}
        />
        <MSLayoutContentRow extraClass='custom-export-create-title'>
          <Row gutter={8}>
            <Col span={6}>
              <CustomExportNameInput
                disabled
                value={customExportModel.values.customExportName}
                onChange={customExportModel.changeName}
              />
            </Col>
            <Col span={6}>
              <MSHierarchyDropdown
                disabled
                label='Geography'
                id='clientGeographyHierarchyId'
                className='custom-export-geography-search-filter'
                treeData={hierarchies.clientGeographyHierarchies}
                idColumn='clientGeographyHierarchyId'
                valueColumn='geographyHierarchyValue'
                setFieldValue={() => {}}
                selectedValue={customExportModel.values.geographyHierarchyId}
                placeholder='Select Geography'
                data-testid='custom-export-geography-search-filter'
                hierarchyName='geography'
              />
            </Col>
            <Col span={12} />
          </Row>
        </MSLayoutContentRow>
        <MSLayoutContentRow>
          <MSTabs destroyInactiveTabPane>
            <MSTabPane tab="Filters" key='filters'>
              <CustomExportFilterFields
                customExport={customExportModel}
                customExportFields={customExportFields}
                latestFlags={latestFlags}
                operators={operators}
                hierarchies={hierarchies}
                masteredListsData={masteredListsData}
                isRunCustomExport
              />
            </MSTabPane>
          </MSTabs>
        </MSLayoutContentRow>
      </div>
    )
}

export default RunCustomExportContainer
