import { baseApi as api } from '../apis/generated/fieldDataTypesApi'
import { queryCacheValidTime } from './queryCacheValidTime'

const enhancedRtkApi = api.enhanceEndpoints({
  endpoints: {
    getFieldDataTypes: { keepUnusedDataFor: queryCacheValidTime.getFieldDataTypes }
  }
})
export { enhancedRtkApi as baseApi }

export const {
  useGetFieldDataTypesQuery,
  useLazyGetFieldDataTypesQuery
} = enhancedRtkApi
