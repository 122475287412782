import { FieldDataType } from 'Constants/enums/FieldDataType'
import { isFieldOfSameOrHigherLevel } from 'Components/LinkedLookup/helpers/fieldHelper'

export const validateMasteredFields = ({ templateFieldRows }): boolean => {
  const masteredFields = templateFieldRows.filter((item) => item.mediaPlanField?.fieldDataType?.fieldDataTypeId === FieldDataType.FINANCE_BOOKING_CATEGORY_LIST ||
    item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.FINANCE_BUYING_AUDIENCE_LIST ||
    item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.FINANCE_TARGET_AUDIENCE_LIST ||
    item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.FINANCE_PRODUCT_HIERARCHY ||
    item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.FINANCE_STATION_HIERARCHY ||
    item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.COST_BUYING_ROUTE_HIERARCHY)

  const geographyHierarchyFields = templateFieldRows.filter(
    item =>
      item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.GEOGRAPHY_HIERARCHY
  )

  const mediaHierarchyFields = templateFieldRows.filter(
    item =>
      item.mediaPlanField.fieldDataType?.fieldDataTypeId === FieldDataType.MEDIA_HIERARCHY
  )

  const isMasteredFieldInValid = masteredFields.some((item) => {
    const isGeographySelectionValid = !!(geographyHierarchyFields.length && geographyHierarchyFields.some((field) => isFieldOfSameOrHigherLevel(field, item)))
    const isMediaSelectionValid = !!(mediaHierarchyFields.length && mediaHierarchyFields.some((field) => isFieldOfSameOrHigherLevel(field, item)))
    return !isGeographySelectionValid || !isMediaSelectionValid
  })

  return !isMasteredFieldInValid
}
