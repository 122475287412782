import React, { FunctionComponent, useCallback } from 'react'
import FlightModalOptionsComponent from './FlightModalOptionsComponent'
import { Menu } from 'antd'
import moment from 'moment'
import { dateFormat } from 'Helpers/calendarHelper'

interface IProps {
  merge: boolean
  startDate: string
  endDate: string
  flightIndex: number
  subFlightIndex: number
  flightGroupIndex: number
  clientMediaPlanFieldId: number
  planStartDate: string
  planEndDate: string
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  deleteFlight: (flightGroupIndex: number, flightIndex: number, subFlightIndex: number) => void
  clearFlightData: (flightGroupIndex: number, flightIndex: number) => void
  copySelectedFlight: () => void
  handlePasteClick: () => void
  mergeFlightSelection: (startDate: Date, enDate: Date, mergeByWeek: boolean, flightGroupIndex: number, flightIndex: number, mergeByMonth: boolean, clientMediaPlanFieldId?: number) => void
  canCopy: boolean
  canPaste: boolean
}

export const FlightCellContextMenuComponent: FunctionComponent<IProps> = (props: IProps) => {
  const {
    merge,
    endDate,
    startDate,
    flightIndex,
    planStartDate,
    planEndDate,
    setVisible,
    subFlightIndex,
    deleteFlight,
    clearFlightData,
    copySelectedFlight,
    handlePasteClick,
    mergeFlightSelection,
    flightGroupIndex,
    clientMediaPlanFieldId,
    canCopy,
    canPaste
  } = props

  const menuOnClick = useCallback((params: any) => {
    const { domEvent } = params
    const element = (domEvent.target as HTMLElement)

    if ([element.nodeName.toLowerCase(), element.parentNode.nodeName.toLowerCase()].includes('button') && (['Merge Cells', 'Delete', 'Clear Data', 'Copy', 'Paste'].includes(element.textContent))) {
      setVisible(false)
    }
    domEvent.stopPropagation()
  }, [setVisible])

  const handleMergeFlightSelection = (selectedStartDate: Date, selectedEndDate: Date, mergeByWeek: boolean, mergeByMonth: boolean) =>
    mergeFlightSelection(
      selectedStartDate,
      selectedEndDate,
      mergeByWeek,
      flightGroupIndex,
      flightIndex,
      mergeByMonth,
      clientMediaPlanFieldId
    )

  return (
    <Menu style={{ backgroundColor: 'transparent' }}>
      <Menu.Item key='1' onClick={menuOnClick} style={{ height: 'auto', lineHeight: '27px', backgroundColor: 'transparent' }}>
        <FlightModalOptionsComponent
          planStartDate={planStartDate}
          planEndDate={planEndDate}
          selectedFlights={[]}
          deleteFlight={() => deleteFlight(flightGroupIndex, flightIndex, subFlightIndex)}
          clearFlightData={() => clearFlightData(flightGroupIndex, flightIndex)}
          selectedDates={[moment(startDate).format(dateFormat), moment(endDate).format(dateFormat)]}
          mergeFlightSelection={handleMergeFlightSelection}
          isMergeCell={merge}
          isSubFlightCell={!!(subFlightIndex !== undefined && subFlightIndex >= 0)}
          copyFlight={copySelectedFlight}
          showCopyPasteButtons
          canCopy={canCopy}
          canPaste={canPaste}
          handlePasteClick={handlePasteClick}
        />
      </Menu.Item>
    </Menu>
  )
}

export default FlightCellContextMenuComponent
