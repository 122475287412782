import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

export const flightGroupId: IMediaPlanTemplateFields = {
  mediaPlanVersionFieldId: -Number.MAX_SAFE_INTEGER,
  mediaPlanTemplateId: 0,
  isInPlan: true,
  clientMediaPlanFieldId: -Number.MAX_SAFE_INTEGER,
  fieldLabel: 'Flight Group ID',
  templateFieldAvailabilityId: 10,
  templateFieldTypeId: 0,
  functionId: null,
  defaultValue: '',
  calculation: null,
  isComplexCalculation: false,
  requiredHierarchyLevelId: 0,
  includeLevelsBelowHierarchy: false,
  isEditable: true,
  isFooter: false,
  sortOrder: -1000000,
  approvedBy: null,
  approvedOn: null,
  token: '{FlightGroups.mediaPlanFlightGroupId}',
  clientMediaPlanField: {
    clientMediaPlanFieldId: -Number.MAX_SAFE_INTEGER,
    mediaPlanFieldLevelId: 521,
    mediaPlanFieldId: 0,
    fieldLabel: 'FlightGroupId',
    isMandatory: false,
    isDisabled: true,
    sortOrder: 1330,
    isApportionmentField: false,
    isApportioned: false,
    approvedOn: null,
    columnName: '',
    isVisible: true,
    key: 0,
    mediaPlanField: {
      mediaPlanFieldLevelId: -Number.MAX_SAFE_INTEGER,
      mediaPlanFieldId: -Number.MAX_SAFE_INTEGER,
      fieldLabel: 'mediaPlanFlightGroupId',
      fieldDataTypeId: 10,
      columnName: 'mediaPlanFlightGroupId',
      fieldLevelId: 2,
      fieldDescription: 'mediaPlanFlightGroupId',
      isMandatory: false,
      sortOrder: 1410,
      defaultValue: '',
      isApportionable: false,
      isDefaultApportioned: false,
      isDefaultApportionmentField: false,
      isSelected: false,
      isVisible: false,
      key: 0,
      fieldDataType: {
        fieldDataTypeId: 10,
        dataTypeName: 'Integer',
        dataType: 'BIGINT',
        label: 'An integer',
        isHierarchy: false
      },
      fieldLevel: {
        fieldLevelId: 2,
        fieldLevelName: 'FlightGroup'
      },
      fieldTags: []
    },
    clientFieldValues: []
  }
}
