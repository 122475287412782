import { isFieldOfSameOrHigherLevel, getFieldLevel } from 'Components/LinkedLookup/helpers/fieldHelper'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { ITemplateMasteredFieldHelperValues } from 'Components/TemplateFieldSelection/constants/entities/ITemplateMasteredFieldsHelperValues'

export const getMasteredFieldVariables = ({ currentField, fields }): ITemplateMasteredFieldHelperValues => {
  const geographyHierarchyFields = fields.filter(
    item =>
      (item.mediaPlanField?.fieldDataType?.fieldDataTypeId === FieldDataType.GEOGRAPHY_HIERARCHY ||
      item.clientMediaPlanField?.mediaPlanField?.fieldDataType?.fieldDataTypeId === FieldDataType.GEOGRAPHY_HIERARCHY) &&
      isFieldOfSameOrHigherLevel(item, currentField)
  ).sort((f1, f2) => getFieldLevel(f2) - getFieldLevel(f1))

  const geographyHierarchyField = geographyHierarchyFields.find(item => item.defaultValue)

  const mediaHierarchyFields = fields.filter(
    item =>
      (item.mediaPlanField?.fieldDataType?.fieldDataTypeId === FieldDataType.MEDIA_HIERARCHY ||
      item.clientMediaPlanField?.mediaPlanField?.fieldDataType?.fieldDataTypeId === FieldDataType.MEDIA_HIERARCHY) &&
      isFieldOfSameOrHigherLevel(item, currentField)
  ).sort((f1, f2) => getFieldLevel(f2) - getFieldLevel(f1))

  const mediaHierarchyField = mediaHierarchyFields.find(item => item.defaultValue)

  const isFieldInvalid = currentField && (!geographyHierarchyFields.length || !mediaHierarchyFields.length)
  const isFieldDisabled = currentField && ((!geographyHierarchyFields.length || !mediaHierarchyFields.length) || (!geographyHierarchyField?.defaultValue || !mediaHierarchyField?.defaultValue))

  return {
    isFieldInvalid,
    geographyHierarchyId: geographyHierarchyField?.defaultValue,
    mediaHierarchyId: mediaHierarchyField?.defaultValue,
    isFieldDisabled
  }
}
