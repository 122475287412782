import React from 'react'
import { List, Typography } from 'antd'
import { ITemplateSyncChildTemplate, ITemplateSyncOption, ITemplateSyncOptionPerField } from './TemplateSyncModalComponent'

const { Paragraph } = Typography

interface ITemplateSyncSummaryStepComponentProps {
  selectedClientTemplatesList: ITemplateSyncChildTemplate[]
  selectedLinkedTemplatesList: ITemplateSyncChildTemplate[]
  selectedTemplateOptions: ITemplateSyncOption[]
  selectedTemplateOptionsPerFields: {
    [key: number]: ITemplateSyncOptionPerField[]
  }
}

const TemplateSyncSummaryStepComponent: React.FC<ITemplateSyncSummaryStepComponentProps> = ({
  selectedClientTemplatesList,
  selectedLinkedTemplatesList,
  selectedTemplateOptions,
  selectedTemplateOptionsPerFields
}) => (
  <>
    <Paragraph>Selected clients & templates</Paragraph>
    <div className='template-sync__list-wrapper'>
      <List
        bordered={true}
        dataSource={[...selectedLinkedTemplatesList, ...selectedClientTemplatesList]}
        renderItem={(item) => (
          <List.Item key={`${item.clientId}-${item.mediaPlanTemplateId}`}>
            <div className='template-sync__list-item'>{item.clientDisplayName}</div>
            <div className='template-sync__list-item -align-right'>{item.mediaPlanTemplateDisplayName}</div>
          </List.Item>
        )}
      />
    </div>
    <Paragraph style={{ marginTop: '1em' }}>Changes to push</Paragraph>
    <div className='template-sync__list-wrapper'>
      <List
        bordered={true}
        className='template-sync__summary-options'
        dataSource={selectedTemplateOptions}
        renderItem={(item) => (
          <List.Item key={item.templateSyncOptionId}>
            <div>{item.templateSyncOptionName}</div>
            {selectedTemplateOptionsPerFields[item.templateSyncOptionId]?.map(field => (
              <div className='template-sync__summary-options__field-name' key={field.mediaPlanFieldLevelId}>{field.fieldLabel}</div>
            ))}
          </List.Item>
        )}
      />
    </div>
  </>
)

export default React.memo(TemplateSyncSummaryStepComponent)
